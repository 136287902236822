import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { withRouter } from "react-router-dom";
import PaginationSystem from "../../components/Pagination/PaginationSystem";
import CustomDropdown from "../../components/UI/CustomDropdown/CustomDropdown";
import Label from "../../components/UI/Label/Label";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { adminSubscribersSteps } from "../Steps";
import "./AdminSubscriber.scss";

const dropdownItem = [
  {
    id: "1",
    value: "Pending",
  },
  {
    id: "2",
    value: "Pending 1",
  },
  {
    id: "3",
    value: "Pending 3",
  },

  {
    id: "4",
    value: "Pending 4",
  },
  {
    id: "5",
    value: "Pending 5",
  },
  {
    id: "6",
    value: "Pending 6",
  },
];

function AdminSubscriberPage() {
  const [staff, setSelectedStaff] = useState();
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObjAdmin")
    );
    let newObj = { ...previousLocalStorageValue, adminSubscribers: false };
    localStorage.setItem("guideTourObjAdmin", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(
    localStorage.getItem("guideTourObjAdmin")
  );

  return (
    <div className="admin_SubscriberPage">
      <GuidedTour
        steps={adminSubscribersSteps}
        onRequestClose={handleOnRequestClose}
        isOpen={
          guideTourGetItemValue ? guideTourGetItemValue.adminSubscribers : true
        }
      />
      <div className="no-design-section justify-content-center align-items-center vh-100">
        <Label
          title="Mobile design not supported"
          className="no-design"
        ></Label>
      </div>
      <div className="main_Container">
        <Scrollbars className="admin_SubscriberPage_Scroll">
          <div className="dashboard_Section ">
            <div className="d-flex justify-content-between align-items-center">
              <div className="content_Header pt-2">
                <Label className="page_Title" title="Subscribers"></Label>
                <Label
                  className="inner_Title"
                  title="View and manage all blog post here"
                ></Label>
              </div>
            </div>
            <div className="divider"></div>
            <div className="table_Section pr-3">
              <div className="query-Section d-flex flex-column">
                <div tourselector="admin-subscribers-list">
                  <ul className="header d-flex p-0 m-0">
                    <li>Sl.No.</li>
                    <li className="pr-1">Query ID</li>
                    <li className="pr-1">Raised On</li>
                    <li className="pr-1">Message</li>
                    <li className="pr-1">Comments</li>
                    <li className="pr-1">Status</li>
                    <li className="pr-1">Action</li>
                  </ul>

                  <ul className="dataList d-flex p-0 m-0">
                    <li>1</li>
                    <li className="pr-1">23311 </li>
                    <li className="pr-1">13/Jan/2018</li>
                    <li className="pr-1">
                      Lorem ipsum dolor sit amet Aut ipsa nisi
                    </li>
                    <li className="pr-1">-</li>
                    <li className="pr-1">
                      <CustomDropdown
                        dropDownItems={dropdownItem}
                        placeholder="Pending"
                        selectedValue={staff}
                        onSelect={(selected) => setSelectedStaff(selected)}
                      ></CustomDropdown>
                    </li>
                    <li
                      className="pr-1"
                      onClick={() =>
                        setShowNotificationModal(!showNotificationModal)
                      }
                    >
                      <icon className="icon-edit3"></icon>
                    </li>
                  </ul>
                  <ul className="dataList d-flex p-0 m-0">
                    <li>2</li>
                    <li className="pr-1">23311 </li>
                    <li className="pr-1">13/Jan/2018</li>
                    <li className="pr-1">
                      Lorem ipsum dolor sit amet Aut ipsa nisi
                    </li>
                    <li className="pr-1">-</li>
                    <li className="pr-1">
                      <CustomDropdown
                        dropDownItems={dropdownItem}
                        placeholder="Pending"
                        selectedValue={staff}
                        onSelect={(selected) => setSelectedStaff(selected)}
                      ></CustomDropdown>
                    </li>
                    <li className="pr-1">
                      <icon className="icon-edit3"></icon>
                    </li>
                  </ul>
                  <ul className="dataList d-flex p-0 m-0">
                    <li>3</li>
                    <li className="pr-1">23311 </li>
                    <li className="pr-1">13/Jan/2018</li>
                    <li className="pr-1">
                      Lorem ipsum dolor sit amet Aut ipsa nisi
                    </li>
                    <li className="pr-1">
                      Lorem ipsum dolor sit amet Aut ipsa nisi Lorem ipsum
                    </li>
                    <li className="pr-1">
                      <CustomDropdown
                        dropDownItems={dropdownItem}
                        placeholder="Pending"
                        selectedValue={staff}
                        onSelect={(selected) => setSelectedStaff(selected)}
                      ></CustomDropdown>
                    </li>
                    <li className="pr-1">
                      <icon className="icon-edit3"></icon>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
      {/* <SideModal show={showNotificationModal} ></SideModal> */}
      <PaginationSystem />
    </div>
  );
}

export default withRouter(AdminSubscriberPage);
