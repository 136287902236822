import React from "react";
import { Link } from "react-router-dom";
import CustomFacebookLogin from "../../../components/FacebookLogin/FacebookLoginComponent";
import Label from "../../../components/UI/Label/Label";

import "./AddProfile_DropDown.scss";
import { useDispatch } from "react-redux";
import {
  facebookIntegrationAction,
  instagramIntegrationAction,
} from "../../../Redux_saga/postPublisherSlice";
import LinkedinLogin from "../../../components/LinkedinLogin/linkedinLogin";
import TwitterLogin from "../../../components/TwitterLogin/TwitterLogin";
import YouTubeLogin from "../../../components/YoutubeLogin/youTubeLogin";

const AddProfile_DropDown = (props) => {
  const { active, setActive, userName, ProfileImg, linkName, linkName2 } =
    props;

  const dispatch = useDispatch();

  const responseFacebook = async (response) => {
    if (response) {
      const currentUser = localStorage.getItem("userId");
      const { accessToken } = response;
      const token = localStorage.getItem("token");
      dispatch(
        facebookIntegrationAction({ accessToken, userId: currentUser, token })
      );
      // setTimeout(() => {
      //   history.push("./socialprofilelist?Facebook");
      // }, 2000);
    }
  };

  const responseInstagram = async (response) => {
    if (response) {
      const currentUser = localStorage.getItem("userId");
      const { accessToken } = response;
      const token = localStorage.getItem("token");
      dispatch(
        instagramIntegrationAction({
          accessToken,
          userId: currentUser,
          token,
        })
      );
      // setTimeout(() => {
      //   history.push("./socialprofilelist?Instagram");
      // }, 2000);
    }
  };

  return (
    <div className="accordion_profile">
      <div className="accordionHeading">
        <div className="container_toggle" onClick={() => setActive(userName)}>
          <div className="profile_box">
            <img src={ProfileImg}></img>
            <div className="profile_txt">
              <Label className="userName" title={userName}></Label>
            </div>
          </div>

          <span onClick={() => setActive(userName)}>
            {active === userName ? (
              <div className="status_btn">
                <i className="icon-downarrow"></i>
              </div>
            ) : (
              <div className="status_btn">
                <i className="icon-downarrow"></i>
              </div>
            )}
          </span>
        </div>
      </div>

      <div className={(active === userName ? "show" : "") + "accordionContent"}>
        <div className="option_toggle">
          <div className="profile_container">
            <Label className="link_text" title={linkName}></Label>
            <Link className="link_icon" to="#">
              {userName === "Facebook" && (
                <CustomFacebookLogin responseCallBack={responseFacebook} />
              )}
              {userName == "LinkedIn" && <LinkedinLogin />}
              {userName == "Twitter" && <TwitterLogin />}
              {userName == "Instagram" && (
                <CustomFacebookLogin responseCallBack={responseInstagram} />
              )}
              {userName == "Youtube" && <YouTubeLogin />}
            </Link>
          </div>

          {linkName2 && (
            <div className="profile_container">
              <Label className="link_text" title={linkName2}></Label>
              <Link className="link_icon" to="#">
                <i className="icon-plus"></i>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddProfile_DropDown;
