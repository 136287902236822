import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { withRouter } from "react-router-dom";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import PaginationSystem from "../../components/Pagination/PaginationSystem";
import Label from "../../components/UI/Label/Label";
import SearchBox from "../../components/UI/SearchBox/SearchBox";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import FilterForm from "../Publish/Filter/FilterForm";
import { adminUserProfileSteps } from "../Steps";
import "./AdminUserProfile.scss";

// const loginForm = {
//   email: {
//     name: "email",
//   },
// };

function AdminUserProfile() {
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObjAdmin")
    );
    let newObj = { ...previousLocalStorageValue, adminUserProfile: false };
    localStorage.setItem("guideTourObjAdmin", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(
    localStorage.getItem("guideTourObjAdmin")
  );

  return (
    <div className="admin_UserProfile">
      <GuidedTour
        steps={adminUserProfileSteps}
        onRequestClose={handleOnRequestClose}
        isOpen={
          guideTourGetItemValue ? guideTourGetItemValue.adminUserProfile : true
        }
      />
      <div className="no-design-section justify-content-center align-items-center vh-100">
        <Label
          title="Mobile design not supported"
          className="no-design"
        ></Label>
      </div>
      <AdminHeader />
      <div className="main_Container">
        <Scrollbars className="admin_UserProfile_Scroll">
          <div className="dashboard_Section">
            <div className="d-flex">
              <div className="content_Header pt-2">
                <div className="d-flex flex-column ">
                  <div className="backIcon d-flex align-items-center">
                    <Label className="page_Title" title="User Accounts"></Label>
                  </div>
                  <Label
                    className="inner_Title"
                    title="View all user accounts"
                  ></Label>
                </div>
              </div>
            </div>
            <div className="divider"></div>
            <div className="serach_Filter">
              <div className="search_Section d-flex justify-content-between">
                <SearchBox placeholder="Search by group name,page name members name" />

                <div className="icon_Filter">
                  <div className="filterBtn">
                    <button
                      className="filterIcon"
                      onClick={() => setShowFilterDropdown(!showFilterDropdown)}
                      tourselector="admin-user-filter"
                    >
                      <i className="icon-filter">
                        {" "}
                        <span>Filter</span>
                      </i>
                    </button>
                  </div>
                  {showFilterDropdown && (
                    <>
                      <div
                        className="overLay"
                        onClick={() => setShowFilterDropdown(false)}
                      ></div>
                      <FilterForm
                        title="Filter"
                        onClick={() => setShowFilterDropdown(false)}
                      ></FilterForm>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="table_Sections pr-3">
              <div className="query-Section d-flex flex-column">
                <div tourselector="admin-user-list">
                  <ul className="header d-flex p-0 m-0">
                    <li>User Id</li>
                    <li className="pr-1">User Name</li>
                    <li className="pr-1">Last Active</li>
                    <li className="pr-1">Joining Date</li>
                    <li className="pr-1">Renewal Date</li>
                    <li className="pr-1">Subscription Type</li>
                  </ul>

                  <ul className="dataList d-flex p-0 m-0">
                    <li>00011</li>
                    <li className="pr-1">John Peter </li>
                    <li className="pr-1">13/Jan/2018</li>
                    <li className="pr-1">13/Jan/2018</li>
                    <li className="pr-1">13/Jan/2018</li>
                    <li className="pr-1">Standard (1 year) </li>
                  </ul>
                  <ul className="dataList d-flex p-0 m-0">
                    <li>00011</li>
                    <li className="pr-1">John Peter </li>
                    <li className="pr-1">13/Jan/2018</li>
                    <li className="pr-1">13/Jan/2018</li>
                    <li className="pr-1">13/Jan/2018</li>
                    <li className="pr-1">Standard (1 year) </li>
                  </ul>
                  <ul className="dataList d-flex p-0 m-0">
                    <li>00011</li>
                    <li className="pr-1">John Peter </li>
                    <li className="pr-1">13/Jan/2018</li>
                    <li className="pr-1">13/Jan/2018</li>
                    <li className="pr-1">13/Jan/2018</li>
                    <li className="pr-1">Standard (1 year) </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
      <PaginationSystem />
    </div>
  );
}

export default withRouter(AdminUserProfile);
