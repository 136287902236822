import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import CustomCalendar from "../../components/UI/CustomCalendar/CustomCalendar";
import CustomDropdown from "../../components/UI/CustomDropdown/CustomDropdown";
import FilterDropdown from "../../components/UI/FilterDropdown/FilterDropdown";
import Label from "../../components/UI/Label/Label";
import "./FilterForm.scss";

const itemsStatus = [
  {
    id: "1",
    value: "Stop",
  },
  {
    id: "2",
    value: "Pause",
  },
  {
    id: "3",
    value: "Play",
  },
];
const FilterForm = (props) => {
  const { title, onClose } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [staff, setSelectedStaff] = useState();
  // const YearCustomInput = ({ value, onClick }) => (
  //   <button type="button" className="custom-input" onClick={onClick}>
  //     {value ? value : "2020"} <span className="joyclub-arrow-down"></span>
  //   </button>
  // );

  // const MonthCustomInput = ({ value, onClick }) => (
  //   <button
  //     type="button"
  //     className="custom-input monthcustom"
  //     onClick={onClick}
  //   >
  //     {value ? value : "MONTH"} <span className="joyclub-arrow-down"></span>
  //   </button>
  // );
  return (
    <>
      <FilterDropdown className="filterForm">
        <div className="filterDispute">
          <div className="form">
            <div className="filterItem">
              <div className="header_Section">
                <div className="heading">
                  <Label title={title}></Label>
                  <i className="icon-close" onClick={onClose}></i>
                </div>
              </div>
              <div className="calender_Section">
                <div className="date_Filter">
                  <Label
                    className="date_Title"
                    title="Filter by social media platform"
                  ></Label>
                  <Link className="filter_Title" to="#">
                    Clear all filter
                  </Link>
                </div>
                <div className="dropdown_Sections">
                  <CustomDropdown
                    dropDownItems={itemsStatus}
                    placeholder="Select social media platform"
                    selectedValue={staff}
                    onSelect={(selected) => setSelectedStaff(selected)}
                  ></CustomDropdown>
                </div>
                <div className="dropdown_Sections paddingT">
                  <Label
                    className="filter_Title"
                    title="Filter by group"
                  ></Label>
                  <CustomDropdown
                    dropDownItems={itemsStatus}
                    placeholder="Select group"
                    selectedValue={staff}
                    onSelect={(selected) => setSelectedStaff(selected)}
                  ></CustomDropdown>
                </div>
                <div className="calendar_Section">
                  <Label className="date_Title" title="Filter by date"></Label>
                  <div className="calenderMain">
                    <CustomCalendar
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Date"
                      renderCustomHeader={true}
                    ></CustomCalendar>
                  </div>
                  <div className="calenderMain">
                    <CustomCalendar
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Date"
                      renderCustomHeader={true}
                    ></CustomCalendar>
                  </div>
                </div>
              </div>
            </div>
            <div className="custombtnfield justify-content-around">
              <CustomButton type="submit" title="Apply" />
              <CustomButton type="submit" title="Clear" />
            </div>
          </div>
        </div>
      </FilterDropdown>
    </>
  );
};

export default FilterForm;
