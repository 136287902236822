import React from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import FeatureImg from "../../assests/images/feature.png";
import SocialMedia from "../../assests/images/social-media.png";
import TestimonialsImg from "../../assests/images/test.png";
import TestimonialsImg1 from "../../assests/images/test1.png";
import TestimonialsImg2 from "../../assests/images/test2.png";
import VideoImg from "../../assests/images/video.png";
import Footer from "../../components/Footer/Footer";
import HeaderLogin from "../../components/HeaderLogin/HeaderLogin";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import ImageSlider from "../../components/UI/ImageSlider/ImageSlider";
import Label from "../../components/UI/Label/Label";
import "./Feature.scss";

function FeaturePage() {
  const { t } = useTranslation();
  const dimensions = {
    height: window.innerHeight,
    width: window.innerWidth,
  };

  return (
    <div className="feature_Page" style={{ height: dimensions.height + "px" }}>
      <HeaderLogin></HeaderLogin>
      <Scrollbars className="feature_Scroll">
        <div className="main_featurePage">
          <div className="feature_Inner">
            <div className="get_Start">
              <div className="content">
                <Label
                  className="title_Main"
                  title={t("FEATURE_MAIN_HEAD")}
                ></Label>
                <Label
                  className="inner_Text"
                  title={t("FEATURE_MAIN_HEAD_SUMMARY")}
                ></Label>
                <div className="d-flex">
                  <CustomButton title={t("FEATURE_GET_STARTED")}></CustomButton>
                  <CustomButton
                    className="submitBtn ml-2 outline_Btn"
                    title={t("FEATURE_LEARN_MORE")}
                  ></CustomButton>
                </div>
              </div>
              <div className="img_Section">
                <img
                  src={FeatureImg}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="main_Img"
                />
              </div>
            </div>
            <div className="advantage_Section">
              <Label className="header" title={t("FEATURE_ADVANTAGES")}></Label>
              <Label
                className="inner_title"
                title={t("FEATURE_ADVANTAGES_SUMMARY")}
              ></Label>
              <div className="adv_Section">
                <div className="adv_Inner">
                  <div className="content">
                    <Label
                      title={t("FEATURE_SCHEDULING")}
                      className="main-Title"
                    ></Label>
                    <Label
                      className="desp"
                      title={t("FEATURE_SCHEDULING_SUMM")}
                    ></Label>
                  </div>
                </div>
                <div className="adv_Inner">
                  <div className="content">
                    <Label
                      title={t("FEATURE_ENGAGEMENT")}
                      className="main-Title"
                    ></Label>
                    <Label
                      className="desp"
                      title={t("FEATURE_ENGAGEMENT_SUMM")}
                    ></Label>
                  </div>
                </div>
                <div className="adv_Inner">
                  <div className="content">
                    <Label
                      title={t("FEATURE_PUBLISHING")}
                      className="main-Title"
                    ></Label>
                    <Label
                      className="desp"
                      title={t("FEATURE_PUBLISHING_SUMM")}
                    ></Label>
                  </div>
                </div>
              </div>
            </div>
            <div className="top_Feature">
              <div className="header_Section">
                <Label
                  className="header d-flex justify-content-center"
                  title={t("FEATURE_TOP_FETURE")}
                ></Label>
                <Label
                  className="inner_title"
                  title={t("FEATURE_TOP_FETURE_BRIEF")}
                ></Label>
              </div>
              <div className="img_Content">
                <div className="img_Section">
                  <img
                    src={SocialMedia}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    // fluid="true"
                    className="time_Img"
                  />
                </div>
                <div className="content_Section">
                  <Label className="top_Title" title="Lorem Ipsum"></Label>
                  <Label
                    className="inner_Title"
                    title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                  ></Label>
                  <Label
                    className="inner_Title"
                    title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                  ></Label>
                  <Label
                    className="inner_Title"
                    title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                  ></Label>
                </div>
              </div>
              <div className="img_Content">
                <div className="content_Section">
                  <Label className="top_Title" title="Lorem Ipsum"></Label>
                  <Label
                    className="inner_Title"
                    title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                  ></Label>
                  <Label
                    className="inner_Title"
                    title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                  ></Label>
                  <Label
                    className="inner_Title"
                    title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                  ></Label>
                </div>
                <div className="img_Section">
                  <img
                    src={SocialMedia}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    // fluid="true"
                    className="time_Img"
                  />
                </div>
              </div>
              <div className="img_Content">
                <div className="img_Section">
                  <img
                    src={SocialMedia}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    // fluid="true"
                    className="time_Img"
                  />
                </div>
                <div className="content_Section">
                  <Label className="top_Title" title="Lorem Ipsum"></Label>
                  <Label
                    className="inner_Title"
                    title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                  ></Label>
                  <Label
                    className="inner_Title"
                    title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                  ></Label>
                  <Label
                    className="inner_Title"
                    title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                  ></Label>
                </div>
              </div>
            </div>
            <div className="video_Section d-flex flex-row">
              <div className="img_Content d-flex ">
                <div className="content_Section">
                  <Label
                    className="top_Title"
                    title={t("FEATURE_VIDEO_TOUR")}
                  ></Label>
                  <Label
                    className="inner_Title"
                    title={t("FEATURE_VIDEO_TOUR_BRIEF")}
                  ></Label>
                  <CustomButton
                    title={t("FEATURE_EXPLORE_BUTTON")}
                  ></CustomButton>
                </div>
                <div className="img_Sections">
                  <img
                    src={VideoImg}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    // fluid="true"
                    className="time_Img"
                  />
                </div>
              </div>
            </div>
            <div className="imgSlider_Section">
              <Label
                className="main_Title"
                title={t("FEATURE_TESTIMONIALS")}
              ></Label>
              <ImageSlider
                SliderImg={TestimonialsImg}
                SliderImg1={TestimonialsImg1}
                SliderImg2={TestimonialsImg2}
                headingTxt="Lorem Ipsum is text "
                innerTxt="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
              ></ImageSlider>
            </div>
          </div>
        </div>
        <Footer className="footerheight"></Footer>
      </Scrollbars>
    </div>
  );
}

export default withRouter(FeaturePage);
