import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars";
import Header from "../../components/Header/Header";
import "./SocialProfile.scss";
import Label from "../../components/UI/Label/Label";
import ModalPopup from "../../components/UI/ModalPopup/ModalPopup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  facebookIntegrationAction,
  instagramIntegrationAction,
} from "../../Redux_saga/postPublisherSlice";
import { useAppData } from "../../services/hooks";
import instagram from "../../assests/images/instagram.png";
import youtube from "../../assests/images/youtube.png";
import tiktok from "../../assests/images/tiktok.png";
import GuidedTour from "../GuideTour/GuidedTour";
import { socialProfileSteps } from "../Steps";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import LinkedinLogin from "../../components/LinkedinLogin/linkedinLogin";
import CustomFacebookLogin from "../../components/FacebookLogin/FacebookLoginComponent";
import TwitterLogin from "../../components/TwitterLogin/TwitterLogin";
import YouTubeLogin from "../../components/YoutubeLogin/youTubeLogin";

function SocialProfile(props) {
  const history = useHistory();
  const [showEditPostModal, setShowEditPostModal] = useState(false);
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();

  const dispatch = useDispatch();
  const data = useAppData((r) => r.postPublisherSlice.users);
  const twitterAuthenticationData = useAppData(
    (r) => r.postPublisherSlice.users.twitterAuthenticationResponseValue.data
  );
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const responseFacebook = async (response) => {
    if (response) {
      const currentUser = localStorage.getItem("userId");
      const { accessToken } = response;
      const token = localStorage.getItem("token");
      dispatch(
        facebookIntegrationAction({ accessToken, userId: currentUser, token })
      );
      setTimeout(() => {
        history.push("./socialprofilelist?Facebook");
      }, 2000);
    }
  };

  const responseInstagram = async (response) => {
    if (response) {
      const currentUser = localStorage.getItem("userId");
      const { accessToken } = response;
      const token = localStorage.getItem("token");
      dispatch(
        instagramIntegrationAction({
          accessToken,
          userId: currentUser,
          token,
        })
      );
      setTimeout(() => {
        history.push("./socialprofilelist?Instagram");
      }, 2000);
    }
  };

  useEffect(() => {
    if (data.facebookPageResponseValue.status === true) {
      handleShow();
    }
  }, [data]);

  const handleTiktokLogin = () => {
   
  };

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObj")
    );
    let newObj = { ...previousLocalStorageValue, socialprofile: false };
    localStorage.setItem("guideTourObj", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(localStorage.getItem("guideTourObj"));

  return (
    <div className="social_profile_Page">
      <div className="main_Container">
        <div className="chatboat-section position-absolute">
          <i className="icon-chatboat"></i>
        </div>
        <GuidedTour
          steps={socialProfileSteps}
          onRequestClose={handleOnRequestClose}
          isOpen={
            guideTourGetItemValue ? guideTourGetItemValue.socialprofile : true
          }
        />
        <Scrollbars className="social_profile_Scroll">
          <div className="top_section">
            <Label className="top_text1" title={t("SOCIAL_ADD")}></Label>
            <Label className="top_text2" title={t("SOCIAL_CONNECT")}></Label>
            <div className="border_box">
              <Label className="box_text" title={t("SOCIAL_UPTO")}></Label>
            </div>
          </div>
          <div className="main_box">
            <div className="inner_box" tourselector="facebook">
              <div className="top">
                <i className="icon-fb"></i>
                <Label className="top_text" title="Facebook"></Label>
              </div>

              <div className="middle">
                <Label
                  className="middle_text"
                  title={t("SOCIAL_CONNECT_FB")}
                ></Label>
                <Link className="middle_icon" to="#">
                  <CustomFacebookLogin responseCallBack={responseFacebook} />
                </Link>
              </div>
              <div className="top_border"></div>
            </div>
            <div className="inner_box" tourselector="linkedin">
              <div className="top">
                <i className="icon-linkedin bg_Colr"></i>
                <Label className="top_text" title="LinkedIn"></Label>
              </div>
              <div className="middle">
                <Label
                  className="middle_text"
                  title={t("SOCIAL_CONNECT_LINKEDIN")}
                ></Label>
                <Link className="middle_icon" to="#">
                  <LinkedinLogin />
                </Link>
              </div>
              <div className="top_border"></div>
              <div className="bottom"></div>
            </div>
            <div className="inner_box" tourselector="twitter">
              <div className="top">
                <i className="icon-twitter bg_Colr"></i>
                <Label className="top_text" title="Twitter"></Label>
              </div>

              <div className="middle">
                <Label
                  className="middle_text"
                  title={t("SOCIAL_CONNECT_TWITTER")}
                ></Label>
                <Link className="middle_icon" to="#">
                  <TwitterLogin />
                </Link>
              </div>

              <div className="top_border"></div>

              <div className="bottom"></div>
            </div>

            <div className="inner_box" tourselector="instagram">
              <div className="top">
                {/* <i className="icon-twitter bg_Colr"></i> */}
                <img src={instagram} alt="insta"></img>
                <Label className="top_text" title="Instagram"></Label>
              </div>

              <div className="middle">
                <Label
                  className="middle_text"
                  title={t("SOCIAL_CONNECT_FB")}
                ></Label>
                <Link className="middle_icon" to="#">
                  <CustomFacebookLogin responseCallBack={responseInstagram} />
                </Link>
              </div>

              <div className="top_border"></div>
            </div>

            <div className="inner_box" tourselector="youtube">
              <div className="top">
                {/* <i className="icon-twitter bg_Colr"></i> */}
                <img src={youtube} alt="insta"></img>
                <Label className="top_text" title="Youtube"></Label>
              </div>

              <div className="middle">
                <Label
                  className="middle_text"
                  title={t("SOCIAL_CONNECT_YOUTUBE")}
                ></Label>
                <Link className="middle_icon" to="#">
                  <YouTubeLogin />
                </Link>
              </div>

              <div className="top_border"></div>
            </div>

            <div className="inner_box" tourselector="tiktok">
              <div className="top">
                {/* <i className="icon-twitter bg_Colr"></i> */}
                <img className="tiktok-img" src={tiktok} alt="tiktok"></img>
                <Label className="top_text" title="Tik Tok"></Label>
              </div>

              <div className="middle">
                <Label
                  className="middle_text"
                  title={t("SOCIAL_CONNECT_FB")}
                ></Label>
                <Link className="middle_icon" to="#">
                  <i className="icon-plus" onClick={handleTiktokLogin}></i>
                </Link>
              </div>

              <div className="top_border"></div>

              <div className="bottom">
                <Label
                  className="bottom_text"
                  title={t("SOCIAL_CONNECT_FB")}
                ></Label>
                <Link className="bottom_icon" to="#">
                  <i className="icon-plus"></i>
                </Link>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
      <ModalPopup
        showModal={showEditPostModal}
        onHide={() => setShowEditPostModal(false)}
        className="editpost_Review"
        headerTitle="Review post"
        closeIcon={true}
      >
        <div className="tab_Section">
          <p>hi</p>
        </div>
      </ModalPopup>
    </div>
  );
}

export default withRouter(SocialProfile);
