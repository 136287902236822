import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchQueryDataAction,
  // getUserProfilePictureAction,
  getUsersFullDetailsAction,
  postChangePasswordAction,
  postCreateQueryAction,
  postUploadUserProfileImageAction,
  putUpdateUserProfileAction,
} from "../../Redux_saga/postPublisherSlice";
import CardType from "../../assests/images/card.png";
import CardType1 from "../../assests/images/visa.png";
import BillingHeader from "../../components/BillingHeader/BillingHeader";
import Header from "../../components/Header/Header";
import HookForm from "../../components/HookForm/HookForm";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import CustomDropdown from "../../components/UI/CustomDropdown/CustomDropdown";
import CustomRadio from "../../components/UI/CustomRadio/CustomRadio";
import Label from "../../components/UI/Label/Label";
import ModalPopup from "../../components/UI/ModalPopup/ModalPopup";
import TextField from "../../components/UI/TextField/TextField";
import { useAppData } from "../../services/hooks";
import "../../translations/i18n.js";
import Constant from "../../util/constant";
import Message from "../../util/message";
import {
  guideTourObj,
  guideTourObjDisable,
  localStorageSetItem,
} from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { settingBillingSteps, settingQuerySteps, settingSteps } from "../Steps";
import "./Setting.scss";

import axios from "axios";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useHistory } from "react-router-dom";
import PaginationSystem from "../../components/Pagination/PaginationSystem";
import WhiteLabel from "./components/WhiteLabel/WhiteLabel";
import { Controller } from "react-hook-form";
import { error } from "highcharts";
import moment from "moment/moment.js";

// const SettingForm = {
//   password: {
//     name: 'password',
//     validate: {
//       required: {
//         value: true,
//         message: Message.ERRORMESSAGE.PASSWORDEMPTY,
//       },
//     },
//   },
// };
const userForm = {
  firstName: {
    name: "firstName",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.FIRSTNAMEEMPTY,
      },
      pattern: {
        value: Constant.REGEX.Name,
        message: Message.ERRORMESSAGE.ALPHABETSONLY,
      },
    },
  },
  lastName: {
    name: "lastName",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.LASTNAMEEMPTY,
      },
      pattern: {
        value: Constant.REGEX.Name,
        message: Message.ERRORMESSAGE.ALPHABETSONLY,
      },
    },
  },
  email: {
    name: "email",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.EMAILEMPTY,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ERRORMESSAGE.EMAILINVALID,
      },
    },
  },
  mobileNumber: {
    name: "mobileNumber",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.CONTACTEMPTY,
      },
      pattern: {
        value: Constant.REGEX.NUMBER,
        message: Message.ERRORMESSAGE.EMAILINVALID,
      },
    },
  },
};
const changeForm = {
  current_password: {
    name: "current_password",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.PASSWORDEMPTY,
      },
    },
  },
  password: {
    name: "password",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.PASSWORDEMPTY,
      },
    },
  },
  confirm_password: {
    name: "confirm_password",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.PASSWORDEMPTY,
      },
    },
  },
};
const createQuery = {
  queryId: {
    name: "queryId",
    validate: {
      required: {
        value: true,
      },
      // pattern: {
      //   value: Constant.REGEX.email,
      // },
    },
  },
  description: {
    name: "description",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.TITLEEMPTY,
      },
    },
  },
};

// const queryList = [
//   {
//     srno: "01",
//     date: "01 / 01 / 2021",
//     status: "Resolved",
//     message:
//       "contrary to popular belief, Lorem Ipsum is not simply random text.",
//     comments:
//       "contrary to popular belief, Lorem Ipsum is not simply random text.",
//   },
//   {
//     srno: "01",
//     date: "01 / 01 / 2021",
//     status: "Resolved",
//     message:
//       "contrary to popular belief, Lorem Ipsum is not simply random text.",
//     comments:
//       "contrary to popular belief, Lorem Ipsum is not simply random text.",
//   },
// ];
const dropdownItem = [
  {
    id: "1",
    value: "Asia/Calcutta",
  },
  {
    id: "2",
    value: "Australian Central Daylight Savings Time",
  },
  {
    id: "3",
    value: "Australian Central Standard Time",
  },

  {
    id: "4",
    value: "Pacific/Midway",
  },
  {
    id: "5",
    value: "Pacific/Niue",
  },
  {
    id: "6",
    value: "Pacific/Pago_Pago",
  },
];
const dropdownTourGuideValues = [
  {
    id: "7",
    value: "Enable Tour Guide",
  },
  {
    id: "8",
    value: "Disable Tour Guide",
  },
];
const dropdownItem2 = [
  {
    id: "12",
    value: "English",
    language: "en",
  },
  {
    id: "13",
    value: "Hindi",
    language: "hn",
  },
];
const dropdownItem3 = [
  {
    id: "14",
    value: "Light Mode",
  },
  {
    id: "15",
    value: "Dark Mode",
  },
];

const dropdownItem4 = [
  {
    id: "16",
    value: "Postr Email Notification On",
  },
  {
    id: "17",
    value: "Postr Email Notification Off",
  },
];

const dropdownItem5 = [
  {
    id: "16",
    value: "Weekly",
  },
  {
    id: "17",
    value: "Monthly",
  },
  {
    id: "17",
    value: "Yearly",
  },
];

// const initialImageText = {
//   firstCharacter: "Default",
//   lastCharacter: "Image",
// };
function User() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [form, setUserForm] = React.useState();
  // console.log(form);
  const [changePasswordForm, setChangePasswordForm] = React.useState();
  const [showCreateQueryModal, setShowCreateQueryModal] = useState(false);
  const showBilling = false;
  const [staff, setSelectedStaff] = useState();
  const [language, setLanguage] = useState("Language");
  const [post, setPost] = React.useState(null);
  const [passwordType, setPasswordType] = useState(true);
  const [newpasswordtype, setNewPasswodType] = useState(true);
  const [confirmPasswordType, setConfirmPasswordType] = useState(true);
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();
  const [isOpenTourGuideBilling, setIsOpenTourGuideBilling] = useState();
  const [isOpenTourGuideQuery, setIsOpenTourGuideQuery] = useState();
  const [userUpdatedData, setUserUpdatedData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
  });
  const [allImages, setAllImages] = useState([]);
  // const [randomQid, setRandomQid] = useState(randomQID1);
  const [value, setValue] = useState();
  const [selectTourGuideDropdownValue, setSelectTourGuideDropdownValue] =
    useState();
  // console.log(changePasswordForm);
  const postData = useAppData((r) => r.postPublisherSlice.post);

  const userDetailStateValue = useAppData((r) => r?.postPublisherSlice?.user);

  const userUploadImageStateValue = useAppData(
    (r) => r.postPublisherSlice.users.UploadUserProfileImageValue.data
  );
  const queryData = useAppData((r) => r.postPublisherSlice.queryData);
  console.log("02-03-24 05", queryData);

  const User = useAppData((r) => r.postPublisherSlice?.user);
  const Token = useAppData((r) => r.postPublisherSlice?.tokens);
  console.log("01-02-24 00 003", Token);
  useEffect(() => {
    const data = {
      token: Token?.access?.token,
    };
    console.log("01-02-24 02 calling");
    dispatch(fetchQueryDataAction(data));
  }, [dispatch, Token]);

  // postCreateQueryAction
  // const onFormSubmit = (form) => {
  //   const updatePasswordData = {
  //     bodyData: {
  //       userId: localStorage.getItem("userId"),
  //       currentPassword: form.current_password,
  //       newPassword: form.password,
  //     },
  //     token: localStorage.getItem("token"),
  //   };
  //
  // };

  // useEffect(() => {
  //   const createQueryData = {
  //     bodyData: {
  //       queryId: "QD1234001",
  //       description: "notifications not receiving",
  //       status: "pending",
  //     },
  //     token: localStorage.getItem("token"),
  //   };

  //   dispatch(postCreateQueryAction(createQueryData));
  // }, []);

  React.useEffect(() => {
    function handleResize() {}
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const [showTab, setShowTab] = useState(false);
  const OnTabClick = () => {
    setShowTab(!showTab);
  };
  const onSelectLanguage = (e) => {
    setLanguage(e);
    i18n.changeLanguage(e.language);
  };

  const onClickUserDetails = () => {
    const userFullDetailsData = {
      user: User?._id,
      token: Token?.access?.token,
    };
    dispatch(getUsersFullDetailsAction(userFullDetailsData));
  };
  useEffect(() => {
    onClickUserDetails();
  }, []);

  const handleChangeUploadProfileImage = async (e) => {
    if (e.target.files.length === 0) return;

    const file = e.target.files[0];
    const S3_BUCKET = "vinodevs3new";
    const REGION = "ap-south-1";

    AWS.config.update({
      accessKeyId: "AKIAWTSP3CRY47CP6MMC",
      secretAccessKey: "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",
      region: REGION,
    });

    const s3 = new AWS.S3();

    try {
      const params = {
        Bucket: S3_BUCKET,
        Key: file.name.replace(/ /g, "_"),
        Body: file,
        ContentType: file.type,
      };
      await s3
        .upload(params)
        .promise()
        .catch((err) => console.log("error on upload", err));
      const s3Url = `https://s3.${AWS.config.region}.amazonaws.com/${params.Bucket}/${params.Key}`;
      // Update the state with the S3 URL
      setAllImages([s3Url]);
    } catch (error) {
      console.error("Error handling image:", error);
      // Handle error if needed
    }
  };
  useEffect(() => {
    {
      userUploadImageStateValue &&
        userUploadImageStateValue.fileName &&
        axios
          .get(
            `http://localhost:3001/v1/users/photos/${userUploadImageStateValue.fileName}`
          )
          .then((response) => {
            let responseData = response.request.responseURL;
            setPost(responseData);
          });
    }
  }, [userUploadImageStateValue]);
  const onFormSubmit = (form) => {
    setUserUpdatedData(form);
    const firstName = form.firstName;
    const lastName = form.lastName;
    const phoneNumber = form.mobileNumber;
    const countryCode = phoneNumber.substring(0, 3); // Assumes the country code is always in the first three characters

    // Extracting phone number (excluding country code)
    const phoneNumberOnly = phoneNumber.substring(3);
    const updateUserProfile = {
      bodyData: {
        userId: userDetailStateValue._id,
        firstName: firstName,
        lastName: lastName,
        mobileNumber: phoneNumberOnly,
        mobileNumberCountryCode: countryCode,
        imageUrl:
          allImages.length > 0 ? allImages[0] : userDetailStateValue.imageUrl,
      },
      token: localStorage.getItem("token"),
    };
    dispatch(putUpdateUserProfileAction(updateUserProfile));
  };

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObj")
    );
    let newObj = { ...previousLocalStorageValue, setting: false };
    localStorage.setItem("guideTourObj", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(localStorage.getItem("guideTourObj"));

  const handleTabSelect = (event) => {
    if (event === "Billings") {
      let guideTourGetItemValueBilling = JSON.parse(
        localStorage.getItem("guideTourObj")
      );
      let newObjBilling =
        guideTourGetItemValueBilling.settingBilling === "false"
          ? {
              ...guideTourGetItemValueBilling,
              settingBilling: true,
            }
          : guideTourGetItemValueBilling;

      localStorage.setItem("guideTourObj", JSON.stringify(newObjBilling));
      setIsOpenTourGuideBilling(newObjBilling);
    }
    if (event === "Query") {
      let guideTourGetItemValueQuery = JSON.parse(
        localStorage.getItem("guideTourObj")
      );
      let newObjQuery =
        guideTourGetItemValueQuery.settingQuery === "false"
          ? {
              ...guideTourGetItemValueQuery,
              settingQuery: true,
            }
          : guideTourGetItemValueQuery;

      localStorage.setItem("guideTourObj", JSON.stringify(newObjQuery));
      setIsOpenTourGuideQuery(newObjQuery);
    }
  };

  const handleOnRequestCloseQuery = () => {
    let previousLocalStorageValueQuery = JSON.parse(
      localStorage.getItem("guideTourObj")
    );
    let newObjQuery = {
      ...previousLocalStorageValueQuery,
      settingQuery: false,
    };
    localStorage.setItem("guideTourObj", JSON.stringify(newObjQuery));
    setIsOpenTourGuideQuery(newObjQuery);
  };
  React.useEffect(() => {
    if (isOpenTourGuideQuery && isOpenTourGuideQuery.settingQuery === true) {
      localStorageSetItem(isOpenTourGuideQuery);
    }
  }, [isOpenTourGuideQuery]);
  let guideTourGetItemValueQuery = JSON.parse(
    localStorage.getItem("guideTourObj")
  );

  const handleOnRequestCloseBilling = () => {
    let previousLocalStorageValueBilling = JSON.parse(
      localStorage.getItem("guideTourObj")
    );
    let newObjBilling = {
      ...previousLocalStorageValueBilling,
      settingBilling: false,
    };
    localStorage.setItem("guideTourObj", JSON.stringify(newObjBilling));
    setIsOpenTourGuideBilling(newObjBilling);
  };
  React.useEffect(() => {
    if (
      isOpenTourGuideBilling &&
      isOpenTourGuideBilling.settingBilling === true
    ) {
      localStorageSetItem(isOpenTourGuideBilling);
    }
  }, [isOpenTourGuideBilling]);
  let guideTourGetItemValueBilling = JSON.parse(
    localStorage.getItem("guideTourObj")
  );

  const handleTourDropDownValue = (value) => {
    setSelectTourGuideDropdownValue(value);
    if (value.value === "Enable Tour Guide") {
      let newObj = { ...guideTourObj, guideTourObj };
      localStorage.setItem("guideTourObj", JSON.stringify(newObj));
      history.push("/dashboard");
    }
    if (value.value === "Disable Tour Guide") {
      let newObjDisable = { ...guideTourObjDisable, guideTourObjDisable };
      localStorage.setItem("guideTourObj", JSON.stringify(newObjDisable));
    }
  };
  console.log("localStorage.getItem", localStorage.getItem("token"));
  console.log(userDetailStateValue.firstName, "userdata1");
  const onChangePassword = (form) => {
    const updatePasswordData = {
      bodyData: {
        userId: localStorage.getItem("userId"),
        currentPassword: form.current_password,
        newPassword: form.password,
      },
      token: localStorage.getItem("token"),
    };
    dispatch(postChangePasswordAction(updatePasswordData));
  };
  const generatedNumbers = new Set();

  const generateUniqueRandomQID = () => {
    const min = 100000000000; // Smallest 12-digit number
    const max = 999999999999; // Largest 12-digit number
    let randomNumber;
    do {
      randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    } while (generatedNumbers.has(randomNumber));

    generatedNumbers.add(randomNumber);

    const lastFourDigits = String(randomNumber).slice(3);
    return "QID" + lastFourDigits;
  };

  // Example usage
  const randomQID1 = generateUniqueRandomQID();
  // const randomQID2 = generateUniqueRandomQID();
  console.log("000000001", randomQID1); // This will be unique
  // console.log("000000002", randomQID2); // This will be unique
  //  () => setShowCreateQueryModal(!showCreateQueryModal)
  const handleButtonCreateQuery = () => {
    setShowCreateQueryModal(!showCreateQueryModal);
    generateUniqueRandomQID();
  };
  const onQueryFormSubmit = (form) => {
    const createQueryData = {
      bodyData: {
        queryId: form.queryId,
        description: form.description,
        status: "pending",
      },
      token: localStorage.getItem("token"),
    };

    dispatch(postCreateQueryAction(createQueryData));
    setShowCreateQueryModal(false);
    setTimeout(() => {
      const data = {
        token: Token?.access?.token,
      };
      dispatch(fetchQueryDataAction(data));
    }, 3000);
  };
  return (
    <div className="userdetail">
      <Header />
      <Scrollbars className="userdetail_scroll">
        <GuidedTour
          steps={settingSteps}
          onRequestClose={handleOnRequestClose}
          isOpen={guideTourGetItemValue ? guideTourGetItemValue.setting : true}
        />

        <GuidedTour
          steps={settingBillingSteps}
          onRequestClose={handleOnRequestCloseBilling}
          isOpen={
            guideTourGetItemValueBilling
              ? guideTourGetItemValueBilling.settingBilling === "false" ||
                guideTourGetItemValueBilling.settingBilling === false
                ? false
                : true
              : true
          }
        />

        <GuidedTour
          steps={settingQuerySteps}
          onRequestClose={handleOnRequestCloseQuery}
          isOpen={
            guideTourGetItemValueQuery
              ? guideTourGetItemValueQuery.settingQuery === "false" ||
                guideTourGetItemValueQuery.settingQuery === false
                ? false
                : true
              : true
          }
        />

        <div className="startdiv">
          <div className="position-relative">
            <Label className="heading" title={t("SETTING")}></Label>
            {showBilling && (
              <i
                className="icon-tab position-Icon d-none"
                onClick={OnTabClick}
              ></i>
            )}
            <Label className="manage" title={t("SETTING_MANAGE")}></Label>
            <CustomButton
              className="filterIcon submitBtn Subscribed mr-4"
              title={t("SETTING_SUBSCRIBED")}
              tourselector="subscribed-btn"
            ></CustomButton>

            {showBilling && (
              <CustomButton
                className="filterIcon submitBtn create-QueryModal downloadBtn"
                title="Download all"
              ></CustomButton>
            )}
          </div>
          <Tabs
            className="menu mb-2"
            defaultActiveKey="Userdetails"
            id="justify-tab-example"
            tourselector="setting-tabs"
            onSelect={handleTabSelect}
          >
            <Tab eventKey="Userdetails" title="User details">
              <div className="detail">
                <div className="image-text position-relative">
                  <div>
                    {allImages.length > 0 ? (
                      // If allImages is not empty, render the image
                      <img
                        src={allImages[0]}
                        alt="Image1"
                        className="image-text position-relative"
                      />
                    ) : userDetailStateValue ? (
                      <div>
                        <img
                          src={userDetailStateValue?.imageUrl}
                          alt="Image2"
                          className="image-text position-relative"
                        />
                      </div>
                    ) : (
                      <p className="image-text position-relative">
                        {userDetailStateValue &&
                          userDetailStateValue.firstName
                            .substring(0, 1)
                            .toUpperCase() +
                            userDetailStateValue.lastName
                              .substring(0, 1)
                              .toUpperCase()}
                      </p>
                    )}
                  </div>
                  <div className=" position-absolute">
                    <label
                      className="change_img position-absolute"
                      htmlFor="file-input"
                    >
                      <input
                        id="file-input"
                        type="file"
                        onChange={handleChangeUploadProfileImage}
                        accept="image/*"
                      />
                      <i className="icon-edit1"></i>
                    </label>
                  </div>
                </div>

                <div className="hookform">
                  <HookForm
                    defaultForm={{
                      firstName:
                        userDetailStateValue && userDetailStateValue?.firstName,
                      lastName:
                        userDetailStateValue && userDetailStateValue?.lastName,
                      email:
                        userDetailStateValue && userDetailStateValue?.emailId,
                      mobileNumber:
                        userDetailStateValue &&
                        `${userDetailStateValue?.mobileNumberCountryCode}${userDetailStateValue?.mobileNumber}`,
                    }}
                    init={(form) => setUserForm(form)}
                    onSubmit={onFormSubmit}
                  >
                    {(formMethod) => {
                      return (
                        <div className="form">
                          <div className="first_line">
                            <div className="first_name">
                              <TextField
                                formMethod={formMethod}
                                rules={userForm.firstName.validate}
                                name={userForm.firstName.name}
                                errors={formMethod?.formState.errors}
                                autoFocus={false}
                                type="text"
                                placeholder="john"
                                iconClass="icon-user"
                                defaultValue={formMethod?.getValues(
                                  "firstName"
                                )}
                                controlId={"firstName"}
                              />
                            </div>
                            <div className="last_name">
                              <TextField
                                formMethod={formMethod}
                                rules={userForm.lastName.validate}
                                name={userForm.lastName.name}
                                errors={formMethod?.formState.errors}
                                type="text"
                                placeholder="Doe"
                                iconClass="icon-user"
                                defaultValue={formMethod?.getValues("lastName")}
                                controlId={"lastName"}
                              />
                            </div>
                          </div>
                          <div className="second_line">
                            <div className="email">
                              <TextField
                                formMethod={formMethod}
                                rules={userForm.email.validate}
                                name={userForm.email.name}
                                errors={formMethod?.formState.errors}
                                type="text"
                                placeholder="johndoe@gmail.com"
                                iconClass="icon-mail"
                                defaultValue={formMethod?.getValues("email")}
                                controlId={"email"}
                              />
                            </div>
                            <div className="code_number">
                              <Controller
                                control={formMethod.control}
                                name={userForm.mobileNumber.name}
                                rules={userForm.mobileNumber.validate}
                                render={({ field }) => (
                                  <PhoneInput
                                    {...field}
                                    placeholder="Enter phone number"
                                    international={true}
                                    defaultCountry="IN"
                                    id="mobileNumber"
                                  />
                                )}
                              />
                            </div>
                            {formMethod.formState.errors[
                              userForm.mobileNumber.name
                            ] && (
                              <span>
                                {
                                  formMethod.formState.errors[
                                    userForm.mobileNumber.name
                                  ].message
                                }
                              </span>
                            )}
                          </div>
                          <div className="button_line">
                            {/* <div className="first_button">
                              <CustomButton
                                title={t('SETTING_CHANG_PASSORD')}
                                onClick={onClickUserDetails}
                              ></CustomButton>
                            </div> */}
                            <div className="second_button">
                              <CustomButton title="Save"></CustomButton>
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  </HookForm>
                </div>
              </div>
            </Tab>
            <Tab eventKey="changepassword" title="Change Password">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="change_Password ">
                  <HookForm
                    defaultForm={{
                      current_password: "",
                      password: "",
                      confirm_password: "",
                    }}
                    init={(form) => setChangePasswordForm(form)}
                    onSubmit={onChangePassword}
                  >
                    {(formMethod) => {
                      return (
                        <div className="form">
                          <TextField
                            formMethod={formMethod}
                            controlId={"current_password"}
                            rules={changeForm.current_password.validate}
                            name={changeForm.current_password.name}
                            errors={formMethod?.formState.errors}
                            placeholder="Current Password"
                            type={passwordType === true ? "password" : "text"}
                            iconClass={
                              passwordType === true
                                ? "icon-hide1 cursor"
                                : "icon-eye1 cursor"
                            }
                            onIconClick={() => setPasswordType(!passwordType)}
                          />

                          <TextField
                            formMethod={formMethod}
                            controlId={"password"}
                            rules={changeForm.password.validate}
                            name={changeForm.password.name}
                            errors={formMethod?.errors}
                            placeholder="New Password"
                            type={
                              newpasswordtype === true ? "password" : "text"
                            }
                            iconClass={
                              newpasswordtype === true
                                ? "icon-hide1 cursor"
                                : "icon-eye1 cursor"
                            }
                            onIconClick={() =>
                              setNewPasswodType(!newpasswordtype)
                            }
                          />
                          <TextField
                            formMethod={formMethod}
                            controlId={"confirm_password"}
                            rules={{
                              required: Message.ERRORMESSAGE.PASSWORDEMPTY,
                              validate: (value) =>
                                value === formMethod.watch("password") ||
                                "The passwords do not match",
                            }}
                            name={changeForm.confirm_password.name}
                            errors={formMethod?.formState.errors}
                            placeholder="Confirm New Password"
                            type={
                              confirmPasswordType === true ? "password" : "text"
                            }
                            iconClass={
                              confirmPasswordType === true
                                ? "icon-hide1 cursor"
                                : "icon-eye1 cursor"
                            }
                            onIconClick={() =>
                              setConfirmPasswordType(!confirmPasswordType)
                            }
                          />

                          <CustomButton title="Update password"></CustomButton>
                        </div>
                      );
                    }}
                  </HookForm>
                </div>
              </div>
            </Tab>

            <Tab className="general" eventKey="General" title="General">
              <div className="middle_section">
                <div className="dropdown_box">
                  <div className="dropdown_section1">
                    <div className="dropdown_inner1">
                      <CustomDropdown
                        dropDownItems={dropdownItem}
                        placeholder="Asia/Calcutta"
                        selectedValue={staff}
                        onSelect={(selected) => setSelectedStaff(selected)}
                      ></CustomDropdown>
                    </div>
                    <div className="dropdown_inner2">
                      <CustomDropdown
                        dropDownItems={dropdownTourGuideValues}
                        placeholder="Tour Guide"
                        selectedValue={selectTourGuideDropdownValue}
                        onSelect={(e) => handleTourDropDownValue(e)}
                      ></CustomDropdown>
                    </div>
                  </div>
                  <div className="dropdown_section2">
                    <div className="dropdown_inner3">
                      <CustomDropdown
                        dropDownItems={dropdownItem2}
                        placeholder="Language"
                        selectedValue={language}
                        onSelect={onSelectLanguage}
                      ></CustomDropdown>
                    </div>
                    <div className="dropdown_inner4">
                      <CustomDropdown
                        dropDownItems={dropdownItem3}
                        placeholder="Light Mode"
                        selectedValue={staff}
                        onSelect={(selected) => setSelectedStaff(selected)}
                      ></CustomDropdown>
                    </div>
                  </div>
                  <div className="dropdown_section3">
                    <div className="dropdown_inner5">
                      <CustomDropdown
                        dropDownItems={dropdownItem4}
                        placeholder="Post Email Notification On"
                        selectedValue={staff}
                        onSelect={(selected) => setSelectedStaff(selected)}
                      ></CustomDropdown>
                    </div>
                    <div className="dropdown_inner6">
                      <CustomDropdown
                        dropDownItems={dropdownItem5}
                        placeholder="Download Report"
                        selectedValue={staff}
                        onSelect={(selected) => setSelectedStaff(selected)}
                      ></CustomDropdown>
                    </div>
                  </div>
                  <div className="button">
                    <div className="btn1">
                      <CustomButton
                        title={t("SETTING_BUTTON_DISCARD")}
                      ></CustomButton>
                    </div>
                    <div className="btn2">
                      <CustomButton
                        title={t("SETTING_BUTTON_SAVE")}
                      ></CustomButton>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab className="billing" eventKey="Billings" title="Billings">
              <div className="billing_Section">
                <div className="main_Container">
                  <div className="billing_Inner">
                    <i className="icon-tab tabIcon"></i>
                    <div className="listing_Section">
                      <div className="listing">
                        <div className="left_Section">
                          <div className="data_Listing">
                            <div className="scroll_Listing">
                              <BillingHeader
                                SrNoth="No"
                                Date="Date"
                                Subtype="Subscription  type"
                                Id="Invoice id"
                                Price="Price"
                                Action=""
                                SrNo="1"
                                dateInput="01/01/2021"
                                subsType="Professional plan (1 year)"
                                invoiceId="XT123SD2232878BNM"
                                price="$250"
                                downloadIcon
                              ></BillingHeader>
                            </div>
                          </div>
                          <div className="paginationSystemClass">
                            <PaginationSystem />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carddetails_Right">
                      <Label
                        title="Current plan details"
                        className="main_Title"
                      ></Label>
                      {/* <Scrollbars style={{ height: dimensions.height - 75 + 'px' }}> */}
                      <div className="content_Inner">
                        <div className="current_Plans">
                          <div className="details">
                            <Label title="Current Plan"></Label>
                            <Label title="Professional"></Label>
                          </div>
                          <div className="details">
                            <Label title="Cost"></Label>
                            <Label title="$25/Year"></Label>
                          </div>
                          <div className="details">
                            <Label title="Purchase Date"></Label>
                            <Label title="Mar 25,2021"></Label>
                          </div>
                          <div className="details">
                            <Label title="Renewal Date"></Label>
                            <Label title="Mar 25,2022"></Label>
                          </div>
                        </div>
                        <div className="bottom_Btn d-flex justify-content-end">
                          <CustomButton title="Cancel subscription"></CustomButton>
                          <CustomButton
                            tourselector="upgrade-btn"
                            title="Upgrade plan"
                          ></CustomButton>
                        </div>
                      </div>
                      <div className="card-details">
                        <Label
                          className="main_Title"
                          title="Default payment method"
                        ></Label>
                        <div className="card_Inner">
                          <div className="card_No">
                            <CustomRadio
                              labelTitle=""
                              id="1"
                              checked
                            ></CustomRadio>
                          </div>
                          <div className="visa_Type">
                            <span className="dots"></span>
                            <span className="dots"></span>
                            <span className="dots"></span>
                            <span className="dots"></span>
                            <Label title="1289"></Label>
                            <img
                              src={CardType}
                              alt="image"
                              style={{ maxWidth: "100%", height: "auto" }}
                              // fluid="true"
                              className="top_Patten"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-details">
                        <div className="card_Inner">
                          <div className="card_No">
                            <CustomRadio labelTitle="" id="2"></CustomRadio>
                          </div>
                          <div className="visa_Type">
                            <span className="dots"></span>
                            <span className="dots"></span>
                            <span className="dots"></span>
                            <span className="dots"></span>
                            <Label title="1289"></Label>
                            <img
                              src={CardType1}
                              alt="image"
                              style={{ maxWidth: "100%", height: "auto" }}
                              // fluid="true"
                              className="top_Patten"
                            />
                          </div>
                        </div>
                      </div>
                      <CustomButton
                        className="submitBtn cardBtn"
                        title="Manage cards"
                        tourselector="manage-card-btn"
                      ></CustomButton>
                      {/* </Scrollbars> */}
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab className="query" eventKey="Query" title="Query">
              <CustomButton
                className="filterIcon submitBtn create-QueryModal"
                onClick={handleButtonCreateQuery}
                title="Create Query"
                tourselector="create-query-btn"
              ></CustomButton>

              <div className="query-Section d-flex flex-column">
                <ul className="header d-flex p-0 m-0">
                  <li>Query ID</li>
                  <li>Raised On</li>
                  <li>Resolved On</li>
                  <li>Message</li>
                  <li>Comments</li>
                </ul>
                {queryData?.map((item) => {
                  console.log("02-02-24 006", item);
                  return (
                    <ul className="dataList d-flex p-0 m-0" key={item._id}>
                      <li>{item?.queryId}</li>
                      <li>{moment(item?.createdAt).format("MM/DD/YYYY")}</li>
                      <li>{item?.status}</li>
                      <li>{item?.description}</li>
                      {/* <li>{item.text2}</li> */}
                    </ul>
                  );
                })}
              </div>
              <PaginationSystem />
            </Tab>
            <Tab className="query" eventKey="WhiteLabel" title="White Label">
              <WhiteLabel />
            </Tab>
          </Tabs>
        </div>
      </Scrollbars>
      <div className="ModalStart">
        <ModalPopup
          showModal={showCreateQueryModal}
          onHide={() => setShowCreateQueryModal(false)}
          className="create_Query"
          headerTitle="Create Query"
          closeIcon={true}
        >
          <div className="query_Content">
            {/* <Label className="no" title={randomQID1}></Label> */}
            <HookForm
              defaultForm={{
                queryId: randomQID1,
                description: "",
              }}
              init={(form) => {
                setUserForm(form);
              }}
              onSubmit={onQueryFormSubmit}
            >
              {(formMethod) => {
                return (
                  <div className="query_section">
                    <div className="query_id">
                      <Label className="id" title="Query ID"></Label>
                      <TextField
                        formMethod={formMethod}
                        rules={createQuery.queryId.validate}
                        name={createQuery.queryId.name}
                        errors={formMethod?.formState.errors}
                        autoFocus={true}
                        type="text"
                        placeholder="Query Id*"
                        controlId={"queryId"}
                        defaultValue={formMethod?.getValues("queryId")}
                        disabled
                        // style={{ background: "red" }}
                      />
                    </div>

                    <div className="query_form">
                      <Label className="id" title="Message"></Label>

                      <TextField
                        formMethod={formMethod}
                        rules={createQuery.description.validate}
                        name={createQuery.description.name}
                        errors={formMethod?.formState.errors}
                        placeholder="This form helps us learn more about your query – so feel free to mention specific details and attach media files that might help us understand your query better..."
                        type="text"
                        textarea="textarea"
                      />
                      <div className="button_btm d-flex justify-content-end">
                        <CustomButton
                          className="cancel_Btn mr-2"
                          title="Cancel"
                        ></CustomButton>
                        <CustomButton
                          className="add_Btn submitBtn"
                          title="Add Query"
                        ></CustomButton>
                      </div>
                    </div>
                  </div>
                );
              }}
            </HookForm>
          </div>
        </ModalPopup>
      </div>
      <div className="chatboat-section position-absolute">
        <i className="icon-chatboat"></i>
      </div>
    </div>
  );
}

export default withRouter(User);
