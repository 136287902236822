import React, { useState } from "react";
import { Col, ListGroup, Nav, Navbar, Row, Tab } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import { Link, useHistory, withRouter } from "react-router-dom";
import ProfileImag from "../../assests/images/profile.png";
import Label from "../../components/UI/Label/Label";
import { useAppData } from "../../services/hooks";
import "./AdminHeader.scss";
const Header = (props) => {
  const [clickOutside, setClickOutside] = useState(true);
  const history = useHistory();

  const userDetailStateValue = useAppData(
    (r) => r.postPublisherSlice.users.UsersFullDetailsValue.data
  );

  let activeMenu = "";
  if (props.location.pathname.indexOf("/admin/admindashboardmarketing") != -1) {
    activeMenu = "videomarketing";
  } else if (props.location.pathname.indexOf("/admin/admindashboard") != -1) {
    activeMenu = "dashboard";
  } else if (props.location.pathname.indexOf("adminuielements") != -1) {
    activeMenu = "adminuielements";
  } else if (props.location.pathname.indexOf("/admin/adminuserprofile") != -1) {
    activeMenu = "userprofile";
  } else if (
    props.location.pathname.indexOf("/admin/adminsubscription") != -1
  ) {
    activeMenu = "subscription";
  } else if (props.location.pathname.indexOf("adminqueries") != -1) {
    activeMenu = "adminqueries";
  } else if (props.location.pathname.indexOf("/admin/adminblog") != -1) {
    activeMenu = "blogs";
  } else if (props.location.pathname.indexOf("/admin/adminterms") != -1) {
    activeMenu = "policies";
  }
  const renderTooltip1 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Dashboard
    </Tooltip>
  );
  const renderTooltip2 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      UI Elements
    </Tooltip>
  );
  const renderTooltip3 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      User Profile
    </Tooltip>
  );
  const renderTooltip4 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Subscription
    </Tooltip>
  );
  const renderTooltip5 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Queries
    </Tooltip>
  );
  const renderTooltip6 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Blogs
    </Tooltip>
  );
  const renderTooltip7 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Video Marketing
    </Tooltip>
  );
  const renderTooltip8 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Policies and T&C
    </Tooltip>
  );
  const renderTooltip9 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Log out
    </Tooltip>
  );
  const LogOut = () => {
    localStorage.removeItem("Admintoken");
    history.push("/admin/login");
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setClickOutside(false);
      }}
    >
      <div className="headerAdmin">
        <div className="container p-0">
          <div className="row no-gutters">
            <div>
              <Navbar>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse
                  id="basic-navbar-nav"
                  style={{ background: "red" }}
                >
                  <span className="responsive_title"> Publish</span>
                  <div>
                    <div className="profile_Section">
                      <img
                        src={
                          userDetailStateValue === undefined
                            ? ProfileImag
                            : userDetailStateValue.imageUrl
                        }
                        alt="image"
                        style={{ maxWidth: "100%", height: "auto" }}
                        // fluid="true"
                        className="main_Img"
                      />
                      <div className="border-bottom"></div>
                    </div>
                    <Nav
                      className="menuItem"
                      activeKey={activeMenu}
                      onSelect={() => setClickOutside(!clickOutside)}
                    >
                      <Nav.Link
                        eventKey="dashboard"
                        as={Link}
                        to="/admin/admindashboard"
                      >
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip1}
                        >
                          <i className="icon-home"></i>
                        </OverlayTrigger>
                      </Nav.Link>
                      <Nav.Link
                        eventKey="adminuielements"
                        as={Link}
                        to="/admin/adminuielements?landing"
                        // showSidebar={showMenu}
                      >
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip2}
                        >
                          <i className="icon-element"></i>
                        </OverlayTrigger>
                      </Nav.Link>
                      <Nav.Link
                        eventKey="userprofile"
                        as={Link}
                        to="/admin/adminuserprofile"
                        // showSidebar={showMenu}
                      >
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip3}
                        >
                          <i className="icon-users"></i>
                        </OverlayTrigger>
                      </Nav.Link>
                      <Nav.Link
                        eventKey="subscription"
                        as={Link}
                        to="/admin/adminsubscription"
                      >
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip4}
                        >
                          <i className="icon-cart"></i>
                        </OverlayTrigger>
                      </Nav.Link>
                      <Nav.Link
                        eventKey="adminqueries"
                        as={Link}
                        to="/admin/adminqueries?subscribers"
                        // showSidebar={showMenu}
                      >
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip5}
                        >
                          <i className="icon-query"></i>
                        </OverlayTrigger>
                      </Nav.Link>
                      <Nav.Link
                        eventKey="blogs"
                        as={Link}
                        to="/admin/adminblog"
                      >
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip6}
                        >
                          <i className="icon-blog"></i>
                        </OverlayTrigger>
                      </Nav.Link>
                      <Nav.Link
                        eventKey="videomarketing"
                        as={Link}
                        to="/admin/admindashboardmarketing"
                      >
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip7}
                        >
                          <i className="icon-video"></i>
                        </OverlayTrigger>
                      </Nav.Link>

                      <Nav.Link
                        eventKey="policies"
                        as={Link}
                        to="/admin/adminterms"
                      >
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip8}
                        >
                          <i className="icon-policies"></i>
                        </OverlayTrigger>
                      </Nav.Link>
                    </Nav>
                  </div>
                  <div className="colorIcon" style={{ marginBottom: "24px" }}>
                    <Nav.Link eventKey="log out" onClick={LogOut}>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip9}
                      >
                        {/* <i className="icon-setting"></i> */}
                        <i className="icon-logout1"></i>
                      </OverlayTrigger>
                    </Nav.Link>
                  </div>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        </div>

        {/* Sub Menu */}

        <div
          className={
            activeMenu === "adminuielements" && clickOutside
              ? "sub_Menu show"
              : "sub_Menu "
          }
        >
          <div className="inner_SabMenu">
            <Label title="UI Elements"></Label>
            <Tab.Container
              className="subMenu_Ul"
              id="list-group-tabs-example"
              defaultActiveKey="#landing"
            >
              <Row>
                <Col sm={8}>
                  <ListGroup>
                    <ListGroup.Item
                      className="subMenu_Li "
                      onClick={() =>
                        history.push("/admin/adminuielements?landing")
                      }
                      href="#landing"
                    >
                      Landing Page
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      onClick={() =>
                        history.push("/admin/adminuielements?features")
                      }
                      href="#Feature"
                    >
                      Feature Page
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      onClick={() =>
                        history.push("/admin/adminuielements?support")
                      }
                      href="#Support"
                    >
                      Support Page
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      onClick={() =>
                        history.push("/admin/adminuielements?about")
                      }
                      href="#About"
                    >
                      About us
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>

        {/* //social profile */}

        <div
          className={
            activeMenu === "adminqueries" && clickOutside
              ? "sub_Menu show"
              : "sub_Menu "
          }
        >
          <div className="inner_SabMenu">
            <Label title="Queries"></Label>
            <Tab.Container
              className="subMenu_Ul"
              id="list-group-tabs-example"
              defaultActiveKey="#Subscribers"
            >
              <Row>
                <Col sm={8}>
                  <ListGroup>
                    <ListGroup.Item
                      className="subMenu_Li "
                      onClick={() =>
                        history.push("/admin/adminqueries?subscribers")
                      }
                      href="#Subscribers"
                    >
                      Subscribers
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li "
                      onClick={() =>
                        history.push("/admin/adminqueries?unsubscribers")
                      }
                      href="#Unsubscribers"
                    >
                      Unsubscribers
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      onClick={() =>
                        history.push("/admin/adminqueries?supports")
                      }
                      href="#Help/Supports"
                    >
                      Help/Supports
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default withRouter(Header);
