import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { PostPublisherSlice } from "./Redux_saga/postPublisherSlice";
import { globalSlice } from "./Redux_saga/globalSlice";

export function createRootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    postPublisherSlice: PostPublisherSlice.reducer,
    globalSlice: globalSlice.reducer,
  });
}
