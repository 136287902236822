import React, { useState } from "react";
import { Link } from "react-router-dom";
import FilterDropdown from "../UI/FilterDropdown/FilterDropdown";
import "./BillingHeader.scss";

const BillingHeader = (props) => {
  let {
    SrNoth,
    Date,
    Subtype,
    Price,
    Action,
    Id,
    subsType,
    price,
    editIcon,
    SrNo,
    dateInput,
    invoiceId,
    downloadIcon,
  } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    // <div className='tab_Heading'>
    //     <Label className='heading srno' title={SrNo}></Label>
    //     <Label className='heading dateInput' title={Date}></Label>
    //     <Label className='heading accType' title={Subtype}></Label>
    //     <Label className='heading status' title={Id}></Label>
    //     <Label className='heading price' title={Price}></Label>
    //     <Label className='heading action' title={Action}></Label>
    // </div>

    <table
      id="fantasyTable"
      className="display responsive no-wrap order-column"
      width="100%"
    >
      <thead>
        <tr>
          <th className="heading srno">{SrNoth}</th>
          <th className="heading dateInput">{Date}</th>
          <th className="heading accType">{Subtype}</th>
          <th className="heading status">{Id}</th>
          <th className="heading price">{Price}</th>
          <th className="heading action">{Action}</th>
        </tr>
      </thead>
      {SrNo && <td className="listing_Head srNo">{SrNo}</td>}
      {dateInput && (
        <td className="listing_Head dateInput" data-title="Date">
          {" "}
          {dateInput}
        </td>
      )}
      {subsType && (
        <td className="listing_Head subsType" data-title="Subscription  type">
          {" "}
          {subsType}
        </td>
      )}
      {invoiceId && (
        <td className="listing_Head invoice" data-title="Invoice id">
          {invoiceId}
        </td>
      )}
      {price && (
        <td className="listing_Head price" data-title="Invoice id">
          {price}
        </td>
      )}
      {editIcon && (
        <td className="actionBtn">
          {" "}
          <div className="edit_Dropdown">
            <i
              className="icon-editr1"
              onClick={() => setShowDropdown(!showDropdown)}
            ></i>
          </div>
        </td>
      )}
      {downloadIcon && (
        <td className="actionBtn">
          {" "}
          <div className="edit_Dropdown">
            <i className="icon-circle-download"></i>
          </div>
        </td>
      )}
      {showDropdown && (
        <>
          <div className="overLay" onClick={() => setShowDropdown(false)}></div>
          <FilterDropdown>
            <ul className="dropdown_List">
              <li>
                <Link to="/publish">Edit post</Link>
              </li>
              <li>
                <Link to="#">Delete post</Link>
              </li>
            </ul>
          </FilterDropdown>
        </>
      )}
    </table>
  );
};

export default BillingHeader;
