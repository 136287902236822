import createSagaMiddleware from "@redux-saga/core";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { configureStore } from "@reduxjs/toolkit";
import { createRootReducer } from "./reducer";
import { rootSagas } from "./sagas";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

// Configure redux-persist
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

export function createStore() {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .prepend(routerMiddleware(history))
        .concat(sagaMiddleware),
  });

  const persistor = persistStore(store);

  return { store, persistor };
}

// Create store and persistor
const { store, persistor } = createStore();

// Run sagas
sagaMiddleware.run(rootSagas);

// Export the necessary identifiers
export { persistor,  store };
export default store;
