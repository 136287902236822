import React, { useState } from "react";
import { Card } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Scrollbars from "react-custom-scrollbars";
import ReactHighcharts from "react-highcharts";
import { withRouter } from "react-router-dom";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import CustomDropdown from "../../components/UI/CustomDropdown/CustomDropdown";
import Label from "../../components/UI/Label/Label";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { adminDashBoardSteps } from "../Steps";
import "./AdminDashboard.scss";
var chartConfigOverview = {
  colors: ["#5696FA"],
  chart: {
    type: "column",
    backgroundColor: "#FFFFFF",
  },
  title: {
    text: '<span style="font-size: 16px; color:#000000;font-weight:600">Profit Analytics</span>',
    align: "left",
    verticalAlign: "top",
  },

  xAxis: {
    categories: [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUNE",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  series: [
    {
      name: "Visting",
      data: [
        49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1,
        95.6, 54.4,
      ],
    },
  ],

  legend: {
    align: "right",
    verticalAlign: "top",
  },
  showInLegend: false,
  credits: {
    enabled: false,
  },
};

var chartConfigPost = {
  colors: ["#5696FA", "#F45B0F", "#16056B", "#3F3F47"],
  chart: {
    type: "column",
    backgroundColor: "#FFFFFF",
  },
  title: {
    text: '<span style="font-size: 18px; color:#000000;font-weight:600">Post Stats</span>',
    align: "left",
    verticalAlign: "center",
  },

  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: false,
        distance: -50,
        style: {
          fontWeight: "bold",
          color: "white",
        },
      },
      startAngle: -90,
      endAngle: -90,
      center: ["50%", "60%"],
      size: "80%",
      showInLegend: true,
    },
  },
  series: [
    {
      type: "pie",
      name: "Share",
      innerSize: "75%",
      data: [
        ["Success", 33.3],
        ["Pending", 33.3],
        ["Draft", 13.33],
        ["Failed", 33.33],
      ],
    },
  ],
  legend: {
    align: "center",
    verticalAlign: "bottom",
  },
  credits: {
    enabled: false,
  },
};
var chartConfigAgeGroup = {
  colors: ["#5696FA", "#F45B0F", "#16056B", "#3F3F47"],
  chart: {
    type: "column",
    backgroundColor: "#FFFFFF",
  },
  title: {
    text: '<span style="font-size: 18px; color:#000000;font-weight:600">Age group</span>',
    align: "left",
    verticalAlign: "top",
  },

  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: false,
        distance: -50,
        style: {
          fontWeight: "bold",
          color: "white",
        },
      },
      startAngle: -90,
      endAngle: -90,
      center: ["50%", "50%"],
      size: "80%",
      showInLegend: true,
    },
  },
  series: [
    {
      type: "pie",
      name: "Share",
      innerSize: "75%",
      data: [
        ["Below 20", 33.3],
        ["Below 40", 33.3],
        ["Above 40", 13.33],
        ["Above 40", 33.33],
      ],
    },
  ],
  legend: {
    align: "right",
    verticalAlign: "top",
  },
  credits: {
    enabled: false,
  },
};

var chartConfigGender = {
  colors: ["#A0A3BD"],
  chart: {
    type: "line",
    backgroundColor: "#FFFFFF",
  },

  title: {
    text: '<span style="font-size: 18px; color:#000000;font-weight:600">Gender</span>',
    align: "left",
  },

  xAxis: {
    categories: ["Male", "Female", "Transgender"],
  },

  plotOptions: {
    line: {
      dataLabels: {
        enabled: false,
      },
      enableMouseTracking: false,
    },
  },
  series: [
    {
      name: "",
      data: [0, 2, 3, 0],
      showInLegend: false,
    },
  ],

  credits: {
    enabled: false,
  },
};
var chartConfigDemography = {
  chart: {
    type: "column",
    backgroundColor: "#FFFFFF",
  },
  colors: ["#16056B"],

  title: {
    text: '<span style="font-size: 18px; color:#000000;font-weight:600">Demography</span>',
    align: "left",
  },

  xAxis: {
    type: "category",
    lineColor: "transparent",
  },
  yAxis: {
    title: {
      text: "Audience",
    },
    gridLineColor: "transparent",
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: "{point.y:.1f}%",
      },
    },
  },

  tooltip: {
    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    pointFormat:
      '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
  },

  series: [
    {
      name: "Browsers",
      colorByPoint: true,
      data: [
        {
          name: "India",
          y: 62.74,
          drilldown: "India",
        },
        {
          name: "US",
          y: 50,
          drilldown: "US",
        },
        {
          name: "UK",
          y: 40,
          drilldown: "UK",
        },
        {
          name: "Canda",
          y: 30,
          drilldown: "Canda",
        },
        {
          name: "China",
          y: 70,
          drilldown: "China",
        },
        {
          name: "Poland",
          y: 90,
          drilldown: "Poland",
        },
      ],
    },
  ],
  showInLegend: false,
  credits: {
    enabled: false,
  },

  // plotOptions: {
  //   series: {
  //     pointWidth: 8,
  //   },
  // },
};

const dropdownItemMonth = [
  {
    id: "1",
    value: "January",
  },
  {
    id: "2",
    value: "February",
  },
  {
    id: "3",
    value: "March",
  },

  {
    id: "4",
    value: "April",
  },
  {
    id: "5",
    value: "May",
  },
  {
    id: "6",
    value: "June",
  },
  {
    id: "7",
    value: "July",
  },
  {
    id: "8",
    value: "August",
  },
  {
    id: "9",
    value: "September",
  },
  {
    id: "10",
    value: "October",
  },
  {
    id: "11",
    value: "November",
  },
  {
    id: "12",
    value: "December",
  },
];
const dropdownItemYear = [
  {
    id: "1",
    value: "2000",
  },
  {
    id: "2",
    value: "2001",
  },
  {
    id: "3",
    value: "2002",
  },

  {
    id: "4",
    value: "2003",
  },
  {
    id: "5",
    value: "2004",
  },
  {
    id: "6",
    value: "2005",
  },
];
const dropdownAll = [
  {
    id: "1",
    value: "All",
  },
  {
    id: "2",
    value: "All 1",
  },
  {
    id: "3",
    value: "All 2",
  },
];

function AdminDashboard() {
  const [selectItem, setSelectedData] = useState();
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();

  const renderTooltip1 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Here is description about total profit.
    </Tooltip>
  );
  const renderTooltip2 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Here is description about Total Users
    </Tooltip>
  );
  const renderTooltip3 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Here is description about Total Post
    </Tooltip>
  );
  const renderTooltip4 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Here is description about Total Query
    </Tooltip>
  );
  const renderTooltip5 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Here is description about Total Request
    </Tooltip>
  );

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObjAdmin")
    );
    let newObj = { ...previousLocalStorageValue, adminDashboard: false };
    localStorage.setItem("guideTourObjAdmin", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(
    localStorage.getItem("guideTourObjAdmin")
  );
  return (
    <div className="admin_Dashboard">
      <div className="no-design-section justify-content-center align-items-center vh-100">
        <Label
          title="Mobile design not supported"
          className="no-design"
        ></Label>
      </div>
      <AdminHeader />
      <GuidedTour
        steps={adminDashBoardSteps}
        onRequestClose={handleOnRequestClose}
        isOpen={
          guideTourGetItemValue ? guideTourGetItemValue.adminDashboard : true
        }
      />
      <div className="main_Container">
        <Scrollbars className="admin_Dashboard_Scroll">
          <div className="dashboard_Section">
            <Label className="page_Title" title="Dashboard"></Label>
            <div
              className="card_Section d-flex justify-content-initial"
              tourselector="top-section"
            >
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip1}
              >
                <Card className="card_Width">
                  <div className="details d-flex justify-content-between align-items-center">
                    <div className="bg_Icon d-flex justify-content-center align-items-center">
                      <i className="icon-inr"></i>
                    </div>
                    <div className="counts">
                      <Label className="nos" title="50,000"></Label>
                      <Label
                        className="count-Text"
                        title="Total Profit"
                      ></Label>
                    </div>
                  </div>
                </Card>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip2}
              >
                <Card className="card_Width">
                  <div className="details d-flex justify-content-between align-items-center">
                    <div className="bg_Icon d-flex justify-content-center align-items-center">
                      <i className="icon-user-double"></i>
                    </div>
                    <div className="counts">
                      <Label className="nos" title="50,000"></Label>
                      <Label className="count-Text" title="Total Users"></Label>
                    </div>
                  </div>
                </Card>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip3}
              >
                <Card className="card_Width">
                  <div className="details d-flex justify-content-between align-items-center">
                    <div className="bg_Icon d-flex justify-content-center align-items-center">
                      <i className="icon-user-filled"></i>
                    </div>
                    <div className="counts">
                      <Label className="nos" title="50,000"></Label>
                      <Label className="count-Text" title="Total Post"></Label>
                    </div>
                  </div>
                </Card>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip4}
              >
                <Card className="card_Width">
                  <div className="details d-flex justify-content-between align-items-center">
                    <div className="bg_Icon d-flex justify-content-center align-items-center">
                      <i className="icon-question"></i>
                    </div>
                    <div className="counts">
                      <Label className="nos" title="50,000"></Label>
                      <Label className="count-Text" title="Total Query"></Label>
                    </div>
                  </div>
                </Card>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip5}
              >
                <Card className="card_Width mr-0">
                  <div className="details d-flex justify-content-between align-items-center">
                    <div className="bg_Icon d-flex justify-content-center align-items-center">
                      <i className="icon-user-filled"></i>
                    </div>
                    <div className="counts">
                      <Label className="nos" title="50,000"></Label>
                      <Label
                        className="count-Text"
                        title="Total Request"
                      ></Label>
                    </div>
                  </div>
                </Card>
              </OverlayTrigger>
            </div>
            <div className="main_ChartSection">
              <div className="chart_Part">
                <div className="chart_Section d-flex mb-4">
                  <div
                    className="w75 position-relative"
                    tourselector="profit-analysis"
                  >
                    <div className="position-absolute main_Dropdown">
                      <div className="dropdown_section d-flex">
                        <CustomDropdown
                          dropDownItems={dropdownItemMonth}
                          placeholder="Month"
                          selectedValue={selectItem}
                          onSelect={(selected) => setSelectedData(selected)}
                        ></CustomDropdown>
                        <CustomDropdown
                          dropDownItems={dropdownItemYear}
                          placeholder="Year"
                          selectedValue={selectItem}
                          onSelect={(selected) => setSelectedData(selected)}
                        ></CustomDropdown>
                      </div>
                    </div>
                    <ReactHighcharts
                      config={chartConfigOverview}
                    ></ReactHighcharts>
                  </div>
                  <div
                    className="w25 position-relative"
                    tourselector="post-stats"
                  >
                    <div className="position-absolute main_Dropdown post_Dropdown">
                      <div className="dropdown_section d-flex">
                        <CustomDropdown
                          dropDownItems={dropdownAll}
                          placeholder="All"
                          selectedValue={selectItem}
                          onSelect={(selected) => setSelectedData(selected)}
                        ></CustomDropdown>
                        <CustomDropdown
                          dropDownItems={dropdownItemMonth}
                          placeholder="Month"
                          selectedValue={selectItem}
                          onSelect={(selected) => setSelectedData(selected)}
                        ></CustomDropdown>
                        <CustomDropdown
                          dropDownItems={dropdownItemYear}
                          placeholder="Year"
                          selectedValue={selectItem}
                          onSelect={(selected) => setSelectedData(selected)}
                        ></CustomDropdown>
                      </div>
                    </div>
                    <div className="box_Shadow">
                      <ReactHighcharts
                        config={chartConfigPost}
                      ></ReactHighcharts>
                    </div>
                  </div>
                </div>
                <div className="chart_Section d-flex">
                  <div className="w30" tourselector="age-group-stats">
                    <ReactHighcharts
                      config={chartConfigAgeGroup}
                    ></ReactHighcharts>
                  </div>
                  <div className="w30" tourselector="gender-stats">
                    <ReactHighcharts
                      config={chartConfigGender}
                    ></ReactHighcharts>
                  </div>
                  <div className="w30 mr-0" tourselector="demography">
                    <ReactHighcharts
                      config={chartConfigDemography}
                    ></ReactHighcharts>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
}

export default withRouter(AdminDashboard);
