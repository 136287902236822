import React from "react";
import { useDispatch } from "react-redux";
import { hideLoaderAction } from "../../Redux_saga/postPublisherSlice";
import { useAppData } from "../../services/hooks";
import "./Loader.scss";
const Loader = () => {
  const dispatch = useDispatch();
  const loader = useAppData((r) => r.postPublisherSlice.loader);

  return (
    <>
      {loader ? (
        <div
          onClick={() => {
            dispatch(hideLoaderAction());
          }}
          className={"spinnerWrapper"}
        >
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default Loader;
