import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Scrollbars from "react-custom-scrollbars";
import { withRouter } from "react-router-dom";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import HookForm from "../../components/HookForm/HookForm";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import CustomCalendar from "../../components/UI/CustomCalendar/CustomCalendar";
import Label from "../../components/UI/Label/Label";
import TextField from "../../components/UI/TextField/TextField";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { adminTermSteps } from "../Steps";

import "./AdminTerms.scss";

const loginForm = {
  email: {
    name: "email",
  },
};

function AdminTermsPage() {
  const [form, setLoginForm] = React.useState();
  const [startDate, setStartDate] = useState(new Date());
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();
  // console.log(form);
  const onFormSubmit = () => {};

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObjAdmin")
    );
    let newObj = { ...previousLocalStorageValue, adminTerm: false };
    localStorage.setItem("guideTourObjAdmin", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(
    localStorage.getItem("guideTourObjAdmin")
  );

  return (
    <div className="admin_Terms">
      <GuidedTour
        steps={adminTermSteps}
        onRequestClose={handleOnRequestClose}
        isOpen={guideTourGetItemValue ? guideTourGetItemValue.adminTerm : true}
      />
      <div className="no-design-section justify-content-center align-items-center vh-100">
        <Label
          title="Mobile design not supported"
          className="no-design"
        ></Label>
      </div>
      <AdminHeader />
      <div className="main_Container">
        <Scrollbars className="admin_Terms_Scroll">
          <div className="dashboard_Section ">
            <div className="d-flex justify-content-between align-items-center">
              <div className="content_Header pt-4 termsHeader">
                <Label className="page_Title" title="Term of Use"></Label>
                <Label
                  className="inner_Title"
                  title="View and manage Term of Use"
                ></Label>
              </div>
              <div className="content_Header pt-4 policyHeader">
                <Label className="page_Title" title="Policy of Use"></Label>
                <Label
                  className="inner_Title"
                  title="View and manage Policy of Use"
                ></Label>
              </div>
              <div className="right_Button d-flex align-items-end pr-3">
                <CustomButton
                  className="submitBtn save"
                  title="Save"
                ></CustomButton>
                <CustomButton
                  className="submitBtn publish"
                  title="Publish now"
                  tourselector="admin-term-btn"
                ></CustomButton>
              </div>
            </div>
            <div className="divider"></div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <Col sm={3} className="tabsOfTerm">
                  <Nav
                    variant="pills"
                    className="right_Button d-flex align-items-end tab_Buttons"
                  >
                    <Nav.Item>
                      <Nav.Link tourselector="admin-term-use" eventKey="first">
                        Term of Use
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        tourselector="admin-term-policy"
                        eventKey="second"
                      >
                        Privacy Policy
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>

                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="input_Section d-flex flex-column ">
                        <Label
                          className="center_Text d-flex justify-content-center "
                          title="Update Term of Service Header Section"
                        ></Label>

                        <div className="box-border d-flex  flex-column">
                          <div className="center_Section">
                            <HookForm
                              defaultForm={{}}
                              init={(form) => setLoginForm(form)}
                              onSubmit={onFormSubmit}
                            >
                              {(formMethod) => {
                                return (
                                  <div className="form">
                                    <TextField
                                      formMethod={formMethod}
                                      rules={loginForm.email.validate}
                                      name={loginForm.email.name}
                                      errors={formMethod?.errors}
                                      autoFocus={true}
                                      type="text"
                                      placeholder="Main Title"
                                      iconClass=""
                                    />
                                    <div className="calenderMain">
                                      <CustomCalendar
                                        selected={startDate}
                                        onChange={(e) => setStartDate(e)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Date"
                                        renderCustomHeader={true}
                                      ></CustomCalendar>
                                    </div>
                                    <div className="textarea">
                                      <TextField
                                        formMethod={formMethod}
                                        rules={loginForm.email.validate}
                                        name={loginForm.email.name}
                                        errors={formMethod?.errors}
                                        autoFocus={false}
                                        type="text"
                                        placeholder="Paragraph one"
                                        textarea="textarea"
                                      />
                                    </div>
                                  </div>
                                );
                              }}
                            </HookForm>
                            <CustomButton
                              className="submitBtn addCard mr-1"
                              title="Update"
                            ></CustomButton>
                          </div>
                        </div>
                        <div className="box-border d-flex justify-content-center flex-column">
                          <Label
                            className="center_Text d-flex justify-content-center"
                            title="Update Term of Service"
                          ></Label>
                          <div className="center_Section paddTop">
                            <HookForm
                              defaultForm={{}}
                              init={(form) => setLoginForm(form)}
                              onSubmit={onFormSubmit}
                            >
                              {(formMethod) => {
                                return (
                                  <div className="form">
                                    <TextField
                                      formMethod={formMethod}
                                      rules={loginForm.email.validate}
                                      name={loginForm.email.name}
                                      errors={formMethod?.errors}
                                      autoFocus={false}
                                      type="text"
                                      placeholder="Main Title"
                                      iconClass=""
                                    />

                                    <div className="textarea">
                                      <TextField
                                        formMethod={formMethod}
                                        rules={loginForm.email.validate}
                                        name={loginForm.email.name}
                                        errors={formMethod?.errors}
                                        autoFocus={false}
                                        type="text"
                                        placeholder="Main Title"
                                        textarea="textarea"
                                      />
                                    </div>
                                  </div>
                                );
                              }}
                            </HookForm>

                            <CustomButton
                              className="submitBtn addCard mr-1"
                              title="Add Paragraph"
                            ></CustomButton>
                            <CustomButton
                              className="submitBtn addCard mr-1"
                              title="Add more"
                            ></CustomButton>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="input_Section d-flex flex-column">
                        <Label
                          className="center_Text d-flex justify-content-center"
                          title="Update Privacy Policy Header Section"
                        ></Label>

                        <div className="box-border d-flex justify-content-center flex-column">
                          <div className="center_Section">
                            <HookForm
                              defaultForm={{}}
                              init={(form) => setLoginForm(form)}
                              onSubmit={onFormSubmit}
                            >
                              {(formMethod) => {
                                return (
                                  <div className="form">
                                    <TextField
                                      formMethod={formMethod}
                                      rules={loginForm.email.validate}
                                      name={loginForm.email.name}
                                      errors={formMethod?.errors}
                                      autoFocus={false}
                                      type="text"
                                      placeholder="Main Title"
                                      iconClass=""
                                    />
                                    <div className="calenderMain">
                                      <CustomCalendar
                                        selected={startDate}
                                        onChange={(e) => setStartDate(e)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Date"
                                        renderCustomHeader={true}
                                      ></CustomCalendar>
                                    </div>
                                    <div className="textarea">
                                      <TextField
                                        formMethod={formMethod}
                                        rules={loginForm.email.validate}
                                        name={loginForm.email.name}
                                        errors={formMethod?.errors}
                                        autoFocus={false}
                                        type="text"
                                        placeholder="Paragraph one"
                                        textarea="textarea"
                                      />
                                    </div>
                                  </div>
                                );
                              }}
                            </HookForm>
                            <CustomButton
                              className="submitBtn addCard mr-1"
                              title="Update"
                            ></CustomButton>
                          </div>
                        </div>
                        <div className="box-border d-flex justify-content-center flex-column">
                          <Label
                            className="center_Text d-flex justify-content-center"
                            title="Update Privacy Policy"
                          ></Label>
                          <div className="center_Section paddTop">
                            <HookForm
                              defaultForm={{}}
                              init={(form) => setLoginForm(form)}
                              onSubmit={onFormSubmit}
                            >
                              {(formMethod) => {
                                return (
                                  <div className="form">
                                    <TextField
                                      formMethod={formMethod}
                                      rules={loginForm.email.validate}
                                      name={loginForm.email.name}
                                      errors={formMethod?.errors}
                                      autoFocus={false}
                                      type="text"
                                      placeholder="Main Title"
                                      iconClass=""
                                    />

                                    <div className="textarea">
                                      <TextField
                                        formMethod={formMethod}
                                        rules={loginForm.email.validate}
                                        name={loginForm.email.name}
                                        errors={formMethod?.errors}
                                        autoFocus={false}
                                        type="text"
                                        placeholder="Main Title"
                                        textarea="textarea"
                                      />
                                    </div>
                                  </div>
                                );
                              }}
                            </HookForm>

                            <CustomButton
                              className="submitBtn addCard mr-1"
                              title="Add Paragraph"
                            ></CustomButton>
                            <CustomButton
                              className="submitBtn addCard mr-1"
                              title="Add more"
                            ></CustomButton>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
}

export default withRouter(AdminTermsPage);
