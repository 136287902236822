import React from "react";
import noDataImg from "../../../src/assests/images/noDataImg.png";

const NoApiResponse = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        color: "black",
        fontWeight: "bold",
        marginTop: 50,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          style={{
            width: 300,
            height: 300,
            borderRadius: "50%",
            alignSelf: "center",
            // opacity: 0.5,
          }}
          src={noDataImg}
          alt="nodata"
        ></img>
        <p
          style={{
            color: "red",
            fontWeight: "bold",
            fontSize: 20,
            marginTop: -72,
          }}
        >
          No data Found
        </p>
      </div>
    </div>
  );
};

export default NoApiResponse;
