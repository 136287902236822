import React, { useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { withRouter } from "react-router-dom";
import ProfileImg from "../../assests/images/tab.png";
import ProfileImg1 from "../../assests/images/tab1.png";
import ProfileImg2 from "../../assests/images/tab2.png";
import Header from "../../components/Header/Header";
import Label from "../../components/UI/Label/Label";
import SearchBox from "../../components/UI/SearchBox/SearchBox";
import FilterForm from "./Filter/FilterForm";
import ProfileList from "./ProfileList/ProfileList";
import "./SocialProfile_List.scss";
import NoApiResponse from "../../components/NoApiData/NoApiResponse";
import PaginationSystem from "../../components/Pagination/PaginationSystem";
import { useAppData } from "../../services/hooks";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { socialProfileListSteps } from "../Steps";
import {
  getUsersFullDetailsAction,
  playNPauseAccountAction,
} from "../../Redux_saga/postPublisherSlice";
import { useDispatch } from "react-redux";
import twittericon from "../../assests/images/twittericon.png";
import linkedinicon from "../../assests/images/linkedinicon.png";
import instagramicon from "../../assests/images/instagram.png";
import fbicon from "../../assests/images/fbicon.png";
import youtubeicon from "../../assests/images/youtubeicon.png";
import AddProfileDropdownList from "./AddProfileDropdownList";

const SocialProListScreen = () => {
  const dispatch = useDispatch();
  const dimensions = {
    height: window.innerHeight,
    width: window.innerWidth,
  };
  const messageboxHeight = 0;
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [showTab, setShowTab] = useState(false);
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();

  const [facebook, setFacebook] = useState();
  const [instagram, setInstagram] = useState();
  const [twitter, setTwitter] = useState();
  const [linkedin, setLinkedin] = useState();
  const [youtube, setYoutube] = useState();

  const Token = useAppData((r) => r.postPublisherSlice?.tokens);
  const User = useAppData((r) => r.postPublisherSlice?.user);

  useEffect(() => {
    if (User) {
      setFacebook(User.fb[0]);
      setInstagram(User.instagram);
      setTwitter(User.twitter);
      setLinkedin(User.linkedIn);
      setYoutube(User.youtube);
    }
  }, [User]);

  const [facebookData, setFacebookData] = useState();
  const [instagramData, setInstagramData] = useState();
  const [twitterData, setTwitterData] = useState();
  const [linkedinData, setLinkedinData] = useState();
  const [youtubeData, setYoutubeData] = useState();

  const [filteredFacebookData, setFilteredFacebookData] = useState();
  const [filteredInstagramData, setFilteredInstagramData] = useState();
  const [filteredTwitterData, setFilteredTwitterData] = useState();
  const [filteredLinkedinData, setFilteredLinkedinData] = useState();
  const [filteredYoutubeData, setFilteredYoutubeData] = useState();

  const [paginatedFacebookData, setPaginatedFacebookData] = useState();
  const [paginatedInstagramData, setPaginatedInstagramData] = useState();
  const [paginatedTwitterData, setPaginatedTwitterData] = useState();
  const [paginatedLinkedinData, setPaginatedLinkedinData] = useState();
  const [paginatedYoutubeData, setPaginatedYoutubeData] = useState();

  const [currentPageFacebook, setCurrentPageFacebook] = useState(1);
  const [currentPageTwitter, setCurrentPageTwitter] = useState(1);
  const [currentPageLinkedIn, setCurrentPageLinkedIn] = useState(1);
  const [currentPageInstagram, setCurrentPageInstagram] = useState(1);
  const [currentPageYoutube, setCurrentPageYoutube] = useState(1);

  const itemsPerPage = 3;

  const handlePageChange = (page, tab) => {
    switch (tab) {
      case "Facebook":
        setCurrentPageFacebook(page);
        break;
      case "Twitter":
        setCurrentPageTwitter(page);
        break;
      case "LinkedIn":
        setCurrentPageLinkedIn(page);
        break;
      case "Instagram":
        setCurrentPageInstagram(page);
        break;
      case "Youtube":
        setCurrentPageYoutube(page);
        break;
      default:
        break;
    }
  };

  const OnTabClick = () => {
    setShowTab(!showTab);
  };
  const [active, setActive] = useState("");
  const profilesData = [
    {
      userName: "Facebook",
      linkName: "Facebook account’s page",
      profileImg: fbicon,
    },
    {
      userName: "LinkedIn",
      linkName: "LinkedIn account’s page",
      profileImg: linkedinicon,
    },
    {
      userName: "Twitter",
      linkName: "Twitter account’s page",
      profileImg: twittericon,
    },
    {
      userName: "Instagram",
      linkName: "Instagram account’s page",
      profileImg: instagramicon,
    },
    {
      userName: "Youtube",
      linkName: "Youtube account’s page",
      profileImg: youtubeicon,
    },
  ];

  let search = window.location.search;
  let params = search.split("?")[1];
  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObj")
    );
    let newObj = { ...previousLocalStorageValue, socialprofilelist: false };
    localStorage.setItem("guideTourObj", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(localStorage.getItem("guideTourObj"));

  useEffect(() => {
    if (facebook) {
      setFacebookData(facebook);
      setFilteredFacebookData({
        ...facebook,
        combined: [...facebook.pages, ...facebook.groups],
      });
    }
    if (instagram) {
      setInstagramData(instagram);
      setFilteredInstagramData(instagram);
    }
    if (linkedin) {
      setLinkedinData(linkedin);
      setFilteredLinkedinData(linkedin);
    }

    if (twitter) {
      setTwitterData(twitter);
      setFilteredTwitterData(twitter);
    }

    if (youtube) {
      setYoutubeData(youtube);
      setFilteredYoutubeData(youtube);
    }
  }, [facebook, twitter, linkedin, instagram, youtube]);

  const handleSearchFacebook = (query) => {
    const pages = facebook.pages.filter((page) =>
      page.name.toLowerCase().includes(query.toLowerCase())
    );
    const groups = facebook.groups.filter((group) =>
      group.name.toLowerCase().includes(query.toLowerCase())
    );    

    setFilteredFacebookData({
      ...facebookData,
      pages: pages,
      groups: groups,
      combined: [...pages, ...groups],
    });
  };

  const handleSearchTwitter = (query) => {
    const twtr = twitter.filter((profile) =>
      profile.screenName.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredTwitterData(twtr);
  };

  const handleSearchLinkedin = (query) => {
    const linkIn = linkedin.filter((profile) =>
      profile.firstName.toLowerCase().includes(query.toLowerCase()) || profile.lastName.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredLinkedinData(linkIn);
  };

  const handleSearchInstagram = (query) => {
    const insta = instagram.filter((business) => {
      return business.profile.instagram_business_account_id.includes(query);
    });
    setFilteredInstagramData(insta);
  };

  const handleSearchYoutube = (query) => {
    const ytube = youtube.filter((channel) => {
      return channel.snippet.title.toLowerCase().includes(query.toLowerCase());
    });

    setFilteredYoutubeData(ytube);
  };

  const handleFilterChange = (selectedStatus) => {
    //facebook
    let pages = [],
      groups = [];

    if (!selectedStatus) {
      pages = [...facebookData.pages];
      groups = [...facebookData.groups];
    } else if (+selectedStatus.id === 1 || +selectedStatus.id === 2) {
      const isActive = selectedStatus.id === "2";
      pages = facebookData.pages.filter((page) => page.isActive === isActive);

      groups = facebookData.groups.filter(
        (group) => group.isActive === isActive
      );
    } else if (+selectedStatus.id === 3 || +selectedStatus.id === 4) {
      const isPaused = selectedStatus.id === "3";

      pages = facebookData.pages.filter(
        (page) => page.isPaused === isPaused && page.isActive
      );

      groups = facebookData.groups.filter(
        (group) => group.isPaused === isPaused && group.isActive
      );
    }

    setFilteredFacebookData({
      ...facebookData,
      pages: pages,
      groups: groups,
      combined: [...pages, ...groups],
    });

    //twitter
    let twtr = [];
    if (!selectedStatus) {
      twtr = [...twitterData];
    } else {
      if (+selectedStatus.id === 1 || +selectedStatus.id === 2) {
        const isActive = selectedStatus.id === "2";
        twtr = twitterData.filter((profile) => profile.isActive === isActive);
      } else if (+selectedStatus.id === 3 || +selectedStatus.id === 4) {
        const isPaused = selectedStatus.id === "3";
        twtr = twitterData.filter((profile) => profile.isPaused === isPaused);
      }
    }

    setFilteredTwitterData(twtr);

    //linkedin
    let linkIn = [];

    if (!selectedStatus) {
      linkIn = [...linkedinData];
    } else {
      if (+selectedStatus.id === 1 || +selectedStatus.id === 2) {
        const isActive = selectedStatus.id === "2";
        linkIn = linkedinData.filter(
          (profile) => profile.isActive === isActive
        );
      } else if (+selectedStatus.id === 3 || +selectedStatus.id === 4) {
        const isPaused = selectedStatus.id === "3";
        linkIn = linkedinData.filter(
          (profile) => profile.isPaused === isPaused
        );
      }
    }

    setFilteredLinkedinData(linkIn);

    //instagram
    let insta = [];

    if (!selectedStatus) {
      insta = [...instagramData];
    } else {
      if (+selectedStatus.id === 1 || +selectedStatus.id === 2) {
        const isActive = selectedStatus.id === "2";
        insta = instagramData.filter(
          (business) => business.profile.isActive === isActive
        );
      } else if (+selectedStatus.id === 3 || +selectedStatus.id === 4) {
        const isPaused = selectedStatus.id === "3";
        insta = instagramData.filter(
          (business) => business.profile.isPaused === isPaused
        );
      }
    }

    setFilteredInstagramData(insta);

    //youtube
    let ytube = [];

    if (!selectedStatus) {
      ytube = [...youtubeData];
    } else {
      if (+selectedStatus.id === 1 || +selectedStatus.id === 2) {
        const isActive = selectedStatus.id === "2";
        ytube = youtubeData.filter((channel) => channel.isActive === isActive);
      } else if (+selectedStatus.id === 3 || +selectedStatus.id === 4) {
        const isPaused = selectedStatus.id === "3";
        ytube = youtubeData.filter((channel) => channel.isPaused === isPaused);
      }
    }

    setFilteredYoutubeData(ytube);
  };

  useEffect(() => {
    if (filteredFacebookData) {
      const startPage = (currentPageFacebook - 1) * itemsPerPage;
      const endPage = currentPageFacebook * itemsPerPage;

      const paginatedPages = filteredFacebookData.combined.slice(
        startPage,
        endPage
      );

      setPaginatedFacebookData({
        ...filteredFacebookData,
        combined: [...paginatedPages],
      });
    }
  }, [filteredFacebookData, currentPageFacebook, itemsPerPage]);

  useEffect(() => {
    if (filteredTwitterData) {
      const startPage = (currentPageTwitter - 1) * itemsPerPage;
      const endPage = currentPageTwitter * itemsPerPage;
      const paginatedTwtr = filteredTwitterData.slice(startPage, endPage);

      setPaginatedTwitterData(paginatedTwtr);
    }
  }, [filteredTwitterData, currentPageTwitter, itemsPerPage]);

  useEffect(() => {
    if (filteredLinkedinData) {
      const startPage = (currentPageLinkedIn - 1) * itemsPerPage;
      const endPage = currentPageLinkedIn * itemsPerPage;
      const paginatedlinkein = filteredLinkedinData.slice(startPage, endPage);

      setPaginatedLinkedinData(paginatedlinkein);
    }
  }, [filteredLinkedinData, currentPageLinkedIn, itemsPerPage]);

  useEffect(() => {
    if (filteredInstagramData) {
      const startPage = (currentPageInstagram - 1) * itemsPerPage;
      const endPage = currentPageInstagram * itemsPerPage;
      const paginatedinsta = filteredInstagramData.slice(startPage, endPage);

      setPaginatedInstagramData(paginatedinsta);
    }
  }, [filteredInstagramData, currentPageInstagram, itemsPerPage]);

  useEffect(() => {
    if (filteredYoutubeData) {
      const startPage = (currentPageYoutube - 1) * itemsPerPage;
      const endPage = currentPageYoutube * itemsPerPage;
      const paginatedYtube = filteredYoutubeData.slice(startPage, endPage);

      setPaginatedYoutubeData(paginatedYtube);
    }
  }, [filteredYoutubeData, currentPageYoutube, itemsPerPage]);

  const handlePlayPauseClick = async (isPaused, socialMedia, id) => {
    if (User) {
      let requestData = {};
      switch (socialMedia) {
        case "Facebook":
          const fbPages = [
            ...facebook.pages.map((page) => {
              if (page._id === id) {
                return { ...page, isPaused };
              }
              return page;
            }),
          ];
          const fbGroups = [
            ...facebook.groups.map((group) => {
              if (group._id === id) {
                return { ...group, isPaused };
              }
              return group;
            }),
          ];
          const fbData = {
            ...facebook,
            pages: [...fbPages],
            groups: [...fbGroups],
          };
          delete fbData._id;
          requestData = {
            socialMedia,
            token: Token?.access?.token,

            payload: {
              accountDetails: {
                ...fbData,
                userId: User._id,
              },
            },
          };
          break;
        case "Twitter":
          const twtData = [
            ...twitter.map((twt) => {
              if (twt._id === id) {
                return { ...twt, isPaused };
              }
              return twt;
            }),
          ];
          requestData = {
            socialMedia,
            token: Token?.access?.token,

            payload: {
              twitter: [...twtData],
              user: User._id,
            },
          };
          break;
        case "LinkedIn":
          const linkData = [
            ...linkedin.map((lnk) => {
              if (lnk._id === id) {
                return { ...lnk, isPaused };
              }
              return lnk;
            }),
          ];
          requestData = {
            socialMedia,
            token: Token?.access?.token,

            payload: {
              linkedIn: [...linkData],
              user: User._id,
            },
          };
          break;
        case "Instagram":
          const insData = [
            ...instagram.map((inst) => {
              if (inst._id === id) {
                const updatedProfile = { ...inst.profile, isPaused };

                const updatedInst = { ...inst, profile: updatedProfile };

                return updatedInst;
              }
              return inst;
            }),
          ];
          const fData = { ...insData[0] };
          delete fData._id;
          requestData = {
            socialMedia,
            token: Token?.access?.token,
            payload: {
              accountDetails: {
                ...fData,
                userId: User._id,
              },
            },
          };
          break;
        case "Youtube":
          const ytData = [
            ...youtube.map((yt) => {
              if (yt._id === id) {
                return { ...yt, isPaused };
              }
              return yt;
            }),
          ];
          requestData = {
            socialMedia,
            token: Token?.access?.token,

            payload: {
              youtube: [...ytData],
              user: User._id,
            },
          };
          break;
        default:
          requestData = {};
          break;
      }

      dispatch(playNPauseAccountAction(requestData));
    }
  };

  const handleDisconnectClick = async (socialMedia, id) => {
    if (User) {
      let requestData = {};
      switch (socialMedia) {
        case "Facebook":
          const fbPages = [
            ...facebook.pages.map((page) => {
              if (page._id === id) {
                return { ...page, isActive: false };
              }
              return page;
            }),
          ];
          const fbGroups = [
            ...facebook.groups.map((group) => {
              if (group._id === id) {
                return { ...group, isActive: false };
              }
              return group;
            }),
          ];
          const fbData = {
            ...facebook,
            pages: [...fbPages],
            groups: [...fbGroups],
          };
          delete fbData._id;
          requestData = {
            socialMedia,
            token: Token?.access?.token,

            payload: {
              accountDetails: {
                ...fbData,
                userId: User._id,
              },
            },
          };
          break;
        case "Twitter":
          const twtData = [
            ...twitter.map((twt) => {
              if (twt._id === id) {
                return { ...twt, isActive: false };
              }
              return twt;
            }),
          ];
          requestData = {
            socialMedia,
            token: Token?.access?.token,

            payload: {
              twitter: [...twtData],
              user: User._id,
            },
          };
          break;
        case "LinkedIn":
          const linkData = [
            ...linkedin.map((lnk) => {
              if (lnk._id === id) {
                return { ...lnk, isActive: false };
              }
              return lnk;
            }),
          ];
          requestData = {
            socialMedia,
            token: Token?.access?.token,

            payload: {
              linkedIn: [...linkData],
              user: User._id,
            },
          };
          break;
        case "Instagram":
          const insData = [
            ...instagram.map((inst) => {
              if (inst._id === id) {
                const updatedProfile = { ...inst.profile, isActive: false };

                const updatedInst = { ...inst, profile: updatedProfile };

                return updatedInst;
              }
              return inst;
            }),
          ];
          const fData = { ...insData[0] };
          delete fData._id;
          requestData = {
            socialMedia,
            token: Token?.access?.token,
            payload: {
              accountDetails: {
                ...fData,
                userId: User._id,
              },
            },
          };
          break;
        case "Youtube":
          const ytData = [
            ...youtube.map((yt) => {
              if (yt._id === id) {
                return { ...yt, isActive: false };
              }
              return yt;
            }),
          ];
          requestData = {
            socialMedia,
            token: Token?.access?.token,

            payload: {
              youtube: [...ytData],
              user: User._id,
            },
          };
          break;
        default:
          requestData = {};
          break;
      }

      dispatch(playNPauseAccountAction(requestData));
    }
  };

  return (
    <div className="socialprolist_Page">
      <div className="chatboat-section position-absolute">
        <i className="icon-chatboat"></i>
      </div>
      <Header></Header>
      <div className="main_Container">
        <GuidedTour
          steps={socialProfileListSteps}
          onRequestClose={handleOnRequestClose}
          isOpen={
            guideTourGetItemValue
              ? guideTourGetItemValue.socialprofilelist
              : true
          }
        />
        <div className="socialprolist_Inner">
          {params === "Facebook" ? (
            <div className="tabSearch_section">
              <Scrollbars
                style={{
                  height: dimensions.height - messageboxHeight - 60 + "px",
                }}
              >
                <i className="icon-tab tabProfileIcon" onClick={OnTabClick}></i>
                <Label className="main_Heading" title="Facebook"></Label>
                <div className="search_Filter">
                  <SearchBox
                    placeholder="Search by group name, page name"
                    onSearch={handleSearchFacebook}
                  />
                  <div className="filterBtn">
                    <button
                      className="filterIcon"
                      onClick={() => setShowFilterDropdown(!showFilterDropdown)}
                    >
                      <i className="icon-filter">
                        <span>Filter</span>
                      </i>
                    </button>
                  </div>
                  {showFilterDropdown && (
                    <>
                      <div
                        className="overLay"
                        onClick={() => setShowFilterDropdown(false)}
                      ></div>
                      <FilterForm
                        title="Filter"
                        onClickIconClose={() => setShowFilterDropdown(false)}
                        onFilterChange={handleFilterChange}
                      ></FilterForm>
                    </>
                  )}
                </div>

                <div className="profileList_container">
                  {paginatedFacebookData &&
                    paginatedFacebookData.combined.map((curElem, index) => {
                      return (
                        <ProfileList
                          ProfileImg={
                            facebook.userProfile.picture.data.url
                              ? facebook.userProfile.picture.data.url
                              : ProfileImg1
                          }
                          userName={curElem.name}
                          statusTitle={
                            curElem.isActive ? (
                              <p style={{ width: "150px" }}>
                                {curElem.isPaused
                                  ? " Connected - Paused"
                                  : " Connected - Active"}
                              </p>
                            ) : (
                              <p>Disconnected</p>
                            )
                          }
                          key={index}
                          onPlayPauseClick={(isPaused) =>
                            handlePlayPauseClick(
                              isPaused,
                              "Facebook",
                              curElem._id
                            )
                          }
                          isPaused={curElem.isPaused}
                          isConnected={curElem.isActive}
                          onDisconnectClick={() =>
                            handleDisconnectClick("Facebook", curElem._id)
                          }
                        ></ProfileList>
                      );
                    })}
                </div>
                {paginatedFacebookData && (
                  <PaginationSystem
                    currentPage={currentPageFacebook}
                    totalPages={Math.ceil(
                      filteredFacebookData.combined.length / itemsPerPage
                    )}
                    onPageChange={(page) => handlePageChange(page, "Facebook")}
                  />
                )}
              </Scrollbars>
            </div>
          ) : params === "Twitter" ? (
            <div className="tabSearch_section">
              <Scrollbars
                style={{
                  height: dimensions.height - messageboxHeight - 60 + "px",
                }}
              >
                <i className="icon-tab tabProfileIcon" onClick={OnTabClick}></i>
                <Label className="main_Heading" title="Twitter"></Label>
                <div className="search_Filter">
                  <SearchBox
                    placeholder="Search by profile name"
                    onSearch={handleSearchTwitter}
                  />
                  <div className="filterBtn">
                    <button
                      className="filterIcon"
                      onClick={() => setShowFilterDropdown(!showFilterDropdown)}
                    >
                      <i className="icon-filter">
                        {" "}
                        <span>Filter</span>
                      </i>
                    </button>
                  </div>
                  {showFilterDropdown && (
                    <>
                      <div
                        className="overLay"
                        onClick={() => setShowFilterDropdown(false)}
                      ></div>
                      <FilterForm
                        title="Filter"
                        onClickIconClose={() => setShowFilterDropdown(false)}
                        onFilterChange={handleFilterChange}
                      ></FilterForm>
                    </>
                  )}
                </div>

                <div className="profileList_container">
                  {paginatedTwitterData ? (
                    paginatedTwitterData.map((curElem, index) => {
                      return (
                        <ProfileList
                          ProfileImg={ProfileImg1}
                          userName={curElem.screenName}
                          statusTitle={
                            curElem.isActive ? (
                              <p style={{ width: "150px" }}>
                                {curElem.isPaused
                                  ? " Connected - Paused"
                                  : " Connected - Active"}
                              </p>
                            ) : (
                              <p>Disconnected</p>
                            )
                          }
                          key={index}
                          onPlayPauseClick={(isPaused) =>
                            handlePlayPauseClick(
                              isPaused,
                              "Twitter",
                              curElem._id
                            )
                          }
                          isPaused={curElem.isPaused}
                          isConnected={curElem.isActive}
                          onDisconnectClick={() =>
                            handleDisconnectClick("Twitter", curElem._id)
                          }
                        ></ProfileList>
                      );
                    })
                  ) : (
                    <NoApiResponse />
                  )}
                </div>
                {paginatedTwitterData && (
                  <PaginationSystem
                    currentPage={currentPageTwitter}
                    totalPages={Math.ceil(
                      filteredTwitterData.length / itemsPerPage
                    )}
                    onPageChange={(page) => handlePageChange(page, "Twitter")}
                  />
                )}
              </Scrollbars>
            </div>
          ) : params === "Linkedin" ? (
            <div className="tabSearch_section">
              <Scrollbars
                style={{
                  height: dimensions.height - messageboxHeight - 60 + "px",
                }}
              >
                <i className="icon-tab tabProfileIcon" onClick={OnTabClick}></i>
                <Label className="main_Heading" title="Linkedin"></Label>
                <div className="search_Filter">
                  <SearchBox
                    placeholder="Search by profile name"
                    onSearch={handleSearchLinkedin}
                  />
                  <div className="filterBtn">
                    <button
                      className="filterIcon"
                      onClick={() => setShowFilterDropdown(!showFilterDropdown)}
                    >
                      <i className="icon-filter">
                        {" "}
                        <span>Filter</span>
                      </i>
                    </button>
                  </div>
                  {showFilterDropdown && (
                    <>
                      <div
                        className="overLay"
                        onClick={() => setShowFilterDropdown(false)}
                      ></div>
                      <FilterForm
                        title="Filter"
                        onClickIconClose={() => setShowFilterDropdown(false)}
                        onFilterChange={handleFilterChange}
                      ></FilterForm>
                    </>
                  )}
                </div>

                <div className="profileList_container">
                  {paginatedLinkedinData ? (
                    paginatedLinkedinData.map((curElem, index) => {
                      return (
                        <ProfileList
                          ProfileImg={ProfileImg1}
                          userName={curElem.firstName + " " + curElem.lastName}
                          statusTitle={
                            curElem.isActive ? (
                              <p style={{ width: "150px" }}>
                                {curElem.isPaused
                                  ? " Connected - Paused"
                                  : " Connected - Active"}
                              </p>
                            ) : (
                              <p>Disconnected</p>
                            )
                          }
                          key={index}
                          onPlayPauseClick={(isPaused) =>
                            handlePlayPauseClick(
                              isPaused,
                              "LinkedIn",
                              curElem._id
                            )
                          }
                          isPaused={curElem.isPaused}
                          isConnected={curElem.isActive}
                          onDisconnectClick={() =>
                            handleDisconnectClick("LinkedIn", curElem._id)
                          }
                        ></ProfileList>
                      );
                    })
                  ) : (
                    <NoApiResponse />
                  )}
                </div>
                {paginatedLinkedinData && (
                  <PaginationSystem
                    currentPage={currentPageLinkedIn}
                    totalPages={Math.ceil(
                      filteredLinkedinData.length / itemsPerPage
                    )}
                    onPageChange={(page) => handlePageChange(page, "LinkedIn")}
                  />
                )}
              </Scrollbars>
            </div>
          ) : params === "Instagram" ? (
            <div className="tabSearch_section">
              <Scrollbars
                style={{
                  height: dimensions.height - messageboxHeight - 60 + "px",
                }}
              >
                <i className="icon-tab tabProfileIcon" onClick={OnTabClick}></i>
                <Label className="main_Heading" title="Instagram"></Label>
                <div className="search_Filter">
                  <SearchBox
                    placeholder="Search by Business Id"
                    onSearch={handleSearchInstagram}
                  />
                  <div className="filterBtn">
                    <button
                      className="filterIcon"
                      onClick={() => setShowFilterDropdown(!showFilterDropdown)}
                    >
                      <i className="icon-filter">
                        {" "}
                        <span>Filter</span>
                      </i>
                    </button>
                  </div>
                  {showFilterDropdown && (
                    <>
                      <div
                        className="overLay"
                        onClick={() => setShowFilterDropdown(false)}
                      ></div>
                      <FilterForm
                        title="Filter"
                        onClickIconClose={() => setShowFilterDropdown(false)}
                        onFilterChange={handleFilterChange}
                      ></FilterForm>
                    </>
                  )}
                </div>

                <div className="profileList_container">
                  {paginatedInstagramData ? (
                    paginatedInstagramData.map((curElem, index) => {
                      return (
                        <ProfileList
                          ProfileImg={
                            curElem.profile.picture.data.url
                              ? curElem.profile.picture.data.url
                              : ProfileImg1
                          }
                          userName={
                            curElem.profile.userName
                              ? curElem.profile.userName
                              : curElem.profile.instagram_business_account_id
                          }
                          statusTitle={
                            curElem.profile.isActive ? (
                              <p style={{ width: "150px" }}>
                                {curElem.profile.isPaused
                                  ? " Connected - Paused"
                                  : " Connected - Active"}
                              </p>
                            ) : (
                              <p>Disconnected</p>
                            )
                          }
                          key={index}
                          onPlayPauseClick={(isPaused) =>
                            handlePlayPauseClick(
                              isPaused,
                              "Instagram",
                              curElem._id
                            )
                          }
                          isPaused={curElem.profile.isPaused}
                          isConnected={curElem.profilisActive}
                          onDisconnectClick={() =>
                            handleDisconnectClick("Instagram", curElem._id)
                          }
                        ></ProfileList>
                      );
                    })
                  ) : (
                    <NoApiResponse />
                  )}
                </div>

                {paginatedInstagramData && (
                  <PaginationSystem
                    currentPage={currentPageInstagram}
                    totalPages={Math.ceil(
                      filteredInstagramData.length / itemsPerPage
                    )}
                    onPageChange={(page) => handlePageChange(page, "Instagram")}
                  />
                )}
              </Scrollbars>
            </div>
          ) : params === "Youtube" ? (
            <div className="tabSearch_section">
              <Scrollbars
                style={{
                  height: dimensions.height - messageboxHeight - 60 + "px",
                }}
              >
                <i className="icon-tab tabProfileIcon" onClick={OnTabClick}></i>
                <Label className="main_Heading" title="Youtube"></Label>
                <div className="search_Filter">
                  <SearchBox
                    placeholder="Search by channel name"
                    onSearch={handleSearchYoutube}
                  />
                  <div className="filterBtn">
                    <button
                      className="filterIcon"
                      onClick={() => setShowFilterDropdown(!showFilterDropdown)}
                    >
                      <i className="icon-filter">
                        {" "}
                        <span>Filter</span>
                      </i>
                    </button>
                  </div>
                  {showFilterDropdown && (
                    <>
                      <div
                        className="overLay"
                        onClick={() => setShowFilterDropdown(false)}
                      ></div>
                      <FilterForm
                        title="Filter"
                        onClickIconClose={() => setShowFilterDropdown(false)}
                        onFilterChange={handleFilterChange}
                      ></FilterForm>
                    </>
                  )}
                </div>

                <div className="profileList_container">
                  {paginatedYoutubeData ? (
                    paginatedYoutubeData.map((curElem, index) => {
                      return (
                        <ProfileList
                          ProfileImg={
                            curElem.snippet.thumbnails.default.url
                              ? curElem.snippet.thumbnails.default.url
                              : ProfileImg1
                          }
                          userName={curElem.snippet.title}
                          statusTitle={
                            curElem.isActive ? (
                              <p style={{ width: "150px" }}>
                                {curElem.isPaused
                                  ? " Connected - Paused"
                                  : " Connected - Active"}
                              </p>
                            ) : (
                              <p>Disconnected</p>
                            )
                          }
                          key={index}
                          onPlayPauseClick={(isPaused) =>
                            handlePlayPauseClick(
                              isPaused,
                              "Youtube",
                              curElem._id
                            )
                          }
                          isPaused={curElem.isPaused}
                          isConnected={curElem.isActive}
                          onDisconnectClick={() =>
                            handleDisconnectClick("Youtube", curElem._id)
                          }
                        ></ProfileList>
                      );
                    })
                  ) : (
                    <NoApiResponse />
                  )}
                </div>
                {paginatedYoutubeData && (
                  <PaginationSystem
                    currentPage={currentPageYoutube}
                    totalPages={Math.ceil(
                      filteredYoutubeData.length / itemsPerPage
                    )}
                    onPageChange={(page) => handlePageChange(page, "Youtube")}
                  />
                )}
              </Scrollbars>
            </div>
          ) : params === "TikTok" ? (
            <div className="tabSearch_section">
              <Scrollbars
                style={{
                  height: dimensions.height - messageboxHeight - 60 + "px",
                }}
              >
                <i className="icon-tab tabProfileIcon" onClick={OnTabClick}></i>
                <Label className="main_Heading" title="TikTok"></Label>
                <div className="search_Filter">
                  <SearchBox
                    placeholder="Search by group name,page name members name"
                    onSearch={(query) => handleSearch(query, youtube)}
                  />
                  <div className="filterBtn">
                    <button
                      className="filterIcon"
                      onClick={() => setShowFilterDropdown(!showFilterDropdown)}
                    >
                      <i className="icon-filter">
                        {" "}
                        <span>Filter</span>
                      </i>
                    </button>
                  </div>
                  {showFilterDropdown && (
                    <>
                      <div
                        className="overLay"
                        onClick={() => setShowFilterDropdown(false)}
                      ></div>
                      <FilterForm
                        title="Filter"
                        onClickIconClose={() => setShowFilterDropdown(false)}
                        onFilterChange={handleFilterChange}
                      ></FilterForm>
                    </>
                  )}
                </div>

                <div className="profileList_container">
                  {facebook && facebook.tiktok ? (
                    facebook.tiktok.map((curElem, index) => {
                      return (
                        <ProfileList
                          ProfileImg={ProfileImg1}
                          userName={curElem.firstName}
                          statusTitle={
                            curElem.isActive ? (
                              <p style={{ width: "150px" }}>
                                {curElem.isPaused
                                  ? " Connected - Paused"
                                  : " Connected - Active"}
                              </p>
                            ) : (
                              <p>Disconnected</p>
                            )
                          }
                          key={index}
                          isPaused={curElem.isPaused}
                        ></ProfileList>
                      );
                    })
                  ) : (
                    <NoApiResponse />
                  )}
                </div>
              </Scrollbars>
            </div>
          ) : (
            <></>
          )}
          <div
            className={showTab ? "social_Section showTab" : "social_Section"}
          >
            <i className="icon-close" onClick={() => setShowTab(!showTab)}></i>
            <Label className="main_Title" title="Add more account"></Label>

            <div className="add_profile_link" tourselector="add-more-accounts">
              <AddProfileDropdownList
                active={active}
                setActive={setActive}
                profiles={profilesData}
              />
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SocialProListScreen);
