// import React from "react";
// import Label from "../UI/Label/Label";
// import "./TabHeader.scss";

// const TabHeader = () => {
//   return (
//     <div className="tab_Heading">
//       <Label className="heading profile" title="Profile"></Label>
//       <Label className="heading userName" title="User name"></Label>
//       <Label className="heading accType" title="Account type"></Label>
//       <Label className="heading status" title="Status"></Label>
//       <Label className="heading checkBox" title=""></Label>
//     </div>
//   );
// };

// export default TabHeader;
import React from "react";
import Label from "../UI/Label/Label";
import "./TabHeader.scss";

const TabHeader = (props) => {
  let{col1,col2,col3,col4}=props

  return (
    <div className="tab_Heading">
      <Label className="heading profile" title={col1}></Label>
      <Label className="heading userName" title={col2}></Label>
      <Label className="heading accType" title={col3}></Label>
      <Label className="heading status" title={col4}></Label>
      <Label className="heading checkBox" title=""></Label>
    </div>
  );
};
export default TabHeader;
