import React from "react";
import PopupOnepic from "../../assests/images/tab1.png";
import Label from "../../components/UI/Label/Label";
import "./PopupOne.scss";

const PopupOne = () => {
  return (
    <div className="first_container">
      <div className="popup_box">
        <div className="first_heading">
          <div className="logo_heading">
            <div>
              <img className="logo" src={PopupOnepic} alt="..." />
            </div>
            <div className="title">
              <Label className="heading1" title="Apple fan club"></Label>
              <table className="pauseclass">
                <tr className="row">
                  <td className="status">
                    <Label className="heading2" title="Status :"></Label>
                  </td>
                  <td className="pause">
                    <Label className="pause1" title="Pause"></Label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          {/* <div>
            <i className="icon-close"></i>
          </div> */}
        </div>
        <div className="scroll_box">
          <div className="normaltext">
            <Label title=" Connected by"></Label>
            <Label title="john doe"> </Label>
          </div>
          <div className="normaltext1">
            <Label title=" Last authentication "></Label>
            <Label title="12/05/2021"> </Label>
          </div>
          <div className="normaltext1">
            <Label title=" Last post send "></Label>
            <Label title="15/05/2021"> </Label>
          </div>
          <div className="normaltext1">
            <Label title="Total post send "></Label>
            <Label title="156"> </Label>
          </div>
          <div className="normaltext1">
            <Label title=" Total post scheduled "></Label>
            <Label title="30"> </Label>
          </div>
          <div className="normaltext1">
            <Label title=" Total post failed "></Label>
            <Label title="00"> </Label>
          </div>
          <div className="general">
            <Label title="General"></Label>
          </div>
          <div className="normaltext2">
            <Label title=" Category "></Label>
            <Label title="News"> </Label>
          </div>
          <div className="normaltext3">
            <Label title=" Username "></Label>
            <Label title="John doe"> </Label>
          </div>
          <div className="about">
            <Label className="heading" title=" About Group "></Label>
            <Label
              className="text"
              title="Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's"
            >
              {" "}
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupOne;
