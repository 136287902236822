import React from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import Scrollbars from "react-custom-scrollbars";
import { withRouter } from "react-router-dom";
import SuppportImg from "../../assests/images/supportimg.png";
import HookForm from "../../components/HookForm/HookForm";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import Label from "../../components/UI/Label/Label";
import TextField from "../../components/UI/TextField/TextField";
import "./AdminSupportPage.scss";

const loginForm = {
  email: {
    name: "email",
  },
};

function AdminSupportPage() {
  const [form, setLoginForm] = React.useState();
  // console.log(form);
  const onFormSubmit = () => {};
  return (
    <div className="admin_Support">
      <div className="no-design-section justify-content-center align-items-center vh-100">
        <Label
          title="Mobile design not supported"
          className="no-design"
        ></Label>
      </div>
      <div className="main_Container">
        <Scrollbars className="admin_Dashboard_Scroll">
          <div className="dashboard_Section ">
            <div className="d-flex justify-content-between align-items-center">
              <div className="content_Header d-flex w-100">
                <div className="d-flex flex-column w80">
                  <div className="backIcon d-flex align-items-center pt-3">
                    <div className="icon_Bg justify-content-center d-flex align-items-center">
                      <i className="icon-back"></i>
                    </div>
                    <Label
                      className="page_Title pl-3"
                      title="Support Page"
                    ></Label>
                  </div>
                  <Label
                    className="inner_Title"
                    title="View and manage all your support page here"
                  ></Label>
                </div>
                <div className="right_Button d-flex align-items-end">
                  <CustomButton
                    className="submitBtn save"
                    title="Save"
                  ></CustomButton>
                  {/* <CustomButton
                    className="submitBtn publish"
                    title="Publish now"
                  ></CustomButton> */}
                  <NavDropdown
                    title="Publish now"
                    id="basic-nav-dropdown"
                    className="submitBtn"
                  >
                    <NavDropdown.Item href="#action/3.2">
                      Publish now
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Publish now
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              </div>
            </div>
            <div className="divider"></div>
            <div className="input_Section">
              <div className="box-border">
                <HookForm
                  defaultForm={{}}
                  init={(form) => setLoginForm(form)}
                  onSubmit={onFormSubmit}
                >
                  {(formMethod) => {
                    return (
                      <div className="form">
                        <TextField
                          formMethod={formMethod}
                          rules={loginForm.email.validate}
                          name={loginForm.email.name}
                          errors={formMethod?.errors}
                          autoFocus={true}
                          type="text"
                          placeholder="Main Title"
                          iconClass=""
                        />
                      </div>
                    );
                  }}
                </HookForm>
              </div>
              <div className="box-border marginTop">
                <div className="inner_Section">
                  <HookForm
                    defaultForm={{}}
                    init={(form) => setLoginForm(form)}
                    onSubmit={onFormSubmit}
                  >
                    {(formMethod) => {
                      return (
                        <div className="form">
                          <TextField
                            formMethod={formMethod}
                            rules={loginForm.email.validate}
                            name={loginForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={true}
                            type="text"
                            placeholder="Card Title"
                            iconClass=""
                          />
                          <TextField
                            formMethod={formMethod}
                            rules={loginForm.email.validate}
                            name={loginForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={true}
                            type="text"
                            placeholder="Card Sub-Title"
                            iconClass=""
                          />
                        </div>
                      );
                    }}
                  </HookForm>
                </div>
                <div className="bg_Textfield w-100">
                  <HookForm
                    defaultForm={{}}
                    init={(form) => setLoginForm(form)}
                    onSubmit={onFormSubmit}
                  >
                    {(formMethod) => {
                      return (
                        <div className="form">
                          <TextField
                            formMethod={formMethod}
                            rules={loginForm.email.validate}
                            name={loginForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={true}
                            type="text"
                            placeholder="Card Title"
                            iconClass=""
                          />
                          <TextField
                            formMethod={formMethod}
                            rules={loginForm.email.validate}
                            name={loginForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={true}
                            type="text"
                            placeholder="Card Sub-Title"
                            iconClass=""
                          />
                        </div>
                      );
                    }}
                  </HookForm>
                  <a className="float-right d-flex align-items-center justify-content-center">
                    <Label
                      className="pb-0 pr-1 addMore"
                      title="Add more title list"
                    ></Label>
                    <div className="bg_Color d-flex align-items-center justify-content-center">
                      <i className="icon-adds"></i>
                    </div>
                  </a>
                </div>
              </div>
              <CustomButton
                className="submitBtn addCard mr-1"
                title="Add More Points"
              ></CustomButton>
            </div>
            <div className="img_Section">
              <img src={SuppportImg} alt="image" className="time_Img" />
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
}

export default withRouter(AdminSupportPage);
