// CustomTabs.js
import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";

const CustomTabs = ({ tabs }) => {
  return (
    <Tabs defaultActiveKey={tabs[0].key} id="uncontrolled-tab-example" className="mb-3">
      {tabs.map((tab) => tab.enabled && (
        <Tab key={tab.key} eventKey={tab.key} title={tab.title} className={tab.className}>{tab.content}</Tab>
      ))}
    </Tabs>
  );
};

export default CustomTabs;
