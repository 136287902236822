import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { Link, withRouter } from "react-router-dom";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import PaginationSystem from "../../components/Pagination/PaginationSystem";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import CustomDropdown from "../../components/UI/CustomDropdown/CustomDropdown";
import Label from "../../components/UI/Label/Label";
import { useAppData } from "../../services/hooks";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { adminBlogSteps } from "../Steps";
import "./AdminBlog.scss";
import FilterForm from "./Filter/FilterForm";

const dropDownStatus = [
  { id: "1", value: "Published" },
  { id: "2", value: "Publish Now" },
  { id: "3", value: "Draft" },
];

function AdminBlog() {
  const [dropdown, setDropdown] = useState("");
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();
  const allBlog = useAppData(
    (r) => r.postPublisherSlice.blog.getAllBlogResponseValue
  );
  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObjAdmin")
    );
    let newObj = { ...previousLocalStorageValue, adminBlog: false };
    localStorage.setItem("guideTourObjAdmin", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(
    localStorage.getItem("guideTourObjAdmin")
  );

  return (
    <div className="admin_Blog">
      <div className="no-design-section justify-content-center align-items-center vh-100">
        <Label
          title="Mobile design not supported"
          className="no-design"
        ></Label>
      </div>

      <AdminHeader />
      <GuidedTour
        steps={adminBlogSteps}
        onRequestClose={handleOnRequestClose}
        isOpen={guideTourGetItemValue ? guideTourGetItemValue.adminBlog : true}
      />
      <div className="main_Container">
        <Scrollbars className="admin_Dashboard_Scroll">
          <div className="dashboard_Section ">
            <div className="d-flex justify-content-between align-items-center">
              <div className="content_Header pt-1">
                <Label className="page_Title" title="Blogs"></Label>
                <Label
                  className="inner_Title"
                  title="View and manage all blog post here"
                ></Label>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="filterBtn">
                  <button
                    className="filterIcon"
                    onClick={() => setShowFilterDropdown(!showFilterDropdown)}
                  >
                    <i className="icon-filter">
                      <span>Filter</span>
                    </i>
                  </button>
                </div>
                {showFilterDropdown && (
                  <>
                    <div
                      className="overLay"
                      onClick={() => setShowFilterDropdown(false)}
                    ></div>
                    <FilterForm
                      title="Filter"
                      onClickIcon={() => setShowFilterDropdown(false)}
                    ></FilterForm>
                  </>
                )}
                <Link to="/admin/adminaddblogs">
                  <CustomButton
                    className="submitBtn addBlogs"
                    title="Add Blog"
                    tourselector="add-blog-btn"
                  ></CustomButton>
                </Link>
              </div>
            </div>
            <div className="divider"></div>
            <div className="table_Section">
              <div className="query-Section d-flex flex-column">
                <div tourselector="manage-blog">
                  <ul className="header d-flex p-0 m-0">
                    <li>Sl.No.</li>
                    <li className="pr-1">Title</li>
                    <li className="pr-1">Auther</li>
                    <li className="pr-1">Created On</li>
                    <li className="pr-1">Status</li>
                    <li className="pr-1">Edit</li>
                    <li className="pr-1">Delete</li>
                    <li></li>
                  </ul>

                  {allBlog.data.map(function (item, id) {
                    return (
                      <span key={id}>
                        <ul className="dataList d-flex p-0 m-0">
                          <li>{id + 1}</li>
                          <li className="pr-1">{item.title}</li>
                          <li className="pr-1">{item.createdBy.firstName}</li>
                          <li className="pr-1">{item.createdAt}</li>

                          <li className="pr-1">
                            <div className="dropdown_Section">
                              <CustomDropdown
                                dropDownItems={dropDownStatus}
                                placeholder="Select"
                                selectedValue={dropdown}
                                onSelect={(selected) => setDropdown(selected)}
                              ></CustomDropdown>
                            </div>
                          </li>
                          <li className="pr-1">
                            <icon className="icon-edit3 text-primary"></icon>
                          </li>
                          <li className="pr-1">
                            <icon className="icon-delete text-danger"></icon>
                          </li>
                          <li className="text-right pr-1 "> </li>
                        </ul>
                      </span>
                    );
                  })}

                  {/* <ul className="dataList d-flex p-0 m-0">
                    <li>2</li>
                    <li className="pr-1">
                      Lorem ipsum dolor sit amet Aut ipsa nisi{' '}
                    </li>
                    <li className="pr-1">Admin</li>
                    <li className="pr-1">13/Jan/2018</li>
                    <li className="pr-1">
                      <div className="dropdown_Section">
                        <CustomDropdown
                          dropDownItems={dropDownStatus}
                          placeholder="Select"
                          selectedValue={dropdownA}
                          onSelect={(selected) => setDropdownA(selected)}
                        ></CustomDropdown>
                      </div>
                    </li>
                    <li className="pr-1">
                      <icon className="icon-edit3 text-primary"></icon>
                    </li>
                    <li className="pr-1">
                      <icon className="icon-delete text-danger"></icon>
                    </li>
                    <li className="text-right pr-1 ">
                      {' '}
                      <CustomButton
                        className="publishBtn"
                        title="Publish Now"
                      ></CustomButton>
                    </li>
                  </ul>
                  <ul className="dataList d-flex p-0 m-0">
                    <li>3</li>
                    <li className="pr-1">
                      Lorem ipsum dolor sit amet Aut ipsa nisi{' '}
                    </li>
                    <li className="pr-1">Admin</li>
                    <li className="pr-1">13/Jan/2018</li>
                    <li className="pr-1">
                      <div className="dropdown_Section">
                        <CustomDropdown
                          dropDownItems={dropDownStatus}
                          placeholder="Select"
                          selectedValue={dropdownB}
                          onSelect={(selected) => setDropdownB(selected)}
                        ></CustomDropdown>
                      </div>
                    </li>
                    <li className="pr-1">
                      <icon className="icon-edit3 text-primary"></icon>
                    </li>
                    <li className="pr-1">
                      <icon className="icon-delete text-danger"></icon>
                    </li>
                    <li className="text-right pr-1 ">
                      {' '}
                      <CustomButton
                        className="publishBtn"
                        title="Publish Now"
                      ></CustomButton>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <PaginationSystem />
          </div>
        </Scrollbars>
      </div>
    </div>
  );
}

export default withRouter(AdminBlog);
