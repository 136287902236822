import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import "./TextField.scss";

const TextField = (props) => {
  let {
    errors,
    placeholder,
    multiErrorFields,
    disabled,
    type,
    textarea,
    name,
    autoFocus,
    handleFocus,
    handleBlur,
    iconClass,
    onIconClick,
    // onKeyDown,
    maskType,
    noTextfield,
    formMethod,
    rows,
    defaultValue,
    rules,
    // onChangeData,
    // value,
    style,
    tourselector,
    controlId,
  } = props;

  let hasError = errors[name] !== undefined;
  function handleEnter(event) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      form.elements[index + 1].click();
      event.preventDefault();
    }
  }

  return (
    <div className="textField">
      <Form.Group controlId={controlId}>
        <div className="inputBox">
          {/* why noTextfield variable is used  */}
          {noTextfield ? (
            <Controller
              className="form-control"
              as={<InputMask />}
              mask={maskType}
              maskChar=""
              control={formMethod?.control}
              name={name}
              rules={rules}
              type={type}
              defaultValue={defaultValue}
              placeholder={placeholder}
              disabled={disabled}
            />
          ) : (
            <Controller
              name={name}
              control={formMethod?.control}
              rules={rules}
              defaultValue={defaultValue}
              render={({ field: { onChange, value, name } }) => (
                <Form.Control
                  style={style}
                  className={hasError ? "error" : ""}
                  name={name}
                  defaultValue={defaultValue}
                  autoFocus={autoFocus}
                  disabled={disabled}
                  autoComplete="off"
                  type={type}
                  errors={formMethod?.formState.errors}
                  as={textarea}
                  rows={rows}
                  onBlur={() => handleBlur}
                  onKeyDown={handleEnter}
                  onFocus={(e) => {
                    handleFocus && handleFocus(e);
                  }}
                  onChange={(e) => {
                    e.target.value = e.target.value.trimLeft();
                    onChange && onChange(e.target.value.trimLeft());
                    props.onChange && props.onChange(e);
                  }}
                  placeholder={placeholder}
                  value={formMethod.watch(value)}
                  tourselector={tourselector}
                />
              )}
            />
          )}
          <i className={"icon-css " + iconClass} onClick={onIconClick}></i>

          {multiErrorFields.length > 0 ? (
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ messages }) => {
                if (messages) {
                  let isMultipleError = Object.keys(messages).every(
                    (errKey) =>
                      multiErrorFields.filter((m) => m[errKey] !== undefined)
                        .length > 0
                  );
                  if (isMultipleError) {
                    let arr = [];
                    for (const fieldError of multiErrorFields) {
                      let key = Object.keys(fieldError)[0];
                      let success = Object.keys(messages).includes(key);
                      arr.push(
                        <div className={success ? "red" : "green"}>
                          <span key={key}>{fieldError[key]}</span>
                        </div>
                      );
                    }
                    return (
                      <div className="errorMsg show passwordcustom">{arr}</div>
                    );
                  } else {
                    return (
                      <div className="errorMsg show">
                        {errors[name]?.message}
                      </div>
                    );
                  }
                } else {
                  return <div className="errorMsg"></div>;
                }
              }}
            />
          ) : hasError ? (
            <div className="errorMsg show">
              {}
              <p>{errors[name]?.message}</p>
            </div>
          ) : (
            <div className="errorMsg">erro</div>
          )}
        </div>
      </Form.Group>
    </div>
  );
};

TextField.defaultProps = {
  autoFocus: false,
  value: "",
  errors: {},
  multiErrorFields: [],
};

export default TextField;
