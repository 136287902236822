import React from "react";
import Label from "../UI/Label/Label";
import "./BlogCommentCard.scss";
import TextField from "../UI/TextField/TextField";
import CustomButton from "../UI/CustomButton/CustomButton";
import HookForm from "../HookForm/HookForm";
const shareComment = {
  shareThought: {
    name: "shareThoughts",
    validate: {
      required: {
        value: true,
      },
    },
  },
};

const BlogCommentCard = (props) => {
  let { closeModal, clickToDisable, iconClose, profileImage, profileName } =
    props;
  // const [form, setShareComment] = React.useState();

  const onFormSubmit = () => {};
  return (
    <div className="blog-modal-parent">
      <div className="top-section">
        <i className={iconClose} onClick={closeModal}></i>
      </div>
      <div className="comment-card">
        <div className="profile-image-name">
          <img src={profileImage} alt="profile" className="profile-img" />
          <Label title={profileName} className="profile-name"></Label>
        </div>
        <HookForm
          defaultForm={{
            shareThoughts: "",
          }}
          // init={(form) => setShareComment(form)}
          onSubmit={onFormSubmit}
        >
          {(formMethod) => {
            return (
              <div className="form">
                <TextField
                  controlId={"shareThought"}
                  formMethod={formMethod}
                  rules={shareComment.shareThought.validate}
                  name={shareComment.shareThought.name}
                  errors={formMethod?.formState.errors}
                  autoFocus={true}
                  textarea="textarea"
                  placeholder="Share Your Thoughts"
                  className="input-box"
                />
                <div className="card-btn">
                  <CustomButton
                    title="Cancel"
                    className="cancel-btn"
                    onClick={clickToDisable}
                  />
                  <CustomButton title="Submit" className="submit-btn" />
                </div>
              </div>
            );
          }}
        </HookForm>
      </div>
    </div>
  );
};

export default BlogCommentCard;
