import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import "react-datepicker/dist/react-datepicker.css";
import ReactHighcharts from "react-highcharts";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Header from "../../components/Header/Header";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import CustomCalendar from "../../components/UI/CustomCalendar/CustomCalendar";
import CustomDropdown from "../../components/UI/CustomDropdown/CustomDropdown";
import Label from "../../components/UI/Label/Label";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { statisticsSteps } from "../Steps";
import "./../../assests/scss/allModalPopup.scss";
import ExportFilterForm from "./ExportFilterForm";
import FilterForm from "./FilterForm";
import "./Statistics.scss";
const StatisticScreen = () => {
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();

  let search = window.location.search;
  let params = search.split("?")[1];

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObj")
    );
    let newObj = { ...previousLocalStorageValue, statistics: false };
    localStorage.setItem("guideTourObj", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(localStorage.getItem("guideTourObj"));

  return (
    <div>
      <GuidedTour
        steps={statisticsSteps}
        onRequestClose={handleOnRequestClose}
        isOpen={guideTourGetItemValue ? guideTourGetItemValue.statistics : true}
      />
      <Header></Header>

      {params === "group" ? (
        <Statisticmain />
      ) : params === "facebook" ? (
        <h1 style={{ background: "red", textAlign: "center" }}>
          facebook statistics
        </h1>
      ) : params === "twitter" ? (
        <h1 style={{ background: "green", textAlign: "center" }}>
          {" "}
          twitter statistics
        </h1>
      ) : params === "linkedin" ? (
        <h1 style={{ background: "blue", textAlign: "center", color: "white" }}>
          all linkedin statistics
        </h1>
      ) : params === "instagram" ? (
        <h1 style={{ background: "blue", textAlign: "center", color: "white" }}>
          all instagram statistics
        </h1>
      ) : params === "youtube" ? (
        <h1 style={{ background: "blue", textAlign: "center", color: "white" }}>
          all youtube statistics
        </h1>
      ) : params === "tiktok" ? (
        <h1 style={{ background: "blue", textAlign: "center", color: "white" }}>
          all tiktok statistics
        </h1>
      ) : (
        <Statisticmain />
      )}
    </div>
  );
};

export default withRouter(StatisticScreen);

const Statisticmain = () => {
  var chartConfigOverview = {
    colors: ["#16056B", "#F45B0F", "#5696FA"],
    chart: {
      type: "column",
      backgroundColor: "#EFF0F6",
    },
    title: {
      text: '<span style="font-size: 18px; color:#000000;font-weight:600">Overview</span>',
      align: "left",
      verticalAlign: "top",
    },

    xAxis: {
      categories: [
        "12:00 PM",
        "01:00 PM",
        "02:00 PM",
        "03:00 PM",
        "04:00 PM",
        "05:00 PM",
        "06:00 PM",
        "07:00 PM",
        "08:00 PM",
        "09:00 PM",
        "10:00 PM",
        "11:00 PM",
      ],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Visting",
        data: [
          49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1,
          95.6, 54.4,
        ],
      },
      {
        name: "Likes",
        data: [
          83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6,
          92.3,
        ],
      },
      {
        name: "Comments",
        data: [
          48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3,
          51.2,
        ],
      },
    ],

    legend: {
      align: "right",
      verticalAlign: "top",
    },
    // showInLegend: false,
    credits: {
      enabled: false,
    },
  };

  var chartConfigAgeGroup = {
    colors: ["#16056B", "#F45B0F", "#5696FA"],
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      backgroundColor: "#EFF0F6",
    },
    title: {
      text: '<span style="font-size: 18px; color:#000000;font-weight:600">Age Group</span>',
      align: "left",
      verticalAlign: "top",
    },

    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
          distance: -50,
          style: {
            fontWeight: "bold",
            color: "white",
          },
        },
        startAngle: -90,
        endAngle: -90,
        center: ["50%", "50%"],
        size: "80%",
        showInLegend: true,
      },
    },
    series: [
      {
        type: "pie",
        name: "Share",
        innerSize: "75%",
        data: [
          ["Below 20", 33.3],
          ["Below 40", 33.3],
          ["Above 40", 33.33],
        ],
      },
    ],
    legend: {
      align: "right",
      verticalAlign: "top",
    },
    credits: {
      enabled: false,
    },
  };

  var chartConfigGender = {
    colors: ["#A0A3BD"],
    chart: {
      type: "line",
      backgroundColor: "#EFF0F6",
    },

    title: {
      text: '<span style="font-size: 18px; color:#000000;font-weight:600">Gender</span>',
      align: "left",
    },

    xAxis: {
      categories: ["Male", "Female", "Transgender"],
    },

    // plotOptions: {
    //   line: {
    //     dataLabels: {
    //       enabled: false,
    //     },
    //     enableMouseTracking: false,
    //   },
    // },
    series: [
      {
        name: "",
        data: [0, 2, 3, 0],
        showInLegend: false,
      },
    ],

    credits: {
      enabled: false,
    },
  };
  var chartConfigDemography = {
    chart: {
      type: "column",
      backgroundColor: "#EFF0F6",
    },
    colors: ["#16056B"],

    title: {
      text: '<span style="font-size: 18px; color:#000000;font-weight:600">Demography</span>',
      align: "left",
    },

    xAxis: {
      type: "category",
      lineColor: "transparent",
    },
    yAxis: {
      title: {
        text: "Audience",
      },
      gridLineColor: "transparent",
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y:.1f}%",
        },
      },
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
    },

    series: [
      {
        name: "Browsers",
        colorByPoint: true,
        data: [
          {
            name: "India",
            y: 62.74,
            drilldown: "India",
          },
          {
            name: "US",
            y: 50,
            drilldown: "US",
          },
          {
            name: "UK",
            y: 40,
            drilldown: "UK",
          },
          {
            name: "Canda",
            y: 30,
            drilldown: "Canda",
          },
          {
            name: "China",
            y: 70,
            drilldown: "China",
          },
          {
            name: "Poland",
            y: 90,
            drilldown: "Poland",
          },
        ],
      },
    ],
    showInLegend: false,
    credits: {
      enabled: false,
    },

    // plotOptions: {
    //   series: {
    //     pointWidth: 8,
    //   },
    // },
  };
  const dropdownItem = [
    {
      id: "1",
      value: "Statistic",
    },
    {
      id: "2",
      value: "Statistic1",
    },
    {
      id: "3",
      value: "Statistic3",
    },

    {
      id: "4",
      value: "Statistic4",
    },
    {
      id: "5",
      value: "Statistic5",
    },
    {
      id: "6",
      value: "Statistic6",
    },
  ];

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const { t } = useTranslation();

  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [showFilterExport, setShowFilterExport] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [staff, setSelectedStaff] = useState();
  const [labelFilter, setLabelFilter] = useState(0);
  const [tabSearch, setTabSearch] = useState(0);
  const [showTab, setShowTab] = useState(false);

  const OnTabClick = () => {
    setShowTab(!showTab);
  };
  useEffect(() => {
    setLabelFilter(
      document.getElementsByClassName("label_Filter")[0].offsetHeight
    );
    setTabSearch(document.getElementsByClassName("tab_Search")[0].offsetHeight);
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <div className="statistics_Page">
      {/* <Header></Header> */}
      <div className="main_Container">
        <div className="statistics_Inner">
          <div className="tabSearch_section">
            <i className="icon-tab tabFilerIcon" onClick={OnTabClick}></i>
            <div className="label_Filter">
              <div className="label">
                <Label className="main_Heading" title={t("STATISTICS")}></Label>
                <Label
                  className="inner_Text"
                  title={t("STATISTICS_VIEW")}
                ></Label>
              </div>
              <div className="search_Filter">
                <div className="filterBtn">
                  <button
                    className="filterIcon"
                    onClick={() => setShowFilterDropdown(!showFilterDropdown)}
                  >
                    <i className="icon-filter">
                      {" "}
                      <span>{t("STATISTICS_FILTER")}</span>
                    </i>
                  </button>
                </div>
                {showFilterDropdown && (
                  <>
                    <div
                      className="overLay"
                      onClick={() => setShowFilterDropdown(false)}
                    ></div>
                    <FilterForm
                      title="Filter"
                      onClose={() => setShowFilterDropdown(!showFilterDropdown)}
                    ></FilterForm>
                  </>
                )}
                <CustomButton
                  title="Export chart"
                  onClick={() => setShowFilterExport(!showFilterExport)}
                  tourselector="export-btn"
                ></CustomButton>
                {showFilterExport && (
                  <>
                    <div
                      className="overLay"
                      onClick={() => setShowFilterExport(false)}
                    ></div>
                    <ExportFilterForm
                      title={t("STATISTICS_EXPORT")}
                      onclose={() => setShowFilterExport(!showFilterExport)}
                    ></ExportFilterForm>
                  </>
                )}
              </div>
            </div>
            <div className="tab_Search">
              <div className="dropdown_Calendar">
                <div className="dropdown_Option">
                  <CustomDropdown
                    dropDownItems={dropdownItem}
                    placeholder={t("STATISTICS_SELECT")}
                    selectedValue={staff}
                    onSelect={(selected) => setSelectedStaff(selected)}
                  ></CustomDropdown>
                </div>
                <div className="calendar">
                  <div className="date firstCal">
                    <CustomCalendar
                      selected={startDate}
                      onChange={(e) => setStartDate(e)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Date"
                      renderCustomHeader={true}
                    ></CustomCalendar>
                  </div>
                  <div className="date">
                    <CustomCalendar
                      selected={endDate}
                      onChange={(e) => setEndDate(e)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Date"
                      renderCustomHeader={true}
                    ></CustomCalendar>
                  </div>
                </div>
              </div>
              <Scrollbars
                style={{
                  height:
                    dimensions.height - labelFilter - tabSearch - 200 + "px",
                }}
              >
                <div className="main_ChartSection">
                  <div className="chart_Part">
                    <div
                      className="chart_Section"
                      tourselector="overview-graph"
                    >
                      <ReactHighcharts
                        config={chartConfigOverview}
                      ></ReactHighcharts>
                    </div>
                    <div className="chart_Section inline_Chart">
                      <div className="w30" tourselector="age-group">
                        <ReactHighcharts
                          config={chartConfigAgeGroup}
                        ></ReactHighcharts>
                      </div>
                      <div className="w30" tourselector="gender-graph">
                        <ReactHighcharts
                          config={chartConfigGender}
                        ></ReactHighcharts>
                      </div>
                      <div className="w60" tourselector="demography-graph">
                        <ReactHighcharts
                          config={chartConfigDemography}
                        ></ReactHighcharts>
                      </div>
                    </div>
                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
