import bloglist5 from "../assests/images/bloglist5.png";
import profile from "../assests/images/profile.png";
import ScheduleImg from "../assests/images/schedule.png";
import slider11 from "../assests/images/slider11.png";
import ProfileImg from "../assests/images/tab.png";
import {
  default as ProfileImg1,
  default as Schedule,
} from "../assests/images/tab1.png";
import ProfileImg2 from "../assests/images/tab2.png";

export const TabListingJsonData = [
  {
    ProfileImg: ProfileImg,
    accType: "Page",
    statusTitle: "Connected",
    id: "tab1",
    Labeltitle: "empty",
    userName: "John Peter",
  },
  {
    ProfileImg: ProfileImg1,
    accType: "Group",
    statusTitle: "Disconnected",
    id: "tab2",
    Labeltitle: "empty",
    userName: "Steve Johnson",
  },
  {
    ProfileImg: ProfileImg2,
    accType: "Page",
    statusTitle: "Connected",
    id: "tab3",
    Labeltitle: "empty",
    userName: "Tom Harry",
  },
];

export const GroupTabListingJsonData = [
  {
    ProfileImg: ProfileImg1,
    userName: "John Peter",
    accType:
      "This post is going to be one of the best as we are launching our best xyz product",
    DatePick: "19/Aug/2016",
    id: "tab1",
    icon: "empty",
  },
  {
    ProfileImg: ProfileImg2,
    userName: "Steve Johnson",
    accType:
      "This post is going to be one of the best as we are launching our best xyz product",
    DatePick: "19/Aug/2016",
    id: "tab1",
    icon: "empty",
  },
  {
    ProfileImg: ProfileImg,
    userName: "Harry",
    accType:
      "This post is going to be one of the best as we are launching our best xyz product",
    DatePick: "19/Aug/2016",
    id: "tab1",
    icon: "empty",
  },
];

export const PublishAllJsonData = [
  {
    image: Schedule,
    headTitle: "Tech crunch",
    // subTitle:{t('DRAFT_ONE_TIME')},
    subTitle: "Created-21 min ago",
    iconName: "icon-rotate",
    imageMain: slider11,
    // Title:{t('DRAFT_TWO_TEXT')},
    Title:
      "This post is going to be one of the best as we are launching our best xyz product",
    Time: "Scheduled on 9:00 AM, 24 Dec 2021 (UTC +05:30)",
    showSendIcons: true,
    showthreeIcons: false,
    tourselector: "schedule-card",
  },
  {
    image: Schedule,
    headTitle: "Tech crunch",
    // subTitle:{t('DRAFT_ONE_TIME')},
    subTitle: "Created-21 min ago",
    iconName: "icon-rotate",
    imageMain: ScheduleImg,
    // Title:{t('DRAFT_TWO_TEXT')},
    Title:
      "This post is going to be one of the best as we are launching our best xyz product",
    Time: "Scheduled on 9:00 AM, 24 Dec 2021 (UTC +05:30)",
    showSendIcons: true,
    showthreeIcons: false,
    tourselector: "schedule-card",
  },
  {
    image: Schedule,
    headTitle: "Tech crunch",
    // subTitle:{t('DRAFT_ONE_TIME')},
    subTitle: "Created-21 min ago",
    iconName: "icon-rotate",
    imageMain: bloglist5,
    // Title:{t('DRAFT_TWO_TEXT')},
    Title:
      "This post is going to be one of the best as we are launching our best xyz product",
    Time: "Scheduled on 9:00 AM, 24 Dec 2021 (UTC +05:30)",
    showSendIcons: true,
    showthreeIcons: false,
    tourselector: "schedule-card",
  },
  {
    image: Schedule,
    headTitle: "Tech crunch",
    // subTitle:{t('DRAFT_ONE_TIME')},
    subTitle: "Created-21 min ago",
    iconName: "icon-rotate",
    imageMain: ScheduleImg,
    // Title:{t('DRAFT_TWO_TEXT')},
    Title:
      "This post is going to be one of the best as we are launching our best xyz product",
    Time: "Scheduled on 9:00 AM, 24 Dec 2021 (UTC +05:30)",
    showSendIcons: true,
    showthreeIcons: false,
    tourselector: "schedule-card",
  },
];

export const CalendarListJsonData = [
  {
    ProfileImg: ProfileImg,
    userName: "John Peter",
    accType: "Page",
    statusTitle: "Connected",
  },
  {
    ProfileImg: ProfileImg1,
    userName: "Steve",
    accType: "Group",
    statusTitle: "disonnected",
  },
  {
    ProfileImg: ProfileImg2,
    userName: "Tom Harry",
    accType: "Page",
    statusTitle: "Connected",
  },
];

export const AccountListingJsonData = [
  {
    checkbox: "checkbox1",
    accImage: profile,
    accName: "Post_Of_The_Day",
  },
  {
    checkbox: "checkbox2",
    accImage: profile,
    accName: "Post_Of_The_Day",
  },
  {
    checkbox: "checkbox3",
    accImage: profile,
    accName: "Post_Of_The_Day",
  },
  {
    checkbox: "checkbox4",
    accImage: profile,
    accName: "Post_Of_The_Day",
  },
  {
    checkbox: "checkbox5",
    accImage: profile,
    accName: "Post_Of_The_Day",
  },
  {
    checkbox: "checkbox6",
    accImage: profile,
    accName: "Post_Of_The_Day",
  },
  {
    checkbox: "checkbox7",
    accImage: profile,
    accName: "Post_Of_The_Day",
  },
];
