import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { TabListingJsonData } from "../../ConstantJson/ConstantData";
import {
  postToFacebookAction,
  fetchGroupAction,
} from "../../Redux_saga/postPublisherSlice";
import TabHeader from "../../components/TabHeader/TabHeader";
import TabListing from "../../components/TabListing/TabListing";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import CustomCalendar from "../../components/UI/CustomCalendar/CustomCalendar";
import Label from "../../components/UI/Label/Label";
import MessageBox from "../../components/UI/MessageBox/MessageBox";
import ModalPopup from "../../components/UI/ModalPopup/ModalPopup";
import PreviewTab from "../../components/UI/PreviewTab/PreviewTab";
import SearchBox from "../../components/UI/SearchBox/SearchBox";
import { useAppData } from "../../services/hooks";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { previewTabSteps, publishSteps } from "../Steps";
import "./../../assests/scss/allModalPopup.scss";
import FilterForm from "./Filter/FilterForm";
import "./Publish.scss";
import { showToastAction } from "../../Redux_saga/globalSlice";

const PublishScreen = () => {
  const { t } = useTranslation();
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [showEditPostModal, setShowEditPostModal] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [scheduleTime, setScheduleTime] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [tabsHeight, setTabsHeight] = useState(0);
  const [searchFilter, setSearchFilter] = useState(0);
  const [titleHeightt, setTitleHeight] = useState(0);
  const [showTab, setShowTab] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [facebookInputValue, setFacebookInputVale] = useState("");
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();
  const [isOpenTourGuidePreview, setIsOpenTourGuidePreview] = useState();
  const dispatch = useDispatch();
  const post = useAppData((r) => r.postPublisherSlice.post);
  const User = useAppData((r) => r.postPublisherSlice?.user);
  const Token = useAppData((r) => r.postPublisherSlice?.tokens);
  const UserGroups = useSelector((r) => r.postPublisherSlice?.groups);

  const [postPublished, setPostPublished] = useState(false);

  const location = useLocation();

  const selectedPost = location.state || {};
  const messageboxHeight = 0;
  const OnTabClick = () => {
    setShowTab(!showTab);
  };

  const OnChatClick = () => {};
  const OnChatClose = () => {
    setShowChat(!showChat);
  };

  const onCreatePost = () => {
    setShowChat(!showChat);
    let guideTourGetItemValuePreview = JSON.parse(
      localStorage.getItem("guideTourObj")
    );
    let newObjPreview =
      guideTourGetItemValuePreview.previewtab === "false"
        ? {
            ...guideTourGetItemValuePreview,
            previewtab: true,
          }
        : guideTourGetItemValuePreview;

    localStorage.setItem("guideTourObj", JSON.stringify(newObjPreview));
  };

  useEffect(() => {
    setTabsHeight(
      document.getElementsByClassName("tabs_Height")[0].offsetHeight
    );
    setTitleHeight(
      document.getElementsByClassName("title_Height")[0].offsetHeight
    );
    setSearchFilter(
      document.getElementsByClassName("search_Filter")[0].offsetHeight
    );
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const updatedFbData = User?.fb.map((fbItem) => {
    const { userProfile, pages, groups } = fbItem;

    const updatedPages = pages.map((page) => {
      return { ...page, userProfile };
    });

    const updatedGroups = groups.map((group) => {
      return { ...group, userProfile };
    });

    return {
      ...fbItem,
      pages: updatedPages,
      groups: updatedGroups,
    };
  });

  const newLinkedInArray = User?.linkedIn.map((obj) => ({
    ...obj,
    checked: false,
  }));

  const newFaceBookPagesArray =
    Object.values(updatedFbData || {}).flatMap((fbItem) =>
      (fbItem.pages || []).map((page) => ({
        ...page,
        checked: false,
      }))
    ) || [];

  const newFaceBookGroupsArray =
    Object.values(updatedFbData || {}).flatMap((fbItem) =>
      (fbItem.groups || []).map((group) => ({
        ...group,
        checked: false,
      }))
    ) || [];

  const newInstagramArray = User?.instagram.map((obj) => ({
    ...obj.profile,
    checked: false,
  }));

  const newTwitterProfile = User?.twitter.map((obj) => ({
    ...obj,
    checked: false,
  }));

  const newYoutubeArray = User?.youtube.map((obj) => ({
    ...obj,
    checked: false,
  }));

  const newUserGroups =
    UserGroups.length > 0 &&
    UserGroups.map((obj) => ({ ...obj, checked: false }));

  const [activeKey, setActiveKey] = useState("Group");

  const [linkedInData, setLinkedInData] = useState(newLinkedInArray);
  const [facebookPages, setFacebookPages] = useState(newFaceBookPagesArray);
  const [facebookGroup, setFacebookGroup] = useState(newFaceBookGroupsArray);
  const [instagramPage, setInstagramPage] = useState(newInstagramArray);
  const [twitterProfile, setTwitterProfile] = useState(newTwitterProfile);
  const [youtubeChannel, setYoutubeChannel] = useState(newYoutubeArray);

  const [userGroups, setUserGroups] = useState(newUserGroups);

  const [filteredLinkedInData, setFilteredLinkedInData] =
    useState(newLinkedInArray);
  const [filteredFacebookPages, setFilteredFacebookPages] = useState(
    newFaceBookPagesArray
  );
  const [filteredFacebookGroup, setFilteredFacebookGroup] = useState(
    newFaceBookGroupsArray
  );
  const [filteredInstagramPage, setFilteredInstagramPage] =
    useState(newInstagramArray);
  const [filteredTwitterProfile, setFilteredTwitterProfile] =
    useState(newTwitterProfile);
  const [filteredYoutubeChannel, setFilteredYoutubeChannel] =
    useState(newYoutubeArray);

  const [filteredUserGroups, setFilteredUserGroups] = useState(newUserGroups);

  const [checked, setChecked] = useState();
  const onCheckedChange = (e, id) => {
    const updatedArray = linkedInData.map((obj) => {
      if (obj.id === id) {
        return { ...obj, checked: !obj.checked };
      } else {
        return obj;
      }
    });
    const updatedArray1 = facebookPages.map((obj) => {
      if (obj.id === id) {
        return { ...obj, checked: !obj.checked };
      } else {
        return obj;
      }
    });
    const updatedArray2 = facebookGroup.map((obj) => {
      if (obj.id === id) {
        return { ...obj, checked: !obj.checked };
      } else {
        return obj;
      }
    });
    console.log("20-02-24 02", updatedArray2);
    const updatedArray3 = instagramPage.map((obj) => {
      if (obj.id === id) {
        return { ...obj, checked: !obj.checked };
      } else {
        return obj;
      }
    });
    const updatedArray4 = twitterProfile.map((obj) => {
      if (obj._id === id) {
        return { ...obj, checked: !obj.checked };
      } else {
        return obj;
      }
    });
    const updatedArray5 = youtubeChannel.map((obj) => {
      if (obj.id === id) {
        return { ...obj, checked: !obj.checked };
      } else {
        return obj;
      }
    });
    const updatedUserGroups = userGroups.map((obj) => {
      if (obj._id === id) {
        console.log("20-02-24 07", obj);
        return { ...obj, checked: !obj.checked };
      } else {
        console.log("20-02-24 08", obj);
        return obj;
      }
    });
    console.log("20-02-24 06", updatedUserGroups);
    setFacebookPages(updatedArray1);
    setLinkedInData(updatedArray);
    setFacebookGroup(updatedArray2);
    setInstagramPage(updatedArray3);
    setTwitterProfile(updatedArray4);
    setYoutubeChannel(updatedArray5);
    setUserGroups(updatedUserGroups);

    setFilteredFacebookPages(updatedArray1);
    setFilteredLinkedInData(updatedArray);
    setFilteredFacebookGroup(updatedArray2);
    setFilteredInstagramPage(updatedArray3);
    setFilteredTwitterProfile(updatedArray4);
    setFilteredYoutubeChannel(updatedArray5);
    setFilteredUserGroups(updatedUserGroups);

    // setChecked(e.target.checked);
  };

  const handleSearch = async (query) => {
    setFilteredFacebookPages(
      facebookPages.filter((page) =>
        page.name.toLowerCase().includes(query.toLowerCase())
      )
    );

    setFilteredFacebookGroup(
      facebookGroup.filter((group) =>
        group.name.toLowerCase().includes(query.toLowerCase())
      )
    );

    setFilteredLinkedInData(
      linkedInData.filter((profile) =>
        profile.firstName.toLowerCase().includes(query.toLowerCase())
      )
    );

    setFilteredInstagramPage(
      instagramPage.filter((page) =>
        page.instagram_business_account_id
          .toLowerCase()
          .includes(query.toLowerCase())
      )
    );

    setFilteredTwitterProfile(
      twitterProfile.filter((page) =>
        page.screenName.toLowerCase().includes(query.toLowerCase())
      )
    );

    setFilteredYoutubeChannel(
      youtubeChannel.filter((channel) =>
        channel.snippet.title.toLowerCase().includes(query.toLowerCase())
      )
    );

    setFilteredUserGroups(
      userGroups.filter((ug) =>
        ug.groupTitle.toLowerCase().includes(query.toLowerCase())
      )
    );
  };

  const handleClickPublishNow = () => {
    const MediaType =
      activeKey === "Facebook"
        ? "facebook"
        : activeKey === "Instagram"
        ? "instagram"
        : activeKey === "LinkedIn"
        ? "linkedin"
        : activeKey === "Twitter"
        ? "twitter"
        : activeKey === "Youtube"
        ? "youtube"
        : activeKey === "Group"
        ? "group"
        : null;
    let postDetailsData1;
    switch (MediaType) {
      case "facebook":
        const FacebookGroupData = facebookGroup.map((obj, index) => ({
          mediaType: MediaType,
          profileId: obj.userProfile.id,
          postingInto: "group",
          postIntoId: obj.id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: false,
          isScheduled: false,
          isPaused: false,
          scheduledTime: "",
          checked: obj.checked,
          ...post,
        }));

        const FacebookPagesData = facebookPages.map((obj, index) => ({
          mediaType: MediaType,
          profileId: obj.userProfile.id,
          postingInto: "page",
          postIntoId: obj.id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: false,
          isScheduled: false,
          isPaused: false,
          scheduledTime: "",
          checked: obj.checked,
          ...post,
        }));
        postDetailsData1 = [...FacebookGroupData, ...FacebookPagesData];

        break;
      case "instagram":
        postDetailsData1 = instagramPage.map((obj, index) => ({
          mediaType: MediaType,
          profileId: obj.instagram_business_account_id,
          postingInto: "business",
          postIntoId: obj.instagram_business_account_id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: false,
          isScheduled: false,
          isPaused: false,
          scheduledTime: "",
          checked: obj.checked,
          ...post,
          contentType: post.contentType == "video" ? "reels" : post.contentType,
        }));
        break;
      case "linkedin":
        postDetailsData1 = linkedInData.map((obj, index) => ({
          mediaType: MediaType,
          profileId: obj.id,
          postingInto: "profile",
          postIntoId: obj.id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: false,
          isScheduled: false,
          isPaused: false,
          scheduledTime: "",
          checked: obj.checked,
          ...post,
        }));
        break;
      case "twitter":
        postDetailsData1 = twitterProfile.map((obj, index) => ({
          mediaType: MediaType,
          profileId: obj.userId,
          postingInto: "profile",
          postIntoId: obj.userId,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: false,
          isScheduled: false,
          isPaused: false,
          scheduledTime: "",
          checked: obj.checked,
          ...post,
        }));
        break;
      case "youtube":
        postDetailsData1 = youtubeChannel.map((obj, index) => ({
          ...post,
          mediaType: MediaType,
          profileId: obj.id,
          postingInto: "channel",
          postIntoId: obj.id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: false,
          isScheduled: false,
          isPaused: false,
          scheduledTime: "",
          checked: obj.checked,
          ...post,
        }));
        break;
      case "group":
        postDetailsData1 = userGroups.map((obj, index) => ({
          ...post,
          mediaType: MediaType,
          profileId: obj._id,
          postingInto: "group",
          postIntoId: obj._id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: false,
          isScheduled: false,
          isPaused: false,
          scheduledTime: "",
          checked: obj.checked,
          ...post,
        }));
        break;
      default:
    }

    const checkedItems = postDetailsData1.filter((user) => user.checked);

    if (checkedItems.length === 0) {
      console.error("Please select at least one item to post.");
      dispatch(
        showToastAction({
          message: "Please select atleat one social media to proceed.",
          type: "error",
        })
      );
      return;
    }

    const invalidFeed = checkedItems.filter(
      (item) => item.contentType === "feed" && item.message === ""
    );

    if (invalidFeed.length > 0) {
      dispatch(
        showToastAction({
          message: "Post cannot be empty. Atleast enter some texts to post.",
          type: "error",
        })
      );
      return;
    }

    const invalidLinks = checkedItems.filter(
      (item) =>
        (item.contentType === "photo" ||
          item.contentType === "reels" ||
          item.contentType === "video") &&
        item.link === ""
    );

    if (invalidLinks.length > 0) {
      dispatch(
        showToastAction({
          message: "Posts such as Photo, reels/video cannot be posted blank..",
          type: "error",
        })
      );
      return;
    }

    //insta check
    const instaUnsupportedPosts = checkedItems.filter(
      (item) =>
        item.mediaType === "instagram" &&
        !(item.contentType === "reels" || item.contentType === "photo")
    );

    console.log("instaUnsupportedPosts ==>", instaUnsupportedPosts);

    if (instaUnsupportedPosts.length > 0) {
      dispatch(
        showToastAction({
          message:
            "For Instagram, you can either post a image/reels video. other types are unsupported.",
          type: "error",
        })
      );
      return;
    }

    //youtube check
    const youtubeUnsupportedPosts = checkedItems.filter(
      (item) => item.mediaType === "youtube" && item.contentType !== "video"
    );

    if (youtubeUnsupportedPosts.length > 0) {
      dispatch(
        showToastAction({
          message:
            "For Youube, you can post only video. other types are unsupported.",
          type: "error",
        })
      );
      return;
    }

    const filteredPostDetails = postDetailsData1
      .filter((user) => user.checked)
      .map(({ checked, ...rest }) => rest);

    console.log("filteredPostDetails ===>", filteredPostDetails);

    const postList = {
      bodyData: {
        category: MediaType === "group" ? "group" : "individual",
        user: User?._id,
        groupId: MediaType === "group" ? filteredPostDetails[0].profileId : "",
        postDetails: filteredPostDetails,
      },
      token: Token?.access?.token,
    };

    dispatch(postToFacebookAction(postList));
    setPostPublished(true);
  };

  const handleChnagePostToFacebook = (e) => {
    setFacebookInputVale(e.target.value);
  };

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObj")
    );
    let newObj = { ...previousLocalStorageValue, publish: false };
    localStorage.setItem("guideTourObj", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(localStorage.getItem("guideTourObj"));

  const handleOnRequestClosePreview = () => {
    let previousLocalStorageValuePreview = JSON.parse(
      localStorage.getItem("guideTourObj")
    );
    let newObjPreview = {
      ...previousLocalStorageValuePreview,
      previewtab: false,
    };
    localStorage.setItem("guideTourObj", JSON.stringify(newObjPreview));
    setIsOpenTourGuidePreview(newObjPreview);
  };
  React.useEffect(() => {
    if (isOpenTourGuidePreview != undefined) {
      localStorageSetItem(isOpenTourGuidePreview);
    }
  }, [isOpenTourGuidePreview]);
  let guideTourGetItemValuePreview = JSON.parse(
    localStorage.getItem("guideTourObj")
  );

  const onSchedulePost = () => {
    const combinedDateTime = new Date(
      scheduleDate.getFullYear(),
      scheduleDate.getMonth(),
      scheduleDate.getDate(),
      scheduleTime.getHours(),
      scheduleTime.getMinutes()
    );

    const epochTime = Math.floor(combinedDateTime.getTime() / 1000);

    const MediaType =
      activeKey === "Facebook"
        ? "facebook"
        : activeKey === "Instagram"
        ? "instagram"
        : activeKey === "LinkedIn"
        ? "linkedin"
        : activeKey === "Twitter"
        ? "twitter"
        : activeKey === "Youtube"
        ? "youtube"
        : activeKey === "Group"
        ? "group"
        : null;
    let postDetailsData1;
    switch (MediaType) {
      case "facebook":
        const FacebookGroupData = facebookGroup.map((obj, index) => ({
          mediaType: MediaType,
          profileId: obj.userProfile.id,
          postingInto: "group",
          postIntoId: obj.id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: false,
          isScheduled: true,
          isPaused: false,
          scheduledTime: epochTime.toString(),
          checked: obj.checked,
          ...post,
        }));

        const FacebookPagesData = facebookPages.map((obj, index) => ({
          mediaType: MediaType,
          profileId: obj.userProfile.id,
          postingInto: "page",
          postIntoId: obj.id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: false,
          isScheduled: true,
          isPaused: false,
          scheduledTime: epochTime.toString(),
          checked: obj.checked,
          ...post,
        }));
        postDetailsData1 = [...FacebookGroupData, ...FacebookPagesData];

        break;
      case "instagram":
        postDetailsData1 = instagramPage.map((obj, index) => ({
          mediaType: MediaType,
          profileId: obj.instagram_business_account_id,
          postingInto: "business",
          postIntoId: obj.instagram_business_account_id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: false,
          isScheduled: true,
          isPaused: false,
          scheduledTime: epochTime.toString(),
          checked: obj.checked,
          ...post,
          contentType: post.contentType == "video" ? "reels" : post.contentType,
        }));
        break;
      case "linkedin":
        postDetailsData1 = linkedInData.map((obj, index) => ({
          mediaType: MediaType,
          profileId: obj.id,
          postingInto: "profile",
          postIntoId: obj.id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: false,
          isScheduled: true,
          isPaused: false,
          scheduledTime: epochTime.toString(),
          checked: obj.checked,
          ...post,
        }));
        break;
      case "twitter":
        postDetailsData1 = twitterProfile.map((obj, index) => ({
          mediaType: MediaType,
          profileId: obj.userId,
          postingInto: "profile",
          postIntoId: obj.userId,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: false,
          isScheduled: true,
          isPaused: false,
          scheduledTime: epochTime.toString(),
          checked: obj.checked,
          ...post,
        }));
        break;
      case "youtube":
        postDetailsData1 = youtubeChannel.map((obj, index) => ({
          ...post,
          mediaType: MediaType,
          profileId: obj.id,
          postingInto: "channel",
          postIntoId: obj.id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: false,
          isScheduled: true,
          isPaused: false,
          scheduledTime: epochTime.toString(),
          checked: obj.checked,
          ...post,
        }));
        break;
      case "group":
        postDetailsData1 = userGroups.map((obj, index) => ({
          ...post,
          mediaType: MediaType,
          profileId: obj._id,
          postingInto: "group",
          postIntoId: obj._id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: false,
          isScheduled: true,
          isPaused: false,
          scheduledTime: epochTime.toString(),
          checked: obj.checked,
          ...post,
        }));
        break;
      default:
    }
    const checkedItems = postDetailsData1.filter((user) => user.checked);

    if (checkedItems.length === 0) {
      dispatch(
        showToastAction({
          message: "Please select atleat one social media to proceed.",
          type: "error",
        })
      );
      return;
    }

    const invalidFeed = checkedItems.filter(
      (item) => item.contentType === "feed" && item.message === ""
    );

    if (invalidFeed.length > 0) {
      dispatch(
        showToastAction({
          message: "Post cannot be empty. Atleast enter some texts to post.",
          type: "error",
        })
      );
      return;
    }

    const invalidLinks = checkedItems.filter(
      (item) =>
        (item.contentType === "photo" ||
          item.contentType === "reels" ||
          item.contentType === "video") &&
        item.link === ""
    );

    if (invalidLinks.length > 0) {
      dispatch(
        showToastAction({
          message: "Posts such as Photo, reels/video cannot be posted blank..",
          type: "error",
        })
      );
      return;
    }

    let filteredPostDetails = postDetailsData1
      .filter((user) => user.checked)
      .map(({ checked, ...rest }) => rest);

    const postList = {
      bodyData: {
        category: MediaType === "group" ? "group" : "individual",
        user: User?._id,
        groupId: MediaType === "group" ? filteredPostDetails[0].profileId : "",
        postDetails: filteredPostDetails,
      },
      token: Token?.access?.token,
    };

    dispatch(postToFacebookAction(postList));
    setShowEditPostModal(false);
    setPostPublished(true);
  };

  const handleFilterChange = (selectedStatus) => {
    //facebook
    let pages = [],
      groups = [];

    if (!selectedStatus) {
      pages = [...facebookPages];
      groups = [...facebookGroup];
    } else if (+selectedStatus.id === 1 || +selectedStatus.id === 2) {
      const isActive = selectedStatus.id === "2";
      pages = facebookPages.filter((page) => page.isActive === isActive);

      groups = facebookGroup.filter((group) => group.isActive === isActive);
    } else if (+selectedStatus.id === 3 || +selectedStatus.id === 4) {
      const isPaused = selectedStatus.id === "3";

      pages = facebookPages.filter(
        (page) => page.isPaused === isPaused && page.isActive
      );

      groups = facebookGroup.filter(
        (group) => group.isPaused === isPaused && group.isActive
      );
    }

    setFilteredFacebookPages([...pages]);

    setFilteredFacebookGroup([...groups]);

    //twitter
    let twtr = [];
    if (!selectedStatus) {
      twtr = [...twitterProfile];
    } else {
      if (+selectedStatus.id === 1 || +selectedStatus.id === 2) {
        const isActive = selectedStatus.id === "2";
        twtr = twitterProfile.filter(
          (profile) => profile.isActive === isActive
        );
      } else if (+selectedStatus.id === 3 || +selectedStatus.id === 4) {
        const isPaused = selectedStatus.id === "3";
        twtr = twitterProfile.filter(
          (profile) => profile.isPaused === isPaused
        );
      }
    }

    setFilteredTwitterProfile(twtr);

    //linkedin
    let linkIn = [];

    if (!selectedStatus) {
      linkIn = [...linkedInData];
    } else {
      if (+selectedStatus.id === 1 || +selectedStatus.id === 2) {
        const isActive = selectedStatus.id === "2";
        linkIn = linkedInData.filter(
          (profile) => profile.isActive === isActive
        );
      } else if (+selectedStatus.id === 3 || +selectedStatus.id === 4) {
        const isPaused = selectedStatus.id === "3";
        linkIn = linkedInData.filter(
          (profile) => profile.isPaused === isPaused
        );
      }
    }

    setFilteredLinkedInData(linkIn);

    //instagram
    let insta = [];
    if (!selectedStatus) {
      insta = [...instagramPage];
    } else {
      if (+selectedStatus.id === 1 || +selectedStatus.id === 2) {
        const isActive = selectedStatus.id === "2";
        insta = instagramPage.filter(
          (business) => business.isActive === isActive
        );
      } else if (+selectedStatus.id === 3 || +selectedStatus.id === 4) {
        const isPaused = selectedStatus.id === "3";
        insta = instagramPage.filter(
          (business) => business.isPaused === isPaused
        );
      }
    }

    setFilteredInstagramPage(insta);

    //youtube
    let ytube = [];
    if (!selectedStatus) {
      ytube = [...youtubeChannel];
    } else {
      if (+selectedStatus.id === 1 || +selectedStatus.id === 2) {
        const isActive = selectedStatus.id === "2";
        ytube = youtubeChannel.filter(
          (channel) => channel.isActive === isActive
        );
      } else if (+selectedStatus.id === 3 || +selectedStatus.id === 4) {
        const isPaused = selectedStatus.id === "3";
        ytube = youtubeChannel.filter(
          (channel) => channel.isPaused === isPaused
        );
      }
    }

    setFilteredYoutubeChannel(ytube);

    //userGorups
    let uGroups = [];
    if (!selectedStatus) {
      uGroups = [...userGroups];
    } else {
      if (+selectedStatus.id === 1 || +selectedStatus.id === 2) {
        const isActive = selectedStatus.id === "2";
        uGroups = userGroups.filter((grp) => grp.isActive === isActive);
      } else if (+selectedStatus.id === 3 || +selectedStatus.id === 4) {
        const isPaused = selectedStatus.id === "3";
        uGroups = userGroups.filter((grp) => grp.isPaused === isPaused);
      }
    }

    setFilteredUserGroups(uGroups);
  };

  const handleClickDraftPost = () => {
    const MediaType =
      activeKey === "Facebook"
        ? "facebook"
        : activeKey === "Instagram"
        ? "instagram"
        : activeKey === "LinkedIn"
        ? "linkedin"
        : activeKey === "Twitter"
        ? "twitter"
        : activeKey === "Youtube"
        ? "youtube"
        : activeKey === "Group"
        ? "group"
        : null;
    let postDetailsData1;
    switch (MediaType) {
      case "facebook":
        const FacebookGroupData = facebookGroup.map((obj, index) => ({
          mediaType: MediaType,
          profileId: obj.userProfile.id,
          postingInto: "group",
          postIntoId: obj.id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: true,
          isScheduled: false,
          isPaused: false,
          scheduledTime: "",
          checked: obj.checked,
          ...post,
        }));

        const FacebookPagesData = facebookPages.map((obj, index) => ({
          mediaType: MediaType,
          profileId: obj.userProfile.id,
          postingInto: "page",
          postIntoId: obj.id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: true,
          isScheduled: false,
          isPaused: false,
          scheduledTime: "",
          checked: obj.checked,
          ...post,
        }));
        postDetailsData1 = [...FacebookGroupData, ...FacebookPagesData];

        break;
      case "instagram":
        postDetailsData1 = instagramPage.map((obj, index) => ({
          mediaType: MediaType,
          profileId: obj.instagram_business_account_id,
          postingInto: "business",
          postIntoId: obj.instagram_business_account_id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: true,
          isScheduled: false,
          isPaused: false,
          scheduledTime: "",
          checked: obj.checked,
          ...post,
          contentType: post.contentType == "video" ? "reels" : post.contentType,
        }));
        break;
      case "linkedin":
        postDetailsData1 = linkedInData.map((obj, index) => ({
          mediaType: MediaType,
          profileId: obj.id,
          postingInto: "profile",
          postIntoId: obj.id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: true,
          isScheduled: false,
          isPaused: false,
          scheduledTime: "",
          checked: obj.checked,
          ...post,
        }));
        break;
      case "twitter":
        postDetailsData1 = twitterProfile.map((obj, index) => ({
          mediaType: MediaType,
          profileId: obj.userId,
          postingInto: "profile",
          postIntoId: obj.userId,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: true,
          isScheduled: false,
          isPaused: false,
          scheduledTime: "",
          checked: obj.checked,
          ...post,
        }));
        break;
      case "youtube":
        postDetailsData1 = youtubeChannel.map((obj, index) => ({
          ...post,
          mediaType: MediaType,
          profileId: obj.id,
          postingInto: "channel",
          postIntoId: obj.id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: true,
          isScheduled: false,
          isPaused: false,
          scheduledTime: "",
          checked: obj.checked,
          ...post,
        }));
        break;
      case "group":
        postDetailsData1 = userGroups.map((obj, index) => ({
          ...post,
          mediaType: MediaType,
          profileId: obj._id,
          postingInto: "group",
          postIntoId: obj._id,
          contentType: "feed",
          linkType: "",
          link: "",
          isDraft: true,
          isScheduled: false,
          isPaused: false,
          scheduledTime: "",
          checked: obj.checked,
          ...post,
        }));
        break;
      default:
    }

    const checkedItems = postDetailsData1.filter((user) => user.checked);

    if (checkedItems.length === 0) {
      dispatch(
        showToastAction({
          message: "Please select atleat one social media to proceed.",
          type: "error",
        })
      );
      return;
    }

    const invalidFeed = checkedItems.filter(
      (item) => item.contentType === "feed" && item.message === ""
    );

    if (invalidFeed.length > 0) {
      dispatch(
        showToastAction({
          message: "Post cannot be empty. Atleast enter some texts to post.",
          type: "error",
        })
      );
      return;
    }

    const invalidLinks = checkedItems.filter(
      (item) =>
        (item.contentType === "photo" ||
          item.contentType === "reels" ||
          item.contentType === "video") &&
        item.link === ""
    );

    if (invalidLinks.length > 0) {
      dispatch(
        showToastAction({
          message: "Posts such as Photo, reels/video cannot be posted blank..",
          type: "error",
        })
      );
      return;
    }

    let filteredPostDetails = postDetailsData1
      .filter((user) => user.checked)
      .map(({ checked, ...rest }) => rest);

    const postList = {
      bodyData: {
        category: MediaType === "group" ? "group" : "individual",
        user: User?._id,
        groupId: MediaType === "group" ? filteredPostDetails[0].profileId : "",
        postDetails: filteredPostDetails,
      },
      token: Token?.access?.token,
    };

    dispatch(postToFacebookAction(postList));
    setPostPublished(true);
  };

  useEffect(() => {
    if (
      selectedPost &&
      selectedPost.postDetails &&
      selectedPost.postDetails.length > 0
    ) {
      const mediaType = selectedPost?.postDetails[0]?.mediaType || "Group";
      // onCreatePost();
      setShowChat(!showChat);

      if (selectedPost.category === "Group") {
        setActiveKey("Group");

        const updatedUsrGroupDetails = UserGroups.map((profile) => {
          if (profile._id === selectedPost.groupId) {
            return { ...profile, checked: true };
          }
          return profile;
        });

        setUserGroups([...updatedUsrGroupDetails]);

        const updateFilteredUsrGroupDetails = filteredUserGroups.map(
          (profile) => {
            if (profile._id === selectedPost.groupId) {
              return { ...profile, checked: true };
            }
            return profile;
          }
        );

        setFilteredUserGroups([...updateFilteredUsrGroupDetails]);
      }
      switch (mediaType) {
        case "facebook":
          setActiveKey("Facebook");
          if (selectedPost.postDetails[0].postingInto === "page") {
            const updateFbPages = facebookPages.map((page) => {
              if (page.id === selectedPost.postDetails[0].postIntoId) {
                return { ...page, checked: true };
              }
              return page;
            });

            setFacebookPages([...updateFbPages]);

            const updatedFilteredFbPages = filteredFacebookPages.map((page) => {
              if (page.id === selectedPost.postDetails[0].postIntoId) {
                return { ...page, checked: true };
              }
              return page;
            });

            setFilteredFacebookPages([...updatedFilteredFbPages]);
          }
          if (selectedPost.postDetails[0].postingInto === "group") {
            const updatedfbGroups = filteredFacebookGroups.map((group) => {
              if (group.id === selectedPost.postDetails[0].postIntoId) {
                return { ...group, checked: true };
              }
              return group;
            });

            setFilteredFacebookGroup([...updatedfbGroups]);
          }
          break;
        case "twitter":
          setActiveKey("Twitter");
          const updatedTwitterProfile = twitterProfile.map((profile) => {
            if (profile.userId === selectedPost.postDetails[0].postIntoId) {
              return { ...profile, checked: true };
            }
            return profile;
          });

          setTwitterProfile([...updatedTwitterProfile]);

          const updatedFilteredTwitterProfile = filteredTwitterProfile.map(
            (profile) => {
              if (profile.userId === selectedPost.postDetails[0].postIntoId) {
                return { ...profile, checked: true };
              }
              return profile;
            }
          );

          setFilteredTwitterProfile([...updatedFilteredTwitterProfile]);
          break;
        case "linkedin":
          setActiveKey("LinkedIn");
          const updatedLinkedInData = linkedInData.map((profile) => {
            if (profile.id === selectedPost.postDetails[0].postIntoId) {
              return { ...profile, checked: true };
            }
            return profile;
          });

          setLinkedInData([...updatedLinkedInData]);

          const updatedFilteredLinkedInData = filteredLinkedInData.map(
            (profile) => {
              if (profile.id === selectedPost.postDetails[0].postIntoId) {
                return { ...profile, checked: true };
              }
              return profile;
            }
          );

          setFilteredLinkedInData([...updatedFilteredLinkedInData]);
          break;
        case "youtube":
          setActiveKey("Youtube");
          const updateYoutubeChannel = youtubeChannel.map((channel) => {
            if (channel.id === selectedPost.postDetails[0].postIntoId) {
              return { ...channel, checked: true };
            }
            return channel;
          });

          setYoutubeChannel([...updateYoutubeChannel]);

          const updatedFilteredYoutubeChannel = filteredYoutubeChannel.map(
            (channel) => {
              if (channel.id === selectedPost.postDetails[0].postIntoId) {
                return { ...channel, checked: true };
              }
              return channel;
            }
          );

          setFilteredYoutubeChannel([...updatedFilteredYoutubeChannel]);
          break;
        case "instagram":
          setActiveKey("Instagram");
          const updateInstagramPage = instagramPage.map((page) => {
            if (
              page.instagram_business_account_id ===
              selectedPost.postDetails[0].postIntoId
            ) {
              return { ...page, checked: true };
            }
            return page;
          });

          setInstagramPage([...updateInstagramPage]);

          const updatedFilteredInstagramPage = filteredInstagramPage.map(
            (page) => {
              if (
                page.instagram_business_account_id ===
                selectedPost.postDetails[0].postIntoId
              ) {
                return { ...page, checked: true };
              }
              return page;
            }
          );

          setFilteredInstagramPage([...updatedFilteredInstagramPage]);
          break;
        default:
          setActiveKey("default");
      }
    }
  }, [selectedPost]);
  return (
    <div className="publish_Page">
      <GuidedTour
        steps={publishSteps}
        onRequestClose={handleOnRequestClose}
        isOpen={guideTourGetItemValue ? guideTourGetItemValue.publish : true}
      />
      <GuidedTour
        steps={previewTabSteps}
        onRequestClose={handleOnRequestClosePreview}
        isOpen={
          guideTourGetItemValuePreview
            ? guideTourGetItemValuePreview.previewtab === "false" ||
              guideTourGetItemValuePreview.previewtab === false
              ? false
              : true
            : true
        }
      />

      <div className="main_Container">
        <div className="publish_Inner">
          <div className="tabSearch_section">
            <div className="title_Height">
              <i className="icon-tab tabFilerIcon" onClick={OnTabClick}></i>
              <Label
                className="main_Heading"
                title={t("PUBLISHER_ONE_HEADING")}
              ></Label>
              <Label
                className="inner_Text"
                title={t("PUBLISHER_TWO_BRIEF")}
              ></Label>
            </div>
            <div className="tab_Search">
              <div className="tabs_Section">
                <div className="preview_Section main_TabEvent">
                  <div className="tab-filter-main">
                    <div className="search_Filter" tourselector="tabs">
                      <div className="search-filter-child">
                        <SearchBox
                          placeholder={t("PUBLISHER_THREE_SEARCH")}
                          onSearch={handleSearch}
                        />
                        <div className="filterBtn">
                          <button
                            className="filterIcon"
                            onClick={() =>
                              setShowFilterDropdown(!showFilterDropdown)
                            }
                          >
                            <i className="icon-filter">
                              {" "}
                              <span>{t("PUBLISHER_FOUR_FILTER")}</span>
                            </i>
                          </button>
                        </div>
                      </div>
                      {showFilterDropdown && (
                        <>
                          <div
                            className="overLay"
                            onClick={() => setShowFilterDropdown(false)}
                          ></div>
                          <FilterForm
                            title="Filter"
                            onClick={() => setShowFilterDropdown(false)}
                            onFilterChange={handleFilterChange}
                          ></FilterForm>
                        </>
                      )}
                    </div>

                    <div className="all-tabs">
                      <Tabs
                        onSelect={(e) => setActiveKey(e)}
                        activeKey={activeKey}
                        id="uncontrolled-tab-example"
                        className="mb-3 tabs_Height"
                        tourselector="tabs"
                      >
                        <Tab eventKey="Group" title="Group">
                          <div className="tab_Section">
                            <TabHeader
                              col1={"Profile"}
                              col2={"User name"}
                              col3={"Account type"}
                              col4={"Status"}
                            ></TabHeader>

                            <Scrollbars
                              className="scroll-scroll"
                              style={
                                showChat
                                  ? {
                                      height:
                                        dimensions.height -
                                        tabsHeight -
                                        messageboxHeight -
                                        searchFilter -
                                        80 -
                                        420 +
                                        "px",
                                    }
                                  : {
                                      height:
                                        dimensions.height -
                                        tabsHeight -
                                        messageboxHeight -
                                        searchFilter -
                                        250 +
                                        "px",
                                    }
                              }
                            >
                              <div className="scroll--listing">
                                {filteredUserGroups &&
                                  filteredUserGroups.map((e) => (
                                    <div key={e._id}>
                                      <TabListing
                                        ProfileImg={e.profilePicture}
                                        userName={e.groupTitle}
                                        accType={"Groups"}
                                        statusTitle={
                                          e.isActive
                                            ? e.isPaused
                                              ? "Connected - Paused"
                                              : "Connected - Active"
                                            : "Disconnected"
                                        }
                                        id={e._id}
                                        Labeltitle={e.groupDescription}
                                        onCheckedChange1={onCheckedChange}
                                        checked={e.checked}
                                        // checked={ e.isActive
                                        //   ? e.isPaused
                                        //     ? false
                                        //     : e.checked ? true : false
                                        //   : false}
                                        // disabled={true}
                                        // tooltipText={
                                        //   e.isActive
                                        //     ? e.isPaused
                                        //       ? "You cannot modify a paused group"
                                        //       : null
                                        //     : "You cannot modify a disconnected group"
                                        // }
                                      ></TabListing>
                                    </div>
                                  ))}
                              </div>
                            </Scrollbars>
                          </div>
                        </Tab>
                        <Tab eventKey="Facebook" title="Facebook">
                          <div className="tab_Section">
                            <TabHeader
                              col1={"Profile"}
                              col2={"User name"}
                              col3={"Account type"}
                              col4={"Status"}
                            ></TabHeader>
                            {filteredFacebookPages?.map((e) => (
                              <div key={e.id}>
                                <TabListing
                                  ProfileImg={
                                    e?.userProfile?.picture?.data?.url
                                  }
                                  userName={e?.name}
                                  accType={"Page"}
                                  statusTitle={
                                    e.isActive
                                      ? e.isPaused
                                        ? "Connected - Paused"
                                        : "Connected - Active"
                                      : "Disconnected"
                                  }
                                  id={e.id}
                                  onCheckedChange1={onCheckedChange}
                                  // checked={e.checked}
                                  checked={
                                    e.isActive
                                      ? e.isPaused
                                        ? false
                                        : e.checked
                                        ? true
                                        : false
                                      : false
                                  }
                                  Labeltitle={"e.Labeltitle"}
                                ></TabListing>
                              </div>
                            ))}
                            {filteredFacebookGroup?.map((e) => (
                              <div key={e.id}>
                                <TabListing
                                  ProfileImg={e.ProfileImg}
                                  userName={e.name}
                                  accType={"Group"}
                                  statusTitle={
                                    e.isActive
                                      ? e.isPaused
                                        ? "Connected - Paused"
                                        : "Connected - Active"
                                      : "Disconnected"
                                  }
                                  id={e.id}
                                  onCheckedChange1={onCheckedChange}
                                  checked={e.checked}
                                  Labeltitle={"e.Labeltitle"}
                                ></TabListing>
                              </div>
                            ))}
                          </div>
                        </Tab>
                        <Tab eventKey="Twitter" title="Twitter">
                          <div className="tab_Section">
                            <TabHeader
                              col1={"Profile"}
                              col2={"User name"}
                              col3={"Account type"}
                              col4={"Status"}
                            ></TabHeader>
                            {filteredTwitterProfile &&
                              filteredTwitterProfile.map((e) => (
                                <div key={e._id}>
                                  <TabListing
                                    ProfileImg={e.ProfileImg}
                                    userName={e.screenName}
                                    accType={"Profile"}
                                    statusTitle={
                                      e.isActive
                                        ? e.isPaused
                                          ? "Connected - Paused"
                                          : "Connected - Active"
                                        : "Disconnected"
                                    }
                                    id={e._id}
                                    onCheckedChange1={onCheckedChange}
                                    // checked={e.checked}
                                    checked={
                                      e.isActive
                                        ? e.isPaused
                                          ? false
                                          : e.checked
                                          ? true
                                          : false
                                        : false
                                    }
                                    Labeltitle={"e.Labeltitle"}
                                  ></TabListing>
                                </div>
                              ))}
                          </div>
                        </Tab>
                        <Tab eventKey="LinkedIn" title="LinkedIn">
                          <div className="tab_Section">
                            <TabHeader
                              col1={"Profile"}
                              col2={"User name"}
                              col3={"Account type"}
                              col4={"Status"}
                            ></TabHeader>
                            {filteredLinkedInData?.map((e) => (
                              <div key={e.id}>
                                <TabListing
                                  ProfileImg={e.ProfileImg}
                                  userName={e.firstName}
                                  accType={"Profile"}
                                  statusTitle={
                                    e.isActive
                                      ? e.isPaused
                                        ? "Connected - Paused"
                                        : "Connected - Active"
                                      : "Disconnected"
                                  }
                                  id={e.id}
                                  Labeltitle={"e.Labeltitle"}
                                  onCheckedChange1={onCheckedChange}
                                  // checked={e.checked}
                                  checked={
                                    e.isActive
                                      ? e.isPaused
                                        ? false
                                        : e.checked
                                        ? true
                                        : false
                                      : false
                                  }
                                ></TabListing>
                              </div>
                            ))}
                          </div>
                        </Tab>
                        <Tab eventKey="Instagram" title="Instagram">
                          <div className="tab_Section">
                            <TabHeader
                              col1={"Profile"}
                              col2={"User name"}
                              col3={"Account type"}
                              col4={"Status"}
                            ></TabHeader>
                            {filteredInstagramPage?.map((e) => (
                              <div key={e.id}>
                                <TabListing
                                  ProfileImg={e.picture?.data?.url}
                                  userName={e.instagram_business_account_id}
                                  accType={"Profile"}
                                  statusTitle={
                                    e.isActive
                                      ? e.isPaused
                                        ? "Connected - Paused"
                                        : "Connected - Active"
                                      : "Disconnected"
                                  }
                                  id={e.id}
                                  Labeltitle={"e.Labeltitle"}
                                  onCheckedChange1={onCheckedChange}
                                  // checked={e.checked}
                                  checked={
                                    e.isActive
                                      ? e.isPaused
                                        ? false
                                        : e.checked
                                        ? true
                                        : false
                                      : false
                                  }
                                ></TabListing>
                              </div>
                            ))}
                          </div>
                        </Tab>
                        <Tab eventKey="Youtube" title="Youtube">
                          <div className="tab_Section">
                            <TabHeader
                              col1={"Profile"}
                              col2={"User name"}
                              col3={"Account type"}
                              col4={"Status"}
                            />
                            {filteredYoutubeChannel?.map((e) => (
                              <div key={e.id}>
                                <TabListing
                                  ProfileImg={e.snippet.thumbnails.default.url}
                                  userName={e.snippet.title}
                                  accType={"Channel"}
                                  statusTitle={
                                    e.isActive
                                      ? e.isPaused
                                        ? "Connected - Paused"
                                        : "Connected - Active"
                                      : "Disconnected"
                                  }
                                  id={e.id}
                                  Labeltitle={"e.Labeltitle"}
                                  onCheckedChange1={onCheckedChange}
                                  // checked={e.checked}
                                  checked={
                                    e.isActive
                                      ? e.isPaused
                                        ? false
                                        : e.checked
                                        ? true
                                        : false
                                      : false
                                  }
                                ></TabListing>
                              </div>
                            ))}
                          </div>
                        </Tab>
                        <Tab eventKey="TikTok" title="TikTok">
                          <div className="tab_Section">TikTok section</div>
                        </Tab>
                      </Tabs>
                    </div>
                    {/* </Scrollbars> */}
                  </div>
                  {showChat && (
                    <div
                      className={showChat ? "showChat chat_Box" : "chat_Box"}
                    >
                      <MessageBox
                        className="messagebox_Height"
                        onClickDownArrow={OnChatClick}
                        SchedulePost={() =>
                          setShowEditPostModal(!showEditPostModal)
                        }
                        value={facebookInputValue}
                        handleChange={(e) => handleChnagePostToFacebook(e)}
                        onClickPublishNow={handleClickPublishNow}
                        onClickDraft={handleClickDraftPost}
                        tourselectorIcons="click-to-add-img"
                        tourselectorschedulebtn="click-to-schedule-post"
                        tourselectorpublishbtn="click-to-publish-post"
                        selectedPost={selectedPost}
                        postPublished={postPublished}
                      ></MessageBox>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {!showChat && (
            <div
              className={
                showTab ? " social_Section showTab" : "social_Section "
              }
            >
              <div className="create_Post">
                <div className="content_Btn">
                  <i
                    className="icon-close onClose"
                    onClick={() => setShowTab(!showTab)}
                  ></i>
                  <Label
                    className="inner_Txt"
                    title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has bee"
                  ></Label>
                  <div className="create_Btn">
                    <CustomButton
                      title="Create Post"
                      onClick={onCreatePost}
                      tourselector="create-post-btn"
                    ></CustomButton>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showChat && (
            <div
              className={
                showTab ? " social_Section showTab" : "social_Section "
              }
              tourselector="preview-tabs"
            >
              <Label className="main_Title" title="Preview"></Label>
              <i
                className="icon-close onClose"
                onClick={() => setShowTab(!showTab)}
              ></i>
              <PreviewTab
                onClick={() => setShowEditPostModal(!showEditPostModal)}
                showFacebookPreviewTitle={facebookInputValue}
                // tourselector="preview-tabs"
              ></PreviewTab>
            </div>
          )}
        </div>
        {!showChat && (
          <div className="message_IconBg" onClick={OnChatClose}>
            <i className="icon-edit1 messageIcon"></i>
          </div>
        )}
      </div>

      <ModalPopup
        showModal={showEditPostModal}
        onHide={() => setShowEditPostModal(false)}
        className="edit_Post"
        headerTitle="Schedule post"
      >
        <div className="preview_Section modal_Tabsection">
          <Tabs
            defaultActiveKey="Publish once"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Publish once" title="Publish once">
              <div className="tab_Section">
                <div className="inner_Tab">
                  <div className="dropdown-Calendar">
                    <Label
                      className="filter_Title"
                      title="Select Date & Time:"
                    ></Label>
                    <div className="inner_dropSection">
                      <div className="calendar_Section">
                        <div className="calenderMain">
                          <CustomCalendar
                            selected={scheduleDate}
                            onChange={(e) => setScheduleDate(e)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="No End date"
                            renderCustomHeader={true}
                          ></CustomCalendar>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="timer_Section">
                    <div className="calenderMain">
                      <DatePicker
                        selected={scheduleTime}
                        onChange={(date) => setScheduleTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        placeholderText="00:00"
                      />
                      <i className="icon-timer"></i>
                    </div>
                  </div>
                  <div className="label_Section">
                    <Label
                      className="time_Zone"
                      title="Current timezone selected"
                    ></Label>
                    <Label
                      className="time_Inner"
                      title="( GMT 5:30 ) India Standard Time(Asia/Calcuta)"
                    ></Label>
                  </div>
                  <div className="bottom_Button">
                    <CustomButton
                      title="Schedule"
                      onClick={onSchedulePost}
                    ></CustomButton>
                    <CustomButton title="Cancel"></CustomButton>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </ModalPopup>
    </div>
  );
};

export default withRouter(PublishScreen);
