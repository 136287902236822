export const guideTourObj = {
  login: true,
  signup: true,
  forgot: true,
  verify: true,
  resetpassword: true,
  dashboard: true,
  socialprofile: true,
  socialprofilelist: true,
  publish: true,
  previewtab: "false",
  schedule: true,
  group: true,
  statistics: true,
  setting: true,
  settingBilling: "false",
  settingQuery: "false",
  calendar: true,
  calendarList: "false",
};

export const guideTourObjDisable = {
  login: false,
  signup: false,
  forgot: false,
  verify: false,
  resetpassword: false,
  dashboard: false,
  socialprofile: false,
  socialprofilelist: false,
  publish: false,
  previewtab: false,
  schedule: false,
  group: false,
  statistics: false,
  setting: false,
  settingBilling: false,
  settingQuery: false,
  calendar: false,
  calendarList: false,
};

export const guideTourObjAdmin = {
  adminLogin: true,
  adminDashboard: true,
  adminLandingPage: true,
  adminBlog: true,
  adminUserProfile: true,
  adminSubscription: true,
  adminSubscribers: true,
  adminMarketing: true,
  adminTerm: true,
};

export const localStorageSetItem = (isOpenTourGuide) => {
  localStorage.setItem("guideTourObj", JSON.stringify(isOpenTourGuide));
};
