import React from "react";
import { Route, Redirect } from "react-router-dom";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import SignUp from "./pages/SignUp/SignUp";
import Blog from "./pages/Blog/Blog";
import Dashboard from "./pages/Dashboard/Dashboard";
import Publish from "./pages/Publish/Publish";
import ScheduleScreen from "./pages/Schedule/Schedule";
import Login from "./pages/Login/Login";
import Forgot from "./pages/Forgot/Forgot";
import VerifyMail from "./pages/VerifyMail/VerifyMail";
import BlogSix from "./pages/BlogSix/BlogSix";
import BlogSeven from "./pages/BlogSeven/BlogSeven";
import BlogThree from "./pages/BlogThree/BlogThree";
import BlogTwo from "./pages/BlogTwo/BlogTwo";
import BlogFour from "./pages/BlogFour/BlogFour";
import BlogFive from "./pages/BlogFive/BlogFive";
import Setting from "./pages/Setting_userdetails/Setting";
import SocialProfile from "./pages/AddSocialProfile/SocialProfile";
import CreateGroup from "./pages/CreateGroup/CreateGroup";
import BlogList from "./pages/BlogList/BlogList";
import SocialProfile_List from "./pages/SocialProfile_List/SocialProfile_List";
import Group from "./pages/Group/Group";
import LandingPage from "./pages/LandingPage/LandingPage";
// import SettingGeneralPage from './pages/SettingGeneralPage/SettingGeneralPage';
import PopupOne from "./pages/SocialProfilePopup_One/PopupOne";
import PopupTwo from "./pages/SocialProfilePopup_two/PopupTwo";
import CalendarEvent from "./pages/CalendarEvent/CalendarEvent";
import SubscriptionPage from "./pages/SubscriptionPage/SubscriptionPage";
import AppMap from "./AppMap";
// import BillingScreen from './pages/BillingScreen/BillingScreen';
import Published from "./pages/Published/Published";
import Failed from "./pages/Failed/Failed";
import Draft from "./pages/Draft/Draft";
import Statistics from "./pages/Statistics/Statistics";
import CancelSubsPopUp from "./pages/CancelSubscriptionPopUp/CancelSubsPopUp";
import SocialmediaPopup from "./pages/SocialmediaPopup/SocialmediaPopup";
import Feature from "./pages/FeaturePage/Feature";
import Support from "./pages/Support/Support";
import ThankyouPopup from "./pages/ThankyouPopup/ThankyouPopup";
import AboutUs from "./pages/AboutusPage/AboutUs";
import MarketingVideo from "./pages/MarketingVideos/MarketingVideo";
import AddProfile_DropDown from "./pages/SocialProfile_List/AddProfile_DropDown/AddProfile_DropDown";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import PublisherAll from "./pages/PublisherAll/PublisherAll";

// to be remove
// import Language from '../src/translations/language';
import GuidedTour from "./pages/GuideTour/GuidedTour";

// to be remove
import ReduxSagaPage from "./pages/ReduxSagaTestingPage/ReduxSagaPage";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import AdminBlog from "./pages/AdminBlog/AdminBlog";
import AdminAddBlogs from "./pages/AdminAddBlogs/AdminAddBlogs";
// import AdminHelp from "./pages/AdminHelp/AdminHelp";
import AdminTerms from "./pages/AdminTerms/AdminTerms";
import AdminSubscription from "./pages/AdminSubscription/AdminSubscription";
import AdminUserProfile from "./pages/AdminUserProfile/AdminUserProfile";
import AdminDashboardMarketingRequests from "./pages/AdminDashboardMarketingRequests/AdminDashboardMarketingRequests";
import AdminDashboardBlog from "./pages/AdminDashboardBlog/AdminDashboardBlog";
import AdminLogin from "./pages/AdminLogIn/AdminLogin";
// import MarketingVideoScreen from "./pages/MarketingVideos/MarketingVideo";
import AdminUiElements from "./pages/AdminUiElements/AdminUiElements";
import AdminQueries from "./pages/AdminQueries/AdminQueries";
import AdminAbout from "./pages/AdminAbout/AdminAbout";
import Team from "./pages/TeamsScreens/Team/Team";
import AdminAboutPage from "./pages/AdminAboutPage/AdminAboutPage";
import SupportList from "./pages/SupportList/SupportList";

const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  // const refreshToken = localStorage.getItem("refreshToken");
  try {
    if (token) {
      return true;
    }
    // decode(token);
    // decode(refreshToken);
    // return true;
  } catch (error) {
    return false;
  }
};

export function PrivateUserRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
}

export function PrivateAdminRoute({ component: Component, ...rest }) {
  const tokenAmin = localStorage.getItem("Admintoken");
  return (
    <Route
      {...rest}
      render={(props) =>
        tokenAmin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/admin/login",
            }}
          />
        )
      }
    />
  );
}

export const privateUserRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    exact: true,
  },

  // {
  //   path: "/bloglist",
  //   component: BlogList,
  //   exact: true,
  // },
  {
    path: "/support",
    component: Support,
    exact: true,
  },
  {
    path: "/publish",
    component: Publish,
    exact: true,
  },
  {
    path: "/schedule",
    component: ScheduleScreen,
    exact: true,
  },
  {
    path: "/setting",
    component: Setting,
    exact: true,
  },
  {
    path: "/socialprofile",
    component: SocialProfile,
    exact: true,
  },
  {
    path: "/group",
    component: Group,
    exact: true,
  },
  {
    path: "/creategroup",
    component: CreateGroup,
    exact: true,
  },

  {
    path: "/socialprofilelist",
    component: SocialProfile_List,
    exact: true,
  },

  {
    path: "/popupone",
    component: PopupOne,
    exact: true,
  },

  {
    path: "/popuptwo",
    component: PopupTwo,
    exact: true,
  },

  {
    path: "/calendar",
    component: CalendarEvent,
    exact: true,
  },

  {
    path: "/subscriptionpage",
    component: SubscriptionPage,
    exact: true,
  },
  {
    path: "/creategroup",
    component: CreateGroup,
    exact: true,
  },

  {
    path: "/socialprofilelist",
    component: SocialProfile_List,
    exact: true,
  },

  {
    path: "/popupone",
    component: PopupOne,
    exact: true,
  },

  {
    path: "/popuptwo",
    component: PopupTwo,
    exact: true,
  },

  {
    path: "/calendar?customgroup",
    component: CalendarEvent,
    exact: true,
  },

  {
    path: "/subscriptionpage",
    component: SubscriptionPage,
    exact: true,
  },
  {
    path: "/published",
    component: Published,
    exact: true,
  },

  {
    path: "/failed",
    component: Failed,
    exact: true,
  },

  {
    path: "/draft",
    component: Draft,
    exact: true,
  },

  {
    path: "/statistics",
    component: Statistics,
    exact: true,
  },
  {
    path: "/PublisherAll",
    component: PublisherAll,
  },
];

export const privateAdminRoutes = [
  {
    path: "/admin/adminuielements",
    component: AdminUiElements,
    exact: true,
  },
  {
    path: "/admin/adminabout",
    component: AdminAboutPage,
    exact: true,
  },
  {
    path: "/admin/adminqueries",
    component: AdminQueries,
    exact: true,
  },
  {
    path: "/admin/adminuserprofile",
    component: AdminUserProfile,
    exact: true,
  },
  {
    path: "/admin/admindashboard",
    component: AdminDashboard,
    exact: true,
  },
  {
    path: "/admin/adminblog",
    component: AdminBlog,
    exact: true,
  },
  {
    path: "/admin/adminaddblogs",
    component: AdminAddBlogs,
    exact: true,
  },
  {
    path: "/admin/adminsubscription",
    component: AdminSubscription,
    exact: true,
  },
  {
    path: "/admin/adminterms",
    component: AdminTerms,
    exact: true,
  },
  {
    path: "/admin/admindashboardmarketing",
    component: AdminDashboardMarketingRequests,
    exact: true,
  },
  {
    path: "/admin/admindashboardblog",
    component: AdminDashboardBlog,
    exact: true,
  },
  {
    path: "/admin/marketingvideo",
    component: MarketingVideo,
    exact: true,
  },
];
const routes = [
  {
    path: "/appMap",
    component: AppMap,
    exact: true,
  },
  {
    path: "/",
    component: LandingPage,
    exact: true,
  },

  // To be remove
  {
    path: "/redux",
    component: ReduxSagaPage,
    exact: true,
  },
  {
    path: "/login",
    component: Login,
    exact: true,
  },
  {
    path: "/admin/login",
    component: AdminLogin,
    exact: true,
  },
  {
    path: "/signup",
    component: SignUp,
    exact: true,
  },
  {
    path: "/resetPassword",
    component: ResetPassword,
    exact: true,
  },
  {
    path: "/verifymail",
    component: VerifyMail,
    exact: true,
  },
  {
    path: "/forgot",
    component: Forgot,
    exact: true,
  },
  {
    path: "/supportlist",
    component: SupportList,
    exact: true,
  },

  // {
  //   path: '/dashboard',
  //   component: Dashboard,
  //   exact: true,
  // },

  {
    path: '/bloglist',
    component: BlogList,
    exact: true,
  },
  // {
  //   path: '/support',
  //   component: Support,
  //   exact: true,
  // },
  // {
  //   path: '/publish',
  //   component: Publish,
  //   exact: true,
  // },
  // {
  //   path: '/schedule',
  //   component: ScheduleScreen,
  //   exact: true,
  // },
  // {
  //   path: '/setting',
  //   component: Setting,
  //   exact: true,
  // },
  // {
  //   path: '/socialprofile',
  //   component: SocialProfile,
  //   exact: true,
  // },
  {
    path: "/blog",
    component: Blog,
    exact: true,
  },
  {
    path: "/blogtwo",
    component: BlogTwo,
    exact: true,
  },
  {
    path: "/blogthree",
    component: BlogThree,
    exact: true,
  },
  {
    path: "/blogfour",
    component: BlogFour,
    exact: true,
  },
  {
    path: "/blogfive",
    component: BlogFive,
    exact: true,
  },
  {
    path: "/blogsix",
    component: BlogSix,
    exact: true,
  },
  {
    path: "/blogseven",
    component: BlogSeven,
    exact: true,
  },
  // {
  //   path: '/group',
  //   component: Group,
  //   exact: true,
  // },
  // {
  //   path: '/settinggeneralpage',
  //   component: SettingGeneralPage,
  //   exact: true,
  // },
  // {
  //   path: '/creategroup',
  //   component: CreateGroup,
  //   exact: true,
  // },

  // {
  //   path: '/socialprofilelist',
  //   component: SocialProfile_List,
  //   exact: true,
  // },

  // {
  //   path: '/popupone',
  //   component: PopupOne,
  //   exact: true,
  // },

  // {
  //   path: '/popuptwo',
  //   component: PopupTwo,
  //   exact: true,
  // },

  // {
  //   path: '/calendar',
  //   component: CalendarEvent,
  //   exact: true,
  // },

  // {
  //   path: '/subscriptionpage',
  //   component: SubscriptionPage,
  //   exact: true,
  // },

  // {
  //   path: '/BillingScreen',
  //   component: BillingScreen,
  //   exact: true,
  // },

  // {
  //   path: '/published',
  //   component: Published,
  //   exact: true,
  // },

  // {
  //   path: '/failed',
  //   component: Failed,
  //   exact: true,
  // },

  // {
  //   path: '/draft',
  //   component: Draft,
  //   exact: true,
  // },

  // {
  //   path: '/statistics',
  //   component: Statistics,
  //   exact: true,
  // },
  {
    path: "/cancelsubscription",
    component: CancelSubsPopUp,
    exact: true,
  },
  {
    path: "/socialmediapopup",
    component: SocialmediaPopup,
    exact: true,
  },
  {
    path: "/featurepage",
    component: Feature,
    exact: true,
  },
  {
    path: "/supportpage",
    component: Support,
    exact: true,
  },
  {
    path: "/thankyouPopup",
    component: ThankyouPopup,
    exact: true,
  },
  {
    path: "/aboutUs",
    component: AboutUs,
    exact: true,
  },
  {
    path: "/MarketingVideo",
    component: MarketingVideo,
    exact: true,
  },
  {
    path: "/PrivacyPolicy",
    component: PrivacyPolicy,
    exact: true,
  },
  {
    path: "/add",
    component: AddProfile_DropDown,
  },
  {
    path: "/termsofservice",
    component: TermsOfService,
    exact: true,
  },
  {
    path: "/adminabout",
    component: AdminAbout,
    exact: true,
  },
  // {
  //   path: '/language',
  //   component: Language,
  //   exact: true,
  // },
  {
    path: "/guidedtour",
    component: GuidedTour,
    exact: true,
  },
  // {
  //   path: '/admindashboard',
  //   component: AdminDashboard,
  //   exact: true,
  // },
  // {
  //   path: '/adminblog',
  //   component: AdminBlog,
  //   exact: true,
  // },
  // {
  //   path: '/adminsupport',
  //   component: AdminSupportPage,
  //   exact: true,
  // },
  // {
  //   path: '/adminaddblogs',
  //   component: AdminAddBlogs,
  //   exact: true,
  // },

  // {
  //   path: '/adminsubscriber',
  //   component: AdminSubscriber,
  //   exact: true,
  // },
  // {
  //   path: '/adminunsubscriber',
  //   component: AdminUnsubscriber,
  //   exact: true,
  // },
  // {
  //   path: '/adminsubscription',
  //   component: AdminSubscription,
  //   exact: true,
  // },
  // {
  //   path: '/adminhelp',
  //   component: AdminHelp,
  //   exact: true,
  // },
  // {
  //   path: '/adminterms',
  //   component: AdminTerms,
  //   exact: true,
  // },
  // {
  //   path: '/adminuserprofile',
  //   component: AdminUserProfile,
  //   exact: true,
  // },
  // {
  //   path: '/admindashboardmarketing',
  //   component: AdminDashboardMarketingRequests,
  //   exact: true,
  // },
  // {
  //   path: '/PublisherAll',
  //   component: PublisherAll,
  // },
  // {
  //   path: '/admindashboardblog',
  //   component: AdminDashboardBlog,
  //   exact: true,
  // },
  // {
  //   path: '/dashboardelement',
  //   component: DashboardElement,
  //   exact: true,
  // },
  {
    path: "/teams",
    component: Team,
    exact: true,
  },
];

export default routes;
