import React, { useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { postUsersSignUpAction } from "../../Redux_saga/postPublisherSlice";
import MainImg from "../../assests/images/main-img.png";
import PatterBottom from "../../assests/images/patten-btm.png";
import PatterTop from "../../assests/images/patten-top.png";
import HookForm from "../../components/HookForm/HookForm";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import Label from "../../components/UI/Label/Label";
import TextField from "../../components/UI/TextField/TextField";
import Constant from "../../util/constant";
import Message from "../../util/message";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { SignUpSteps } from "../Steps";
import "./SignUp.scss";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
function SignUp() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form, setLoginForm] = React.useState();
  const [newpasswordtype, setNewPasswodType] = useState(true);
  const [confirmPasswordType, setConfirmPasswordType] = useState(true);
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();
  const [formKey, setFormKey] = React.useState(0);
  const signupSuccess = useSelector(
    (e) => e?.postPublisherSlice?.users?.SignUpResponseValue
  );
  const history = useHistory();
  // const navigate= useNavigate();
  // console.log(form);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const loginForm = {
    email: {
      name: "email",
      validate: {
        required: {
          value: true,
          message: Message.ERRORMESSAGE.EMAILEMPTY,
        },
        pattern: {
          value: Constant.REGEX.email,
          message: Message.ERRORMESSAGE.EMAILINVALID,
        },
      },
    },
    password: {
      name: "password",
      validate: {
        required: {
          value: true,
          message: Message.ERRORMESSAGE.PASSWORDEMPTY,
        },
      },
    },
    confirm_password: {
      name: "confirm_password",
      validate: {
        required: {
          value: true,
          message: Message.ERRORMESSAGE.PASSWORDEMPTY,
        },
      },
    },
  };
  const { reset } = useForm();
  const initialFormState = {
    email: "",
    password: "",
    confirm_password: "",
  };
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const onFormSubmit = (form) => {
    const signUpData = {
      emailId: form.email,
      password: form.password,
    };
    dispatch(postUsersSignUpAction(signUpData));
  };

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObj")
    );

    let newObj = { ...previousLocalStorageValue, signup: false };
    localStorage.setItem("guideTourObj", JSON.stringify(newObj));

    setIsOpenTourGuide(newObj);
  };
  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      // localStorage.setItem('guideTourObj', JSON.stringify(isOpenTourGuide));
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(localStorage.getItem("guideTourObj"));
  useEffect(() => {
    console.log(typeof signupSuccess, "signupSuccess");

    if (typeof signupSuccess === "string") {
      resetForm();
    }
  }, [signupSuccess]);
  const resetForm = () => {
    reset();
    setFormKey((prevKey) => prevKey + 1);
  };
  return (
    <div
      className="signup_Page"
      style={{ height: dimensions.height + "px" }}
      key={formKey}
    >
      <img
        src={PatterTop}
        alt="image"
        style={{ maxWidth: "100%", height: "auto" }}
        // fluid="true"
        className="top_Patten"
      />
      <img
        src={PatterBottom}
        alt="image"
        style={{ maxWidth: "100%", height: "auto" }}
        // fluid="true"
        className="bottom_Patten"
      />
      <Scrollbars className="scroll_Section">
        <GuidedTour
          steps={SignUpSteps}
          onRequestClose={handleOnRequestClose}
          isOpen={guideTourGetItemValue ? guideTourGetItemValue.signup : true}
        />
        <div className="center_Section">
          <div className="chatboat-section position-absolute">
            <i className="icon-chatboat"></i>
          </div>
          <div className="img_Section">
            <img
              src={MainImg}
              alt="image"
              style={{ maxWidth: "100%", height: "auto" }}
              // fluid="true"
              className="main_Img"
            />
          </div>
          <div className="signup_Box" tourselector="signup-form">
            <Label title={t("SIGNUP_INPUTBOX_WELCOME")}></Label>
            <Label
              className="inner_Text"
              title={t("SIGNUP_INPUTBOX_SETUP")}
            ></Label>
            <div className="link_Section">
              <Label
                className="form_Name"
                title={t("SIGNUP_INPUTBOX_ALREADY")}
              ></Label>
              <Link className="link_Css" to="/login">
                {t("SIGNUP_INPUTBOX_SIGNIN")}
              </Link>
            </div>

            <HookForm
              defaultForm={{
                email: "",
                password: "",
                confirm_password: "",
              }}
              init={(form) => setLoginForm(form)}
              onSubmit={onFormSubmit}
            >
              {(formMethod) => {
                return (
                  <div className="form">
                    <TextField
                      formMethod={formMethod}
                      rules={loginForm.email.validate}
                      name={loginForm.email.name}
                      errors={formMethod?.formState.errors}
                      autoFocus={true}
                      type="text"
                      placeholder="Email Id*"
                      iconClass="icon-mail"
                      controlId={"email"}
                    />

                    <TextField
                      formMethod={formMethod}
                      controlId={"password"}
                      rules={loginForm.password.validate}
                      name={loginForm.password.name}
                      errors={formMethod?.formState.errors}
                      placeholder="6+ Character & 1 Capital letter"
                      type={newpasswordtype === true ? "password" : "text"}
                      iconClass={
                        newpasswordtype === true
                          ? "icon-hide1 cursor"
                          : "icon-eye1 cursor"
                      }
                      onIconClick={() => setNewPasswodType(!newpasswordtype)}
                    />

                    <TextField
                      formMethod={formMethod}
                      controlId={"confirm_password"}
                      rules={{
                        required: Message.ERRORMESSAGE.PASSWORDEMPTY,
                        validate: (value) =>
                          value === formMethod.watch("password") ||
                          "The passwords do not match",
                      }}
                      name={loginForm.confirm_password.name}
                      errors={formMethod?.formState.errors}
                      placeholder="Confirm password"
                      type={confirmPasswordType === true ? "password" : "text"}
                      iconClass={
                        confirmPasswordType === true
                          ? "icon-hide1 cursor"
                          : "icon-eye1 cursor"
                      }
                      onIconClick={() =>
                        setConfirmPasswordType(!confirmPasswordType)
                      }
                    />

                    <p className="click_Action">
                      {t("SIGNUP_INPUTBOX_CLICKING")}&nbsp; ‘
                      <span className="color">
                        {" "}
                        {t("SIGNUP_INPUTBOX_CREATE")}
                      </span>
                      ’ {t("SIGNUP_INPUTBOX_AGREE")}
                      <Link className="link" to="/termsofservice">
                        {" "}
                        {t("SIGNUP_INPUTBOX_TERM")}
                      </Link>{" "}
                      {t("SIGNUP_INPUTBOX_AND")}
                      <Link className="link" to="/PrivacyPolicy">
                        {" "}
                        {t("SIGNUP_INPUTBOX_POLICY")}
                      </Link>
                    </p>
                    <CustomButton title="Create account"></CustomButton>
                  </div>
                );
              }}
            </HookForm>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}

export default withRouter(SignUp);
