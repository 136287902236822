import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const CustomFacebookLogin = ({ responseCallBack }) => {
  return (
    <FacebookLogin
      appId="420323126870685"
      callback={responseCallBack}
      fields="name,email,picture"
      scope="public_profile,publish_to_groups,pages_manage_posts "
      render={(renderProps) => (
        <i className="icon-plus" onClick={renderProps.onClick}></i>
      )}
    ></FacebookLogin>
  );
};

export default CustomFacebookLogin;
