import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Blog4 from "../../assests/images/Blog4.png";
import SliderImg from "../../assests/images/slider1.png";
import BlogFeature from "../../components/BlogFeature/BlogFeature";
import BlogModal from "../../components/BlogFeature/BlogModal";
import Footer from "../../components/Footer/Footer";
import HeaderLogin from "../../components/HeaderLogin/HeaderLogin";
import ImageSlider from "../../components/UI/ImageSlider/ImageSlider";
import Label from "../../components/UI/Label/Label";
import "./BlogFour.scss";

function BlogFour() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const clickShow = () => {
    setShow(!show);
  };

  return (
    <div className="blogfour_Page">
      {show && <BlogModal closeModal={clickShow} />}

      <HeaderLogin />
      <Scrollbars className="blogScroll">
        <Label className="first_Title" title={t("BLOG_FOUR_HEAD")}></Label>
        <div className="center_Section">
          <div className="inner_Section">
            <p className="inner_Text">{t("BLOG_FOUR_PARA_TWO")}</p>
            <div className="inner_Img">
              <img
                src={Blog4}
                alt="image"
                style={{ maxWidth: "100%", height: "auto" }}
                // fluid="true"
                className="blog_Inner"
              />
            </div>
            <Label
              className="inner_Text"
              title={t("BLOG_FOUR_PARA_TWO")}
            ></Label>
            <Label
              className="inner_Text"
              title={t("BLOG_FOUR_PARA_THREE")}
            ></Label>
          </div>
        </div>
        <div className="blog-feature-four">
          <BlogFeature clickToShowModal={clickShow} />
        </div>
        <div className="image_Section">
          <Label title={t("BLOG_TWO_MORE")}></Label>
          <ImageSlider
            SliderImg={SliderImg}
            SliderImg1={SliderImg}
            SliderImg2={SliderImg}
            headingTxt={t("BLOG_FOUR_MORE")}
            innerTxt={t("BLOG_FOUR_BRIEF")}
          ></ImageSlider>
        </div>
        <Footer className="footerheight"></Footer>
      </Scrollbars>
    </div>
  );
}

export default withRouter(BlogFour);
