import React from "react";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import Label from "../../components/UI/Label/Label";
import "./CancelSubsPopUp.scss";

const CancelSubsPopUp = () => {
  return (
    <div className="cancelSubscription">
      <div className="mainCard">
        <div className="cardInner">
          <div className="topSection">
            <Label className="cardTitle" title="Hey, Wait!!"></Label>
            <span className="closeIcon">
              <i className="icon-close"></i>
            </span>
          </div>
          <Label
            className="cancelTitle"
            title="Are you sure, you want to cancel  the current subscription"
          ></Label>
          <div className="button">
            <div className="cancelButton">
              <CustomButton title="Cancel" className="cancelBtn"></CustomButton>
            </div>
            <CustomButton title="Confirm" className="submitBtn"></CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelSubsPopUp;
