export const TRANSLATIONS_HN = {
  // login
  LOGIN_INPUTBOX_GLAD: "Glad to see you again",
  LOGIN_INPUTBOX_POSTING: "Login to start posting!!!",
  LOGIN_INPUTBOX_HAVE: "Don’t have an account?",
  LOGIN_INPUTBOX_SIGNUP: "Sign up",
  LOGIN_INPUTBOX_FORGOT: "Forgot Password?",
  LOGIN_INPUTBOX_LOGIN: "Login",
  //signup
  SIGNUP_INPUTBOX_WELCOME: "Welcome to Post Publisher",
  SIGNUP_INPUTBOX_SETUP: "Let’s get to your account set up",
  SIGNUP_INPUTBOX_ALREADY: "Already member?",
  SIGNUP_INPUTBOX_SIGNIN: " Sign in",
  SIGNUP_INPUTBOX_CLICKING: "By clicking",
  SIGNUP_INPUTBOX_CREATE: "create account",
  SIGNUP_INPUTBOX_AGREE: ", i agree to post publisher",
  SIGNUP_INPUTBOX_TERM: "terms of use",
  SIGNUP_INPUTBOX_AND: "and",

  SIGNUP_INPUTBOX_POLICY: "privacy policy",
  SIGNUP_INPUTBOX_CREATE_ACCOUNT: "Create Account",
  //forgot
  FORGOT_INPUTBOX_FORGOT: "Forgot password",
  FORGOT_INPUTBOX_ENTER: "Enter your registered email address to continue",
  FORGOT_INPUTBOX_SUBMIT: "Submit",
  FORGOT_INPUTBOX_BACK: "Back to login",

  //RESET
  RESET_INPUTBOX_RESET: "Reset password",
  RESET_INPUTBOX_SEND:
    "We have send the link on your registered email address to reset password",
  RESET_INPUTBOX_RESET_PASSWORD: "Reset password",

  //VERIFY
  VERIFY_INPUTBOX_VERIFY_EMAIL: "Verify your email",
  VERIFY_INPUTBOX_VERIFY_DIGIT: "Please enter 4 digit code send to",
  VERIFY_INPUTBOX_VERIFY: "Verify",

  //LANDING
  LANDING_FIRST_BEST: "The best way to manage social media account",
  LANDING_SECOND_LOREM:
    "The most comprehensive, intuitive and easy to use social media publishing, scheduling and analytics tool. Schedule, organize, and analyze multiple socialaccounts & manage their conversations using one social media management tool.",
  LANDING_THIRD_GET: "Get Started",
  LANDING_FOURTH_BEST: "Choose the best plan that fit best for you",
  LANDING_FIFTH_BEST:
    "With our suite of products you can understand and engage with your customer at the speed of social.",
  LANDING_SIXTH_MONTH: "Monthly",
  LANDING_SEVEN_YEAR: "Yearly",
  // year plan text
  LANDING_YEAR_FIRST_HEAD: "Basic",
  LANDING_YEAR_FIRST_BRIEF:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,",

  LANDING_YEAR_SECOND_HEAD: "Basic",
  LANDING_YEAR_SECOND_BRIEF:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,",

  LANDING_YEAR_THIRD_HEAD: "Basic",
  LANDING_YEAR_THIRD_BRIEF:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,",
  //CONTINUE
  LANDING_EIGHT_PLAN_FIRST: "Basics",
  LANDING_PLAN_FIRST:
    "For individuals and businesses getting started with social media. Manage up to three channels Billed at $0/year",
  LANDING_EIGHT_PLAN_SECOND: "Standard",
  LANDING_PLAN_SECOND:
    "The Standard Plan covers both with timely responding and consistent planned posting schedules.Standard Plan is intended for the business that wants to take a well-rounded approach to marketing.",
  LANDING_EIGHT_PLAN_THIRD: "Professional",
  LANDING_PLAN_THIRD:
    "Once you’ve chosen the best channels for your brand and audience, it’s time to strategize. Here’s how to pull together the correct elements to create a killer advertising campaign.       ",

  LANDING_NINE: "A powerful scheduler that does everything you need",
  LANDING_TEN:
    "Publish your social media content across all major social networks using this ultimate social media scheduler. Get everything you need to run your strategy smoothly. It’s not only the best scheduling tools you`re getting, but also a dedicated account manager, unlimited seats, and a reliable support team.",
  LANDING_ELEVEN:
    "Doing Everything Yourself is Difficult Save time.Improve quality.Collaborate effectively.",
  LANDING_TWELVE:
    "Reach new audience and build your brand online with postpublisher easy-to-use social media management platform.",
  LANDING_TWELVE_POINT_0NE:
    "Stare at a screen for hours trying to think of new post and email ideas.",
  LANDING_TWELVE_POINT_TWO:
    "Waste time switching between separate tools for email, social, and design. ",
  LANDING_TWELVE_POINT_THREE:
    "Stress about having to create and schedule all of your social & email from scratch. Settle for guessing at what’s working and what you should do next.",

  LANDING_THIRTEEN: "Life Is Easier With Social Media Automation",
  LANDING_FOURTEEN:
    "Creating, posting, and analyzing content across so many touchpoints can take an overwhelming amount of time. Unless, of course, you engage in some social media automation",
  LANDING_FIFTEEN_READ: "Read our latest stories",
  LANDING_SIXTEEN_HEAD_ONE: "Lorem Ipsum is text",
  LANDING_SIXTEEN_BRIEF:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,",

  LANDING_NINETEEN: "The best way to manage social media account",
  LANDING_NINETEEN_BRIEF:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,",

  LANDING_GET_IN: "Get in touch with us",
  LANDING_SUBMIT: "Submit",
  LANDING_BUTTON_READ: "Read More",

  //dashboard

  DASHBOARD_HEADING: "Social Media Management Software",
  DASHBOARD_BRIEF:
    "Post Publisher  is  the best social media management tools . The tool offers a range of features that allow users to effectively plan, execute, and analyze their social media campaigns.",
  DASHBOARD_BUTTON: "Get Started",

  //bloglist
  BLOGLIST_HEAD_ONE: "Lorem Ipsum is text",
  BLOGLIST_HEAD_TWO: "Lorem Ipsum is text",
  BLOGLIST_HEAD_THREE: "Lorem Ipsum is text",
  BLOGLIST_ONE_BRIEF:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,",
  BLOGLIST_TWO_BRIEF:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,",
  BLOGLIST_THREE_BRIEF:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,",
  BLOGLIST_READ_BUTTON: "Read More",

  //publish
  PUBLISHER_ONE_HEADING: "Publisher",
  PUBLISHER_TWO_BRIEF:
    "Select the social platforms and groups/ pages on which you want to share the post",
  PUBLISHER_THREE_SEARCH: "Search by group name,page name",
  PUBLISHER_FOUR_FILTER: "Filter",
  PUBLISHER_FIVE_INPUT: "What`s in your mind",
  PUBLISHER_SIX_JUST: "Just now",
  PUBLISHER_SEVEN_LATEST: "Checkout the latest news",
  PUBLISHER_LIKE_BUTTON: "Like",
  PUBLISHER_COMMENT_BUTTON: "Comment",
  PUBLISHER_SHARE: "Share",
  PUBLISHER_SCHEDULE: "Schedule Post",
  PUBLISHER_PUBLISH_BUTTON: "Publish now",
  PUBLISHER_DRAFT_BUTTON: "Draft Post",


  // //schedule
  SCHEDULE: "Schedule",
  SCHEDULE_SEARCH: "Search by group name,page name",
  SCHEDULE_FILTER: "Filter",
  SCHEDULE_ONE_TIME: "Created-21 min ago",
  SCHEDULE_TWO_TEXT:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  //PUBLISHED
  PUBLISHED: "Published",
  PUBLISHED_SEARCH: "Search by group name,page name",
  PUBLISHED_FILTER: "Filter",
  PUBLISHED_ONE_TIME: "Created-21 min ago",
  PUBLISHED_TWO_TEXT:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",

  //FAILED
  FAILED: "Failed",
  FAILED_SEARCH: "Search by group name,page name",
  FAILED_FILTER: "Filter",
  FAILED_ONE_TIME: "Created-21 min ago",
  FAILED_TWO_TEXT:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",

  //DRAFT
  DRAFT: "Draft",
  DRAFT_SEARCH: "Search by group name,page name",
  DRAFT_FILTER: "Filter",
  DRAFT_ONE_TIME: "Created-21 min ago",
  DRAFT_TWO_TEXT:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",

  //STATISTICS
  STATISTICS: "Statistics",
  STATISTICS_FILTER: "FILTER",
  STATISTICS_EXPORT: "Export Chart",
  STATISTICS_VIEW:
    "View your post progress and share next your post accordingly",
  STATISTICS_SELECT: "Select group to see statistics",
  STATISTICS_OVERVIEW: "Overview",
  STATISTICS_VISITING: "Visiting",
  STATISTICS_LIKES: "Likes",
  STATISTICS_COMMENTS: "Comments",
  STATISTICS_AGE_GROUP: "Age Group",
  STATISTICS_BELOW_TWENTY: "Below 20",
  STATISTICS_BELOW_FORTY: "Below 40",
  STATISTICS_ABOVE_FORTY: "Above 40",
  STATISTICS_GENDER: "Gender",
  STATISTICS_DEMOGRAPHY: "Demography",

  //setting

  SETTING: "Settings",
  SETTING_MANAGE:
    "Manage your profile update name password and other personal information",
  SETTING_SUBSCRIBED: "Subscribed till 18 Oct",
  SETTING_USER: "User details",
  SETTING_BILLING: "Billing",
  SETTING_GENERAL: "General",
  SETTING_BILLLINGS: "Billings",
  SETTING_QUERY: "Query",
  SETTING_CHANGE_IMAGE: "Change Image",
  SETTING_CHANG_PASSORD: "Change Password",
  SETTING_EDIT: "Edit Profile",

  SETTING_BUTTON_DISCARD: "Discard",
  SETTING_BUTTON_SAVE: "Save",

  //SOCIAL PROFILE
  SOCIAL_ADD: "Add your social profile",
  SOCIAL_CONNECT: "Connect social profile you’d like to manage.",
  SOCIAL_UPTO: "You can add up to 3 social profile in free plan",
  SOCIAL_CONNECT_FB: "Connect to your facebook account`s page",
  SOCIAL_CONNECT_LINKEDIN: "Connect to your linkedIn account`s profile",
  SOCIAL_CONNECT_TWITTER: "Connect to your twitter profile",

  //BLOG
  BLOG_HEAD: "Lorem Ipsum is simply dummy text of the printing",
  BLOG_ONE:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",

  BLOG_TWO:
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using `Content here, content here`, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for `lorem ipsum` will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",

  BLOG_THREE:
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur",

  BLOG_MORE: "More article",
  BLOG_MORE_HEAD: "Lorem Ipsum is text",

  BLOG_MORE_BRIEF:
    "Lorem Ipsum is dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,",
  BLOG_READ_BUTTON: "Read More",

  //blog two
  BLOG_TWO_HEAD: "Lorem Ipsum is simply dummy text of the printing",
  BLOG_TWO_PARA_ONE:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  BLOG_TWO_PARA_TWO:
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
  BLOG_TWO_PARA_THREE:
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur",
  BLOG_TWO_MORE: "More article",
  BLOG_TWO_BRIEF:
    "Lorem Ipsum is dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,",
  BLOG_TWO_READ_BUTTON: "Read More",

  //BLOG THREE
  BLOG_THREE_HEAD: "Lorem Ipsum is simply dummy text of the printing",
  BLOG_THREE_PARA_ONE:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  BLOG_THREE_PARA_TWO:
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
  BLOG_THREE_PARA_THREE:
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur",
  BLOG_THREE_MORE: "More article",
  BLOG_THREE_BRIEF:
    "Lorem Ipsum is dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,",
  BLOG_THREE_READ_BUTTON: "Read More",

  //BLOG FOUR
  BLOG_FOUR_HEAD: "Lorem Ipsum is simply dummy text of the printing",
  c: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  BLOG_FOUR_PARA_TWO:
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
  BLOG_FOUR_PARA_THREE:
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur",
  BLOG_FOUR_MORE: "More article",
  BLOG_FOUR_BRIEF:
    "Lorem Ipsum is dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,",
  BLOG_FOUR_READ_BUTTON: "Read More",

  //BLOG Five
  BLOG_FIVE_HEAD: "Lorem Ipsum is simply dummy text of the printing",
  BLOG_FIVE_PARA_ONE:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  BLOG_FIVE_PARA_TWO:
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
  BLOG_FIVE_MORE: "More article",
  BLOG_FIVE_BRIEF:
    "Lorem Ipsum is dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,",
  BLOG_FIVE_READ_BUTTON: "Read More",

  //BLOG SIX
  BLOG_SIX_HEAD: "Lorem Ipsum is simply dummy text of the printing",
  BLOG_SIX_PARA_ONE:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  BLOG_SIX_PARA_TWO:
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
  BLOG_SIX_PARA_THREE:
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur",
  BLOG_SIX_MORE: "More article",
  BLOG_SIX_BRIEF:
    "Lorem Ipsum is dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,",
  BLOG_SIX_READ_BUTTON: "Read More",

  //BLOG SEVEN
  BLOG_SEVEN: "Lorem Ipsum is simply dummy text of the printing",
  BLOG_SEVEN_HEAD: "Lorem Ipsum is simply dummy text of the printing",
  BLOG_SEVEN_PARA_ONE:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  BLOG_SEVEN_PARA_TWO:
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
  BLOG_SEVEN_PARA_THREE:
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur",
  BLOG_SEVEN_MORE: "More article",
  BLOG_SEVEN_MORE_HEAD: "Lorem Ipsum is text",
  BLOG_SEVEN_BRIEF:
    "Lorem Ipsum is dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,",
  BLOG_SEVEN_READ_BUTTON: "Read More",

  //GROUP

  GROUP_HEAD: "Groups",
  GROUP_EASILY: "Create, and manage all your social media group easily",
  GROUP_SEARCH: "Search by group name, page name",
  GROUP_FILTER: "Filter",
  GROUP_CREATE_BUTTON: "Create new group",

  //CREATE GROUP

  CREATE_GROUP_HEAD: "Create groups",
  CREATE_GROUP_EASILY: "Create, and manage all your social media group easily",
  CREATE_GROUP_SEARCH: "Search by group name, page name",
  CREATE_GROUP_SELECT: "Select all",
  GROUP_CREATE_INPUT_TITLE: "Group Title",
  GROUP_CREATE_INPUT_DESCRIPTION: "Description",

  //Calendar

  CALENDAR: "Calendar",
  CALENDAR_CREATE: "Create, edit, pause and manage all your post at one place",
  CALENDAR_MONTH: "May 01",

  //subcriptionpage
  SUBSCRIPTION_HEAD: "Choose the right plan for you",
  SUBSCRIPTION_AFTER_HEAD:
    "We have several powerful plans to showcase your business and get discovered as a creative entrepreneurs. everything you need.",
  SUBSCRIPTION_MONTHLY: "Monthly plans",
  SUBSCRIPTION_ANNUAL: "Annual plans",
  SUBSCRIPTION_BASIC: "Basic",
  SUBSCRIPTION_BASIC_FIRST: "Lorem Ipsum is simply text",
  SUBSCRIPTION_BASIC_SECOND: "Lorem Ipsum is simply dummy dummy text text",
  SUBSCRIPTION_BASIC_THIRD: "Lorem Ipsum is dummy text",
  SUBSCRIPTION_BASIC_FOUR: "Lorem Ipsum is simply dummy text text",
  SUBSCRIPTION_BASIC_FIVE: "Lorem Ipsum is simply dummy dummy text text",
  SUBSCRIPTION_BASIC_SIX: "Lorem Ipsum is simply dummy text",
  SUBSCRIPTION_BASIC_SEVEN: "Choose",
  SUBSCRIPTION_BASIC_EIGHT: "Get a free 14-day Trial",
  SUBSCRIPTION_BASIC_NINE: "NO CREDIT CARD REQUIRED",

  SUBSCRIPTION_STANDARD: "Standard",
  SUBSCRIPTION_STANDARD_FIRST: "Lorem Ipsum is simply text",
  SUBSCRIPTION_STANDARD_SECOND: "Lorem Ipsum is simply dummy dummy text text",
  SUBSCRIPTION_STANDARD_THIRD: "Lorem Ipsum is dummy text",
  SUBSCRIPTION_STANDARD_FOUR: "Lorem Ipsum is simply dummy text text",
  SUBSCRIPTION_STANDARD_FIVE: "Lorem Ipsum is simply dummy dummy text text",
  SUBSCRIPTION_STANDARD_SIX: "Lorem Ipsum is simply dummy text",
  SUBSCRIPTION_STANDARD_SEVEN: "Choose",
  SUBSCRIPTION_STANDARD_EIGHT: "Get a free 14-day Trial",
  SUBSCRIPTION_STANDARD_NINE: "NO CREDIT CARD REQUIRED",

  SUBSCRIPTION_PROFESIONAL: "Professional",
  SUBSCRIPTION_PROFESIONAL_FIRST: "Lorem Ipsum is simply text",
  SUBSCRIPTION_PROFESIONAL_SECOND:
    "Lorem Ipsum is simply dummy dummy text text",
  SUBSCRIPTION_PROFESIONAL_THIRD: "Lorem Ipsum is dummy text",
  SUBSCRIPTION_PROFESIONAL_FOUR: "Lorem Ipsum is simply dummy text text",
  SUBSCRIPTION_PROFESIONAL_FIVE: "Lorem Ipsum is simply dummy dummy text text",
  SUBSCRIPTION_PROFESIONAL_SIX: "Lorem Ipsum is simply dummy text",
  SUBSCRIPTION_PROFESIONAL_SEVEN: "Choose",
  SUBSCRIPTION_PROFESIONAL_EIGHT: "Get a free 14-day Trial",
  SUBSCRIPTION_PROFESIONAL_NINE: "NO CREDIT CARD REQUIRED",

  //Support

  SUPPORT_HEAD: "Support that you need",
  SUPPORT_SEARCH: "Search",
  SUPPORT_CHOOSE: "the category you`d like to take support",
  SUPPORT_CLASSIFICATION: "Classification",
  SUPPORT_STARTED: "Getting Started",
  SUPPORT_CONTENT_ONE: "Accounts & Network Connections",
  SUPPORT_CONTENT_TWO: "Content Publishing",
  SUPPORT_CONTENT_THREE: "Analytics & Reports",
  SUPPORT_CONTENT_FOUR: "Social Inbox",
  SUPPORT_CONTENT_FIVE: "Team & Client Collaboration",
  SUPPORT_CONTENT_SIX: "Mobile App & Browser Extension",
  SUPPORT_CONTENT_SEVEN: "Common Errors",

  //footer
  FOOTER_TEXT:
    "Post Publisher provides Turn hours into minutes when managing your social media clients. Plan ahead, directly schedule, collaborate, and report on your successes in one place.",

  //mARKETING PAGE

  MARKETING_MAIN_HEAD_ONE:
    "Request marketing videos that show the best of your brand",
  MARKETING_SUB_HEAD_TWO:
    " Video content can grab a viewer’s attention or hook your target audience. Learn how to make high-quality video productions that turn watchers into customers.",
  MARKETING_HEAD_GROW_THREE: "Grow your businesss",

  MARKETING_HEAD_START_FOUR: "START MARKETING",
  MARKETING_VIDEO_FIVE:
    "Video content can grab a viewer’s attention or hook your target audience. Learn how to make high-quality video productions that turn watchers into customers.",

  MARKETING_TAB_HEAD_ONE: "Increased brand awareness",
  MARKETING_HEAD_ONE_DETAIL:
    "Marketing helps to increase brand awareness by reaching a wider audience and creating a positive image for your business.",

  MARKETING_TAB_HEAD_TWO: "Increased customer loyalty",
  MARKETING_HEAD_TWO_DETAIL:
    "Marketing can help to create customer loyalty by building trust and credibility with your target audience.",

  MARKETING_TAB_HEAD_THREE: "Cost-effective",
  MARKETING_HEAD_THREE_DETAIL:
    "Marketing can be cost-effective, especially when utilizing digital marketing tactics such as social media and email marketing.",

  MARKETING_TOP_ADVANTAGE: "Top Advantaged",

  MARKETING_ADVANTAGE_SHORT:
    "Increased engagement: Videos are more engaging than text and images, and are more likely to hold a viewer's attention.",
  MARKETING_ADVANTAGE_SUMMARY:
    "An explainer video is a powerful tool that businesses can use to increase conversions and customer engagement on their product or service landing pages. It is a short video that provides a comprehensive overview of the features and benefits of a product or service in an eye-catching and entertaining way",
  MARKETING_ADVANTAGE_SUMM_ARR:
    "Additionally, an explainer video can also be used to increase conversions by providing potential customers with a clear understanding of the product or service and the benefits they can expect to receive.This can increase the chances of a customer making a purchase or taking the next step in the sales funnel.",

  MARKETING_ADVANTAGE_POINT_ONE: "Content planning and scheduling:",
  MARKETING_ADVANTAGE_ONE:
    " Post Publisher allows users to plan and schedule their content, including video content, across multiple channels.",

  MARKETING_ADVANTAGE_POINT_TWO: "Social media management:",
  MARKETING_ADVANTAGE_TWO:
    "Post Publisher offers social media management tools that can be used to promote and distribute video content across different platforms.",

  MARKETING_ADVANTAGE_POINT_THREE: "Content planning and scheduling:",
  MARKETING_ADVANTAGE_THREE:
    " Post Publisher allows users to plan and schedule their content, including video content, across multiple channels.",

  MARKETING_ADVANTAGE_POINT_FOUR: "Analytics and reporting:",
  MARKETING_ADVANTAGE_FOUR:
    "Post Publisher provides analytics and reporting features that can be used to track the performance of video content and make data-driven decisions.",

  MARKETING_ADVANTAGE_POINT_FIVE: "Customizable templates:",
  MARKETING_ADVANTAGE_FIVE:
    "Post Publisher offers customizable templates for creating and distributing video content, which can help to streamline the process and ensure consistency across campaigns.",

  MARKETING_VIDEO_TOUR: "Take a video tour of fetures.",
  MARKETING_VIDEO_TOUR_SUMMARY:
    "Post Publisher is a popular marketing and project management tool that offers a variety of features for managing video content.",
  MARKETING_BUTTON: "Get started",
  MARKETING_READ: "Read our latest stories ",

  MARKETING_FAQ: "FAQ ",
  MARKETING_FAQ_FIRST_QUESTION: "Why video marketing is powerful ? ",
  MARKETING_FAQ_FIRST_ANSWER:
    "Video marketing uses videos to promote a product/service, educate audience and build personal connections, increase engagement and trust. It's a powerful tool for conveying messages and showcasing products/services. ",

  MARKETING_FAQ_SECOND_QUESTION: "How can use email marketing ? ",
  MARKETING_FAQ_SECOND_ANSWER:
    "Video marketing uses videos to promote a product/service, educate audience and build personal connections, increase engagement and trust. It's a powerful tool for conveying messages and showcasing products/services. ",

  MARKETING_FAQ_THIRD_QUESTION: "How can use videos add marketing ? ",
  MARKETING_FAQ_THIRD_ANSWER:
    "Video marketing uses videos to promote a product/service, educate audience and build personal connections, increase engagement and trust. It's a powerful tool for conveying messages and showcasing products/services. ",

  MARKETING_REQUEST: "Request for post creation",
  MARKETING_READ_MORE_BUTTON: "Read More",
  MARKETING_SUBMIT_BUTTON: "Submit",
  //ABOUT US
  ABOUT_MAIN_HEAD: "Why Post Publisher",
  ABOUT_MAIN_SUMMARY:
    "Post Publisher  provide content creation, scheduling, and promotion. It helps to improve productivity, consistency and audience growth. With features like content calendar, scheduling and analytics all in one place.",
  ABOUT_EXPLORER: "Explore More",

  ABOUT_PUBLISHER_DO: "What does Post Publisher do ?",
  ABOUT_PUBLISHER_DO_BRIEF:
    "Post Publisher is an editorial calendar software that helps bloggers, solopreneurs, and startups plan, schedule, collaborate and track the success of their blog and social media efforts all in one place.",
  ABOUT_PURPOSE: "Purpose",
  ABOUT_PURPOSE_BRIEF:
    "Post Publisher is a tool that helps businesses streamline digital marketing efforts. It makes it easy to publish blog posts consistently, schedule social media posts, track performance, and drive traffic to your site, by allowing you to plan, schedule and collaborate in one place, easily reporting on your successes.",
  ABOUT_READ_OUR: "Read our latest stories ",
  ABOUT_MEET_OUR: "Meet Our Leadership",
  ABOUT_GET_IN: "Get in touch with us",
  ABOUT_SUBMIT_BUTTON: "Submit",

  //FEATURE PAGE
  FEATURE_MAIN_HEAD: "The best features of platform which will blow your mind.",
  FEATURE_MAIN_HEAD_SUMMARY:
    "Post Publisher  is a comprehensive marketing platform that offers a variety of features that can help businesses plan, execute, and track their marketing campaigns.",

  FEATURE_GET_STARTED: "Get started",

  FEATURE_LEARN_MORE: "Learn more",

  FEATURE_ADVANTAGES: "Advantages",

  FEATURE_ADVANTAGES_SUMMARY:
    "Post Publisher is a content marketing platform that offers a variety of features to help users schedule and organize their content across multiple social media platforms.",
  FEATURE_SCHEDULING: "Scheduling",
  FEATURE_SCHEDULING_SUMM:
    "Post Publisher is a platform that allows users to schedule and organize their content across various social media platforms. It also offers features such as team collaboration, analytics, and a calendar view to help users stay on top of their content marketing efforts.",

  FEATURE_ENGAGEMENT: "Engagement ",
  FEATURE_ENGAGEMENT_SUMM:
    "Post Publisher provide the Engagement feature also allows you to track engagement by platform, time of day, and day of the week, which can help you identify trends in audience engagement and optimize your content for maximum impact.",

  FEATURE_PUBLISHING: "Publishing ",
  FEATURE_PUBLISHING_SUMM:
    "Post Publisher  allows users to schedule and publish posts across various social media platforms. Once a post has been scheduled, it will be automatically published at the specified date and time.",
  FEATURE_TOP_FETURE: "Top Featurs",
  FEATURE_TOP_FETURE_BRIEF:
    "Post Publisher allows users to connect and schedule content across multiple social media platforms.",

  FEATURE_TOP_ONE:
    "Post Publisher allows users to connect and schedule content across multiple social media platforms.",

  FEATURE_TOP_TWO_FACEBOOK: "Facebook:",
  FEATURE_TOP_TWO_BRIEF:
    "Connect your Facebook account to Post Publisher  and schedule posts to your personal profile, pages, and groups.",

  FEATURE_TOP_THREE_TWITTER: "Twitter",
  FEATURE_TOP_THREE_BRIEF:
    " Connect your Twitter account to Post Publisher  and schedule tweets to be sent at a specific time.",
  FEATURE_TOP_FOUR_LINKEDIN: "LinkedIn",
  FEATURE_TOP_FOUR_BRIEF:
    "Connect your LinkedIn account to Post Publisher  and schedule posts to your personal profile or company page.",
  FEATURE_TOP_FIVE:
    "Post Publisher  offers a variety of analytics and statistics to help users understand the performance of their content. Some of the key metrics that can be tracked using Post Publisher  include:",

  FEATURE_TOP_SIX_SOCIAL: "Social Media Reach:",

  FEATURE_TOP_SOCIAL_BRIEF:
    "This metric shows how many people have seen your posts across different social media platforms.",
  FEATURE_TOP_SEVEN_ENGAGEMENT: "Engagement:",
  FEATURE_TOP_ENGAGEMENTL_BRIEF:
    " This metric measures the number of likes, comments, shares, and other interactions on your posts.",
  FEATURE_TOP_NINE_Traffic: "Traffic:",
  FEATURE_TOP_Traffic_BRIEF:
    "This metric shows how many people have clicked on a link in your post and visited your website.",
  FEATURE_TOP_TEN_AUDIENCE: "Audience Demographics:",
  FEATURE_TOP_AUDIENCE_BRIEF:
    "This metric provides insights into the demographics of your audience, such as age, gender, location, and interests.",

  FEATURE_TOP_ELEVEN:
    "Post Publisher  offers several options for handling failed posts or scheduling errors.Some of these options include: ",

  FEATURE_OPTION_EDITING: "Editing:",
  FEATURE_EDITING_BRIEF:
    "This option allows you to edit the post and try scheduling it again.",

  FEATURE_VIDEO_TOUR: "Take a video tour of fetures",
  FEATURE_VIDEO_TOUR_BRIEF:
    "Post Publisher is a popular marketing and project management tool that offers a variety of features for scheduling  photos  , text, videos over all social media platforms .",

  FEATURE_EXPLORE_BUTTON: "Explore More",
  FEATURE_TESTIMONIALS: "Testimonials",
};
