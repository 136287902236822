import React from "react";
import "./Label.scss";

const Label = (props) => {
  let { title, onClick } = props;

  // tourselector={tourselector}
  return (
    <h1 className={"titleText " + props.className} onClick={onClick}>
      {title}
    </h1>
  );
};

Label.defaultProps = {
  className: "titleText",
};
export default Label;
