import React from "react";
import Label from "../../../components/UI/Label/Label";
import "./ProfileList.scss";

const ProfileList = (props) => {
  const {
    userName,
    statusTitle,
    ProfileImg,
    onPlayPauseClick,
    isPaused,
    isConnected,
    onDisconnectClick,
  } = props;
  const handlePlayPauseClick = () => {
    onPlayPauseClick && onPlayPauseClick(!isPaused);
  };
  const handleDisconnectClick = () => {
    onDisconnectClick && onDisconnectClick();
  };

  return (
    <div className="profile_Listing">
      <div className="profile_list_inner">
        <div className="profile_box">
          <div className="profile_image">
            <img
              src={ProfileImg}
              style={{ height: "48px", width: "48px" }}
            ></img>
          </div>
          <div className="profile_txt">
            <Label className="userName" title={userName}></Label>
            <div className="profile_status">
              <Label className="status_title" title="Status:"></Label>
              <Label className="status" title={statusTitle}></Label>
            </div>
          </div>
        </div>
        <div className="icon_area">
          <div className="status_btn">
            {isConnected && (
              <i
                className={isPaused ? "icon-play" : "icon-pause"}
                title={isPaused ? "Resume Account" : "Pause Account"}
                style={{ cursor: "pointer" }}
                onClick={handlePlayPauseClick}
              ></i>
            )}
          </div>
          <div className="dot_icon">
            {isConnected && (
              <i
                className="icon-delete"
                title="Disconnect Account"
                style={{ cursor: "pointer" }}
                onClick={handleDisconnectClick}
              ></i>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileList;
