import React, { useState } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import Scrollbars from "react-custom-scrollbars";
import { Link, withRouter } from "react-router-dom";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import HookForm from "../../components/HookForm/HookForm";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import Label from "../../components/UI/Label/Label";
import TextField from "../../components/UI/TextField/TextField";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { adminSubscriptionSteps } from "../Steps";
import "./AdminSubscription.scss";

const loginForm = {
  email: {
    name: "email",
  },
};

function AdminSubscriptionPage() {
  const [showBasic, setShowBasic] = useState(true);
  const [showStandard, setShowStandard] = useState(false);
  const [showProfessional, setShowProfessional] = useState(false);
  const [form, setLoginForm] = React.useState();
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();
  // console.log(form);
  const onFormSubmit = () => {};

  const onClickProfessional = () => {
    setShowProfessional(true);
    setShowBasic(false);
    setShowStandard(false);
  };

  const onClickStandard = () => {
    setShowStandard(true);
    setShowBasic(false);
    setShowProfessional(false);
  };
  const OnClickBasic = () => {
    setShowBasic(true);
    setShowProfessional(false);
    setShowStandard(false);
  };

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObjAdmin")
    );
    let newObj = { ...previousLocalStorageValue, adminSubscription: false };
    localStorage.setItem("guideTourObjAdmin", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(
    localStorage.getItem("guideTourObjAdmin")
  );
  return (
    <div className="admin_Subscription">
      <GuidedTour
        steps={adminSubscriptionSteps}
        onRequestClose={handleOnRequestClose}
        isOpen={
          guideTourGetItemValue ? guideTourGetItemValue.adminSubscription : true
        }
      />
      <div className="no-design-section justify-content-center align-items-center vh-100">
        <Label
          title="Mobile design not supported"
          className="no-design"
        ></Label>
      </div>
      <AdminHeader />
      <div className="main_Container">
        <Scrollbars className="admin_Subscription_Scroll">
          <div className="dashboard_Section ">
            <div className="d-flex justify-content-between align-items-center">
              <div className="content_Header">
                <Label className="page_Title" title="Subscription"></Label>
                <Label
                  className="inner_Title"
                  title="View and manage Subscription plan"
                ></Label>
              </div>
              <div className="right_Button d-flex align-items-end">
                <CustomButton
                  className="submitBtn save"
                  title="Save"
                ></CustomButton>
                {/* <CustomButton
                  className="submitBtn publish"
                  title="Publish now"
                ></CustomButton> */}
                <NavDropdown
                  title="Publish now"
                  id="basic-nav-dropdown"
                  className="submitBtn mr-2"
                  tourselector="admin-subscription-btn"
                >
                  <NavDropdown.Item href="#action/3.2">
                    Publish now
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Publish now
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>
            <div className="divider"></div>
            <div className="input_Section d-flex flex-column align-items-center">
              <div className="box-border d-flex justify-content-center flex-column">
                <div
                  className="center_Section"
                  tourselector="admin-subscription-data"
                >
                  <HookForm
                    defaultForm={{}}
                    init={(form) => setLoginForm(form)}
                    onSubmit={onFormSubmit}
                  >
                    {(formMethod) => {
                      return (
                        <div className="form">
                          <TextField
                            formMethod={formMethod}
                            rules={loginForm.email.validate}
                            name={loginForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={true}
                            type="text"
                            placeholder="Title"
                            iconClass=""
                          />
                          <TextField
                            formMethod={formMethod}
                            rules={loginForm.email.validate}
                            name={loginForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={false}
                            type="text"
                            placeholder="Sub Title"
                            iconClass=""
                          />
                        </div>
                      );
                    }}
                  </HookForm>
                  <div className="plans_Section">
                    <div className="toggle_Section justify-content-center">
                      <Label
                        className="plansName"
                        title="Monthly plans"
                      ></Label>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                      </label>
                      <Label className="plansName" title="Annual plans"></Label>
                    </div>
                    <div className="menu">
                      <Link className="active" onClick={OnClickBasic} to="#">
                        Basic
                      </Link>
                      <Link
                        className="standard"
                        onClick={onClickStandard}
                        to="#"
                      >
                        Standard
                      </Link>
                      <Link
                        className="professional"
                        onClick={onClickProfessional}
                        to="#"
                      >
                        Professional
                      </Link>
                    </div>
                    {showBasic && (
                      <div className="detail">
                        <div className="inner_Width d-flex justify-content-center">
                          <HookForm
                            defaultForm={{}}
                            init={(form) => setLoginForm(form)}
                            onSubmit={onFormSubmit}
                          >
                            {(formMethod) => {
                              return (
                                <div className="form">
                                  <div className="colum d-flex w-100">
                                    <div className="w-80">
                                      <TextField
                                        formMethod={formMethod}
                                        rules={loginForm.email.validate}
                                        name={loginForm.email.name}
                                        errors={formMethod?.errors}
                                        autoFocus={false}
                                        type="text"
                                        placeholder="Title"
                                        iconClass=""
                                      />
                                    </div>
                                    <div className="w-20">
                                      <TextField
                                        formMethod={formMethod}
                                        rules={loginForm.email.validate}
                                        name={loginForm.email.name}
                                        errors={formMethod?.errors}
                                        autoFocus={false}
                                        type="text"
                                        placeholder="Price"
                                        iconClass=""
                                      />
                                    </div>
                                  </div>
                                  <TextField
                                    formMethod={formMethod}
                                    rules={loginForm.email.validate}
                                    name={loginForm.email.name}
                                    errors={formMethod?.errors}
                                    autoFocus={false}
                                    type="text"
                                    placeholder="Price"
                                    iconClass="icon-delete"
                                  />
                                  <a className="float-right d-flex align-items-center justify-content-center">
                                    <Label
                                      className="pb-0 pr-3 addMore"
                                      title="Add more points"
                                    ></Label>
                                    <div className="bg_Color d-flex align-items-center justify-content-center">
                                      <i className="icon-adds"></i>
                                    </div>
                                  </a>
                                </div>
                              );
                            }}
                          </HookForm>
                        </div>
                      </div>
                    )}
                    {showStandard && (
                      <div className="detail">
                        <div className="inner_Width d-flex justify-content-center">
                          <HookForm
                            defaultForm={{}}
                            init={(form) => setLoginForm(form)}
                            onSubmit={onFormSubmit}
                          >
                            {(formMethod) => {
                              return (
                                <div className="form">
                                  <div className="colum d-flex w-100">
                                    <div className="w-80">
                                      <TextField
                                        formMethod={formMethod}
                                        rules={loginForm.email.validate}
                                        name={loginForm.email.name}
                                        errors={formMethod?.errors}
                                        autoFocus={false}
                                        type="text"
                                        placeholder="Title"
                                        iconClass=""
                                      />
                                    </div>
                                    <div className="w-20">
                                      <TextField
                                        formMethod={formMethod}
                                        rules={loginForm.email.validate}
                                        name={loginForm.email.name}
                                        errors={formMethod?.errors}
                                        autoFocus={false}
                                        type="text"
                                        placeholder="Price"
                                        iconClass=""
                                      />
                                    </div>
                                  </div>
                                  <TextField
                                    formMethod={formMethod}
                                    rules={loginForm.email.validate}
                                    name={loginForm.email.name}
                                    errors={formMethod?.errors}
                                    autoFocus={false}
                                    type="text"
                                    placeholder="Price"
                                    iconClass="icon-delete"
                                  />
                                  <a className="float-right d-flex align-items-center justify-content-center">
                                    <Label
                                      className="pb-0 pr-3 addMore"
                                      title="Add more points"
                                    ></Label>
                                    <div className="bg_Color d-flex align-items-center justify-content-center">
                                      <i className="icon-adds"></i>
                                    </div>
                                  </a>
                                </div>
                              );
                            }}
                          </HookForm>
                        </div>
                      </div>
                    )}
                    {showProfessional && (
                      <div className="detail">
                        <div className="inner_Width d-flex justify-content-center">
                          <HookForm
                            defaultForm={{}}
                            init={(form) => setLoginForm(form)}
                            onSubmit={onFormSubmit}
                          >
                            {(formMethod) => {
                              return (
                                <div className="form">
                                  <div className="colum d-flex w-100">
                                    <div className="w-80">
                                      <TextField
                                        formMethod={formMethod}
                                        rules={loginForm.email.validate}
                                        name={loginForm.email.name}
                                        errors={formMethod?.errors}
                                        autoFocus={false}
                                        type="text"
                                        placeholder="Title"
                                        iconClass=""
                                      />
                                    </div>
                                    <div className="w-20">
                                      <TextField
                                        formMethod={formMethod}
                                        rules={loginForm.email.validate}
                                        name={loginForm.email.name}
                                        errors={formMethod?.errors}
                                        autoFocus={false}
                                        type="text"
                                        placeholder="Price"
                                        iconClass=""
                                      />
                                    </div>
                                  </div>
                                  <TextField
                                    formMethod={formMethod}
                                    rules={loginForm.email.validate}
                                    name={loginForm.email.name}
                                    errors={formMethod?.errors}
                                    autoFocus={false}
                                    type="text"
                                    placeholder="Price"
                                    iconClass="icon-delete"
                                  />
                                  <a className="float-right d-flex align-items-center justify-content-center">
                                    <Label
                                      className="pb-0 pr-3 addMore"
                                      title="Add more points"
                                    ></Label>
                                    <div className="bg_Color d-flex align-items-center justify-content-center">
                                      <i className="icon-adds"></i>
                                    </div>
                                  </a>
                                </div>
                              );
                            }}
                          </HookForm>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="feature_Section">
                    <div className="inner_Section d-flex">
                      <div className="w-70">
                        <div className="d-flex flex-column">
                          <Label title="Feature"></Label>
                          <div className="d-flex align-items-center">
                            <div className="toggle_Section">
                              <Label
                                className="plansName"
                                title="Monthly"
                              ></Label>
                              <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round"></span>
                              </label>
                              <Label
                                className="plansName"
                                title="Yearly"
                              ></Label>
                            </div>
                            <HookForm
                              defaultForm={{}}
                              init={(form) => setLoginForm(form)}
                              onSubmit={onFormSubmit}
                            >
                              {(formMethod) => {
                                return (
                                  <div className="form">
                                    <TextField
                                      formMethod={formMethod}
                                      rules={loginForm.email.validate}
                                      name={loginForm.email.name}
                                      errors={formMethod?.errors}
                                      autoFocus={false}
                                      type="text"
                                      placeholder="Discount text"
                                      iconClass=""
                                    />
                                  </div>
                                );
                              }}
                            </HookForm>
                          </div>
                        </div>
                      </div>
                      <div className="w-10 ">
                        <div className="d-flex flex-column">
                          <Label title="Basic"></Label>
                          <HookForm
                            defaultForm={{}}
                            init={(form) => setLoginForm(form)}
                            onSubmit={onFormSubmit}
                          >
                            {(formMethod) => {
                              return (
                                <div className="form">
                                  <TextField
                                    formMethod={formMethod}
                                    rules={loginForm.email.validate}
                                    name={loginForm.email.name}
                                    errors={formMethod?.errors}
                                    autoFocus={false}
                                    type="text"
                                    placeholder="Price"
                                    iconClass=""
                                  />
                                </div>
                              );
                            }}
                          </HookForm>
                        </div>
                      </div>
                      <div className="w-10">
                        <div className="d-flex flex-column">
                          <Label title="Standard"></Label>
                          <HookForm
                            defaultForm={{}}
                            init={(form) => setLoginForm(form)}
                            onSubmit={onFormSubmit}
                          >
                            {(formMethod) => {
                              return (
                                <div className="form">
                                  <TextField
                                    formMethod={formMethod}
                                    rules={loginForm.email.validate}
                                    name={loginForm.email.name}
                                    errors={formMethod?.errors}
                                    autoFocus={false}
                                    type="text"
                                    placeholder="Price"
                                    iconClass=""
                                  />
                                </div>
                              );
                            }}
                          </HookForm>
                        </div>
                      </div>
                      <div className="w-10">
                        <div className="d-flex flex-column">
                          <Label title="Professional"></Label>
                          <HookForm
                            defaultForm={{}}
                            init={(form) => setLoginForm(form)}
                            onSubmit={onFormSubmit}
                          >
                            {(formMethod) => {
                              return (
                                <div className="form">
                                  <TextField
                                    formMethod={formMethod}
                                    rules={loginForm.email.validate}
                                    name={loginForm.email.name}
                                    errors={formMethod?.errors}
                                    autoFocus={false}
                                    type="text"
                                    placeholder="Price"
                                    iconClass=""
                                  />
                                </div>
                              );
                            }}
                          </HookForm>
                        </div>
                      </div>
                    </div>
                    <div className="inner_Section d-flex lightBg align-items-center">
                      <div className="w-70 pr-4">
                        <div className="d-flex flex-column">
                          <HookForm
                            defaultForm={{}}
                            init={(form) => setLoginForm(form)}
                            onSubmit={onFormSubmit}
                          >
                            {(formMethod) => {
                              return (
                                <div className="form">
                                  <TextField
                                    formMethod={formMethod}
                                    rules={loginForm.email.validate}
                                    name={loginForm.email.name}
                                    errors={formMethod?.errors}
                                    autoFocus={false}
                                    type="text"
                                    placeholder="Points"
                                    iconClass=""
                                  />
                                </div>
                              );
                            }}
                          </HookForm>
                        </div>
                      </div>
                      <div className="w-10 ">
                        <div className="d-flex flex-column">
                          <div className="toggle_Section">
                            <label className="switch">
                              <input type="checkbox" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="w-10">
                        <div className="d-flex flex-column">
                          <div className="toggle_Section">
                            <label className="switch">
                              <input type="checkbox" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="w-10">
                        <div className="d-flex">
                          <div className="toggle_Section">
                            <label className="switch">
                              <input type="checkbox" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                          <i className="icon-delete colorRed"></i>
                        </div>
                      </div>
                    </div>
                    <a className="float-right d-flex align-items-center justify-content-center">
                      <Label
                        className="pb-0 pr-3 addMore"
                        title="Add more points"
                      ></Label>
                      <div className="bg_Color d-flex align-items-center justify-content-center">
                        <i className="icon-adds"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
}

export default withRouter(AdminSubscriptionPage);
