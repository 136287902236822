import React from "react";
import AddProfile_DropDown from "./AddProfile_DropDown/AddProfile_DropDown";

const AddProfileDropdownList = ({ active, setActive, profiles }) => {
  return (
    <div className="add_profile_link" tourselector="add-more-accounts">
      {profiles.map((profile, index) => (
        <React.Fragment key={index}>
          <AddProfile_DropDown
            active={active}
            setActive={setActive}
            ProfileImg={profile.profileImg}
            userName={profile.userName}
            linkName={profile.linkName}
          />
          {index < profiles.length - 1 && <span className="line"></span>}
        </React.Fragment>
      ))}
    </div>
  );
};

export default AddProfileDropdownList;
