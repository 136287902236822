import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { Link, withRouter } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import HeaderLogin from "../../components/HeaderLogin/HeaderLogin";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import CustomDropdown from "../../components/UI/CustomDropdown/CustomDropdown";
import Label from "../../components/UI/Label/Label";
import PlanCardView from "../../components/UI/PlanCard/PlanCard";
import "./SubscriptionPage.scss";
const dropdownItem = [
  {
    id: "1",
    value: "India",
  },
  {
    id: "2",
    value: "USA",
  },
  {
    id: "3",
    value: "UK",
  },
];
function SubscriptionPage() {
  const [staff, setSelectedStaff] = useState();
  return (
    <div className="subscription_Page">
      <div className="main_SubscriptionPage">
        <HeaderLogin></HeaderLogin>
        <Scrollbars className="subscription_Scroll">
          <div className="subscription_Inner pt-2">
            <div className="price_Section">
              <Label
                className="main_title"
                title="Choose the right plan for you"
              ></Label>
              <Link to="#" className="decoration d-none">
                <i className="icon-back" />
              </Link>
              <div className="subscriptiondropdown_Section">
                <CustomDropdown
                  dropDownItems={dropdownItem}
                  placeholder="Select Country"
                  selectedValue={staff}
                  onSelect={(selected) => setSelectedStaff(selected)}
                ></CustomDropdown>
              </div>
              <Label
                className="inner_title"
                title="We have several powerful plans to showcase your business and get discovered as a creative entrepreneurs. everything you need."
              ></Label>
              <div className="sub_Section ">
                <div className="toggle_Section justify-content-center">
                  <Label className="plansName" title="Monthly plans"></Label>
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                  <Label className="plansName" title="Annual plans"></Label>
                </div>
                {/* <Scrollbars className="subscription_Scroll" > */}
                <div className="d-flex flex-column">
                  <div className="plan_Section">
                    <PlanCardView
                      className="plan"
                      planHead="Basic"
                      planPrice="$5"
                      plandetail1="Lorem Ipsum is simply text"
                      plandetail2="Lorem Ipsum is simply dummy dummy  text text"
                      plandetail3="Lorem Ipsum is  dummy text"
                      plandetail4="Lorem Ipsum is simply dummy text text"
                      plandetail5="Lorem Ipsum is simply dummy dummy  text text"
                      plandetail6="Lorem Ipsum is simply dummy text"
                      buttonTitle="Choose"
                      buttonSubTitle="Get a free 14-day Trial"
                      bottomLabel="NO CREDIT CARD REQUIRED"
                    ></PlanCardView>
                    <PlanCardView
                      className="plan"
                      planHead="Standard"
                      planPrice="$15"
                      plandetail1="Lorem Ipsum is simply text"
                      plandetail2="Lorem Ipsum is simply dummy dummy  text text"
                      plandetail3="Lorem Ipsum is  dummy text"
                      plandetail4="Lorem Ipsum is simply dummy text text"
                      plandetail5="Lorem Ipsum is simply dummy dummy  text text"
                      plandetail6="Lorem Ipsum is simply dummy text"
                      buttonTitle="Choose"
                      buttonSubTitle="Get a free 14-day Trial"
                      bottomLabel="NO CREDIT CARD REQUIRED"
                    ></PlanCardView>
                    <PlanCardView
                      className="plan"
                      planHead="Professional"
                      planPrice="$25"
                      plandetail1="Lorem Ipsum is simply text"
                      plandetail2="Lorem Ipsum is simply dummy dummy  text text"
                      plandetail3="Lorem Ipsum is  dummy text"
                      plandetail4="Lorem Ipsum is simply dummy text text"
                      plandetail5="Lorem Ipsum is simply dummy dummy  text text"
                      plandetail6="Lorem Ipsum is simply dummy text"
                      buttonTitle="Choose"
                      buttonSubTitle="Get a free 14-day Trial"
                      bottomLabel="NO CREDIT CARD REQUIRED"
                    ></PlanCardView>
                  </div>
                  <div className="d-flex more-details">
                    <Label
                      className="plansName"
                      title="Click here for more detail"
                    ></Label>
                    <Link to="#" className="backIcon">
                      <i className="icon-back" />
                    </Link>
                  </div>
                  <div className="feature_Section mb-2">
                    <div className="plan_Sections d-flex flex-row bg-Color">
                      <div className="plan_Items">
                        <Label className="plansName" title="Feature"></Label>
                        <div className="toggle_Section">
                          <Label className="plansName" title="Monthly"></Label>
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                          <Label
                            className="plansName blue"
                            title="Yearly"
                          ></Label>
                          <span className="bg-section">
                            <Label
                              className="smallText"
                              title="10% discount"
                            ></Label>
                          </span>
                        </div>
                      </div>
                      <div className="plan_Items">
                        <Label className="plansName" title="Basic"></Label>
                        <div className="d-flex">
                          <Label className="plansName" title="$5"></Label>
                          <Label
                            className="lightText pb-0 d-flex align-items-center"
                            title="25/month"
                          ></Label>
                        </div>
                      </div>
                      <div className="plan_Items">
                        <Label className="plansName" title="Standarad"></Label>
                        <div className="d-flex">
                          <Label className="plansName" title="$15"></Label>
                          <Label
                            className="lightText pb-0 d-flex align-items-center"
                            title="2000/month"
                          ></Label>
                        </div>
                      </div>
                      <div className="plan_Items">
                        <Label
                          className="plansName"
                          title="Professional"
                        ></Label>
                        <div className="d-flex">
                          <Label className="plansName" title="$25"></Label>
                          <Label
                            className="lightText pb-0 d-flex align-items-center"
                            title="750/month"
                          ></Label>
                        </div>
                      </div>
                    </div>
                    <div className="items_Section">
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Audience targeting"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Draft Your Posts"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label className="list" title="Mentions"></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Instagram Image Tagging"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-close iconRed"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Locations tagging"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Custom Call-To-Actions"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label className="list" title="Image Editor"></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex blueBorder">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Visual Editor For mobile app"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list bold"
                            title="Analytics"
                          ></Label>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Detailed Social Media Analytics"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Post performance"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Whiteable Reports"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-close iconRed"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Send PDF Report Via Email"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-close iconRed"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex blueBorder">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Sechedule reports"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-close iconRed"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-close iconRed"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list bold"
                            title="Social Inbox"
                          ></Label>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Reply to Post Comments"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-close iconRed"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Reply to messages"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-close iconRed"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Reply to story replies"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-close iconRed"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Reply to Facebook Page Posts"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-close iconRed"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Reply to Post Comments"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-close iconRed"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list bold"
                            title="Team & Client Collaboration"
                          ></Label>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Client Management"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Comments on Post"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-close iconRed"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Content Approval Workflows"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-close iconRed"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex blueBorder">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Whitelabel Dashboard"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-close iconRed"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-close iconRed"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list bold"
                            title="Facebook Ads"
                          ></Label>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Schedule Boost Post"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <Label className="list" title="No limits"></Label>
                        </div>
                        <div className="paddingLeft basic">
                          <Label className="list" title="No limits"></Label>
                        </div>
                        <div className="paddingLeft basic">
                          <Label className="list" title="No limits"></Label>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Boost Popular Posts"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label className="list" title="Ads Analytics"></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list bold"
                            title="Integrations And Apps"
                          ></Label>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Zapier Integration"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <Label className="list" title="No limits"></Label>
                        </div>
                        <div className="paddingLeft basic">
                          <Label className="list" title="No limits"></Label>
                        </div>
                        <div className="paddingLeft basic">
                          <Label className="list" title="No limits"></Label>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Browser Extension"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label className="list" title="mobile App"></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Cannva Integration"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex blueBorder">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Dropbox And Box.com Integration"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list bold"
                            title="Integrations And Apps"
                          ></Label>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Costomer Support"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <Label className="list" title="24/5"></Label>
                        </div>
                        <div className="paddingLeft basic">
                          <Label className="list" title="24/5"></Label>
                        </div>
                        <div className="paddingLeft basic">
                          <Label className="list" title="24/5"></Label>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Concierge Setup"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex">
                        <div className="d-flex monthly">
                          <Label
                            className="list"
                            title="Self-Service knowledge Base"
                          ></Label>
                          <span className="bgClr d-flex justify-content-center ml-1">
                            i
                          </span>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                        <div className="paddingLeft basic">
                          <i className="icon-tick iconGreen"></i>
                        </div>
                      </div>
                      <div className="item-listing d-flex noBorder">
                        <div className="d-flex monthly">
                          <Label className="list" title=""></Label>
                        </div>
                        <div className=" basic">
                          <CustomButton
                            className="submitBtn subscribed noBg"
                            title="Get a Free 14-Day Trial"
                          ></CustomButton>
                          <Label
                            className="smallTxt"
                            title="No credit card required"
                          ></Label>
                        </div>
                        <div className=" basic">
                          <CustomButton
                            className="submitBtn subscribed "
                            title="Get a Free 14-Day Trial"
                          ></CustomButton>
                          <Label
                            className="smallTxt"
                            title="No credit card required"
                          ></Label>
                        </div>
                        <div className=" basic">
                          <CustomButton
                            className="submitBtn subscribed noBg"
                            title="Get a Free 14-Day Trial"
                          ></CustomButton>
                          <Label
                            className="smallTxt"
                            title="No credit card required"
                          ></Label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer className="footerheight"></Footer>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
}

export default withRouter(SubscriptionPage);
