import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router-dom";
import GroupImg from "../../assests/images/group1.png";
import LandingBottom from "../../assests/images/lp-bottom.png";
import LandingTop from "../../assests/images/lp-top.png";
import SliderImg from "../../assests/images/slider1.png";
import SocialGroup from "../../assests/images/socail-group.png";
import SocialGroupImg from "../../assests/images/socialgroup.png";
import TimeImg from "../../assests/images/time.png";
import Footer from "../../components/Footer/Footer";
import HeaderLogin from "../../components/HeaderLogin/HeaderLogin";
import HookForm from "../../components/HookForm/HookForm";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import ImageSlider from "../../components/UI/ImageSlider/ImageSlider";
import Label from "../../components/UI/Label/Label";
import TextField from "../../components/UI/TextField/TextField";
import Constant from "../../util/constant";
import Message from "../../util/message";
import "./LandingPage.scss";

const contactForm = {
  name: {
    name: "name",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.EMAILEMPTY,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ERRORMESSAGE.EMAILINVALID,
      },
    },
  },
  email: {
    name: "email",
    validate: {
      required: {
        value: true,
      },
    },
  },
};
const onFormSubmit = () => {};
function LandingPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const [form, setLoginForm] = React.useState();
  const dimensions = {
    height: window.innerHeight,
    width: window.innerWidth,
  };
  // console.log(form);
  const handleClick = (page) => {
    if (page === "subscriptionpage") {
      history.push("./subscriptionpage");
    } else if (page === "thankyouPopup") {
      history.push("./thankyouPopup");
    }
  };

  return (
    <div className="landing_Page" style={{ height: dimensions.height + "px" }}>
      <HeaderLogin />
      <Scrollbars className="landing_Scroll">
        <div className="main_LandingPage">
          <div className="landing_Inner">
            <div className="get_Start">
              <div className="content">
                <Label
                  className="title_Main"
                  title={t("LANDING_FIRST_BEST")}
                ></Label>
                <Label
                  className="inner_Text"
                  title={t("LANDING_SECOND_LOREM")}
                ></Label>
                <CustomButton title={t("LANDING_THIRD_GET")}></CustomButton>
              </div>
              <div className="img_Section">
                <img
                  src={GroupImg}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="main_Img"
                />
              </div>
            </div>
            <div className="price_Section">
              <Label
                className="main_title"
                title={t("LANDING_FOURTH_BEST")}
              ></Label>
              <Label
                className="inner_title"
                title={t("LANDING_FIFTH_BEST")}
              ></Label>
              <div className="Monthly_yearly_tab ">
                <Tabs
                  defaultActiveKey="Monthly"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="Monthly" title={t("LANDING_SIXTH_MONTH")}>
                    <div className="tab_Section">
                      <div className="price_TabInner">
                        <div className="content basic">
                          <div className="label_Inline">
                            <Label className="no" title="01"></Label>
                            <Label
                              className="main_Text"
                              title={t("LANDING_EIGHT_PLAN_FIRST")}
                            ></Label>
                            <Label className="amt" title="Free"></Label>
                          </div>
                          <Label
                            className="desp"
                            title={t("LANDING_PLAN_FIRST")}
                          ></Label>
                          <CustomButton
                            title={t("LANDING_THIRD_GET")}
                            onClick={() => handleClick("subscriptionpage")}
                          ></CustomButton>
                        </div>
                      </div>
                      <div className="price_TabInner">
                        <div className="content">
                          <div className="label_Inline">
                            <Label className="no" title="02"></Label>
                            <Label
                              className="main_Text"
                              title={t("LANDING_EIGHT_PLAN_SECOND")}
                            ></Label>
                            <Label className="amt" title="$15"></Label>
                          </div>
                          <Label
                            className="desp"
                            title={t("LANDING_PLAN_SECOND")}
                          ></Label>
                          <CustomButton
                            title={t("LANDING_THIRD_GET")}
                            onClick={() => handleClick("subscriptionpage")}
                          ></CustomButton>
                        </div>
                      </div>
                      <div className="price_TabInner">
                        <div className="content">
                          <div className="label_Inline">
                            <Label className="no" title="03"></Label>
                            <Label
                              className="main_Text"
                              title={t("LANDING_EIGHT_PLAN_THIRD")}
                            ></Label>
                            <Label className="amt" title="$25"></Label>
                          </div>
                          <Label
                            className="desp"
                            title={t("LANDING_PLAN_THIRD")}
                          ></Label>
                          <CustomButton
                            title={t("LANDING_THIRD_GET")}
                            onClick={() => handleClick("subscriptionpage")}
                          ></CustomButton>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Yearly" title={t("LANDING_SEVEN_YEAR")}>
                    <div className="tab_Section">
                      <div className="price_TabInner">
                        <div className="content">
                          <div className="label_Inline">
                            <Label className="no" title="01"></Label>
                            <Label
                              className="main_Text"
                              title={t("LANDING_YEAR_FIRST_HEAD")}
                            ></Label>
                            <Label className="amt" title="$5"></Label>
                          </div>
                          <Label
                            className="desp"
                            title={t("LANDING_YEAR_FIRST_BRIEF")}
                          ></Label>
                          <CustomButton
                            title={t("LANDING_THIRD_GET")}
                          ></CustomButton>
                        </div>
                      </div>
                      <div className="price_TabInner">
                        <div className="content">
                          <div className="label_Inline">
                            <Label className="no" title="02"></Label>
                            <Label
                              className="main_Text"
                              title={t("LANDING_YEAR_SECOND_HEAD")}
                            ></Label>
                            <Label className="amt" title="$5"></Label>
                          </div>
                          <Label
                            className="desp"
                            title={t("LANDING_YEAR_SECOND_BRIEF")}
                          ></Label>
                          <CustomButton
                            title={t("LANDING_THIRD_GET")}
                          ></CustomButton>
                        </div>
                      </div>
                      <div className="price_TabInner">
                        <div className="content">
                          <div className="label_Inline">
                            <Label className="no" title="03"></Label>
                            <Label
                              className="main_Text"
                              title={t("LANDING_YEAR_THIRD_HEAD")}
                            ></Label>
                            <Label className="amt" title="$5"></Label>
                          </div>
                          <Label
                            className="desp"
                            title={t("LANDING_YEAR_THIRD_BRIEF")}
                          ></Label>
                          <CustomButton
                            title={t("LANDING_THIRD_GET")}
                          ></CustomButton>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
            <div className="time_Section">
              <div className="img_Section">
                <img
                  src={TimeImg}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="time_Img"
                />
                {/* <img src={SocialImg} alt="image"  
 
style={{ maxWidth: "100%", height: "auto" }} className='time_Img resp_Show' /> */}
              </div>
              <div className="content_Section">
                <Label className="main_Title" title={t("LANDING_NINE")}></Label>
                <Label className="inner_Title" title={t("LANDING_TEN")}></Label>
              </div>
            </div>
            <div className="social_Section">
              <div className="content_Section">
                <Label
                  className="main_Title"
                  title={t("LANDING_ELEVEN")}
                ></Label>
                <Label
                  className="inner_Title"
                  title={t("LANDING_TWELVE")}
                ></Label>
                {/* //added badal */}
                <ul>
                  <li>
                    <Label
                      className="inner_Title"
                      title={t("LANDING_TWELVE_POINT_0NE")}
                    ></Label>
                  </li>
                  <li>
                    {" "}
                    <Label
                      className="inner_Title"
                      title={t("LANDING_TWELVE_POINT_TWO")}
                    ></Label>
                  </li>
                  <li>
                    {" "}
                    <Label
                      className="inner_Title"
                      title={t("LANDING_TWELVE_POINT_THREE")}
                    ></Label>
                  </li>
                </ul>
              </div>
              <div className="img_Section">
                <img
                  src={SocialGroupImg}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="time_Img "
                />
                {/* <img src={SocialGroupImg1} alt="image"  
                  style={{ maxWidth: "100%", height: "auto" }} className='time_Img img_Show' /> */}
              </div>
            </div>
            <div className="manage_Section">
              <img
                src={LandingTop}
                alt="image"
                style={{ maxWidth: "100%", height: "auto" }}
                // fluid="true"
                className="main_Top"
              />
              <img
                src={LandingBottom}
                alt="image"
                style={{ maxWidth: "100%", height: "auto" }}
                // fluid="true"
                className="main_Bottom"
              />
              <div className="content_Section">
                <Label
                  className="main_Title"
                  title={t("LANDING_THIRTEEN")}
                ></Label>
                <Label
                  className="inner_Title"
                  title={t("LANDING_FOURTEEN")}
                ></Label>
                <CustomButton title={t("LANDING_THIRD_GET")}></CustomButton>
              </div>
            </div>
            <div className="imgSlider_Section">
              <Label
                className="main_Title"
                title={t("LANDING_FIFTEEN_READ")}
              ></Label>
              <ImageSlider
                SliderImg={SliderImg}
                SliderImg1={SliderImg}
                SliderImg2={SliderImg}
                headingTxt={t("LANDING_SIXTEEN_HEAD_ONE")}
                innerTxt={t("LANDING_SIXTEEN_BRIEF")}
              ></ImageSlider>
            </div>
            <div className="input_Section" id="contactus">
              <Label className="main_Title" title={t("LANDING_GET_IN")}></Label>
              <div className="content_Textbox">
                <div className="img_Section">
                  <img
                    src={SocialGroup}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    // fluid="true"
                    className=""
                  />
                </div>
                <div className="text_Box">
                  <HookForm
                    defaultForm={{ name: "", email: "" }}
                    init={(form) => setLoginForm(form)}
                    onSubmit={onFormSubmit}
                  >
                    {(formMethod) => {
                      return (
                        <div className="form1">
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.name.validate}
                            name={contactForm.name.name}
                            errors={formMethod?.errors}
                            placeholder="Name"
                            iconClass="icon-user"
                            type="text"
                          />
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.email.validate}
                            name={contactForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={false}
                            type="text"
                            placeholder="Email Id*"
                            iconClass="icon-mail"
                          />

                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.name.validate}
                            name={contactForm.name.name}
                            errors={formMethod?.errors}
                            placeholder="Share your throughts"
                            type="text"
                            textarea="textarea"
                          />
                          <CustomButton
                            title={t("LANDING_SUBMIT")}
                            onClick={() => handleClick("thankyouPopup")}
                          ></CustomButton>
                        </div>
                      );
                    }}
                  </HookForm>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer className="footerheight"></Footer>
      </Scrollbars>
    </div>
  );
}

export default withRouter(LandingPage);
