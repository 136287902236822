import React from "react";
import Scrollbars from "react-custom-scrollbars";
import { withRouter } from "react-router-dom";
import SupportImg from "../../assests/images/support.svg";
import Footer from "../../components/Footer/Footer";
import HeaderLogin from "../../components/HeaderLogin/HeaderLogin";
import Label from "../../components/UI/Label/Label";
import SearchBox from "../../components/UI/SearchBox/SearchBox";
import "./Support.scss";

function SupportList() {
  // let navigate = useNavigate();
  // const clickToNavigate = (page) => {
  //   if (page === "SupportList") {
  //     navigate("/supportlist");
  //   }
  // };
  return (
    <div className="support_Page">
      <div className="main_support">
        <HeaderLogin></HeaderLogin>
        <Scrollbars className="support_Scroll">
          <div className="support_img">
            <div className="support_content">
              <img
                src={SupportImg}
                alt="image"
                style={{ maxWidth: "100%", height: "auto" }}
                // fluid="true"
                className="main_Img"
              />
              <div className="content-Top position-absolute">
                <Label
                  title="Welcome to Post Publisher"
                  className="top-Head"
                ></Label>
                <Label
                  title="Support that you need"
                  className="top-Head"
                ></Label>
                <Label
                  title="What can we help you find?"
                  className="top-Inner"
                ></Label>
                <div className="search_Section">
                  <SearchBox placeholder="Search"></SearchBox>
                </div>
              </div>
            </div>
            <div className="center_card">
              <Label title="Our Top Article"></Label>
              <div className="card_section">
                <div className="card cards">
                  <div className="bg-Color">
                    <i className="icon-file"></i>
                  </div>
                  <div className="inner d-flex">
                    <div className="text-section">
                      <Label title="Article" className="article"></Label>
                      <Label
                        title="Start setting up your profile"
                        className="title"
                      ></Label>
                    </div>
                    <i className="icon-rightarrow"></i>
                  </div>
                </div>
                <div className="card cards">
                  <div className="bg-Color">
                    <i className="icon-file"></i>
                  </div>
                  <div className="inner d-flex">
                    <div className="text-section">
                      <Label title="Article" className="article"></Label>
                      <Label
                        title="Start setting up your profile"
                        className="title"
                      ></Label>
                    </div>
                    <i className="icon-rightarrow"></i>
                  </div>
                </div>
                <div className="card cards">
                  <div className="bg-Color">
                    <i className="icon-file"></i>
                  </div>
                  <div className="inner d-flex">
                    <div className="text-section">
                      <Label title="Article" className="article"></Label>
                      <Label
                        title="Start setting up your profile"
                        className="title"
                      ></Label>
                    </div>
                    <i className="icon-rightarrow"></i>
                  </div>
                </div>
                <div className="card cards">
                  <div className="bg-Color">
                    <i className="icon-file"></i>
                  </div>
                  <div className="inner d-flex">
                    <div className="text-section">
                      <Label title="Article" className="article"></Label>
                      <Label
                        title="Start setting up your profile"
                        className="title"
                      ></Label>
                    </div>
                    <i className="icon-rightarrow"></i>
                  </div>
                </div>
                <div className="card cards">
                  <div className="bg-Color">
                    <i className="icon-file"></i>
                  </div>
                  <div className="inner d-flex">
                    <div className="text-section">
                      <Label title="Article" className="article"></Label>
                      <Label
                        title="Start setting up your profile"
                        className="title"
                      ></Label>
                    </div>
                    <i className="icon-rightarrow"></i>
                  </div>
                </div>
                <div className="card cards">
                  <div className="bg-Color">
                    <i className="icon-file"></i>
                  </div>
                  <div className="inner d-flex">
                    <div className="text-section">
                      <Label title="Article" className="article"></Label>
                      <Label
                        title="Start setting up your profile"
                        className="title"
                      ></Label>
                    </div>
                    <i className="icon-rightarrow"></i>
                  </div>
                </div>
              </div>
            </div>
            <Footer className="footerheight"></Footer>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
}

export default withRouter(SupportList);
