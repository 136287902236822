import React from "react";
import "./CustomDropdown.scss";
import PropTypes from "prop-types";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

const CustomDropdown = (props) => {
  let { selectedValue, dropDownItems = [], placeholder, onSelect } = props;

  const getTitle = () => {
    if (selectedValue && selectedValue.id) {
      const item = dropDownItems.find((i) => i.id === selectedValue.id);
      return (item && item.value) || placeholder;
    } else {
      return placeholder;
    }
  };

  return (
    <div className="customDropdown">
      <DropdownButton
        key={selectedValue?.id}
        id={`dropdown-variants-${selectedValue?.id}`}
        title={getTitle()}
        onSelect={(evt) => {
          onSelect(dropDownItems.find((i) => i.id === evt));
        }}
        onClick={(e, ev) => console.log("Custom DropdownButton", e, ev)}
        className={selectedValue ? "selected" : ""}
      >
        <div className="dropdownData">
          {dropDownItems.map((item) => (
            <Dropdown.Item key={item.id} eventKey={item.id}>{item.value}</Dropdown.Item>
          ))}
          {props.children}
        </div>
      </DropdownButton>
    </div>
  );
};

CustomDropdown.propTypes = {
  onClick: PropTypes.func,
};

export default CustomDropdown;
