import React from "react";
import Header from "../../../components/Header/Header";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import Label from "../../../components/UI/Label/Label";
import SearchBox from "../../../components/UI/SearchBox/SearchBox";
import FilterForm from "../Filter/FilterForm";
import InviteModal from "../InviteModal/InviteModal";
import TeamTabHeader from "../TeamTabHeader/TeamTabHeader";
import "./Team.scss";

// const dropDownData = [
//   {
//     id: "1",
//     value: "Edit Link",
//   },
// ];

const Team = () => {
  // const [selected, setSelected] = React.useState();
  const [showFilterDropdown, setShowFilterDropdown] = React.useState(false);
  const [showInviteModal, setShowInviteModal] = React.useState(false);
  const [showTab, setShowTab] = React.useState(false);
  const handleClickEmail = () => {
    setShowTab(!showTab);
    setShowInviteModal(!showInviteModal);
  };

  const handleEditClick = () => {
    setShowTab(!showTab);
    setShowInviteModal(!showInviteModal);
  };

  // const handleSelect = (value) => {
  //   setSelected(value);
  // };

  const handleClickInvite = () => {
    setShowInviteModal(!showInviteModal);
  };

  return (
    <div className="super-admin-parent">
      <div className="side-menu-bar">
        <Header />
      </div>
      <div className="divide-section">
        <div className="left-section">
          <div className="top-section">
            <Label
              title="Manage your Teams and Clients"
              className="top-title"
            />

            {/* <div className="invite-link">
              <CustomDropdown
                dropDownItems={dropDownData}
                placeholder="Invite Via Link"
                selectedValue={selected}
                onSelect={handleSelect}
              ></CustomDropdown>
            </div> */}
            <i className="icon-tab" onClick={() => setShowTab(!showTab)}></i>
          </div>
          <Label
            className="top-subTitle"
            title="You can manage your teams and clients from here"
          />
          <div className="search-filter-main">
            <div className="search-section">
              <SearchBox placeholder="Search by Email_Id, Role or Status" />
            </div>
            <div className="filter-section">
              <button
                className="filterIcon"
                onClick={() => setShowFilterDropdown(!showFilterDropdown)}
              >
                <i className="icon-filter">
                  <span>Filter</span>
                </i>
              </button>
              <div className="filter-modal">
                {showFilterDropdown && (
                  <>
                    <FilterForm
                      title="Filter"
                      onClick={() => setShowFilterDropdown(false)}
                      onClickCloseIcon={() => setShowFilterDropdown(false)}
                    ></FilterForm>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="table-listing">
            <TeamTabHeader
              handleClickEmail={handleClickEmail}
              handleEditClick={handleEditClick}
            />
          </div>
        </div>
        <div className={showTab ? "right-section showTab" : "right-section"}>
          <i className="icon-close" onClick={() => setShowTab(!showTab)}></i>

          {!showInviteModal && (
            <div className="right-center-section">
              <Label
                className="center-text"
                title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has bee"
              />
              <div className="btn-invite">
                <CustomButton title="Invite" onClick={handleClickInvite} />
              </div>
            </div>
          )}
          {showInviteModal && (
            <div className="show-invite-modal" style={{}}>
              <InviteModal
                handleClickCloseIcon={() =>
                  setShowInviteModal(!showInviteModal)
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Team;
