import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./DropdownGroup.scss";
import Scrollbars from "react-custom-scrollbars";
import CheckBox from "../../components/UI/CheckBox/CheckBox";
import Label from "../../components/UI/Label/Label";

const listingItem = [
  {
    title: "Arlene McCoy",
    id: "1",
    image: require("../../assests/images/CreateGroupProfileImg.png"),
  },
  {
    title: "Darlene Robertson",
    id: "2",
    image: "../../assests/images/CreateGroupProfileImg.png",
  },
  {
    title: "Esther Howard",
    id: "3",
    image: "../../assests/images/CreateGroupProfileImg.png",
  },
  {
    title: "Darrell Steward",
    id: "4",
    image: "../../assests/images/CreateGroupProfileImg.png",
  },
  {
    title: "Albert Flores",
    id: "5",
    image: "../../assests/images/CreateGroupProfileImg.png",
  },
  {
    title: "name",
    id: "6",
    image: "../../assests/images/CreateGroupProfileImg.png",
  },
];

const DropdownGroup = () => {
  return (
    <div className="DropdownGroupScreen">
      <div className="maincontainer">
        <div className="link_section">
          <Tabs defaultActiveKey="Pages" id="uncontrolled-tab-example">
            <Tab eventKey="Pages" title="Pages" className="inner_link1"></Tab>
            <Tab eventKey="Group" title="Group" className="inner_link2"></Tab>
          </Tabs>
        </div>
        <div className="main_box">
          <Scrollbars>
            <div className="inner_box">
              {listingItem.map((listingItem, index) => {
                return (
                  <div className="content" key={index}>
                    <div className="content_inner">
                      <img
                        src={listingItem.image}
                        alt="image"
                        style={{ maxWidth: "100%", height: "auto" }}
                        // fluid="true"
                        className="profile_Img"
                      />
                      <Label className="profile_name" title={listingItem.title}>
                        hi
                      </Label>
                    </div>
                    <CheckBox
                      className="checkboxMain"
                      id={listingItem.id}
                      labelTitle=""
                    ></CheckBox>
                  </div>
                );
              })}
              <div className="box_border"></div>
              {/* <div className="content">
                                <div className="content_inner">
                                    <img src={CreateGroupProfileImg} alt="image"  
 
style={{ maxWidth: "100%", height: "auto" }} className='profile_Img' />
                                    <Label className="profile_name" title="Arlene McCoy"></Label>
                                </div>
                                <CheckBox className="checkboxMain" id='2' labelTitle=""></CheckBox>
                            </div>
                            <div className="box_border"></div>
                            <div className="content">
                                <div className="content_inner">
                                    <img src={CreateGroupProfileImg} alt="image"  
 
style={{ maxWidth: "100%", height: "auto" }} className='profile_Img' />
                                    <Label className="profile_name" title="Arlene McCoy"></Label>
                                </div>
                                <CheckBox className="checkboxMain" id='3' labelTitle=""></CheckBox>
                            </div>
                            <div className="box_border"></div>
                            <div className="content">
                                <div className="content_inner">
                                    <img src={CreateGroupProfileImg} alt="image"  
 
style={{ maxWidth: "100%", height: "auto" }} className='profile_Img' />
                                    <Label className="profile_name" title="Arlene McCoy"></Label>
                                </div>
                                <CheckBox className="checkboxMain" id='4' labelTitle=""></CheckBox>
                            </div>
                            <div className="box_border"></div>
                            <div className="content">
                                <div className="content_inner">
                                    <img src={CreateGroupProfileImg} alt="image"  
 
style={{ maxWidth: "100%", height: "auto" }} className='profile_Img' />
                                    <Label className="profile_name" title="Arlene McCoy"></Label>
                                </div>
                                <CheckBox className="checkboxMain" id='5' labelTitle=""></CheckBox>
                            </div>
                            <div className="box_border"></div>
                            <div className="content">
                                <div className="content_inner">
                                    <img src={CreateGroupProfileImg} alt="image"  
 
style={{ maxWidth: "100%", height: "auto" }} className='profile_Img' />
                                    <Label className="profile_name" title="Arlene McCoy"></Label>
                                </div>
                                <CheckBox className="checkboxMain" id='6' labelTitle=""></CheckBox>
                            </div>
                            <div className="box_border"></div> */}
            </div>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default DropdownGroup;
