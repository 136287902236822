import React from "react";
import { Button } from "react-bootstrap";
import "./CustomButton.scss";

const CustomButton = (props) => {
  let {
    title,
    iconClass,
    type,
    className,
    onClick,
    tourselector,
    tourselectorpublishbtn,
    tourselectorschedulebtn,
    style,
  } = props;
  return (
    <Button
      tourselector={tourselector}
      className={className}
      type={type}
      onClick={onClick}
      tourselectorpublishbtn={tourselectorpublishbtn}
      tourselectorschedulebtn={tourselectorschedulebtn}
      style={style}
    >
      {title}
      {props.iconShow && <i className={"icon-css " + iconClass}></i>}
    </Button>
  );
};

CustomButton.defaultProps = {
  className: "submitBtn",
};

export default CustomButton;
