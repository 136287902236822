import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import React, { useState, useRef, useEffect } from "react";
import CloseButton from "react-bootstrap/CloseButton";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { createUpdatePostAction } from "../../../Redux_saga/postPublisherSlice";
import { useAppData } from "../../../services/hooks";
import CustomButtom from "../CustomButton/CustomButton";
import "./MessageBox.scss";
import AWS from "aws-sdk";
import { showToastAction } from "../../../Redux_saga/globalSlice";

const MessageBox = (props) => {
  let {
    onClickDownArrow,
    SchedulePost,
    // handleChange,
    // value,
    onClickPublishNow,
    tourselectorIcons,
    tourselectorschedulebtn,
    tourselectorpublishbtn,
    onClickDraft,
    selectedPost,
    postPublished,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openEmoji, setOpenEmoji] = useState(false);
  const [condition, setCondition] = useState(false);
  const [images, setImages] = useState("");
  const [allImages, setAllImages] = useState([]);
  const [videos, setVideos] = useState("");
  const [allVideos, setAllVideos] = useState([]);
  const videoRefs = useRef([]);
  const post = useAppData((r) => r.postPublisherSlice.post);

  const [showLinkPopup, setShowLinkPopup] = useState(false);
  const [linkType, setLinkType] = useState("photo"); // 'image' or 'video'
  const [linkUrl, setLinkUrl] = useState("");

  const emojiToggle = () => {
    setShowLinkPopup(false);

    setOpenEmoji(!openEmoji);
  };
  const addEmoji = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);

    dispatch(
      createUpdatePostAction({ ...post, message: post.message + emoji })
    );
  };

  const removeImage = (index) => {
    setAllImages([
      ...allImages.slice(0, index),
      ...allImages.slice(index + 1, allImages.length),
    ]);
    if (allImages.length === 1) {
      setCondition(false);
    }
  };
  const removeVideos = (index) => {
    setAllVideos([
      ...allVideos.slice(0, index),
      ...allVideos.slice(index + 1, allVideos.length),
    ]);

    if (allVideos.length === 1) {
      setCondition(false);
    }
  };
  const removeLink = () => {
    setLinkType("photo");
    setLinkUrl("");
    setShowLinkPopup(false);
    dispatch(
      createUpdatePostAction({
        ...post,
        link: "",
        linkType: "",
        contentType: "feed",
      })
    );
  };

  const imageHandler = async (e) => {
    if (e.target.files.length === 0) return;
    setAllVideos([]);

    const file = e.target.files[0];

    if (
      !file.type.startsWith("image/") ||
      !["image/jpeg", "image/png"].includes(file.type)
    ) {
      dispatch(
        showToastAction({
          message:
            "Invalid file type. Please upload an image in JPG, PNG, or GIF format.",
          type: "error",
        })
      );
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      dispatch(
        showToastAction({
          message:
            "Image size exceeds 2MB limit. Please upload an image with size up to 2MB.",
          type: "error",
        })
      );
      return;
    }

    const S3_BUCKET = "vinodevs3new";
    const REGION = "ap-south-1";

    AWS.config.update({
      accessKeyId: "AKIAWTSP3CRY47CP6MMC",
      secretAccessKey: "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",
      region: REGION,
    });

    const s3 = new AWS.S3();

    try {
      const params = {
        Bucket: S3_BUCKET,
        Key: file.name.replace(/ /g, "_"),
        Body: file,
        ContentType: file.type,
      };

      await s3
        .upload(params)
        .promise()
        .catch((err) => console.log("error on upload", err));

      const s3Url = `https://s3.${AWS.config.region}.amazonaws.com/${params.Bucket}/${params.Key}`;

      // const s3Url = `https://${S3_BUCKET}.${s3Endpoint}/${file.name}`;

      // Update the state with the S3 URL
      setImages(s3Url);
      setCondition(true);
      allImages.push(s3Url);

      // Use the S3 URL in your link
      dispatch(
        createUpdatePostAction({
          ...post,
          link: s3Url,
          linkType: "local",
          contentType: "photo",
        })
      );
    } catch (error) {
      console.error("Error handling image:", error);
      // Handle error if needed
    }
  };
  const videoHandler = async (e) => {
    setOpenEmoji(false);
    setShowLinkPopup(false);
    if (e.target.files.length === 0) return;
    setAllImages([]);

    const file = e.target.files[0];

    if (!file.type.startsWith("video/") || file.type !== "video/mp4") {
      dispatch(
        showToastAction({
          message: "Invalid file type. Please upload a video in MP4 format.",
          type: "error",
        })
      );
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      dispatch(
        showToastAction({
          message:
            "Video size exceeds 5MB limit. Please upload a video with size up to 5MB.",
          type: "error",
        })
      );
      return;
    }

    const S3_BUCKET = "vinodevs3new";
    const REGION = "ap-south-1";

    AWS.config.update({
      accessKeyId: "AKIAWTSP3CRY47CP6MMC",
      secretAccessKey: "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",
      region: REGION,
    });

    const s3 = new AWS.S3();

    try {
      const params = {
        Bucket: S3_BUCKET,
        Key: file.name.replace(/ /g, ""),
        Body: file,
        ContentType: file.type,
      };

      await s3
        .upload(params)
        .promise()
        .catch((err) => console.log("error on upload", err));

      const s3Url = `https://s3.${AWS.config.region}.amazonaws.com/${params.Bucket}/${params.Key}`;

      setVideos(s3Url);
      setCondition(true);
      allVideos.push(s3Url);

      dispatch(
        createUpdatePostAction({
          ...post,
          link: s3Url,
          linkType: "local",
          contentType: "video",
        })
      );
    } catch (error) {
      console.error("Error handling video:", error);
    }
  };
  const playVideo = (videoRef) => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };
  const toggleLinkPopup = (type) => {
    setOpenEmoji(false);
    setShowLinkPopup(!showLinkPopup);
    setLinkType(type);
    setLinkUrl("");
  };

  const submitLink = () => {
    dispatch(
      createUpdatePostAction({
        ...post,
        link: linkUrl,
        linkType: "remote",
        contentType: linkType,
      })
    );
    setShowLinkPopup(false);
  };

  useEffect(() => {
    if (
      selectedPost &&
      selectedPost.postDetails &&
      selectedPost.postDetails.length > 0
    ) {
      if (selectedPost?.postDetails[0]?.contentType === "photo") {
        const s3Url = selectedPost?.postDetails[0]?.link;

        setImages(s3Url);
        setCondition(true);
        allImages.push(s3Url);
      }

      if (
        selectedPost?.postDetails[0]?.contentType === "reels" ||
        selectedPost?.postDetails[0]?.contentType === "video"
      ) {
        const s3Url = selectedPost?.postDetails[0]?.link;

        setVideos(s3Url);
        setCondition(true);
        allVideos.push(s3Url);
      }

      dispatch(
        createUpdatePostAction({
          link: selectedPost?.postDetails[0].link,
          linkType: selectedPost?.postDetails[0].linkType,
          contentType: selectedPost?.postDetails[0].contentType,
          message: selectedPost?.postDetails[0].message,
        })
      );
    }
  }, [selectedPost]);

  const handleSchedulePost = async () => {
    SchedulePost && (await SchedulePost());
  };

  const handlePublishNow = async () => {
    onClickPublishNow && (await onClickPublishNow());
    
  };

  const handleDraftPost = async () => {
    onClickDraft && (await onClickDraft());
  };

  useEffect(() => {
    if (postPublished) {
      setImages();
      setVideos();
      setAllImages([]);
      setAllVideos([]);
    }
  }, [postPublished]);

  useEffect(() => {
    if (allImages.length === 0) {
      dispatch(
        createUpdatePostAction({
          ...post,
          link: "",
          linkType: "",
          contentType: "feed",
        })
      );
    }

    if (allVideos.length === 0) {
      dispatch(
        createUpdatePostAction({
          ...post,
          link: "",
          linkType: "",
          contentType: "feed",
        })
      );
    }
  }, [allImages, allVideos]);

  return (
    <div className={"message_Box " + props.className}>
      {openEmoji && (
        <div className="emoj">
          <Picker data={data} onEmojiSelect={addEmoji} />
        </div>
      )}
      <div className="scroll">
        {allImages.map((element, index) => {
          return (
            <div key={index}>
              <CloseButton
                className="close icon-close"
                variant="white"
                onClick={() => removeImage(index)}
              />
              <img
                id={index}
                src={element}
                alt="...."
                width="71"
                height="70"
                style={{ borderRadius: "4px", marginBottom: "6px" }}
              />
            </div>
          );
        })}
        {allVideos.map((element, index) => (
          <div key={index}>
            <CloseButton
              className="close icon-close"
              variant="white"
              style={{ zIndex: 999 }}
              onClick={() => removeVideos(index)}
            />
            <video
              ref={(videoRef) => {
                videoRefs.current[index] = videoRef;
              }}
              width="70"
              height="70"
              controls
              onClick={() => playVideo(videoRefs.current[index])}
            >
              <source src={element} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        ))}
      </div>
      <div className="message_Inner">
        <div className="close_Box" onClick={onClickDownArrow}>
          <i className="icon-downarrow"></i>
        </div>

        <div className="form">
          {showLinkPopup && (
            <div className="link-popup">
              <div className="link-options">
                <input
                  type="radio"
                  id="imageOption"
                  name="linkType"
                  value="photo"
                  checked={linkType === "photo"}
                  onChange={() => setLinkType("photo")}
                />
                <label htmlFor="imageOption">Photo</label>

                <input
                  type="radio"
                  id="videoOption"
                  name="linkType"
                  value="video"
                  checked={linkType === "video"}
                  onChange={() => setLinkType("video")}
                />
                <label htmlFor="videoOption">Video</label>
              </div>
              <input
                type="text"
                placeholder={`Paste ${
                  linkType === "photo" ? "Photo" : "Video"
                } URL`}
                value={linkUrl}
                onChange={(e) => setLinkUrl(e.target.value)}
              />
              <button onClick={submitLink}>Submit</button>
            </div>
          )}
          {linkUrl && (
            <div className="link-container">
              <label className="link-label">{linkUrl}</label>
              <i className="icon-close" onClick={() => removeLink()}></i>
            </div>
          )}
          <textarea
            className="textArea"
            value={post?.message}
            // value={input}
            // onChange={(e) => setInput(e.target.value)}
            onChange={(e) =>
              dispatch(
                createUpdatePostAction({ ...post, message: e.target.value })
              )
            }
            placeholder="What’s in your mind"
            rows={5}
            cols={5}
          />

          <div className="bottom_Bar">
            <div
              className="icons_Section"
              tourselectorIcons={tourselectorIcons}
            >
              <label htmlFor="icon_Link" onClick={emojiToggle}>
                <i className="icon-smile"></i>
              </label>

              <label htmlFor="file-input">
                <i className="icon-image"></i>
              </label>
              <input
                id="file-input"
                type="file"
                multiple
                onChange={imageHandler}
                accept=".jpg, .jpeg, .png"
                hidden
              />

              <label htmlFor="file-input1">
                <i className="icon-video"></i>
              </label>
              <input
                id="file-input1"
                type="file"
                multiple
                onChange={videoHandler}
                accept=".mp4"
                hidden
              />

              <label onClick={() => toggleLinkPopup("photo")}>URL</label>

              {/* <Link className="icon_Link" to="#">
                <i className="icon-location"></i>
              </Link>
              <Link className="icon_Link" to="#">
                <i className="icon-tag"></i>
              </Link> */}
              <Link className="icon_Link" to="#">
                <i className="icon-gif"></i>
              </Link>
            </div>
            <div className="bottom_button">
              <CustomButtom
                iconClass="icon-timer"
                iconShow="true"
                title={t("PUBLISHER_SCHEDULE")}
                onClick={() => handleSchedulePost()}
                tourselectorschedulebtn={tourselectorschedulebtn}
              ></CustomButtom>
              <CustomButtom
                title={t("PUBLISHER_PUBLISH_BUTTON")}
                onClick={() => handlePublishNow()}
                tourselectorpublishbtn={tourselectorpublishbtn}
              ></CustomButtom>
              <CustomButtom
                iconClass="icon-file"
                iconShow="true"
                title={t("PUBLISHER_DRAFT_BUTTON")}
                onClick={() => handleDraftPost()}
                tourselectorpublishbtn={tourselectorpublishbtn}
              ></CustomButtom>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageBox;
