import React from "react";
import Tour from "reactour";
import "./GuideTour.scss";

const GuidedTour = (props) => {
  let { steps, isOpen, onRequestClose } = props;
  const accentColor = "#16056B";

  return (
    <>
      <Tour
        onRequestClose={onRequestClose}
        steps={steps}
        isOpen={isOpen}
        rounded={5}
        accentColor={accentColor}
      />
    </>
  );
};

export default GuidedTour;

// Refecence
// https://codesandbox.io/s/6z56m8x18k?module=/App.js&file=/App.js:141-169
// https://codesandbox.io/s/6z56m8x18k?module=/App.js&file=/App.js:141-169
// https://www.nickelled.com/blog/product-tours-in-reactjs/
// node version in local : v16.10.0
