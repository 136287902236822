import React from 'react';

const TwitterLogin = () => {
  const popupWidth = 700;
  const popupHeight = 700;

  const handleTwitterAddClick = async () => {
    try {
      const currentUser = localStorage.getItem('userId');
      const bearerToken = localStorage.getItem('token');

      const requestBody = {
        user: currentUser,
      };

      const response = await fetch(
        'http://localhost:3003/v1/operations/twitter/account',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearerToken}`,
          },
          body: JSON.stringify(requestBody),
        },
      );
      const data = await response.json();

      const left = window.innerWidth / 2 - popupWidth / 2;
      const top = window.innerHeight / 2 - popupHeight / 2;
      window.open(
        data.data.url,
        'Twitter Authentication',
        `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`,
      );
    } catch (error) {
      console.error('Error fetching Twitter auth URL:', error);
    }
  };

  return (
    <div>
      <i className="icon-plus" onClick={handleTwitterAddClick}></i>
    </div>
  );
};

export default TwitterLogin;
