import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
// import { getAllBlogAction } from "./Redux_saga/postPublisherSlice";
import Loader from "./components/Loader/Loader";
import Toasts from "./components/Toasts/toasts";
import routes, {
  PrivateAdminRoute,
  PrivateUserRoute,
  privateAdminRoutes,
  privateUserRoutes,
} from "./routes";
import { useAppData } from "./services/hooks";

function App() {
  const toastValue = useAppData((r) => r.globalSlice.toast);

  // Why blog API is called in APP.js ?

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   const payloadData = {
  //     token:
  //       "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2M2UzZDQxMjA3NDgwYjE4ZmEwMmViNjEiLCJpYXQiOjE2NzU5MDE1NjUsImV4cCI6MTY4MTA4NTU2NSwidHlwZSI6ImFjY2VzcyJ9.iRGUPcBS4FjAL0YuJNfL-UyHE5uV6zMaVZElXSlTHYE",
  //   };
  //   dispatch(getAllBlogAction(payloadData));
  // }, []);
  return (
    <div className="App">
      <Toasts
        header={toastValue.type}
        message={toastValue.message}
        type={toastValue.type}
        isVisible={toastValue.isVisible}
      />
      <Loader />
      <BrowserRouter>
        <Switch>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            );
          })}
          {privateUserRoutes.map((route, index) => {
            return (
              <PrivateUserRoute
                key={index}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            );
          })}
          {privateAdminRoutes.map((route, index) => {
            return (
              <PrivateAdminRoute
                key={index}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            );
          })}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
