import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useHistory, withRouter } from "react-router-dom";
import HookForm from "../../components/HookForm/HookForm";
import Constant from "../../util/constant";
import CustomButton from "../UI/CustomButton/CustomButton";
import ModalPopup from "../UI/ModalPopup/ModalPopup";
import TextField from "../UI/TextField/TextField";
import "./HeaderLogin.scss";

const contactForm = {
  name: {
    name: "name",
    validate: {
      required: {
        value: true,
      },
      pattern: {
        value: Constant.REGEX.email,
      },
    },
  },
  email: {
    name: "email",
    validate: {
      required: {
        value: true,
      },
    },
  },
};
const Header = (props) => {
  const history = useHistory();

  let activeMenu = "";
  if (props.location.pathname.indexOf("pricing") != -1) {
    activeMenu = "pricing";
  } else if (props.location.pathname.indexOf("features") != -1) {
    activeMenu = "features";
  } else if (props.location.pathname.indexOf("publish") != -1) {
    activeMenu = "publish";
  } else if (props.location.pathname.indexOf("blog") != -1) {
    activeMenu = "blog";
  } else if (props.location.pathname.indexOf("support") != -1) {
    activeMenu = "support";
  }

  // const [showTimeConf, setShowTimeConf] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const onFormSubmit = () => {};

  const handleClick = (page) => {
    if (page === "subscriptionpage") {
      history.push("./subscriptionpage");
    } else if (page === "featurePage") {
      history.push("./featurePage");
    } else if (page === "support") {
      history.push("./support");
    }
  };

  //  useEffect(() => {
  //   const payloadData = {
  //     token:
  //       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2M2UzZDQxMjA3NDgwYjE4ZmEwMmViNjEiLCJpYXQiOjE2NzU5MDE1NjUsImV4cCI6MTY4MTA4NTU2NSwidHlwZSI6ImFjY2VzcyJ9.iRGUPcBS4FjAL0YuJNfL-UyHE5uV6zMaVZElXSlTHYE',
  //   };
  //   dispatch(getAllBlogAction(payloadData));
  // }, []);
  return (
    <div className="headerLogin">
      <Navbar>
        <Container>
          <Navbar.Brand href="#home">
            <span>Post </span>Publisher
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="menuItem" activeKey={activeMenu}>
              <Nav.Link
                eventKey="pricing"
                as={Link}
                to="#"
                // onClick={() => setShowDemoModal(!showDemoModal)}
                // onClick={() => setShowTimeConf(!showTimeConf)}
                // onClick={() => setShowContactForm(!showTimeConf)}
              >
                Demo
              </Nav.Link>
              <Nav.Link
                eventKey="pricing"
                as={Link}
                to="#"
                onClick={() => handleClick("subscriptionpage")}
              >
                Pricing
              </Nav.Link>
              <Nav.Link
                eventKey="features"
                as={Link}
                to="#"
                onClick={() => handleClick("featurePage")}
              >
                Features
              </Nav.Link>
              <Nav.Link eventKey="blog" as={Link} to="/bloglist">
                Blog
              </Nav.Link>
              <Nav.Link
                eventKey="support"
                as={Link}
                to="#"
                onClick={() => handleClick("support")}
              >
                Support
              </Nav.Link>
              <div className="button_Link">
                <Nav.Link href="/login">
                  <CustomButton title="Sign In"></CustomButton>
                </Nav.Link>
                <Nav.Link href="/signup">
                  <CustomButton
                    title="Sign Up"
                    className="submitBtn btn btn-primary signUp"
                  ></CustomButton>
                </Nav.Link>
              </div>
              {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* <ModalPopup
            showModal={showDemoModal}
            onHide={() =>
               setShowDemoModal(false)}
            className='demo_Modal'
            headerTitle=''
         >
            <div className='demo_Modalpopup'>
               <div className='main_Section d-flex'>
                  <div className='left_Section'>
                     <Label title='Post Publisher Demo'></Label>
                     <img src={CalendarImg} alt="image"  
 
style={{ maxWidth: "100%", height: "auto" }} className='main_Top' />
                     <span className='d-flex align-items-center'>
                        <i className='icon-timer'></i>
                        <Label className='time' title='30 mins'></Label>
                     </span>
                     <Label className='timeZone' title='Times displayed are in your local timezone.'></Label>
                  </div>
                  <div className='right_Section'>
                     <Label title='Select a Date & Time'></Label>
                     <div>
                        <DateTimePicker onChange={onChange} value={value} />
                     </div>
                  </div>
               </div>
            </div>
         </ModalPopup> */}
      {/* <ModalPopup
            showModal={showTimeConf}
            onHide={() => setShowTimeConf(false)}
            className='time_Confirm_Modal'
            headerTitle=''
         >
            <div className='conf_Modalpopup'>
               <Label className='msg' title='Confirm date and time for scheduling meeting on '></Label>
               <Label className='dateTime d-flex justify-content-center' title='11 October 2022 (10:00 O’Clock)'></Label>
               <div className='d-flex footer_Modal justify-content-center'>
                  <CustomButton title='Confirm'></CustomButton>
                  <CustomButton title='Cancel'></CustomButton>
               </div>
            </div>
         </ModalPopup> */}
      <ModalPopup
        showModal={showContactForm}
        onHide={() => setShowContactForm(false)}
        className="contact_Modal demo_ModalPopup"
        headerTitle=""
      >
        <div className="form_Modalpopup">
          <HookForm defaultForm={{}} onSubmit={onFormSubmit}>
            {(formMethod) => {
              return (
                <div className="contact_Form">
                  <TextField
                    formMethod={formMethod}
                    name={contactForm.name.name}
                    placeholder="Name"
                    // iconClass='icon-user'
                    type="text"
                  />
                  <TextField
                    formMethod={formMethod}
                    name={contactForm.email.name}
                    autoFocus={true}
                    type="text"
                    placeholder="Email*"
                    // iconClass='icon-mail'
                  />

                  <TextField
                    formMethod={formMethod}
                    name={contactForm.name.name}
                    placeholder="Contact No"
                    type="text"
                  />
                  <TextField
                    formMethod={formMethod}
                    name={contactForm.name.name}
                    placeholder="Company name"
                    type="text"
                  />
                  <TextField
                    formMethod={formMethod}
                    name={contactForm.name.name}
                    placeholder="Any Specific Question Points you’d like to discuss during the demo? "
                    type="text"
                  />
                  <div className="d-flex footer_Modal justify-content-center">
                    <CustomButton title="Submit"></CustomButton>
                    <CustomButton title="Cancel"></CustomButton>
                  </div>
                </div>
              );
            }}
          </HookForm>
        </div>
      </ModalPopup>
    </div>
  );
};

export default withRouter(Header);
