import React from "react";
import "./PaginationSystem.scss";
import Pagination from "react-bootstrap/Pagination";

const PaginationSystem = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const renderPaginationItems = () => {
    const items = [];

    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <div className="paginationClass">
    <Pagination>
      {totalPages > 1 && (
        <>
          <Pagination.First onClick={() => handlePageChange(1)} />
        </>
      )}
      <Pagination.Prev
        onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
      />

      {renderPaginationItems()}

      <Pagination.Next
        onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
      />
      {totalPages > 1 && (
        <>
          <Pagination.Last onClick={() => handlePageChange(totalPages)} />
        </>
      )}
    </Pagination>
    </div>
  );
};

export default PaginationSystem;
