import React from "react";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import AdminHelp from "../AdminHelp/AdminHelp";
import AdminSubscriber from "../AdminSubscriber/AdminSubscriber";
import AdminUnsubscriber from "../AdminUnsubscriber/AdminUnsubscriber";

const AdminQueries = () => {
  let search = window.location.search;
  let params = search.split("?")[1];
  return (
    <div>
      <AdminHeader />
      {params === "subscribers" ? (
        <AdminSubscriber />
      ) : params === "unsubscribers" ? (
        <AdminUnsubscriber />
      ) : params === "supports" ? (
        <AdminHelp />
      ) : (
        <AdminSubscriber />
      )}
    </div>
  );
};

export default AdminQueries;
