import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router-dom";
import { GroupTabListingJsonData } from "../../ConstantJson/ConstantData";
import GroupHeader from "../../components/GroupHeader/GroupHeader";
import Header from "../../components/Header/Header";
import PaginationSystem from "../../components/Pagination/PaginationSystem";
import TabListing from "../../components/TabListing/TabListing";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import Label from "../../components/UI/Label/Label";
import ModalPopup from "../../components/UI/ModalPopup/ModalPopup";
import SearchBox from "../../components/UI/SearchBox/SearchBox";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import FilterForm from "../Publish/Filter/FilterForm";
import PopupOne from "../SocialProfilePopup_One/PopupOne";
import { groupSteps } from "../Steps";
import "./../../assests/scss/allModalPopup.scss";
import "./Group.scss";
import { useAppData } from "../../services/hooks";
import {
  fetchGroupAction,
  updateGroupsAction,
} from "../../Redux_saga/postPublisherSlice";
import { useDispatch } from "react-redux";

const GroupScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const dimensions = {
    height: window.innerHeight,
    width: window.innerWidth,
  };
  const { t } = useTranslation();
  const [showCreateQueryModal, setShowCreateQueryModal] = useState(false);

  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const messageboxHeight = 0;
  const [showTab, setShowTab] = useState(false);
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();
  const UserGroups = useAppData((r) => r.postPublisherSlice?.groups);
  const Token = useAppData((r) => r.postPublisherSlice?.tokens);
  const User = useAppData((r) => r.postPublisherSlice?.user);

  const [userGroups, setUserGroups] = useState([]);
  const [filteredUserGroups, setFilteredUserGroups] = useState([]);
  const [paginatedUserGroups, setPaginatedUserGroups] = useState([]);
  const [currentPageUserGroup, setCurrentPageUserGroup] = useState(1);

  const itemsPerPage = 5;

  useEffect(() => {
    //if ((User && Token) || redirectedFrom) {
    const data = {
      user: User?._id,
      token: Token?.access?.token,
    };
    dispatch(fetchGroupAction(data));
    //}
  }, [dispatch, User, Token]);

  const handlePageChange = (page) => {
    setCurrentPageUserGroup(page);
  };

  useEffect(() => {
    const fetchPostData = async () => {
      if (UserGroups.length > 0) {
        setUserGroups([...UserGroups]);
        setFilteredUserGroups([...UserGroups]);
      } else {
        setUserGroups([]);
        setFilteredUserGroups([]);
      }
    };

    fetchPostData();
  }, [UserGroups]);

  const handleSearch = (query) => {
    const grps = userGroups.filter(
      (grp) =>
        grp.groupTitle.toLowerCase().includes(query.toLowerCase()) ||
        grp.groupDescription.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredUserGroups(grps);
  };

  useEffect(() => {
    if (filteredUserGroups) {
      const startPage = (currentPageUserGroup - 1) * itemsPerPage;
      const endPage = currentPageUserGroup * itemsPerPage;
      const paginatedUgrp = filteredUserGroups.slice(startPage, endPage);

      setPaginatedUserGroups(paginatedUgrp);
    }
  }, [filteredUserGroups, currentPageUserGroup, itemsPerPage]);

  const OnTabClick = () => {
    setShowTab(!showTab);
  };

  const handleClick = () => {
    history.push("./creategroup");
  };

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObj")
    );
    let newObj = { ...previousLocalStorageValue, group: false };
    localStorage.setItem("guideTourObj", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(localStorage.getItem("guideTourObj"));

  const handleFilterChange = (selectedStatus) => {
    let ugrps = [];
    if (!selectedStatus) {
      ugrps = [...UserGroups];
    } else {
      if (+selectedStatus.id === 1 || +selectedStatus.id === 2) {
        const isActive = selectedStatus.id === "2";
        ugrps = userGroups.filter((profile) => profile.isActive === isActive);
      } else if (+selectedStatus.id === 3 || +selectedStatus.id === 4) {
        const isPaused = selectedStatus.id === "3";
        ugrps = userGroups.filter((profile) => profile.isPaused === isPaused);
      }
    }

    setFilteredUserGroups(ugrps);
  };

  const handlePlayPauseClick = async (isPaused, id) => {
    console.log("isPaused isPaused", isPaused);
    if (Token) {
      const requestData = {
        groupId: id,
        token: Token?.access?.token,

        payload: {
          isPaused,
        },
      };

      dispatch(updateGroupsAction(requestData));
    }
  };

  const handleDisconnectClick = async (id) => {
    if (Token) {
      const requestData = {
        groupId: id,
        token: Token?.access?.token,

        payload: {
          isActive: false,
        },
      };

      dispatch(updateGroupsAction(requestData));
    }
  };

  return (
    <div className="group_Page">
      <Header></Header>
      <GuidedTour
        steps={groupSteps}
        onRequestClose={handleOnRequestClose}
        isOpen={guideTourGetItemValue ? guideTourGetItemValue.group : true}
      />

      <div className="main_Container">
        <ModalPopup
          showModal={showCreateQueryModal}
          onHide={() => setShowCreateQueryModal(false)}
          className="profilePopup"
          headerTitle={<PopupOne />}
          closeIcon={false}
        ></ModalPopup>
        <div className="group_Inner">
          <div className="tabSearch_section">
            <Scrollbars
              style={{
                height: dimensions.height - messageboxHeight - 60 + "px",
              }}
            >
              <i className="icon-tab tabFilerIcon" onClick={OnTabClick}></i>
              <Label className="main_Heading" title={t("GROUP_HEAD")}></Label>
              <Label className="inner_Text" title={t("GROUP_EASILY")}></Label>
              <div className="tab_Search">
                <div className="tabs_Section">
                  <SearchBox
                    placeholder={t("GROUP_SEARCH")}
                    onSearch={handleSearch}
                  />

                  <div className="search_Filter">
                    <div className="filterBtn">
                      <button
                        className="filterIcon"
                        onClick={() =>
                          setShowFilterDropdown(!showFilterDropdown)
                        }
                      >
                        <i className="icon-filter">
                          {" "}
                          <span>{t("GROUP_FILTER")}</span>
                        </i>
                      </button>
                    </div>
                    {showFilterDropdown && (
                      <>
                        <div
                          className="overLay"
                          onClick={() => setShowFilterDropdown(false)}
                        ></div>
                        <FilterForm
                          title="Filter"
                          onClickIconClose={() => setShowFilterDropdown(false)}
                          onFilterChange={handleFilterChange}
                        ></FilterForm>
                      </>
                    )}
                    <CustomButton
                      title={t("GROUP_CREATE_BUTTON")}
                      onClick={() => {
                        handleClick();
                      }}
                      tourselector="create-grp-btn"
                    ></CustomButton>
                  </div>
                </div>
                <div className="preview_Section main_TabEvent">
                  <Tabs>
                    <Tab title="Group" eventKey="Group">
                      <div className="tab_Section">
                        <GroupHeader></GroupHeader>

                        {paginatedUserGroups.map((e, index) => {
                          return (
                            <div key={index}>
                              <TabListing
                                onProfileClick={() => {}}
                                ProfileImg={e.profilePicture}
                                userName={e.groupTitle}
                                accType={e.groupDescription}
                                statusTitle={
                                  e.isActive ? (
                                    <p style={{ width: "150px" }}>
                                      {e.isPaused
                                        ? " Connected - Paused"
                                        : " Connected - Active"}
                                    </p>
                                  ) : (
                                    <p>Disconnected</p>
                                  )
                                }
                                id={e.id}
                                DatePick={new Date(
                                  e.createdAt
                                ).toLocaleString()}
                                icon={"yes"}
                                onPlayPauseClick={(isPaused) =>
                                  handlePlayPauseClick(isPaused, e._id)
                                }
                                isPaused={e.isPaused}
                                isConnected={e.isActive}
                                onDisconnectClick={() =>
                                  handleDisconnectClick(e._id)
                                }
                              ></TabListing>
                            </div>
                          );
                        })}
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
      <PaginationSystem
        currentPage={currentPageUserGroup}
        totalPages={Math.ceil(filteredUserGroups.length / itemsPerPage)}
        onPageChange={(page) => handlePageChange(page)}
      />
    </div>
  );
};

export default withRouter(GroupScreen);
