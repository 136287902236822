import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import "react-datepicker/dist/react-datepicker.css";
// import { Link } from "react-router-dom";
import DropdownGroup from "../../../components/DropdownGroup/DropdownGroup";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import CustomCalendar from "../../../components/UI/CustomCalendar/CustomCalendar";
import FilterDropdown from "../../../components/UI/FilterDropdown/FilterDropdown";
import Label from "../../../components/UI/Label/Label";
import SearchBox from "../../../components/UI/SearchBox/SearchBox";
import "./FilterForm.scss";

const FilterForm = (props) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const { title, onClick } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showGroupDropdown, setShowGroupDropdown] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");

  const [bottomHeight, setBottomHeight] = useState(0);
  // // console.log(bottomHeight);
  useEffect(() => {
    setBottomHeight(
      document.getElementsByClassName("custombtnfield")[0].offsetHeight
    );
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <>
      <FilterDropdown className="schedule_Form">
        <div className="filter_Section">
          <div className="form">
            <Scrollbars style={{ height: dimensions.height - 300 + "px" }}>
              <div className="filterItem">
                <div className="header_Section">
                  <div className="heading">
                    <Label title={title}></Label>
                    <i className="icon-close" onClick={onClick}></i>
                  </div>
                </div>
                <div className="calender_Section">
                  <div className="date_Filter">
                    <Label
                      className="date_Title"
                      title="Filter by date"
                    ></Label>
                    {/* <Link
                      className="filter_Title"
                      to="#"
                      onClick={() => {
                        props.onClear();
                        setShowGroupDropdown(false);
                      }}
                    >
                      Clear all filter
                    </Link> */}
                  </div>
                  <div className="calenderMain fromDate">
                    <CustomCalendar
                      selected={startDate}
                      onChange={(e) => setStartDate(e)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Date"
                      renderCustomHeader={true}
                    ></CustomCalendar>
                  </div>
                  <div className="calenderMain">
                    <CustomCalendar
                      selected={endDate}
                      onChange={(e) => setEndDate(e)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Date"
                      renderCustomHeader={true}
                    ></CustomCalendar>
                  </div>
                </div>
                {/* <div className="dropdown_Section">
                  <Label
                    className="filter_Title"
                    title="Filter by Status"
                  ></Label>

                  <div
                    className="search_Icon"
                    onClick={() => setShowGroupDropdown(!showGroupDropdown)}
                  >
                    <SearchBox placeholder="Select facebook group and pages"></SearchBox>
                    <i className="icon-downarrow"></i>
                  </div>

                  {showGroupDropdown && (
                    <>
                      <div onClick={() => setShowGroupDropdown(false)}></div>
                      <DropdownGroup title="Filter"></DropdownGroup>
                    </>
                  )}
                </div> */}
              </div>
            </Scrollbars>
            <div className="custombtnfield">
              <CustomButton
                type="submit"
                title="Apply"
                onClick={() => {
                  props.onFilter(
                    startDate,
                    endDate,
                    selectedStatus,
                    selectedGroup
                  );
                  setShowGroupDropdown(false); // Close the dropdown
                }}
              />
              <CustomButton
                type="button"
                title="Clear"
                onClick={() => {
                  props.onClear();
                  setShowGroupDropdown(false);
                }}
              />
            </div>
          </div>
        </div>
      </FilterDropdown>
    </>
  );
};

export default FilterForm;
