import React, { useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { Link, useHistory, withRouter } from "react-router-dom";
import { CalendarListJsonData } from "../../ConstantJson/ConstantData";
import PreviewImg from "../../assests/images/preview.png";
import PreviewLogo from "../../assests/images/previewlogo.png";
import BigCalendar from "../../components/BigCalendar/BigCalendar";
import Header from "../../components/Header/Header";
import TabHeader from "../../components/TabHeader/TabHeader";
import TabListing from "../../components/TabListing/TabListing";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import CustomCalendar from "../../components/UI/CustomCalendar/CustomCalendar";
import Label from "../../components/UI/Label/Label";
import ModalPopup from "../../components/UI/ModalPopup/ModalPopup";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { calendarListSteps, calendarSteps } from "../Steps";
import "./CalendarEvent.scss";
import Listing from "./Listing";

const CalendarEvent = () => {
  let search = window.location.search;
  let params = search.split("?")[1];

  const [socialMedia, setSocialMedia] = useState("customgroup");

  useEffect(() => {
    switch (params) {
      case "facebook":
        setSocialMedia("Facebook");
        break;
      case "twitter":
        setSocialMedia("Twitter");
        break;
      case "linkedin":
        setSocialMedia("LinkedIn");
        break;
      case "instagram":
        setSocialMedia("Instagram");
        break;
      case "youtube":
        setSocialMedia("Youtube");
        break;
      case "tiktok":
        setSocialMedia("TikTok");
        break;
      default:
        setSocialMedia("Group");
        break;
    }
  }, [params]);

  return (
    <div>
      <Header></Header>
      {params === "customgroup" ? (
        <Listing socialMedia={socialMedia}/>
      ) : params === "facebook" ? (
        <Listing socialMedia={socialMedia}/>
      ) : params === "twitter" ? (
        <Listing socialMedia={socialMedia}/>
      ) : params === "linkedin" ? (
        <Listing socialMedia={socialMedia}/>
      ) : params === "instagram" ? (
        <Listing socialMedia={socialMedia}/>
      ) : params === "youtube" ? (
        <Listing socialMedia={socialMedia}/>
      ) : params === "tiktok" ? (
        <Listing socialMedia={socialMedia}/>
      ) : (
        <Listing socialMedia={socialMedia}/>
      )}
    </div>
  );
};

export default withRouter(CalendarEvent);
