import React from "react";
import profile from "../../../assests/images/profile.png";
import ProfileImg from "../../../assests/images/tab.png";
import "./TeamTabHeader.scss";

const teamJsonData = [
  {
    image: ProfileImg,
    userName: "John Peter",
    Email: "johnpeter99@gmail.com",
    Role: "Manager",
    Status: "Pending",
    resendMailIcon: "",
    editIcon: "",
    deleteIcon: "",
  },
  {
    image: profile,
    userName: "John Peter",
    Email: "johnpeter99@gmail.com",
    Role: "Manager",
    Status: "Joined",
    resendMailIcon: "",
    editIcon: "",
    deleteIcon: "",
  },
];

const TeamTabHeader = (props) => {
  let { handleClickEmail, handleEditClick } = props;

  return (
    <div className="table-responsive-lg team-tab-parent">
      <table className="table team-table-wrapper">
        <thead className="table-header">
          <tr className="brd">
            <th>Profile</th>
            <th>Name</th>
            <th>Email Id</th>
            <th>Role</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="listing-main">
          {teamJsonData.map((e, index) => {
            return (
              <tr key={index}>
                <td>
                  <img
                    className="profile-image"
                    src={e.image}
                    alt="profile"
                  ></img>
                </td>
                <td>{e.userName}</td>
                <td className="email" onClick={handleClickEmail}>
                  {e.Email}
                </td>
                <td>{e.Role}</td>
                <td>{e.Status}</td>
                <td>
                  <div className="icons">
                    <i className="icon-mail" style={{ paddingRight: 16 }}></i>
                    <span className="hover-text">Resend Mail</span>
                    <i className="icon-edit1" onClick={handleEditClick}></i>
                    <span className="hover-text">Update Role</span>
                    <i className="icon-delete" style={{ paddingLeft: 16 }}></i>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TeamTabHeader;
