import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { postVerifyOtpAction } from "../../Redux_saga/postPublisherSlice";
import MainImg from "../../assests/images/main-img.png";
import PatterBottom from "../../assests/images/patten-btm.png";
import PatterTop from "../../assests/images/patten-top.png";
import HookForm from "../../components/HookForm/HookForm";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import Label from "../../components/UI/Label/Label";
import { useAppData } from "../../services/hooks";
import GuidedTour from "../GuideTour/GuidedTour";
import { verifySteps } from "../Steps";
import "./VerifyMail.scss";

// const VerifyForm = {
//   otp: {
//     name: 'otp',
//     validate: {
//       required: {
//         value: true,
//         message: Message.ERRORMESSAGE.PASSWORDEMPTY,
//       },
//     },
//   },
// };

function VerifyMail() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [otp, setOtp] = useState("");
  const [form, setVerifyForm] = React.useState();
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  // console.log(form, isOpenTourGuide);
  const data = useAppData((r) => r.postPublisherSlice.users);
  const verifyOtpResponse = useAppData(
    (r) => r.postPublisherSlice.users.VerifyOtpResponseValue.status
  );
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const onFormSubmit = () => {};

  const onClickVerifyOtp = () => {
    const VerifyOtpData = {
      emailId: params,
      emailOTP: otp,
    };

    dispatch(postVerifyOtpAction(VerifyOtpData));
  };

  const handleChange = (otp) => {
    setOtp(otp);
  };
  // React.useEffect(() => {
  //   onClickVerifyOtp();
  // }, []);
  let search = window.location.search;
  let params = search.split("?")[1];

  useEffect(() => {
    if (verifyOtpResponse === true) {
      let search = window.location.search;
      let params = search.split("?")[1];

      history.push(`/resetPassword?${params}`);
    }
  }, [verifyOtpResponse]);

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObj")
    );
    let newObj = { ...previousLocalStorageValue, verify: false };
    localStorage.setItem("guideTourObj", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  // React.useEffect(() => {
  //   if (isOpenTourGuide != undefined) {
  //     localStorageSetItem(isOpenTourGuide);
  //   }
  // }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(localStorage.getItem("guideTourObj"));
  return (
    <div className="verify_Page" style={{ height: dimensions.height + "px" }}>
      <img src={PatterTop} alt="image" fluid="true" className="top_Patten" />
      <img
        src={PatterBottom}
        alt="image"
        fluid="true"
        className="bottom_Patten"
      />
      <Scrollbars className="scroll_Section">
        <GuidedTour
          steps={verifySteps}
          onRequestClose={handleOnRequestClose}
          isOpen={guideTourGetItemValue ? guideTourGetItemValue.verify : true}
        />
        <div className="center_Section">
          <div className="chatboat-section position-absolute">
            <i className="icon-chatboat"></i>
          </div>
          <div className="img_Section">
            <img src={MainImg} alt="image" fluid="true" className="main_Img" />
          </div>
          <div className="verify_Box" tourselector="verify-form">
            <Label title={t("VERIFY_INPUTBOX_VERIFY_EMAIL")}></Label>
            <div className="inner_text_wrap">
              <Label
                className="inner_Text"
                title={t("VERIFY_INPUTBOX_VERIFY_DIGIT")}
              ></Label>
              <Label
                className="inner_Text text_color"
                title={`${params}`}
              ></Label>
            </div>

            <HookForm
              defaultForm={{}}
              init={(form) => setVerifyForm(form)}
              onSubmit={onFormSubmit}
            >
              {() => {
                return (
                  <div className="form">
                    <div className="otp_box">
                      <OtpInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={4}
                        separator={<span></span>}
                        isInputNum={true}
                      />
                    </div>

                    <CustomButton
                      title="Verify"
                      // onClick={() => onClickVerifyOtp('resetpassword')}
                      onClick={onClickVerifyOtp}
                    ></CustomButton>
                  </div>
                );
              }}
            </HookForm>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}

export default withRouter(VerifyMail);
