import React, { useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { Link, useHistory, withRouter } from "react-router-dom";
import { CalendarListJsonData } from "../../ConstantJson/ConstantData";
import PreviewImg from "../../assests/images/preview.png";
import PreviewLogo from "../../assests/images/previewlogo.png";
import BigCalendar from "../../components/BigCalendar/BigCalendar";
import Header from "../../components/Header/Header";
import TabHeader from "../../components/TabHeader/TabHeader";
import TabListing from "../../components/TabListing/TabListing";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import CustomCalendar from "../../components/UI/CustomCalendar/CustomCalendar";
import Label from "../../components/UI/Label/Label";
import ModalPopup from "../../components/UI/ModalPopup/ModalPopup";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { calendarListSteps, calendarSteps } from "../Steps";
import "./CalendarEvent.scss";
import CustomDropdown from "../../components/UI/CustomDropdown/CustomDropdown";
import Accordion from "react-bootstrap/Accordion";
import noimagepreview from "../../assests/images/noimagepreview.jpg";
import twittericon from "../../assests/images/twittericon.png";
import linkedinicon from "../../assests/images/linkedinicon.png";
import instagramicon from "../../assests/images/instagram.png";
import fbicon from "../../assests/images/fbicon.png";
import youtubeicon from "../../assests/images/youtubeicon.png";
import { FacebookIcon } from "../../components/BlogFeature/BlogIcons";
import { Button, Tab, Tabs } from "react-bootstrap";
import { useAppData } from "../../services/hooks";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import moment from "moment/moment";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const itemsStatus = [
  {
    id: "1",
    value: "Scheduled",
  },
  {
    id: "2",
    value: "Draft",
  },
  {
    id: "3",
    value: "Failed",
  },
  {
    id: "4",
    value: "Published",
  },
];

const Listing = ({ socialMedia }) => {
  console.log(socialMedia, "- social media");
  const { t } = useTranslation();
  const dimensions = {
    height: window.innerHeight,
    width: window.innerWidth,
  };
  const scheduledPosts = useSelector(
    (e) => e?.postPublisherSlice?.scheduledPosts
  );
  console.log(scheduledPosts, "sch");
  const [startDate, setStartDate] = useState(new Date());
  const [showTab, setShowTab] = useState(false);
  const [showEditPost, setEditPost] = useState(false);
  const [showEditPostModal, setShowEditPostModal] = useState(false);
  const [changeTab, setChangeTab] = useState(false);
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();
  const [isOpenTourCalendarList, setIsOpenTourCalendarList] = useState();
  const [selectedItem, setSelectedItem] = useState({
    id: "1",
    value: "Scheduled",
  });
  const history = useHistory();
  const OnTabClick = () => {
    setShowTab(!showTab);
  };

  const handleClickCalendar = (flag) => {
    setChangeTab(flag);
  };

  const handleClickList = () => {
    setChangeTab(true);
    if (changeTab === false) {
      let guideTourGetItemValueQuery = JSON.parse(
        localStorage.getItem("guideTourObj")
      );
      let newObjQuery =
        guideTourGetItemValueQuery.calendarList === "false"
          ? {
              ...guideTourGetItemValueQuery,
              calendarList: true,
            }
          : guideTourGetItemValueQuery;

      localStorage.setItem("guideTourObj", JSON.stringify(newObjQuery));
      setIsOpenTourCalendarList(newObjQuery);
    }
  };

  const handleOnRequestCloseCalendarList = () => {
    let previousLocalStorageValueCalendarList = JSON.parse(
      localStorage.getItem("guideTourObj")
    );
    let newObjQuery = {
      ...previousLocalStorageValueCalendarList,
      calendarList: false,
    };
    localStorage.setItem("guideTourObj", JSON.stringify(newObjQuery));
    setIsOpenTourCalendarList(newObjQuery);
  };

  React.useEffect(() => {
    if (
      isOpenTourCalendarList &&
      isOpenTourCalendarList.calendarList === true
    ) {
      localStorageSetItem(isOpenTourCalendarList);
    }
  }, [isOpenTourCalendarList]);
  let guideTourGetItemValueCalendarList = JSON.parse(
    localStorage.getItem("guideTourObj")
  );

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObj")
    );
    let newObj = { ...previousLocalStorageValue, calendar: false };
    localStorage.setItem("guideTourObj", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(localStorage.getItem("guideTourObj"));

  const dispatch = useDispatch();
  const User = useAppData((r) => r.postPublisherSlice?.user);
  const Token = useAppData((r) => r.postPublisherSlice?.tokens);

  const fetchScheduledPosts = async (fromdt = null, todt = null) => {
    const params = {
      filter: {
        user: User?._id,
        "postDetails.isDraft": false,
        "postDetails.isScheduled": true,
        "postDetails.isPublished": false,
        // "postDetails.isPaused": false,
        isDeleted: false,
        fromdt: fromdt,
        todt: todt,
      },
      token: Token?.access?.token,
    };
    dispatch(fetchScheduledPostAction(params));
  };

  const fetchDraftPosts = async (fromdt = null, todt = null) => {
    const params = {
      filter: {
        user: User?._id,
        "postDetails.isDraft": true,
        "postDetails.isScheduled": false,
        "postDetails.isPublished": false,
        "postDetails.isPaused": false,
        isDeleted: false,
        fromdt: fromdt,
        todt: todt,
      },
      token: Token?.access?.token,
    };
    dispatch(fetchDraftPostAction(params));
  };

  const fetchFailedPosts = async (fromdt = null, todt = null) => {
    const params = {
      filter: {
        user: User?._id,
        "postDetails.isDraft": false,
        "postDetails.isScheduled": false,
        "postDetails.isPublished": false,
        "postDetails.isPaused": false,
        isDeleted: false,
        fromdt: fromdt,
        todt: todt,
      },
      token: Token?.access?.token,
    };
    dispatch(fetchFailedPostAction(params));
  };

  const fetchPublishedPosts = async (fromdt = null, todt = null) => {
    const params = {
      filter: {
        user: User?._id,
        "postDetails.isDraft": false,
        "postDetails.isScheduled": false,
        "postDetails.isPublished": true,
        "postDetails.isPaused": false,
        isDeleted: false,
        fromdt: fromdt,
        todt: todt,
      },
      token: Token?.access?.token,
    };
    await dispatch(fetchPublishedPostAction(params));
  };

  useEffect(() => {
    if (selectedItem == "Scheduled") {
      fetchScheduledPosts();
    }
    if (selectedItem == "Draft") {
      fetchDraftPosts();
    }
    if (selectedItem == "Failed") {
      fetchFailedPosts();
    }
    if (selectedItem == "Published") {
      fetchPublishedPosts();
    }
  }, [selectedItem]);

  const ScheduledPosts = useAppData(
    (r) => r.postPublisherSlice?.scheduledPosts
  );

  const PublishedPosts = useAppData(
    (r) => r.postPublisherSlice?.publishedPosts
  );

  const DraftPosts = useAppData((r) => r.postPublisherSlice?.draftPosts);
  const FailedPosts = useAppData((r) => r.postPublisherSlice?.failedPosts);

  const [facebookPosts, setFacebookPosts] = useState([]);
  const [youtubePosts, setYoutubePosts] = useState([]);
  const [instagramPosts, setInstagramPosts] = useState([]);
  const [linkedInPosts, setLinkedinPosts] = useState([]);
  const [twitterPosts, setTwitterPosts] = useState([]);
  const [userGroupPosts, setUserGroupPosts] = useState([]);

  const [filteredFacebookPosts, setFilteredFacebookPosts] = useState([]);
  const [filteredYoutubePosts, setFilteredYoutubePosts] = useState([]);
  const [filteredInstagramPosts, setFilteredInstagramPosts] = useState([]);
  const [filteredLinkedInPosts, setFilteredLinkedinPosts] = useState([]);
  const [filteredTwitterPosts, setFilteredTwitterPosts] = useState([]);
  const [filteredUserGroupPosts, setFilteredUserGroupPosts] = useState([]);

  const [modalData, setModelData] = useState([
    {
      mediaType: "facebook",
      profileId: "112285518292678",
      postingInto: "Group",
      postIntoId: "65aa975f30f081cd73a945f4",
      contentType: "feed",
      message: "Default",
      link: "",
      linkType: "",
      isPublished: false,
      isDraft: false,
      isScheduled: true,
      isPaused: false,
      scheduledTime: "1709101800000",
      _id: "65dc3b9a50b7f306baf88319",
    },
  ]);
  const [postList, setPostList] = useState([]);

  useEffect(() => {
    const fetchPostData = async () => {
      console.log("selecteditem useeffect ===>", selectedItem);
      if (selectedItem.value == "Scheduled" && ScheduledPosts.length > 0) {
        setFacebookPosts([]);
        setTwitterPosts([]);
        setLinkedinPosts([]);
        setYoutubePosts([]);
        setInstagramPosts([]);
        setUserGroupPosts([]);

        setFilteredFacebookPosts([]);
        setFilteredInstagramPosts([]);
        setFilteredLinkedinPosts([]);
        setFilteredTwitterPosts([]);
        setFilteredYoutubePosts([]);
        setFilteredUserGroupPosts([]);

        let posts = [];
        switch (socialMedia) {
          case "Group":
            posts = ScheduledPosts.filter((post) => post.category === "group");
            console.log("scheduled posts", posts);
            setUserGroupPosts([...posts]);
            break;
          case "Facebook":
            posts = ScheduledPosts.filter((post) =>
              post.postDetails.some((detail) => detail.mediaType === "facebook")
            );
            console.log("scheduled fb posts", posts);

            setFacebookPosts(posts);
            break;
          case "Twitter":
            posts = ScheduledPosts.filter((post) =>
              post.postDetails.some((detail) => detail.mediaType === "twitter")
            );
            console.log("scheduled tw posts", posts);

            setTwitterPosts(posts);
            break;
          case "LinkedIn":
            posts = ScheduledPosts.filter((post) =>
              post.postDetails.some((detail) => detail.mediaType === "linkedin")
            );
            console.log("scheduled lin posts", posts);

            setLinkedinPosts(posts);
            break;
          case "Youtube":
            posts = ScheduledPosts.filter((post) =>
              post.postDetails.some((detail) => detail.mediaType === "youtube")
            );
            console.log("scheduled yt posts", posts);

            setYoutubePosts(posts);
            break;
          case "Instagram":
            posts = ScheduledPosts.filter((post) =>
              post.postDetails.some(
                (detail) => detail.mediaType === "instagram"
              )
            );
            console.log("scheduled inst posts", posts);

            setInstagramPosts(posts);
            break;
          default:
            setUserGroupPosts(posts);

            break;
        }
      } else if (selectedItem.value == "Draft" && DraftPosts.length > 0) {
        setFacebookPosts([]);
        setTwitterPosts([]);
        setLinkedinPosts([]);
        setYoutubePosts([]);
        setInstagramPosts([]);
        setUserGroupPosts([]);

        setFilteredFacebookPosts([]);
        setFilteredInstagramPosts([]);
        setFilteredLinkedinPosts([]);
        setFilteredTwitterPosts([]);
        setFilteredYoutubePosts([]);
        setFilteredUserGroupPosts([]);

        let posts = [];
        switch (socialMedia) {
          case "Group":
            posts = DraftPosts.filter((post) => post.category === "group");
            setUserGroupPosts([...posts]);
            break;
          case "Facebook":
            posts = DraftPosts.filter((post) =>
              post.postDetails.some((detail) => detail.mediaType === "facebook")
            );
            setFacebookPosts(posts);
            break;
          case "Twitter":
            posts = DraftPosts.filter((post) =>
              post.postDetails.some((detail) => detail.mediaType === "twitter")
            );

            setTwitterPosts(posts);
            break;
          case "LinkedIn":
            posts = DraftPosts.filter((post) =>
              post.postDetails.some((detail) => detail.mediaType === "linkedin")
            );

            setLinkedinPosts(posts);
            break;
          case "Youtube":
            posts = DraftPosts.filter((post) =>
              post.postDetails.some((detail) => detail.mediaType === "youtube")
            );

            setYoutubePosts(posts);
            break;
          case "Instagram":
            posts = DraftPosts.filter((post) =>
              post.postDetails.some(
                (detail) => detail.mediaType === "instagram"
              )
            );

            setInstagramPosts(posts);
            break;
          default:
            setUserGroupPosts(posts);

            break;
        }
      } else if (selectedItem.value == "Failed" && FailedPosts.length > 0) {
        setFacebookPosts([]);
        setTwitterPosts([]);
        setLinkedinPosts([]);
        setYoutubePosts([]);
        setInstagramPosts([]);
        setUserGroupPosts([]);

        setFilteredFacebookPosts([]);
        setFilteredInstagramPosts([]);
        setFilteredLinkedinPosts([]);
        setFilteredTwitterPosts([]);
        setFilteredYoutubePosts([]);
        setFilteredUserGroupPosts([]);

        let posts = [];
        switch (socialMedia) {
          case "Group":
            posts = FailedPosts.filter((post) => post.category === "group");
            setUserGroupPosts([...posts]);
            break;
          case "Facebook":
            posts = FailedPosts.filter((post) =>
              post.postDetails.some((detail) => detail.mediaType === "facebook")
            );
            setFacebookPosts(posts);
            break;
          case "Twitter":
            posts = FailedPosts.filter((post) =>
              post.postDetails.some((detail) => detail.mediaType === "twitter")
            );

            setTwitterPosts(posts);
            break;
          case "LinkedIn":
            posts = FailedPosts.filter((post) =>
              post.postDetails.some((detail) => detail.mediaType === "linkedin")
            );

            setLinkedinPosts(posts);
            break;
          case "Youtube":
            posts = FailedPosts.filter((post) =>
              post.postDetails.some((detail) => detail.mediaType === "youtube")
            );

            setYoutubePosts(posts);
            break;
          case "Instagram":
            posts = FailedPosts.filter((post) =>
              post.postDetails.some(
                (detail) => detail.mediaType === "instagram"
              )
            );

            setInstagramPosts(posts);
            break;
          default:
            setUserGroupPosts(posts);

            break;
        }
      } else if (
        selectedItem.value == "Published" &&
        PublishedPosts.length > 0
      ) {
        setFacebookPosts([]);
        setTwitterPosts([]);
        setLinkedinPosts([]);
        setYoutubePosts([]);
        setInstagramPosts([]);
        setUserGroupPosts([]);

        setFilteredFacebookPosts([]);
        setFilteredInstagramPosts([]);
        setFilteredLinkedinPosts([]);
        setFilteredTwitterPosts([]);
        setFilteredYoutubePosts([]);
        setFilteredUserGroupPosts([]);

        let posts = [];
        switch (socialMedia) {
          case "Group":
            posts = [];
            posts = PublishedPosts.filter((post) => post.category === "group");
            setUserGroupPosts([...posts]);
            break;
          case "Facebook":
            posts = [];
            posts = PublishedPosts.filter((post) =>
              post.postDetails.some((detail) => detail.mediaType === "facebook")
            );
            setFacebookPosts(posts);
            break;
          case "Twitter":
            posts = [];
            posts = PublishedPosts.filter((post) =>
              post.postDetails.some((detail) => detail.mediaType === "twitter")
            );

            setTwitterPosts(posts);
            break;
          case "LinkedIn":
            posts = [];
            posts = PublishedPosts.filter((post) =>
              post.postDetails.some((detail) => detail.mediaType === "linkedin")
            );

            setLinkedinPosts(posts);
            break;
          case "Youtube":
            posts = PublishedPosts.filter((post) =>
              post.postDetails.some((detail) => detail.mediaType === "youtube")
            );

            setYoutubePosts(posts);
            break;
          case "Instagram":
            posts = [];
            posts = PublishedPosts.filter((post) =>
              post.postDetails.some(
                (detail) => detail.mediaType === "instagram"
              )
            );

            setInstagramPosts(posts);
            break;
          default:
            posts = [];
            setUserGroupPosts(posts);

            break;
        }
      } else {
        setFacebookPosts([]);
        setTwitterPosts([]);
        setLinkedinPosts([]);
        setYoutubePosts([]);
        setInstagramPosts([]);
        setUserGroupPosts([]);

        setFilteredFacebookPosts([]);
        setFilteredInstagramPosts([]);
        setFilteredLinkedinPosts([]);
        setFilteredTwitterPosts([]);
        setFilteredYoutubePosts([]);
        setFilteredUserGroupPosts([]);
      }
    };

    fetchPostData();
  }, [
    ScheduledPosts,
    socialMedia,
    PublishedPosts,
    DraftPosts,
    FailedPosts,
    selectedItem,
  ]);

  useEffect(() => {
    if (socialMedia) {
      console.log("startDate ==>", startDate);
      setPostList([]);
      switch (socialMedia) {
        case "Group":
          setPostList([...userGroupPosts]);
          break;
        case "Facebook":
          setPostList([
            ...facebookPosts.filter((post) => post.category != "group"),
          ]);

        case "Twitter":
          setPostList([
            ...twitterPosts.filter((post) => post.category != "group"),
          ]);

          break;
        case "Instagram":
          setPostList([
            ...instagramPosts.filter((post) => post.category != "group"),
          ]);

          break;
        case "LinkedIn":
          setPostList([
            ...linkedInPosts.filter((post) => post.category != "group"),
          ]);

          break;
        case "Youtube":
          setPostList([
            ...youtubePosts.filter((post) => post.category != "group"),
          ]);

          break;
        default:
          setPostList([]);
          break;
      }
    }
  }, [
    socialMedia,
    userGroupPosts,
    facebookPosts,
    twitterPosts,
    instagramPosts,
    linkedInPosts,
    youtubePosts,
    startDate,
  ]);

  useEffect(() => {
    console.log("postList ===>", postList);
  }, [postList]);

  const modelDataHandler = (postId) => {
    const foundItemsReduce = scheduledPosts.reduce((acc, obj) => {
      return acc.concat(obj.postDetails.filter((item) => item._id === postId));
    }, []);

    setModelData(foundItemsReduce);
    setEditPost(true);
  };
  return (
    <div className="calendar_Page">
      <GuidedTour
        steps={calendarSteps}
        onRequestClose={handleOnRequestClose}
        isOpen={guideTourGetItemValue ? guideTourGetItemValue.calendar : true}
      />
      <GuidedTour
        steps={calendarListSteps}
        onRequestClose={handleOnRequestCloseCalendarList}
        isOpen={
          guideTourGetItemValueCalendarList
            ? guideTourGetItemValueCalendarList.calendarList === "false" ||
              guideTourGetItemValueCalendarList.calendarList === false
              ? false
              : true
            : true
        }
      />
      <div className="main_Container">
        <div className="calendar_Inner position-relative">
          <div className="chatboat-section position-absolute">
            <i className="icon-chatboat"></i>
          </div>
          <div className={`${changeTab ? "listing_Section" : ""}`}>
            <div className="title_Height">
              <i className="icon-tab tabFilerIcon" onClick={OnTabClick}></i>
              <Label
                className="main_Heading"
                title={`${t("CALENDAR")} - ${socialMedia}`}
              ></Label>
              <Label
                className="inner_Text"
                title={t("CALENDAR_CREATE")}
              ></Label>
            </div>
            <div className="listing">
              <div className="left_Section">
                <div className="data_Listing position-relative">
                  <div className="chatboat-section position-absolute calenderChat">
                    <i className="icon-chatboat"></i>
                  </div>
                  {/* <a onClick={() => setEditPost(!showEditPost)}>button</a> */}
                  <div className="parentBox">
                    <div className="top-section-text-tab">
                      <div
                        className="list-calendar-tab"
                        tourselector="change-tab"
                      >
                        <CustomButton
                          className={` ${
                            !changeTab ? "btn-list-active" : "btn-list"
                          } `}
                          onClick={() => handleClickCalendar(false)}
                          title="Calendar"
                        ></CustomButton>
                        <CustomButton
                          className={` ${
                            changeTab ? "btn-list-active" : "btn-list"
                          } `}
                          onClick={handleClickList}
                          title="List"
                        ></CustomButton>
                      </div>
                      {/* 
                    {changeTab === true && (
                      <Label
                        // title={t('CALENDAR_MONTH')}
                        title={`${startDate.getDate()} ${""} ${
                          months[startDate.getMonth()]
                        }`}
                        className="date"
                      ></Label>
                    )} */}
                    </div>
                    <div className="dropdown_Section">
                      {/* <Label
                      className="filter_Title"
                      title="Filter by Post type"
                    ></Label> */}
                      <CustomDropdown
                        dropDownItems={itemsStatus}
                        placeholder="Select group status"
                        selectedValue={selectedItem}
                        onSelect={(selected) => {
                          setSelectedItem(selected);
                        }}
                      ></CustomDropdown>
                    </div>
                  </div>
                  <div className="border-class"></div>
                  <Scrollbars
                    style={{ height: dimensions.height - 100 + "px" }}
                  >
                    <div className="scroll_Listing">
                      {changeTab === true && (
                        <div>
                          <TabHeader
                            col1={"Social media"}
                            col2={"Posting Date Time"}
                            col3={"Status"}
                            col4={"View Post"}
                          ></TabHeader>
                          {scheduledPosts.map((element) => {
                            return (
                              <Accordion
                                defaultActiveKey={element.id}
                                flush
                                className="acccordionForListing"
                              >
                                <Accordion.Item
                                  eventKey="0"
                                  className="headerAccordion"
                                >
                                  <Accordion.Header className="headerAccordion">
                                    <div className="expandBlock">
                                      <div className="category">
                                        {element.category}
                                      </div>
                                      <div className="date">
                                        {moment(element.updatedAt).format(
                                          "MM/DD/YYYY, hh:mm A"
                                        )}
                                      </div>
                                      <div className="deleted">
                                        {element.isDeleted
                                          ? "Not Available"
                                          : "Available"}
                                      </div>
                                      <div className="expand">
                                      <i className="icon-downarrow"></i>
                                      </div>
                                    </div>
                                  </Accordion.Header>
                                  <Accordion.Body className="bodyAccordion">
                                    {element?.postDetails?.map((e) => {
                                      let utcSeconds = e.scheduledTime;
                                      let d = new Date(0);
                                      let d1 = new Date(
                                        d.setUTCSeconds(utcSeconds) / 1000
                                      );

                                      return (
                                        <div
                                          key={e._id}
                                          className="postDetailBlock"
                                          // onClick={() => showModelPreview()}
                                        >
                                          <div className="category">
                                            <img src= {e.mediaType === "facebook"?fbicon:
                                            e.mediaType=== "twitter"? twittericon:
                                            e.mediaType === "instagram" ? instagramicon:
                                            e.mediaType === "linkedin"?linkedinicon:
                                            e.mediaType === "youtube" ? youtubeicon:
                                            null} alt="icon"/>
                                           
                                          </div>
                                          <div className="date">
                                            {moment(d1).format(
                                              "MM/DD/YYYY, hh:mm A"
                                            )}
                                          </div>
                                          <div className="deleted">
                                            {e.isScheduled === "true"
                                              ? "Posted"
                                              : "Pending"}
                                          </div>

                                          <div
                                            className="expand"
                                            onClick={() =>
                                              modelDataHandler(e._id)
                                            }
                                          >
                                            View
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            );
                          })}
                        </div>
                      )}
                      {changeTab === false && (
                        <>
                          <div className="big-calendar">
                            <BigCalendar
                              socialMedia={socialMedia}
                              onEventClick={handleClickCalendar}
                              selectedItem={selectedItem.value}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
          {changeTab === true && (
            <div
              className={showTab ? "calendar_Right showTab" : "calendar_Right"}
              tourselector="calendar-input"
            >
              <i
                className="icon-close onClose"
                onClick={() => setShowTab(!showTab)}
              ></i>
              <div className="content_Btn">
                <CustomCalendar
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Date"
                  renderCustomHeader={true}
                  open={true}
                ></CustomCalendar>
              </div>
            </div>
          )}
        </div>
      </div>

      <ModalPopup
        showModal={showEditPost}
        onHide={() => setEditPost(false)}
        className="editpost_Review"
        headerTitle="Review post"
        closeIcon={true}
      >
        <div className="tab_Section">
          <div className="preview_Details">
            <div className="with_Logo">
              <div className="logo">
                <img
                  src={PreviewLogo}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="main_Img"
                />
              </div>

              <div className="details">
                <Label className="main_Heading" title="Tech crunch"></Label>
                <Label
                  className="new_Txt"
                  title="Checkout the latest news"
                ></Label>
              </div>
            </div>

            <div className="time_Update">
              <Label title="Just now"></Label>
            </div>
          </div>
          <div className="main_Img">
            <img
              src={PreviewImg}
              alt="image"
              style={{ maxWidth: "100%", height: "auto" }}
              // fluid="true"
              className="main_Img"
            />
          </div>
          <div>{modalData[0]?.message}</div>
          <div className="share_Comment">
            <Link className="link" to="#">
              <i className="icon-like"></i>
              <span>Like</span>
            </Link>
            <Link className="link" to="#">
              <i className="icon-comment"></i>
              <span>Comment</span>
            </Link>
            <Link className="link" to="#">
              <i className="icon-share"></i>
              <span>Share</span>
            </Link>
          </div>
        </div>
        {/* <CustomButton title="Edit Post"></CustomButton> */}
      </ModalPopup>
    </div>
  );
};

export default withRouter(Listing);
