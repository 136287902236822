import * as API from "../util/api-helper";

const API_ENDPOINT_SERVER3001 = "http://localhost:3001";
const API_ENDPOINT_SERVER3002 = "http://localhost:3002";
const API_ENDPOINT_SERVER3003 = "http://localhost:3003";
const API_ENDPOINT_SERVER3005 = "http://localhost:3005";

const API_ENDPOINT = "https://catfact.ninja/fact";

export const fetchCatApiResults = (params) => {
  // console.log("api.js 08", params)

  // const { query, limit } = params;
  // const url = `${API_ENDPOINT}/r/all/search.json?q=${query}&limit=${limit}`;
  // const configData = {
  //   url: "localhost:3002/v1/security/authenticate/",
  //   method: "post",
  //   baseURL: API_ENDPOINT_SERVER3001,
  //   responseType: "json",
  //   data: bodyData
  // }
  return API.get(API_ENDPOINT);
};

export const postUsersSignUpApiResults = (bodyData) => {
  const url = `${API_ENDPOINT_SERVER3001}/v1/users/signup`;
  return API.post(url, bodyData);
};

export const postUsersSignInApiResults = (bodyData) => {
  const url = `${API_ENDPOINT_SERVER3002}/v1/security/authenticate/`;
  return API.post(url, bodyData);
};

export const getUsersFullDetailsApiResults = (data) => {
  let token = data.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = `${API_ENDPOINT_SERVER3001}/v1/users/user/${data.user}`;
  return API.get(url, data, config);
};

export const getAllBlogApiResults = (data) => {
  let token = data.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = `${API_ENDPOINT_SERVER3005}/v1/admin/blog`;
  return API.get(url, "", config);
};

export const putUpdateUserProfileApiResults = (data) => {
  let body = data.bodyData;
  let token = data.token;

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = `${API_ENDPOINT_SERVER3001}/v1/users/profile`;
  return API.put(url, body, config);
};

export const postUploadUserProfileImageApiResults = (data) => {
  let body = data.bodyData.formData;
  let token = data.token;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const url = `${API_ENDPOINT_SERVER3001}/v1/users/upload/profile`;
  return API.post(url, body, config);
};
export const postCreateBlogApiResults = (data) => {
  let body = data.bodyData;
  let token = data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      // 'Content-Type': 'multipart/form-data',
    },
  };
  const url = `${API_ENDPOINT_SERVER3005}/v1/admin/blog`;
  return API.post(url, body, config);
};
export const postForgotPasswordApiResults = (bodyData) => {
  const url = `${API_ENDPOINT_SERVER3002}/v1/security/forgotpassword`;
  return API.post(url, bodyData);
};

export const postVerifyOtpApiResults = (bodyData) => {
  const url = `${API_ENDPOINT_SERVER3002}/v1/security/verifyotp`;
  return API.post(url, bodyData);
};

export const changePasswordApiResults = (data) => {
  let body = data.bodyData;
  let token = data.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = `${API_ENDPOINT_SERVER3002}/v1/security/changePassword`;
  return API.post(url, body, config);
};

export const getFacebookPagesResult = (data) => {
  let body = data.bodyData;
  let token = data.authToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = `${API_ENDPOINT_SERVER3003}/v1/operations/fb/accounts`;
  return API.post(url, body, config);
};

export const postTwitterAuthenticationResult = (data) => {
  let body = data.bodyData;
  let token = data.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = `${API_ENDPOINT_SERVER3003}/v1/operations/twitter/account`;
  return API.post(url, body, config);
};

export const postResendMailVerification = (bodyData) => {
  // let body = data.bodyData;
  // let token = data.token;
  // const config = {
  //   headers: { Authorization: `Bearer ${token}` },
  // };
  const url = `${API_ENDPOINT_SERVER3002}/v1/security/resendemailverification`;
  return API.post(url, bodyData);
};

export const postToFacebookResult = (data) => {
  let body = data.bodyData;
  let token = data.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = `${API_ENDPOINT_SERVER3003}/v1/operations/posts`;
  return API.post(url, body, config);
};

export const postResetPasswordApiResults = (bodyData) => {
  const url = `${API_ENDPOINT_SERVER3002}/v1/security/resetpassword`;
  return API.post(url, bodyData);
};

export const getLinkedInAuthDetails = (data) => {
  const url = `${API_ENDPOINT_SERVER3003}/v1/operations/linkedin/auth/callback`;

  const params = {
    code: data.code,
    state: data.currentUser,
  };

  return API.get(url, params);
};
export const getFacebookAccountDetails = (data) => {
  const payload = {
    accessToken: data.accessToken,
    userId: data.userId,
  };
  const token = data.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = `${API_ENDPOINT_SERVER3003}/v1/operations/fb/accounts`;

  return API.post(url, payload, config);
};

export const getInstagramAccountDetails = (data) => {
  const payload = {
    accessToken: data.accessToken,
    userId: data.userId,
  };
  const token = data.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = `${API_ENDPOINT_SERVER3003}/v1/operations/instagram/accounts`;

  return API.post(url, payload, config);
};

export const getYoutubeAccountDetails = (data) => {
  const payload = {
    tokens: data.tokens,
    user: data.user,
  };
  const token = data.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = `${API_ENDPOINT_SERVER3003}/v1/operations/youtube/account`;

  return API.post(url, payload, config);
};

export const fetchPosts = (data) => {
  const payload = {
    ...data.filter,
  };
  const token = data.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = `${API_ENDPOINT_SERVER3003}/v1/operations/posts/filter`;

  return API.post(url, payload, config);
};

export const deletePost = (data) => {
  const token = data.token;
  const queryParams = {
    ...data.payload,
  };

  const queryString = Object.keys(queryParams)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
    )
    .join("&");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const url = `${API_ENDPOINT_SERVER3003}/v1/operations/posts/userposts/${data.user}?${queryString}`;

  return API.deleteResource(url, config);
};

export const pauseNPlayPost = (data) => {
  const payload = {
    isPaused: data.isPaused,
    postDetails_id: data.postDetails_id,
  };
  const token = data.token;
  const config = {
    method: "put",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: { isPaused: data.isPlaying },
  };
  const url = `${API_ENDPOINT_SERVER3003}/v1/operations/posts/pausepost/${data._id}`;

  return API.post(url, payload, config);
};

export const updateAccounts = (data) => {
  const payload = {
    ...data.payload,
  };
  let url;

  if (data.socialMedia === "Facebook") {
    url = `${API_ENDPOINT_SERVER3003}/v1/operations/fb/accounts`;
  } else if (data.socialMedia === "Twitter") {
    url = `${API_ENDPOINT_SERVER3003}/v1/operations/twitter/account`;
  } else if (data.socialMedia === "Instagram") {
    url = `${API_ENDPOINT_SERVER3003}/v1/operations/instagram/accounts`;
  } else if (data.socialMedia === "Youtube") {
    url = `${API_ENDPOINT_SERVER3003}/v1/operations/youtube/account`;
  } else if (data.socialMedia === "LinkedIn") {
    url = `${API_ENDPOINT_SERVER3003}/v1/operations/linkedin/account`;
  }

  const token = data.token;
  const config = {
    method: "put",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: { ...payload },
  };

  return API.put(url, payload, config);
};

export const getGroups = (data) => {
  let token = data.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = `${API_ENDPOINT_SERVER3003}/v1/operations/groups/user/${data.user}`;
  return API.get(url, data, config);
};

export const updateGroups = (data) => {
  const payload = {
    ...data.payload,
  };
  const url = `${API_ENDPOINT_SERVER3003}/v1/operations/groups/${data.groupId}`;

  const token = data.token;
  const config = {
    method: "put",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: { ...payload },
  };

  if (!data.groupId) return;

  return API.put(url, payload, config);
};

export const createGroups = (data) => {
  const payload = {
    ...data.payload,
  };
  const url = `${API_ENDPOINT_SERVER3003}/v1/operations/groups`;

  const token = data.token;
  const config = {
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: { ...payload },
  };

  return API.post(url, payload, config);
};
export const fetchQueryData = (data) => {
  const url = `${API_ENDPOINT_SERVER3001}/v1/users/query`;

  const token = data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  // console.log("01-02-24 00 04", payload);

  return API.get(url, "", config);
};
export const createQuery = (data) => {
  let body = data.bodyData;
  let token = data.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = `${API_ENDPOINT_SERVER3001}/v1/users/query`;

  return API.post(url, body, config);
};
