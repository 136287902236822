import React from "react";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import Label from "../../components/UI/Label/Label";
import "./PopupTwo.scss";
const PopupTwo = () => {
  return (
    <div className="popuptwo_container">
      <div className="popup_box">
        <div className="heading">
          <div>
            <Label title="Upgrade plan" className="upgrade"></Label>
          </div>
          <div>
            <i className="icon-close"></i>
          </div>
        </div>

        <Label
          className="text"
          title="You limit of 3 account has been exhust to connect more account you have to update your subscription plan "
        ></Label>
        <div className="button">
          <CustomButton title="Upgrade plan"></CustomButton>
        </div>
      </div>
    </div>
  );
};

export default PopupTwo;
