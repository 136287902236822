import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import DashboardImg from '../../assests/images/dashboard.png';
import Label from '../../components/UI/Label/Label';
import './Dashboard.scss';
import Header from '../../components/Header/Header';
import CustomButton from '../../components/UI/CustomButton/CustomButton';
import { useTranslation } from 'react-i18next';
import SocialProfile from '../AddSocialProfile/SocialProfile';
import GuidedTour from '../GuideTour/GuidedTour';
import { dashboardSteps } from '../Steps';
import { localStorageSetItem } from '../GuideTour/GuideTourJSON';
import { useDispatch } from 'react-redux';
import {
  fetchGroupAction,
  twitterIntegrationAction,
  youtubeIntegrationAction,
} from '../../Redux_saga/postPublisherSlice';
import { useAppData } from '../../services/hooks';

function DashboardScreen() {
  const history = useHistory();
  const { t } = useTranslation();
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();
  const dispatch = useDispatch();

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObj")
    );
    let newObj = { ...previousLocalStorageValue, dashboard: false };
    localStorage.setItem("guideTourObj", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };

  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(localStorage.getItem("guideTourObj"));

  const [show, setShow] = React.useState(
    localStorage.getItem("intialLoad") === "true"
  );

  React.useEffect(() => {
    localStorage.setItem("intialLoad", show);
  }, [show]);

  const handlePostMessage = (event) => {
    const { rFrom, tokens, updatedUser } = event.data;
    const user = localStorage.getItem('userId');
    const accesstoken = localStorage.getItem('token');
    if (rFrom === 'youtube' && user && accesstoken && tokens) {
      dispatch(
        youtubeIntegrationAction({
          tokens: tokens,
          user,
          token: accesstoken,
        }),
      );
      setTimeout(() => {
        history.push('./socialprofilelist?Youtube');
      }, 2000);
    }

    if (rFrom === 'twitter' && updatedUser) {
      dispatch(
        twitterIntegrationAction({
          user: updatedUser,
        }),
      );
      setTimeout(() => {
        history.push('./socialprofilelist?Twitter');
      }, 2000);
    }
  };

  React.useEffect(() => {
    if (window.opener && window.opener !== window) {
      const urlParams = new URLSearchParams(window.location.search);
      const rFrom = urlParams.get('rFrom');
      const tokens = urlParams.get('tokens');
      const updatedUserParam = urlParams.get('updatedUser');

      if (rFrom == 'youtube') {
        window.opener.postMessage(
          {
            rFrom,
            tokens: JSON.parse(tokens),
          },
          '*',
        );

        setTimeout(() => {
          window.close();
        }, 2000);
      }

      if (rFrom == 'twitter' && updatedUserParam) {
        const decodedUserParam = decodeURIComponent(updatedUserParam);

        const updatedUser = JSON.parse(decodedUserParam);
        window.opener.postMessage(
          {
            rFrom,
            updatedUser,
          },
          '*',
        );

        setTimeout(() => {
          window.close();
        }, 2000);
      }
    }

    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, []);

const User = useAppData((r) => r.postPublisherSlice?.user);
const Token = useAppData((r) => r.postPublisherSlice?.tokens);

  useEffect(() => {
    console.log('user, token ==>', User, Token)
    if (User && Token) {
      const data = {
        user: User?._id,
        token: Token?.access?.token,
      };

      dispatch(fetchGroupAction(data));
    }
  }, []);

  return (
    <div className="dashboard_Page">
      <Header></Header>

      {show ? (
        <SocialProfile />
      ) : (
        <div className="main_Container">
          <GuidedTour
            steps={dashboardSteps}
            onRequestClose={handleOnRequestClose}
            isOpen={
              guideTourGetItemValue ? guideTourGetItemValue.dashboard : true
            }
          />
          <Scrollbars className="dashboardScroll">
            <div className="chatboat-section position-absolute">
              <i className="icon-chatboat"></i>
            </div>
            <div className="get_Started">
              <div className="image_Section">
                <img
                  src={DashboardImg}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="main_Img"
                />
              </div>
              <Label
                className="main_title"
                title={t("DASHBOARD_HEADING")}
              ></Label>
              <Label className="inner_Txt" title={t("DASHBOARD_BRIEF")}></Label>
              <div className="started_Btn">
                <CustomButton
                  title={t("DASHBOARD_BUTTON")}
                  onClick={() => setShow(true)}
                  tourselector="dashboard-btn"
                ></CustomButton>
              </div>
            </div>
          </Scrollbars>
        </div>
      )}
    </div>
  );
}

export default withRouter(DashboardScreen);
