import React, { useState } from "react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Label from "../../../components/UI/Label/Label";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import FilterDropdown from "../../../components/UI/FilterDropdown/FilterDropdown";
import CustomDropdown from "../../../components/UI/CustomDropdown/CustomDropdown";

import "./FilterForm.scss";

const itemCategory = [
  {
    id: "1",
    value: "Admin",
  },
  {
    id: "2",
    value: "Manager",
  },
  {
    id: "3",
    value: "Scheduler",
  },
  {
    id: "4",
    value: "Client",
  },
];

const itemStatus = [
  {
    id: "5",
    value: "Pending",
  },
  {
    id: "6",
    value: "Joined",
  },
];
const FilterForm = (props) => {
  const { title, onClickCloseIcon } = props;

  const [selectCategory, setSelectedCategory] = useState();
  const [selectStatus, setSelectedStatus] = useState();

  return (
    <>
      <FilterDropdown>
        <div className="filterDispute">
          <div className="form">
            <div className="filterItem">
              <div className="header_Section">
                <div className="heading">
                  <Label title={title}></Label>
                  <i className="icon-close" onClick={onClickCloseIcon}></i>
                </div>
              </div>
              <div className="calender_Section">
                <div className="date_Filter">
                  <Label
                    className="date_Title"
                    title="Filter by Category"
                  ></Label>
                  <Link className="filter_Title" to="#">
                    Clear all filters
                  </Link>
                </div>
              </div>
              <div className="dropdown_Section">
                <CustomDropdown
                  dropDownItems={itemCategory}
                  placeholder="Select Category"
                  selectedValue={selectCategory}
                  onSelect={(selected) => setSelectedCategory(selected)}
                ></CustomDropdown>
              </div>
              <div className="dropdown_Section">
                <Label
                  className="filter_Title"
                  title="Filter by Status"
                ></Label>
                <CustomDropdown
                  dropDownItems={itemStatus}
                  placeholder="Select status"
                  selectedValue={selectStatus}
                  onSelect={(selected) => setSelectedStatus(selected)}
                ></CustomDropdown>
              </div>
            </div>
            <div className="custombtnfield">
              <CustomButton type="submit" title="Apply" />
              <CustomButton type="submit" title="Clear" />
            </div>
          </div>
        </div>
      </FilterDropdown>
    </>
  );
};

export default FilterForm;
