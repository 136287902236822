import { createSlice } from "@reduxjs/toolkit";

export const PostPublisherSlice = createSlice({
  name: "postPublisher",
  initialState: {
    sum: 2,
    queryData: [],
    catFact: {
      catFactValue: [],
      loading: false,
    },
    createQuery: {
      query: [],
    },
    loader: false,
    users: {
      SignUpResponseValue: [],
      SignInResponseValue: [],
      UsersFullDetailsValue: [],
      UpdateUserProfileValue: [],
      UploadUserProfileImageValue: [],
      ForgotPasswordResponseValue: [],
      VerifyOtpResponseValue: [],
      changePasswordResponseValue: [],
      facebookPageResponseValue: [],
      twitterAuthenticationResponseValue: [],
      resendMailVerificationResponseValue: [],
      postToFaceookResponseValue: [],
      resetPasswordResponseValue: [],
    },
    admin: {
      adminSignInResponseValue: [],
    },
    user: null,
    tokens: {
      access: null,
      refresh: null,
    },
    blog: {
      getAllBlogResponseValue: { status: false, data: [] },
    },
    post: {},
    scheduledPosts: [],
    publishedPosts: [],
    failedPosts: [],
    draftPosts: [],
    groups: [],
  },
  reducers: {
    getCatAction: (state) => {
      state.catFact = {
        ...state.catFact,
        loading: true,
      };
    },
    addSumAction: (state) => {},
    createUpdatePostAction: (state, action) => {
      state.post = action.payload;
    },
    hideLoaderAction: (state) => {
      state.loader = false;
    },
    postUsersSignUpAction: () => {
      // state.loader = true;
    },
    postUsersSignInAction: (state, action) => {
      // state.loader = true;
    },
    postAdminSignInAction: () => {
      // state.loader = true;
    },
    getUsersFullDetailsAction: (state) => {
      state.loader = true;
    },
    getAllBlogAction: (state, action) => {
      // state.loader = true;
    },
    postCreateQueryAction: (state, action) => {
      // state.loader = true;
    },
    putUpdateUserProfileAction: (state) => {
      state.loader = true;
    },
    successUpdateUserProfileAction: (state, action) => {
      state.loader = false;
      state.user = action.payload;
    },
    postUploadUserProfileImageAction: (state) => {
      state.loader = true;
    },
    successUsersFullDetailsAction: (state, action) => {
      state.loader = false;

      state.user = action.payload;
    },
    // successUsersFullDetailsAction: (state, action) => {
    //   state.loader = false;
    //   state.user = action.payload;
    // },

    successGetAllBlogAction: (state, action) => {
      state.loader = false;
      state.blog = {
        ...state.blog,
        getAllBlogResponseValue: action.payload,
      };
    },
    successUploadUserProfileImageAction: (state, action) => {
      state.loader = false;
      state.users = {
        ...state.users,
        UploadUserProfileImageValue: action.payload,
      };
    },
    postCreateBlogAction: (state, action) => {
      // state.loader = true;
    },
    postChangePasswordAction: (state, action) => {
      // state.loader = true;
    },
    postForgotPasswordAction: (state, action) => {
      // state.loader = true;
    },
    successChangePasswordAction: (state, action) => {
      state.users = {
        ...state.users,
        changePasswordResponseValue: action.payload,
      };
    },
    successCreateQueryAction: (state, action) => {
      state.createQuery = {
        ...state.createQuery,
        query: action.payload,
      };
    },
    postVerifyOtpAction: (state, action) => {
      // state.loader = true;
    },
    succesCatAction: (state, action) => {
      state.catFact = {
        ...state.catFact,
        catFactValue: action.payload,
        loading: false,
      };
    },
    successUsersSignUpAction: (state, action) => {
      state.users = {
        ...state.users,
        SignUpResponseValue: action.payload,
      };
    },
    successUsersSignInAction: (state, action) => {
      // state.loader = false;
      // state.users = {
      //   ...state.users,
      //   SignInResponseValue: action.payload,
      // };

      state.user = action.payload.data.userLoggedIn;
      state.tokens = {
        access: action.payload.data.access,
        refresh: action.payload.data.refresh,
      };
    },
    successForgotPasswordAction: (state, action) => {
      state.users = {
        ...state.users,
        ForgotPasswordResponseValue: action.payload,
      };
    },
    successVerifyOtpAction: (state, action) => {
      state.loader = false;
      state.users = {
        ...state.users,
        VerifyOtpResponseValue: action.payload,
      };
    },
    getFacebookPageAction: (state) => {
      state.loader = true;
    },
    successFacebookPageAction: (state, action) => {
      state.loader = false;
      state.users = {
        ...state.users,
        facebookPageResponseValue: action.payload,
      };
    },
    postTwitterAuthenticationAction: (state) => {
      state.loader = true;
    },
    successTwitterAuthenticationAction: (state, action) => {
      state.loader = false;
      state.users = {
        ...state.users,
        twitterAuthenticationResponseValue: action.payload,
      };
    },
    postresendMailVerificationAction: (state, action) => {
      // state.loader = true;
    },
    successresendMailVerificationAction: (state, action) => {
      state.users = {
        ...state.users,
        resendMailVerificationResponseValue: action.payload,
      };
    },
    postToFacebookAction: (state) => {
      state.loader = true;
    },
    successPostToFacebookAction: (state, action) => {
      state.loader = false;
      state.users = {
        ...state.users,
        postToFaceookResponseValue: action.payload,
      };
      state.post = {
        link: "",
        linkType: "",
        contentType: "feed",
        message: "",
      };
    },
    postResetPasswordAction: (state, action) => {},
    successPostResetPasswordAction: (state, action) => {
      state.users = {
        ...state.users,
        resetPasswordResponseValue: action.payload,
      };
    },
    successAdminSignInAction: (state, action) => {
      state.admin = {
        ...state.admin,
        adminSignInResponseValue: action.payload,
      };
    },
    linkedInIntegrationAction: (state, action) => {
      // state.loader = true;
    },
    successlinkedInIntegratioAction: (state, action) => {
      // state.user = action.payload.data.user;
      state.user = action.payload.data;
    },
    linkedInIntegrationAction: (state, action) => {},
    facebookIntegrationAction: (state, action) => {},
    succesFacebookIntegratioAction: (state, action) => {
      const paylodData = JSON.parse(action.payload);

      state.user = paylodData.data;
    },
    instagramIntegrationAction: (state, action) => {},
    succesInstagramIntegratioAction: (state, action) => {
      const paylodData = JSON.parse(action.payload);

      state.user = paylodData.data;
    },
    twitterIntegrationAction: (state, action) => {},
    succesTwitterIntegrationAction: (state, action) => {
      const paylodData = action.payload;

      state.user = paylodData.user;
    },
    youtubeIntegrationAction: (state, action) => {},
    succesYoutubeIntegrationAction: (state, action) => {
      const paylodData = JSON.parse(action.payload);

      state.user = paylodData.data;
    },
    logoutAction: (state, action) => {
      return {
        sum: 2,
        catFact: {
          catFactValue: [],
          loading: false,
        },
        loader: false,
        users: {
          SignUpResponseValue: [],
          SignInResponseValue: [],
          UsersFullDetailsValue: [],
          UpdateUserProfileValue: [],
          UploadUserProfileImageValue: [],
          ForgotPasswordResponseValue: [],
          VerifyOtpResponseValue: [],
          changePasswordResponseValue: [],
          facebookPageResponseValue: [],
          twitterAuthenticationResponseValue: [],
          resendMailVerificationResponseValue: [],
          postToFaceookResponseValue: [],
          resetPasswordResponseValue: [],
        },
        admin: {
          adminSignInResponseValue: [],
        },
        user: null,
        tokens: {
          access: null,
          refresh: null,
        },
        blog: {
          getAllBlogResponseValue: { status: false, data: [] },
        },
        post: {},
        scheduledPosts: [],
        publishedPosts: [],
        failedPosts: [],
        draftPosts: [],
        groups: [],
      };
    },
    fetchScheduledPostAction: (state, action) => {},
    fetchScheduledPostSuccessAction: (state, action) => {
      const paylodData = JSON.parse(action.payload);

      state.scheduledPosts = paylodData.data;
    },
    fetchDraftPostAction: (state, action) => {},
    fetchDraftPostSuccessAction: (state, action) => {
      const paylodData = JSON.parse(action.payload);

      state.draftPosts = paylodData.data;
    },
    fetchFailedPostAction: (state, action) => {},
    fetchFailedPostSuccessAction: (state, action) => {
      const paylodData = JSON.parse(action.payload);

      state.failedPosts = paylodData.data;
    },
    fetchPublishedPostAction: (state, action) => {},
    fetchPublishedPostSuccessAction: (state, action) => {
      const paylodData = JSON.parse(action.payload);

      state.publishedPosts = paylodData.data;
    },
    deletePostAction: (state, action) => {},
    deletePostSuccessAction: (state, action) => {},
    playNPauseAction: (state, action) => {},
    playNPauseSuccessAction: (state, action) => {},
    playNPauseAccountAction: (state, action) => {},
    playNPauseAccountSuccessAction: (state, action) => {},
    fetchGroupAction: (state, action) => {},
    fetchGroupSuccessAction: (state, action) => {
      const paylodData = action.payload;
      state.groups = paylodData;
    },
    updateGroupsAction: (state, action) => {},
    updateGroupsSuccessAction: (state, action) => {},
    createGroupAction: (state, action) => {},
    fetchQueryDataAction: (state, action) => {},
    createGroupSuccessAction: (state, action) => {},
    fetchQuerySuccessAction: (state, action) => {
      state.queryData = action.payload;
    },
  },
});

export const {
  hideLoaderAction,
  getCatAction,
  createUpdatePostAction,
  postUsersSignInAction,
  postAdminSignInAction,
  postUsersSignUpAction,
  postForgotPasswordAction,
  postVerifyOtpAction,
  postCreateBlogAction,
  postChangePasswordAction,
  postUploadUserProfileImageAction,
  postTwitterAuthenticationAction,
  postresendMailVerificationAction,
  postToFacebookAction,
  postResetPasswordAction,
  getUsersFullDetailsAction,
  getAllBlogAction,
  postCreateQueryAction,
  successUsersFullDetailsAction,
  successGetAllBlogAction,
  putUpdateUserProfileAction,
  successUpdateUserProfileAction,
  successUploadUserProfileImageAction,
  successVerifyOtpAction,
  succesCatAction,
  successUsersSignInAction,
  successUsersSignUpAction,
  successForgotPasswordAction,
  successChangePasswordAction,
  successCreateQueryAction,
  getFacebookPageAction,
  successFacebookPageAction,
  successTwitterAuthenticationAction,
  successresendMailVerificationAction,
  successPostToFacebookAction,
  successPostResetPasswordAction,
  successAdminSignInAction,
  linkedInIntegrationAction,
  successlinkedInIntegratioAction,
  facebookIntegrationAction,
  succesFacebookIntegratioAction,
  instagramIntegrationAction,
  succesInstagramIntegratioAction,
  twitterIntegrationAction,
  succesTwitterIntegrationAction,
  youtubeIntegrationAction,
  succesYoutubeIntegrationAction,
  logoutAction,
  fetchScheduledPostAction,
  fetchPublishedPostAction,
  fetchDraftPostAction,
  fetchFailedPostAction,
  fetchScheduledPostSuccessAction,
  fetchPublishedPostSuccessAction,
  fetchDraftPostSuccessAction,
  fetchFailedPostSuccessAction,
  deletePostAction,
  deletePostSuccessAction,
  playNPauseAction,
  playNPauseSuccessAction,
  playNPauseAccountAction,
  playNPauseAccountSuccessAction,
  fetchGroupAction,
  fetchGroupSuccessAction,
  updateGroupsAction,
  updateGroupsSuccessAction,
  createGroupAction,
  createGroupSuccessAction,
  fetchQueryDataAction,
  fetchQuerySuccessAction,
} = PostPublisherSlice.actions;
