import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Logo from "../../assests/images/logo.png";
import Label from "../UI/Label/Label";
import "./Footer.scss";

const Footer = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  // const handleClick = (page) => {
  //   if (page === "aboutus") {
  //     history.push("./aboutUs");
  //   } else if (page === "marketingVideo") {
  //     history.push("./marketingVideo");
  //   } else if (page === "bloglist") {
  //     history.push("./bloglist");
  //   } else if (page === "privacyPolicy") {
  //     history.push("./privacyPolicy");
  //   } else if (page === "TermOfUse") {
  //     history.push("./termsofservice");
  //   }
  // };
  function contactForm() {
    history.push("./");
    setTimeout(function () {
      window.location.href = "#contactus";
    }, 0.00001);
  }
  return (
    <div className={"footer_Section " + props.className}>
      <div className="inner_Footer">
        <div className="logo_Content">
          <img
            src={Logo}
            alt="image"
            style={{ maxWidth: "100%", height: "auto" }}
            // fluid="true"
            className="logo"
          />
          <Label title="Postpublisher"></Label>
          <Label className="inner_Text" title={t("FOOTER_TEXT")}></Label>
        </div>
        <div className="ul_Listing contact">
          <Label title="Postpublisher"></Label>
          <Link to={`aboutus`}>About us</Link>
          {/* error in down link */}
          <Link onClick={contactForm}>Contact us</Link>

          <Link
            // onClick={() => handleClick('marketingVideo')}
            to={`marketingVideo`}
          >
            Marketing videos
          </Link>
          {/* error in down link */}
          <Link onClick={contactForm}>Free consultation</Link>
        </div>
        <div className="ul_Listing policy">
          <Label title="Legal"></Label>
          <Link
            // onClick={() => handleClick('privacyPolicy')}
            to={`privacyPolicy`}
          >
            Privacy policy
          </Link>
          <Link
            // onClick={() => handleClick('TermOfUse')}
            to={`TermOfUse`}
          >
            Terms of use
          </Link>
        </div>
        <div className="ul_Listing social">
          <Label title="Follow us on"></Label>
          <ul>
            <li>
              <Link className="icon-fb" to="#"></Link>
            </li>
            <li>
              <Link className="icon-insta" to="#"></Link>
            </li>
            <li>
              <Link className="icon-twitter" to="#"></Link>
            </li>
            <li>
              <Link className="icon-linkedin" to="#"></Link>
            </li>
            <li>
              <Link className="icon-youtube" to="#"></Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="bottom_Section">
        <Label title="Copyright © 2021 postpublisher"></Label>
      </div>
    </div>
  );
};

export default Footer;
