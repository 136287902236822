import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Label from "../Label/Label";
import "./SliderBlog.scss";

const SliderBlog = (props) => {
  // const history = useHistory();
  let { SliderImg, SliderImg1, SliderImg2, nameTag, designationTag, descText } =
    props;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // const handleClick = (page) => {
  //   if (page === "bloglist") {
  //     history.push("./bloglist");
  //   }
  // };
  return (
    <div className="slider_Blog">
      <Slider {...settings}>
        <div className="image_Slider">
          <img
            src={SliderImg}
            alt="image"
            style={{ maxWidth: "100%", height: "auto" }}
            // fluid="true"
            className="main_Img"
          />
          <div className="content_Section">
            <Label className="name" title={nameTag}></Label>
            <Label
              className="designation position-relative"
              title={designationTag}
            ></Label>
            <Label className="inner_Txt" title={descText}></Label>
          </div>
        </div>
        <div className="image_Slider">
          <img
            src={SliderImg1}
            alt="image"
            style={{ maxWidth: "100%", height: "auto" }}
            // fluid="true"
            className="main_Img"
          />
          <div className="content_Section">
            <Label className="name" title={nameTag}></Label>
            <Label
              className="designation position-relative"
              title={designationTag}
            ></Label>
            <Label className="inner_Txt" title={descText}></Label>
          </div>
        </div>
        <div className="image_Slider">
          <img
            src={SliderImg2}
            alt="image"
            style={{ maxWidth: "100%", height: "auto" }}
            // fluid="true"
            className="main_Img"
          />
          <div className="content_Section">
            <Label className="name" title={nameTag}></Label>
            <Label
              className="designation position-relative"
              title={designationTag}
            ></Label>
            <Label className="inner_Txt" title={descText}></Label>
          </div>
        </div>
        <div className="image_Slider">
          <img
            src={SliderImg}
            alt="image"
            style={{ maxWidth: "100%", height: "auto" }}
            // fluid="true"
            className="main_Img"
          />
          <div className="content_Section">
            <Label className="name" title={nameTag}></Label>
            <Label
              className="designation position-relative"
              title={designationTag}
            ></Label>
            <Label className="inner_Txt" title={descText}></Label>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default SliderBlog;
