import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import fblikeimage from "../../../assests/images/fblikeicon.svg";
import fbloveimage from "../../../assests/images/fbloveicon.png";
import InstaLogo from "../../../assests/images/insta.png";
import PreviewImg from "../../../assests/images/preview.png";
import PreviewLogo from "../../../assests/images/previewlogo.png";
import TwitterLogo from "../../../assests/images/twitter.png";
import Userdetailpic from "../../../assests/images/x-men.png";
import { useAppData } from "../../../services/hooks";
import CustomButton from "../CustomButton/CustomButton";
import Label from "../Label/Label";
import "./PreviewTab.scss";

const PreviewTab = (props) => {
  let { showFacebookPreviewTitle } = props;
  const { t } = useTranslation();
  const post = useAppData((r) => r.postPublisherSlice.post);

  const userDetailStateValue = useAppData(
    (r) => r.postPublisherSlice.users.UsersFullDetailsValue.data
  );

  return (
    // tourselector={tourselector}
    <div>
      <div className={"preview_Section " + props.className}>
        <Tabs
          defaultActiveKey="Facebook"
          id="uncontrolled-tab-example"
          className="mb-3 for-scroll"
        >
          <Tab eventKey="Facebook" title="Facebook">
            <div className="tab_Section">
              <div className="preview_Details">
                <div className="with_Logo">
                  <div className="logo">
                    <img
                      src={PreviewLogo}
                      alt="image"
                      style={{ maxWidth: "100%", height: "auto" }}
                      // fluid="true"
                      className="main_Img"
                    />
                  </div>
                  <div className="details">
                    <Label className="main_Heading" title="Tech crunch"></Label>
                    <div className="sub-heading-main">
                      <Label
                        className="sub-heading-title"
                        title={"Sponsored"}
                      ></Label>
                      <span className="sub-heading-icon">
                        <i className="icon-setting"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="time_Update">
                  <i className="icon-edit iconCss"></i>
                </div>
              </div>
              <Label
                className="new_Txt"
                // title={t('PUBLISHER_SEVEN_LATEST')}
                title={showFacebookPreviewTitle}
              ></Label>
              {/* <div className="main_Img">
                <img
                  src={post?.link === undefined ? PreviewImg : post?.link}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="main_Img"
                />
              </div> */}
              {post?.contentType === "video" ? (
                <div className="videoWrapper">
                  {/* Add video player component here */}
                  <video
                    controls
                    width="100%"
                    height="auto"
                    className="main_Img"
                  >
                    <source src={post?.link} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <div className="main_Img">
                  <img
                    src={post?.link === undefined ? PreviewImg : post?.link}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    className="main_Img"
                  />
                </div>
              )}

              <div className="url-main">
                <div className="left-section">
                  <Label
                    className="url-text"
                    // title="This is dummy video url"
                    title={post?.message}
                  ></Label>
                </div>
                <div className="right-section">
                  <CustomButton
                    className="learn-btn"
                    title="Learn more"
                  ></CustomButton>
                </div>
              </div>

              <div className="icons-comments-share-main">
                <div className="left-icons">
                  <img
                    className="fb-like-icon"
                    src={fblikeimage}
                    alt="fblike"
                  ></img>
                  <img
                    className="fb-like-icon"
                    src={fbloveimage}
                    alt="fblove"
                  ></img>
                  <Label className="left-text" title="10k"></Label>
                </div>
                <div className="right-comment-share-text">
                  <div className="comment-text">
                    <Label className="text-colors" title="501"></Label>
                    <Label className="text-colors" title="comments"></Label>
                  </div>
                  <div className="share-text">
                    <Label className="text-colors" title="910"></Label>
                    <Label className="text-colors" title="shares"></Label>
                  </div>
                </div>
              </div>
              <div className="border-bottom"></div>
              <div className="share_Comment">
                <Link className="link" to="#">
                  <i className="icon-like"></i>
                  <span>{t("PUBLISHER_LIKE_BUTTON")}</span>
                </Link>
                <Link className="link" to="#">
                  <i className="icon-comment"></i>
                  <span>{t("PUBLISHER_COMMENT_BUTTON")}</span>
                </Link>
                <Link className="link" to="#">
                  <i className="icon-share"></i>
                  <span>{t("PUBLISHER_SHARE")}</span>
                </Link>
                <Link className="link" to="#">
                  <img
                    className="profile-icon"
                    // src={Userdetailpic}
                    src={
                      userDetailStateValue === undefined
                        ? Userdetailpic
                        : userDetailStateValue.imageUrl
                    }
                    alt="profile"
                  ></img>
                  <i className="icon-dropdown"></i>
                </Link>
              </div>
            </div>
          </Tab>
          <Tab eventKey="Instagram" title="Instagram">
            <div className="tab_Section insta_Section">
              <div className="insta-top-section">
                <div className="insta-profile">
                  <div className="insta-logo">
                    <img
                      src={InstaLogo}
                      alt="image"
                      style={{ maxWidth: "100%", height: "auto" }}
                      // fluid="true"
                      className="main_Img"
                    />
                  </div>
                  <Label
                    className="insta-profile-text"
                    title="Insta Profile Name"
                  ></Label>
                  <Label
                    className="insta-profile-text insta-time"
                    title="1 d"
                  ></Label>
                </div>

                <div className="icon">
                  <i className="icon-edit iconCss"></i>
                </div>
              </div>
              {/* <div className="insta-main-image">
                <img
                  // src={InstaImg}
                  src={post?.link === undefined ? PreviewImg : post?.link}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="main_Img"
                />
              </div> */}
              {post?.contentType === "video" ? (
                <div className="videoWrapper">
                  {/* Add video player component here */}
                  <video
                    controls
                    width="100%"
                    height="auto"
                    className="main_Img"
                  >
                    <source src={post?.link} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <div className="insta-main-image">
                  <img
                    src={post?.link === undefined ? PreviewImg : post?.link}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    className="main_Img"
                  />
                </div>
              )}
              <div className="insta-icons-main">
                <div className="insta-left-icons">
                  <i className="icon-like1"></i>
                  <i className="icon-chat1 comment-icon"></i>
                  <i className="icon-chat1 share-icon"></i>
                </div>
                <div className="insta-right-icon">
                  <i className="icon-bookmark"></i>
                </div>
              </div>
              <div className="total-likes">
                <Label className="likes-text" title="2,077 likes"></Label>
              </div>
              <div className="isnta-about-post-text">
                <Label
                  className="post-text"
                  // title="As we stay committed to enabling merchants and businesses to scale their volumes through digital payments"
                  title={post?.message}
                ></Label>
              </div>
              <div className="view-comments">
                <Label
                  className="comments-text"
                  title="View all 17 comments"
                ></Label>
              </div>
            </div>
          </Tab>
          <Tab eventKey="Twitter" title="Twitter">
            <div className="tab_Section twitter_Section">
              <div className="top-section">
                <div className="twitter-profile">
                  <img
                    src={TwitterLogo}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    // fluid="true"
                    className="twiter-profile"
                  />
                </div>
                <div className="divide">
                  <div className="left-section">
                    <div className="left-title">
                      <Label
                        className="all-twitter-text profile-name"
                        title="Amitabh Bachchan"
                      ></Label>
                      <Label
                        className="all-twitter-text profile-tag"
                        title="@Amitabh"
                      ></Label>
                      <Label
                        className="all-twitter-text profile-tag"
                        title="11 Feb 2020"
                      ></Label>
                    </div>
                    <div className="timeline">
                      <Label
                        className="timeline-text timeline-title"
                        // title="This is timeline static data"
                        title={post?.message}
                      ></Label>
                    </div>
                  </div>
                  <div className="right-section">
                    <i className="icon-edit iconCss"></i>
                  </div>
                </div>
              </div>
              <div className="image-icons-main">
                {/* <div className="twitter-main_Img">
                  <img
                    // src={TwitterImg}
                    src={post?.link === undefined ? PreviewImg : post?.link}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    // fluid="true"
                    className="main_Img"
                  />
                </div> */}
                {post?.contentType === "video" ? (
                  <div className="videoWrapper">
                    {/* Add video player component here */}
                    <video
                      controls
                      width="100%"
                      height="auto"
                      className="main_Img"
                    >
                      <source src={post?.link} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                ) : (
                  <div className="twitter-main_Img">
                    <img
                      src={post?.link === undefined ? PreviewImg : post?.link}
                      alt="image"
                      style={{ maxWidth: "100%", height: "auto" }}
                      className="main_Img"
                    />
                  </div>
                )}
                <div className="twitter-share_Comment">
                  <Link className="link align-icon-text" to="#">
                    <i className="icon-chat1 twitter-all-icon"></i>
                    <span className="icon-text">997</span>
                  </Link>
                  <Link className="link align-icon-text" to="#">
                    <i className="icon-chat1 twitter-all-icon"></i>
                    <span className="icon-text">917</span>
                  </Link>
                  <Link className="link align-icon-text" to="#">
                    <i className="icon-like twitter-all-icon"></i>
                    <span className="icon-text">29k</span>
                  </Link>
                  <Link className="link align-icon-text" to="#">
                    <i className="icon-graph twitter-all-icon"></i>
                    <span className="icon-text">83.2k</span>
                  </Link>
                  <Link className="link align-icon-text" to="#">
                    <i className="icon-share twitter-all-icon"></i>
                  </Link>
                </div>
              </div>
            </div>
          </Tab>

          <Tab eventKey="LinkedIn" title="LinkedIn">
            <div className="tab_Section linked_section">
              <div className="preview_Details">
                <div className="with_Logo">
                  <div className="logo">
                    <img
                      src={PreviewLogo}
                      alt="image"
                      style={{ maxWidth: "100%", height: "auto" }}
                      // fluid="true"
                      className="main_Img"
                    />
                  </div>
                  <div className="details">
                    <Label className="main_Heading" title="Tech crunch"></Label>
                    <div className="sub-heading-main">
                      <Label
                        className="sub-heading-title"
                        title="Profile Bio"
                      ></Label>
                    </div>
                    <Label className="sub-heading-title" title="1w"></Label>
                  </div>
                </div>
                <div className="right-section">
                  <i className="icon-edit"></i>
                </div>
              </div>
              <div className="textWrap">
                <Label
                  className="new_Txt linkedin_about_post"
                  // title={t('PUBLISHER_SEVEN_LATEST')}
                  // title="Something about post"
                  title={post?.message}
                ></Label>
              </div>
              <Label
                className="new_Txt linkedin_hastag"
                // title={t('PUBLISHER_SEVEN_LATEST')}
                title="#hastag"
              ></Label>
              {/* <div className="main_Img">
                <img
                  // src={PreviewImg}
                  src={post?.link === undefined ? PreviewImg : post?.link}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="main_Img"
                />
              </div> */}
              {post?.contentType === "video" ? (
                <div className="videoWrapper">
                  {/* Add video player component here */}
                  <video
                    controls
                    width="100%"
                    height="auto"
                    className="main_Img"
                  >
                    <source src={post?.link} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <div className="main_Img">
                  <img
                    src={post?.link === undefined ? PreviewImg : post?.link}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    className="main_Img"
                  />
                </div>
              )}
              <div className="url-main linkedin_url_main">
                <div className="left-section">
                  <Label
                    className="url-text"
                    title="This is dummy video url"
                  ></Label>
                </div>
                <div className="right-section">
                  <CustomButton
                    className="learn-btn linkedIn_btn"
                    title="Learn more"
                  ></CustomButton>
                </div>
              </div>

              <div className="icons-comments-share-main">
                <div className="left-icons">
                  <img
                    className="fb-like-icon"
                    src={fblikeimage}
                    alt="fblike"
                  ></img>
                  <img
                    className="fb-like-icon"
                    src={fbloveimage}
                    alt="fblove"
                  ></img>
                  <Label className="left-text" title="10k"></Label>
                </div>
                <div className="right-comment-share-text">
                  <div className="comment-text">
                    <Label className="text-colors" title="501"></Label>
                    <Label className="text-colors" title="comments"></Label>
                  </div>
                </div>
              </div>
              <div className="border-bottom linkedin_border"></div>
              <div className="share_Comment">
                <Link className="link" to="#">
                  <i className="icon-like"></i>
                  <span>{t("PUBLISHER_LIKE_BUTTON")}</span>
                </Link>
                <Link className="link" to="#">
                  <i className="icon-comment"></i>
                  <span>{t("PUBLISHER_COMMENT_BUTTON")}</span>
                </Link>
                <Link className="link" to="#">
                  <i className="icon-share"></i>
                  <span>{t("PUBLISHER_SHARE")}</span>
                </Link>
              </div>
            </div>
          </Tab>

          <Tab eventKey="Youtube" title="Youtube">
            <div className="tab_Section linked_section">
              <div className="preview_Details">
                <div className="with_Logo">
                  <div className="logo">
                    <img
                      src={PreviewLogo}
                      alt="image"
                      style={{ maxWidth: "100%", height: "auto" }}
                      // fluid="true"
                      className="main_Img"
                    />
                  </div>
                  <div className="details">
                    <Label className="main_Heading" title="Tech crunch"></Label>
                    <div className="sub-heading-main">
                      <Label
                        className="sub-heading-title"
                        title="Profile Bio"
                      ></Label>
                    </div>
                    <Label className="sub-heading-title" title="1w"></Label>
                  </div>
                </div>
                <div className="right-section">
                  <i className="icon-edit"></i>
                </div>
              </div>
              <div className="textWrap">
                <Label
                  className="new_Txt linkedin_about_post"
                  // title={t('PUBLISHER_SEVEN_LATEST')}
                  // title="Something about post"
                  title={post?.message}
                ></Label>
              </div>
              <Label
                className="new_Txt linkedin_hastag"
                // title={t('PUBLISHER_SEVEN_LATEST')}
                title="#hastag"
              ></Label>
              {/* <div className="main_Img">
                <img
                  // src={PreviewImg}
                  src={post?.link === undefined ? PreviewImg : post?.link}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="main_Img"
                />
              </div> */}
              {post?.contentType === "video" ? (
                <div className="videoWrapper">
                  {/* Add video player component here */}
                  <video
                    controls
                    width="100%"
                    height="auto"
                    className="main_Img"
                  >
                    <source src={post?.link} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <div className="main_Img">
                  <img
                    src={post?.link === undefined ? PreviewImg : post?.link}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    className="main_Img"
                  />
                </div>
              )}

              <div className="url-main linkedin_url_main">
                <div className="left-section">
                  <Label
                    className="url-text"
                    title="This is dummy video url"
                  ></Label>
                </div>
                <div className="right-section">
                  <CustomButton
                    className="learn-btn linkedIn_btn"
                    title="Learn more"
                  ></CustomButton>
                </div>
              </div>

              <div className="icons-comments-share-main">
                <div className="left-icons">
                  <img
                    className="fb-like-icon"
                    src={fblikeimage}
                    alt="fblike"
                  ></img>
                  <img
                    className="fb-like-icon"
                    src={fbloveimage}
                    alt="fblove"
                  ></img>
                  <Label className="left-text" title="10k"></Label>
                </div>
                <div className="right-comment-share-text">
                  <div className="comment-text">
                    <Label className="text-colors" title="501"></Label>
                    <Label className="text-colors" title="comments"></Label>
                  </div>
                </div>
              </div>
              <div className="border-bottom linkedin_border"></div>
              <div className="share_Comment">
                <Link className="link" to="#">
                  <i className="icon-like"></i>
                  <span>{t("PUBLISHER_LIKE_BUTTON")}</span>
                </Link>
                <Link className="link" to="#">
                  <i className="icon-comment"></i>
                  <span>{t("PUBLISHER_COMMENT_BUTTON")}</span>
                </Link>
                <Link className="link" to="#">
                  <i className="icon-share"></i>
                  <span>{t("PUBLISHER_SHARE")}</span>
                </Link>
              </div>
            </div>
          </Tab>

          <Tab eventKey="TikTok" title="TikTok">
            <div className="tab_Section linked_section">
              <div className="preview_Details">
                <div className="with_Logo">
                  <div className="logo">
                    <img
                      src={PreviewLogo}
                      alt="image"
                      style={{ maxWidth: "100%", height: "auto" }}
                      // fluid="true"
                      className="main_Img"
                    />
                  </div>
                  <div className="details">
                    <Label className="main_Heading" title="Tech crunch"></Label>
                    <div className="sub-heading-main">
                      <Label
                        className="sub-heading-title"
                        title="Profile Bio"
                      ></Label>
                    </div>
                    <Label className="sub-heading-title" title="1w"></Label>
                  </div>
                </div>
                <div className="right-section">
                  <i className="icon-edit"></i>
                </div>
              </div>
              <div className="textWrap">
                <Label
                  className="new_Txt linkedin_about_post"
                  // title={t('PUBLISHER_SEVEN_LATEST')}
                  // title="Something about post"
                  title={post?.message}
                ></Label>
              </div>
              <Label
                className="new_Txt linkedin_hastag"
                // title={t('PUBLISHER_SEVEN_LATEST')}
                title="#hastag"
              ></Label>
              <div className="main_Img">
                <img
                  // src={PreviewImg}
                  src={post?.link === undefined ? PreviewImg : post?.link}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="main_Img"
                />
              </div>

              <div className="url-main linkedin_url_main">
                <div className="left-section">
                  <Label
                    className="url-text"
                    title="This is dummy video url"
                  ></Label>
                </div>
                <div className="right-section">
                  <CustomButton
                    className="learn-btn linkedIn_btn"
                    title="Learn more"
                  ></CustomButton>
                </div>
              </div>

              <div className="icons-comments-share-main">
                <div className="left-icons">
                  <img
                    className="fb-like-icon"
                    src={fblikeimage}
                    alt="fblike"
                  ></img>
                  <img
                    className="fb-like-icon"
                    src={fbloveimage}
                    alt="fblove"
                  ></img>
                  <Label className="left-text" title="10k"></Label>
                </div>
                <div className="right-comment-share-text">
                  <div className="comment-text">
                    <Label className="text-colors" title="501"></Label>
                    <Label className="text-colors" title="comments"></Label>
                  </div>
                </div>
              </div>
              <div className="border-bottom linkedin_border"></div>
              <div className="share_Comment">
                <Link className="link" to="#">
                  <i className="icon-like"></i>
                  <span>{t("PUBLISHER_LIKE_BUTTON")}</span>
                </Link>
                <Link className="link" to="#">
                  <i className="icon-comment"></i>
                  <span>{t("PUBLISHER_COMMENT_BUTTON")}</span>
                </Link>
                <Link className="link" to="#">
                  <i className="icon-share"></i>
                  <span>{t("PUBLISHER_SHARE")}</span>
                </Link>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

PreviewTab.defaultProps = {
  className: "tabs_Section",
};
export default PreviewTab;
