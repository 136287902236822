import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomCalendar.scss";
// const months = [
//   "January",
//   "February",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "August",
//   "September",
//   "October",
//   "November",
//   "December",
// ];
const MonthCustomInput = ({ value, onClick }) => (
  <button type="button" className="custom-input monthcustom" onClick={onClick}>
    {value ? value : "MONTH"} <span className="icon-downarrow"></span>
  </button>
);
const YearCustomInput = ({ value, onClick }) => (
  <button type="button" className="custom-input" onClick={onClick}>
    {value ? value : "2020"} <span className="icon-downarrow"></span>
  </button>
);
const CustomCalendar = (props) => {
  let { inline } = props;
  const [myMonth, setMyMonth] = useState();
  const [myYear, setMyYear] = useState();

  return (
    <div className="customCalender">
      <DatePicker
        calendarClassName="commonCalender"
        renderCustomHeader={({
          changeYear,
          changeMonth,
          // decreaseMonth,
          // increaseMonth,
          // prevMonthButtonDisabled,
          // nextMonthButtonDisabled,
        }) =>
          props.renderCustomHeader ? (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DatePicker
                calendarClassName="monthCustom"
                // selected={props.month}
                dateFormat="MMMM"
                showMonthYearPicker
                placeholderText="Month"
                onChange={(month) => {
                  changeMonth(+moment(month).format("M") - 1);
                  setMyMonth(month);
                }}
                selected={myMonth}
                customInput={<MonthCustomInput></MonthCustomInput>}
              ></DatePicker>
              <DatePicker
                calendarClassName="yearCustom"
                // selected={props.year}
                dateFormat="yyyy"
                showYearPicker
                placeholderText="Year"
                onChange={(year) => {
                  changeYear(+moment(year).format("yyyy"));
                  setMyYear(year);
                }}
                selected={myYear}
                customInput={<YearCustomInput></YearCustomInput>}
              ></DatePicker>
            </div>
          ) : (
            <></>
          )
        }
        showTimeInput={props.showTimeInput}
        selected={props.selected}
        onChange={(e) => props.onChange(e)}
        dateFormat={props.dateFormat}
        placeholderText={props.placeholderText}
        timeInputLabel={props.timeInputLabel}
        inline={inline}
        onInputClick={props.onInputClick}
        open={props.open}
      />
    </div>
  );
};

CustomCalendar.defaultProps = {
  showTimeInput: false,
};

export default CustomCalendar;
