import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Blog3 from "../../assests/images/Blog3.png";
import BlogInner3 from "../../assests/images/BlogInner3.png";
import SliderImg from "../../assests/images/slider1.png";
import BlogFeature from "../../components/BlogFeature/BlogFeature";
import BlogModal from "../../components/BlogFeature/BlogModal";
import Footer from "../../components/Footer/Footer";
import HeaderLogin from "../../components/HeaderLogin/HeaderLogin";
import ImageSlider from "../../components/UI/ImageSlider/ImageSlider";
import Label from "../../components/UI/Label/Label";
import "./BlogThree.scss";

function BlogThree() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const clickShow = () => {
    setShow(!show);
  };

  return (
    <div className="BlogThree_Page">
      {show && <BlogModal closeModal={clickShow} />}

      <HeaderLogin></HeaderLogin>
      <Scrollbars className="blogScroll">
        <div className="main_Banner">
          <img
            src={Blog3}
            alt="image"
            style={{ maxWidth: "100%", height: "auto" }}
            // fluid="true"
            className="banner_Img"
          />
        </div>

        <Label className="first_Title" title={t("BLOG_THREE_HEAD")}></Label>
        <div className="center_Section">
          <div className="inner_Section">
            <p className="inner_Text">{t("BLOG_THREE_PARA_ONE")}</p>
            <div className="inner_Img">
              <img
                src={BlogInner3}
                alt="image"
                style={{ maxWidth: "100%", height: "auto" }}
                // fluid="true"
                className="blog_Inner"
              />
            </div>
            <Label
              className="inner_Text"
              title={t("BLOG_THREE_PARA_TWO")}
            ></Label>
            <Label
              className="inner_Text"
              title={t("BLOG_THREE_PARA_THREE")}
            ></Label>
          </div>
        </div>
        <div className="blog-feature-three">
          <BlogFeature clickToShowModal={clickShow} />
        </div>
        <div className="image_Section">
          <Label title="More article "></Label>
          <ImageSlider
            SliderImg={SliderImg}
            SliderImg1={SliderImg}
            SliderImg2={SliderImg}
            headingTxt={t("BLOG_THREE_MORE")}
            innerTxt={t("BLOG_THREE_BRIEF")}
          ></ImageSlider>
        </div>
        <Footer className="footerheight"></Footer>
      </Scrollbars>
    </div>
  );
}

export default withRouter(BlogThree);
