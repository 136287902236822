import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { AccountListingJsonData } from "../../../ConstantJson/ConstantData";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import CustomDropdown from "../../../components/UI/CustomDropdown/CustomDropdown";
import Label from "../../../components/UI/Label/Label";
import AccountListing from "../AccountListing/AccountListing";
import "./AccountSelectionModal.scss";

const dropDownData = [
  {
    id: "1",
    value: "Admin",
  },
  {
    id: "2",
    value: "Manager",
  },
  {
    id: "3",
    value: "Scheduler",
  },
  {
    id: "4",
    value: "Client",
  },
];

const AccountSelectionModal = (props) => {
  let { handleClickCloseIcon } = props;
  const [selected, setSelected] = React.useState();
  const handleSelect = (value) => {
    setSelected(value);
  };

  return (
    <div className="selectAcc-modal-parent">
      <div className="selectAcc-modal-inner">
        <div className="divide-selectAcc-role-and-btn-wrapper">
          <div className="selectAcc-to-roles-wrapper">
            <div className="top-section">
              <Label className="top-title" title="Select Accounts" />
              {/* <i className="icon-close" onClick={handleClickCloseIcon}></i> */}
            </div>
            <div className="change-role-dropdown">
              <CustomDropdown
                dropDownItems={dropDownData}
                placeholder="Change Role"
                selectedValue={selected}
                onSelect={handleSelect}
              ></CustomDropdown>
            </div>

            <div className="tab-section">
              <Tabs
                defaultActiveKey="Facebook"
                id="uncontrolled-tab-example"
                className="mb-3 for-scroll"
              >
                <Tab eventKey="Facebook" title="Facebook">
                  <div className="tab-wrapper">
                    <div className="tab-inner">
                      <div className="tab-top-section">
                        <Tabs
                          defaultActiveKey="Groups"
                          id="uncontrolled-tab-example"
                          className="mb-3 for-scroll"
                        >
                          <Tab eventKey="Groups" title="Groups">
                            <div className="acc-listing-wrapper">
                              {AccountListingJsonData.map((e, index) => {
                                return (
                                  <div className="acc-listing" key={index}>
                                    <AccountListing
                                      checkboxId={e.checkbox}
                                      accImage={e.accImage}
                                      accName={e.accName}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </Tab>
                          <Tab eventKey="Pages" title="Pages">
                            <div className="acc-listing-wrapper">
                              {AccountListingJsonData.map((e, index) => {
                                return (
                                  <div className="acc-listing" key={index}>
                                    <AccountListing
                                      checkboxId={e.checkbox}
                                      accImage={e.accImage}
                                      accName={e.accName}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Twitter" title="Twitter">
                  <div className="tab-wrapper">
                    <Label title="Twitter Profile" className="list-heading" />
                    <div className="tab-inner">
                      <div className="tab-top-section">
                        <div className="acc-listing-wrapper">
                          {AccountListingJsonData.map((e, index) => {
                            return (
                              <div className="acc-listing" key={index}>
                                <AccountListing
                                  checkboxId={e.checkbox}
                                  accImage={e.accImage}
                                  accName={e.accName}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="LinkedIn" title="LinkedIn">
                  <div className="tab-wrapper">
                    <Label title="LinkedIn Profile" className="list-heading" />
                    <div className="tab-inner">
                      <div className="tab-top-section">
                        <div className="acc-listing-wrapper">
                          {AccountListingJsonData.map((e, index) => {
                            return (
                              <div className="acc-listing" key={index}>
                                <AccountListing
                                  checkboxId={e.checkbox}
                                  accImage={e.accImage}
                                  accName={e.accName}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Instagram" title="Instagram">
                  <div className="tab-wrapper">
                    <Label title="Instagram Profile" className="list-heading" />
                    <div className="tab-inner">
                      <div className="tab-top-section">
                        <div className="acc-listing-wrapper">
                          {AccountListingJsonData.map((e, index) => {
                            return (
                              <div className="acc-listing" key={index}>
                                <AccountListing
                                  checkboxId={e.checkbox}
                                  accImage={e.accImage}
                                  accName={e.accName}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Youtube" title="Youtube">
                  <div className="tab-wrapper">
                    <Label title="YouTube Channels" className="list-heading" />
                    <div className="tab-inner">
                      <div className="tab-top-section">
                        <div className="acc-listing-wrapper">
                          {AccountListingJsonData.map((e, index) => {
                            return (
                              <div className="acc-listing" key={index}>
                                <AccountListing
                                  checkboxId={e.checkbox}
                                  accImage={e.accImage}
                                  accName={e.accName}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Tiktok" title="Tiktok">
                  <div className="tab-wrapper">
                    <Label title="Tiktok" className="list-heading" />
                    <div className="tab-inner">
                      <div className="tab-top-section">
                        <div className="acc-listing-wrapper">
                          {AccountListingJsonData.map((e, index) => {
                            return (
                              <div className="acc-listing" key={index}>
                                <AccountListing
                                  checkboxId={e.checkbox}
                                  accImage={e.accImage}
                                  accName={e.accName}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
          <div className="btn-wrapper">
            <div className="btn-section">
              <CustomButton
                title="Cancel"
                className="cancel-btn"
                onClick={handleClickCloseIcon}
              />
              <CustomButton title="Confirm" className="next-btn" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSelectionModal;
