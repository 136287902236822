import React from "react";
import HookForm from "../../../components/HookForm/HookForm";
import CheckBox from "../../../components/UI/CheckBox/CheckBox";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import Label from "../../../components/UI/Label/Label";
import TextField from "../../../components/UI/TextField/TextField";
import AccountSelectionModal from "../AccountSelection/AccountSelectionModal";

const RoleListingJsonData = [
  {
    checkbox: <CheckBox labelTitle="Admin" id="1" />,
    title: "Can add new users and has all permissions on all accounts.",
  },
  {
    checkbox: <CheckBox labelTitle="Manager" id="2" />,
    title:
      "Can Create, Edit, Boost & Comment on posts and connect new accounts.",
  },
  {
    checkbox: <CheckBox labelTitle="Scheduler" id="3" />,
    title: "Posts created will go for manager approval. Can Comment on posts.",
  },
  {
    checkbox: <CheckBox labelTitle="Client" id="4" />,
    title: "Can View & Comment on posts and connect new accounts.",
  },
];
import "./InviteModal.scss";
const emailInput = {
  emailId: {
    name: "emailId",
    validate: {
      required: {
        value: true,
      },
    },
  },
};

const InviteModal = (props) => {
  let { handleClickCloseIcon } = props;
  const onFormSubmit = () => {};
  const [showAccListing, setShowAccListing] = React.useState(false);
  const [form, setEmailInput] = React.useState();
  // console.log(form);
  const handleClickNextBtn = () => {
    setShowAccListing(!showAccListing);
  };

  return (
    <div className="invite-modal-parent">
      {!showAccListing && (
        <div className="invite-modal-inner">
          <div className="divide-invite-role-and-btn-wrapper">
            <div className="invite-to-roles-wrapper">
              <div className="top-section">
                <Label className="top-title" title="Invite your team members" />
                {/* <i className="icon-close" onClick={handleClickCloseIcon}></i> */}
              </div>
              <div className="input-section">
                <Label className="mail-id" title="Email Id" />
                <div className="input-field">
                  <HookForm
                    defaultForm={{
                      emailId: "",
                    }}
                    init={(form) => setEmailInput(form)}
                    onSubmit={onFormSubmit}
                  >
                    {(formMethod) => {
                      return (
                        <div className="form1">
                          <TextField
                            controlId={"emailId"}
                            formMethod={formMethod}
                            rules={emailInput.emailId.validate}
                            name={emailInput.emailId.name}
                            errors={formMethod?.formState.errors}
                            autoFocus={true}
                            placeholder="Enter Email id to give access"
                            className="input-box"
                          />
                        </div>
                      );
                    }}
                  </HookForm>
                </div>
              </div>

              <div className="roles-section">
                <Label className="select-role-text" title="Select Roles" />
                <div className="roles-listing">
                  {RoleListingJsonData.map((e, index) => {
                    return (
                      <div key={index} style={{ paddingBottom: 24 }}>
                        {e.checkbox}
                        <div className="role-text">{e.title}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="btn-wrapper">
              <div className="btn-section">
                <CustomButton
                  title="Cancel"
                  className="cancel-btn"
                  onClick={handleClickCloseIcon}
                />
                <CustomButton
                  title="Next"
                  className="next-btn"
                  onClick={handleClickNextBtn}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showAccListing && (
        <div className="show-acc-listing">
          <AccountSelectionModal
            handleClickCloseIcon={() => setShowAccListing(!showAccListing)}
          />
        </div>
      )}
    </div>
  );
};

export default InviteModal;
