import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { withRouter } from "react-router-dom";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import PaginationSystem from "../../components/Pagination/PaginationSystem";
import Label from "../../components/UI/Label/Label";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { adminMarketingSteps } from "../Steps";
import "./AdminDashboardMarketingRequests.scss";

function AdminDashboardMarketingRequest() {
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObjAdmin")
    );
    let newObj = { ...previousLocalStorageValue, adminMarketing: false };
    localStorage.setItem("guideTourObjAdmin", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(
    localStorage.getItem("guideTourObjAdmin")
  );
  return (
    <div className="admin_Marketing">
      <GuidedTour
        steps={adminMarketingSteps}
        onRequestClose={handleOnRequestClose}
        isOpen={
          guideTourGetItemValue ? guideTourGetItemValue.adminMarketing : true
        }
      />
      <div className="no-design-section justify-content-center align-items-center vh-100">
        <Label
          title="Mobile design not supported"
          className="no-design"
        ></Label>
      </div>
      <AdminHeader />
      <div className="main_Container">
        <Scrollbars className="admin_Marketing_Scroll">
          <div className="dashboard_Section ">
            <div className="d-flex justify-content-between align-items-center pt-4">
              <div className="content_Header">
                <Label
                  className="page_Title"
                  title="Marketing Requests"
                ></Label>
                <Label
                  className="inner_Title"
                  title="View and manage all your requested post here"
                ></Label>
              </div>
            </div>
            <div className="divider"></div>
            <div className="table_Section pr-3">
              <div className="query-Section d-flex flex-column">
                <div tourselector="admin-marketing-list">
                  <ul className="header d-flex p-0 m-0">
                    <li>Sl.No.</li>
                    <li className="pr-1">Request Id</li>
                    <li className="pr-1">Requested on</li>
                    <li className="pr-1">Created On</li>
                    <li className="pr-1">Purpose</li>
                    <li className="pr-1">Discription</li>
                    <li className="pr-1">Status</li>
                    <li className="pr-1"></li>
                    <li></li>
                  </ul>

                  <ul className="dataList d-flex p-0 m-0">
                    <li>1</li>
                    <li className="pr-1">000114</li>
                    <li className="pr-1">22/Sep/2019</li>
                    <li className="pr-1">-</li>
                    <li className="pr-1">
                      Lorem ipsum dolor sit amet Aut ipsa nisi{" "}
                    </li>
                    <li className="pr-1">
                      Lorem ipsum dolor sit amet Aut ipsa nisi{" "}
                    </li>
                    <li className="pr-1">Pending</li>
                    <li className="text-right pr-1 ">
                      <icon className="icon-download"></icon>{" "}
                    </li>
                  </ul>
                  <ul className="dataList d-flex p-0 m-0">
                    <li>2</li>
                    <li className="pr-1">000115</li>
                    <li className="pr-1">22/Sep/2019</li>
                    <li className="pr-1">22/Sep/2019</li>
                    <li className="pr-1">
                      Lorem ipsum dolor sit amet Aut ipsa nisi{" "}
                    </li>
                    <li className="pr-1">
                      Lorem ipsum dolor sit amet Aut ipsa nisi{" "}
                    </li>
                    <li className="pr-1">Pending</li>
                    <li className="text-right pr-1 ">
                      <icon className="icon-download"></icon>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
      <PaginationSystem />
    </div>
  );
}

export default withRouter(AdminDashboardMarketingRequest);
