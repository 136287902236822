import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import AboutUsImg from "../../assests/images/about.png";
import LeaderImg from "../../assests/images/leader.png";
import LeaderImg1 from "../../assests/images/leader1.png";
import LeaderImg2 from "../../assests/images/leader2.png";
import PurposeImg from "../../assests/images/purpose.png";
import SliderImg from "../../assests/images/slider1.png";
import SocialGroup from "../../assests/images/socail-group.png";
import WeDoImg from "../../assests/images/wedo.png";
import Footer from "../../components/Footer/Footer";
import HeaderLogin from "../../components/HeaderLogin/HeaderLogin";
import HookForm from "../../components/HookForm/HookForm";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import ImageSlider from "../../components/UI/ImageSlider/ImageSlider";
import Label from "../../components/UI/Label/Label";
import SliderBlog from "../../components/UI/SliderBlog/SliderBlog";
import TextField from "../../components/UI/TextField/TextField";
import Constant from "../../util/constant";
import Message from "../../util/message";
import "./AboutUs.scss";

const contactForm = {
  name: {
    name: "name",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.EMAILEMPTY,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ERRORMESSAGE.EMAILINVALID,
      },
    },
  },
  email: {
    name: "email",
    validate: {
      required: {
        value: true,
      },
    },
  },
};
const onFormSubmit = () => {};
function AboutUsPage() {
  const { t } = useTranslation();

  const [showThankYou, setShowThankYou] = useState(false);
  const [form, setLoginForm] = React.useState();
  const dimensions = {
    height: window.innerHeight,
    width: window.innerWidth,
  };
  return (
    <div className="about_Page" style={{ height: dimensions.height + "px" }}>
      <HeaderLogin></HeaderLogin>
      <Scrollbars className="about_Scroll">
        <div className="main_aboutPage">
          <div className="about_Inner">
            <div className="who_Section">
              <div className="content_Section">
                <Label
                  className="main_Title"
                  title={t("ABOUT_MAIN_HEAD")}
                ></Label>
                <Label
                  className="inner_Title"
                  title={t("ABOUT_MAIN_SUMMARY")}
                ></Label>
                <CustomButton
                  title={t("ABOUT_EXPLORER")}
                  className="expoloreBtn"
                ></CustomButton>
              </div>
              <div className="img_Section">
                <img
                  src={AboutUsImg}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="time_Img"
                />
              </div>
            </div>
            <div className="wedo_Section align-items-center d-flex">
              <div className="img_Section">
                <img
                  src={WeDoImg}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="time_Img"
                />
              </div>
              <div className="content_Section">
                <Label
                  className="main_Title"
                  title={t("ABOUT_PUBLISHER_DO")}
                ></Label>
                <Label
                  className="inner_Title"
                  title={t("ABOUT_PUBLISHER_DO_BRIEF")}
                ></Label>
                <CustomButton
                  title={t("ABOUT_EXPLORER")}
                  className="expoloreBtn"
                ></CustomButton>
              </div>
            </div>
            <div className="purpose_Section">
              <div className="content_Section">
                <Label
                  className="main_Title"
                  title={t("ABOUT_PURPOSE")}
                ></Label>
                <Label
                  className="inner_Title"
                  title={t("ABOUT_PURPOSE_BRIEF")}
                ></Label>
              </div>
              <div className="img_Section">
                <img
                  src={PurposeImg}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="time_Img"
                />
              </div>
            </div>
            <div className="imgSlider_Section">
              <Label className="main_Title" title={t("ABOUT_READ_OUR")}></Label>
              <ImageSlider
                SliderImg={SliderImg}
                SliderImg1={SliderImg}
                SliderImg2={SliderImg}
                headingTxt="Lorem Ipsum is text "
                innerTxt="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
              ></ImageSlider>
            </div>
            <div className="imgSlider_Section bgColor">
              <Label className="main_Title" title={t("ABOUT_MEET_OUR")}></Label>

              <SliderBlog
                SliderImg={LeaderImg}
                SliderImg1={LeaderImg1}
                SliderImg2={LeaderImg2}
                nameTag="Jonathan Kay"
                designationTag="Co-Founder & CEO "
                descText="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
              ></SliderBlog>
            </div>
            <div className="input_Section">
              <Label className="main_Title" title={t("ABOUT_GET_IN")}></Label>
              <div className="content_Textbox">
                <div className="img_Section">
                  <img
                    src={SocialGroup}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    // fluid="true"
                    className=""
                  />
                </div>
                <div className="text_Box">
                  <HookForm
                    defaultForm={{}}
                    init={(form) => setLoginForm(form)}
                    onSubmit={onFormSubmit}
                  >
                    {(formMethod) => {
                      return (
                        <div className="form1">
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.name.validate}
                            name={contactForm.name.name}
                            errors={formMethod?.errors}
                            placeholder="Name"
                            iconClass="icon-user"
                            type="text"
                          />
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.email.validate}
                            name={contactForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={false}
                            type="text"
                            placeholder="Email Id*"
                            iconClass="icon-mail"
                          />

                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.name.validate}
                            name={contactForm.name.name}
                            errors={formMethod?.errors}
                            placeholder="Share your throughts"
                            type="text"
                            textarea="textarea"
                          />
                          <CustomButton
                            title={t("ABOUT_SUBMIT_BUTTON")}
                            onClick={() => setShowThankYou(!showThankYou)}
                          ></CustomButton>
                        </div>
                      );
                    }}
                  </HookForm>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer className="footerheight"></Footer>
      </Scrollbars>
    </div>
  );
}

export default withRouter(AboutUsPage);
