import { all, call, put, takeLatest, select } from "redux-saga/effects";
import * as Api from "../services/Api";

import {
  getAllBlogAction,
  getCatAction,
  getFacebookPageAction,
  getUsersFullDetailsAction,
  linkedInIntegrationAction,
  postAdminSignInAction,
  postChangePasswordAction,
  postCreateBlogAction,
  postForgotPasswordAction,
  postResetPasswordAction,
  postToFacebookAction,
  postTwitterAuthenticationAction,
  postUploadUserProfileImageAction,
  postUsersSignInAction,
  postUsersSignUpAction,
  postVerifyOtpAction,
  postresendMailVerificationAction,
  putUpdateUserProfileAction,
  succesCatAction,
  successAdminSignInAction,
  successChangePasswordAction,
  successFacebookPageAction,
  successForgotPasswordAction,
  successGetAllBlogAction,
  successPostResetPasswordAction,
  successPostToFacebookAction,
  successTwitterAuthenticationAction,
  successUpdateUserProfileAction,
  successUploadUserProfileImageAction,
  successUsersFullDetailsAction,
  successUsersSignInAction,
  successUsersSignUpAction,
  successVerifyOtpAction,
  successlinkedInIntegratioAction,
  successresendMailVerificationAction,
  facebookIntegrationAction,
  succesFacebookIntegratioAction,
  instagramIntegrationAction,
  succesInstagramIntegratioAction,
  twitterIntegrationAction,
  succesTwitterIntegrationAction,
  youtubeIntegrationAction,
  succesYoutubeIntegrationAction,
  logoutAction,
  fetchDraftPostAction,
  fetchFailedPostAction,
  fetchPublishedPostAction,
  fetchScheduledPostAction,
  fetchDraftPostSuccessAction,
  fetchFailedPostSuccessAction,
  fetchPublishedPostSuccessAction,
  fetchScheduledPostSuccessAction,
  deletePostAction,
  deletePostSuccessAction,
  playNPauseAction,
  playNPauseSuccessAction,
  playNPauseAccountAction,
  playNPauseAccountSuccessAction,
  fetchUserSuccessAction,
  fetchGroupAction,
  fetchGroupSuccessAction,
  updateGroupsAction,
  updateGroupsSuccessAction,
  createGroupAction,
  createGroupSuccessAction,
  fetchQueryDataAction,
  fetchQuerySuccessAction,
  successCreateQueryAction,
  getCreateQueryAction,
  postCreateQueryAction,
} from "./postPublisherSlice";
import { showToastAction } from "./globalSlice";

function* getApprovers(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.fetchCatApiResults, payloadData);
    const data = response.data;
    if (response.status !== 200) {
      // yield put(getResultsError(data.error));
    } else {
      yield put(succesCatAction(data));
    }
  } catch (err) {
    alert(err);
  }
}

function* postUsersSignUp(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.postUsersSignUpApiResults, payloadData);
    const data = response.data;
    if (response.status === 200) {
      yield put(successUsersSignUpAction(data));
    }
    yield put(
      // showToastAction({ message: response?.message?.message, type: "success" })
      showToastAction({ message: "Check your mail for otp", type: "success" })
    );
  } catch (err) {
    const errData = err.response.data;

    let errDataToObj = JSON.parse(errData);

    yield put(
      showToastAction({
        message: errDataToObj?.message?.message,
        type: "error",
      })
    );
  }
}

function* postCreateBlog(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.postCreateBlogApiResults, payloadData);
    if (response.status === 200) {
      yield put(
        showToastAction({ message: "successfully publushed", type: "success" })
      );
    } else {
      alert(response.status);
    }
  } catch (err) {
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}

function* changePassword(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.changePasswordApiResults, payloadData);
    const data = response.data;
    if (response.status === 200) {
      yield put(successChangePasswordAction(data));
      const changePasswordData = JSON.parse(data);

      yield put(
        showToastAction({
          message: changePasswordData.message,
          type: "success",
        })
      );
    } else {
      alert(response.status);
    }
  } catch (err) {
    // yield put(getResultsError(getCustomError(err)))
    const errResponseChangePswd = JSON.parse(err.response.data);
    yield put(
      showToastAction({
        message: errResponseChangePswd.message,
        type: "error",
      })
    );
  }
}

function* postForgotPassword(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.postForgotPasswordApiResults, payloadData);
    const data = response.data;

    if (response.status === 200) {
      let dataObject = JSON.parse(data);
      yield put(successForgotPasswordAction(dataObject));
      {
        dataObject.status === true
          ? yield put(
              showToastAction({ message: dataObject.message, type: "success" })
            )
          : null;
      }
    } else {
      alert("err");
    }
  } catch (err) {
    let errorMsg = err.response.data;
    let errorMsgToObj = JSON.parse(errorMsg);
    yield put(
      showToastAction({ message: errorMsgToObj.message, type: "error" })
    );
  }
}

function* postUsersSignIn(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.postUsersSignInApiResults, payloadData);
    const data = response.data;
    if (response.status === 200) {
      // yield put(replace('/dashboard'));
      let updatedData = JSON.parse(data);
      yield put(successUsersSignInAction(updatedData));
      {
        updatedData.status === false
          ? yield put(
              showToastAction({ message: updatedData.message, type: "error" })
            )
          : null;
      }
      if (updatedData.data) {
        let token = updatedData.data.access.token;
        let userId = updatedData.data.userLoggedIn._id;
        window.localStorage.setItem("token", token);
        window.localStorage.setItem("userId", userId);
      }
    } else {
      alert("err");
    }
  } catch (err) {
    let payloadData = action.payload;
    if (payloadData.emailId === "pp@digipank.com") {
      yield put(successUsersSignInAction({ token: "tokenfortesing" }));
      window.localStorage.setItem(
        "token",
        "statictokenfortestinganduidesignteamtoken"
      );
    }
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}

function* postAdminSignIn(action) {
  try {
    yield put(successAdminSignInAction({ token: "tokenforAdmintesing" }));
    // const response = yield call(Api.postUsersSignInApiResults, payloadData);
    window.localStorage.setItem(
      "Admintoken",
      "statictokenfortestinganduidesignteamtoken"
    );
    // if (response.status === 200) {
    //   let updatedData = JSON.parse(data);
    //   yield put(successAdminSignInAction(updatedData));
    // } else {
    // }
  } catch (err) {
    let payloadData = action.payload;
    if (payloadData.emailId === "pp@digipank.com") {
      yield put(successAdminSignInAction({ token: "tokenforAdmintesing" }));
      window.localStorage.setItem(
        "Admintoken",
        "statictokenfortestinganduidesignteamtoken"
      );
    }
    // yield put(getResultsError(getCustomError(err)))
  }
}

function* getUsersFullDetails(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.getUsersFullDetailsApiResults, payloadData);
    if (response.status) {
      const data = response.data;
      yield put(successUsersFullDetailsAction(data));
    }
    // const data = response.data;
    // if (response.status === 200) {
    //   let updatedData = JSON.parse(data);
    //   yield put(successUsersFullDetailsAction(updatedData));
    // } else {
    // }
  } catch (err) {
    alert(err);
    // yield put(getResultsError(getCustomError(err)))
  }
}

function* getAllBlog(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.getAllBlogApiResults, payloadData);
    yield put(successGetAllBlogAction(response));
  } catch (err) {
    alert(err);
  }
}

function* putUpdateUserProfile(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(
      Api.putUpdateUserProfileApiResults,
      payloadData
    );
    const data = response.data;
    if (response.status === 200) {
      yield put(successUpdateUserProfileAction(data));
      yield put(
        showToastAction({ message: "User Detail Updated", type: "success" })
      );
    } else {
      alert(response.status);
    }
  } catch (err) {
    alert(err);
    // yield put(getResultsError(getCustomError(err)))
  }
}

function* postVerifyOtp(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.postVerifyOtpApiResults, payloadData);
    const data = response.data;
    if (response.status === 200) {
      let dataObject = JSON.parse(data);
      yield put(successVerifyOtpAction(dataObject));
      {
        dataObject.status === true
          ? yield put(
              showToastAction({ message: dataObject.message, type: "success" })
            )
          : null;
      }
    } else {
      alert("err");
    }
  } catch (err) {
    alert(err);
    // ------
    // yield put(getResultsError(getCustomError(err)))
  }
}

function* postUploadUserProfileImage(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(
      Api.postUploadUserProfileImageApiResults,
      payloadData
    );
    const data = response.data;
    if (response.status === 200) {
      let updatedData = JSON.parse(data);
      yield put(successUploadUserProfileImageAction(updatedData));
    } else {
      alert("err");
    }
  } catch (err) {
    alert(err);
    // yield put(getResultsError(getCustomError(err)))
  }
}

function* pagesFetched(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.getFacebookPagesResult, payloadData);
    yield put(successFacebookPageAction(response));
    // const data = response.data;
    // if (response.status === 200) {
    //   let dataObject = JSON.parse(data);
    //   yield put(successFacebookPageAction(dataObject));
    // } else {
    // }
  } catch (err) {
    alert(err);
    // yield put(getResultsError(getCustomError(err)))
  }
}

function* twitterAuthentication(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(
      Api.postTwitterAuthenticationResult,
      payloadData
    );
    const data = response.data;
    if (response.status === 200) {
      let dataObject = JSON.parse(data);
      yield put(successTwitterAuthenticationAction(dataObject));
    } else {
      alert("err");
    }
  } catch (err) {
    alert(err);
    // yield put(getResultsError(getCustomError(err)))
  }
}

function* resendMailVerification(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.postResendMailVerification, payloadData);
    const data = response.data;
    if (response.status === 200) {
      let dataObject = JSON.parse(data);
      yield put(successresendMailVerificationAction(dataObject));
      {
        dataObject.status === true
          ? yield put(
              showToastAction({
                message: dataObject.message,
                type: "success",
              })
            )
          : null;
      }
    } else {
      alert("err");
    }
  } catch (err) {
    alert(err);
    // yield put(getResultsError(getCustomError(err)))
  }
}

function* postToFacebook(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.postToFacebookResult, payloadData);
    const data = response.data;
    if (response.status === 200) {
      let dataObject = JSON.parse(data);
      yield put(successPostToFacebookAction(dataObject));
      yield put(
        showToastAction({
          message: response.message,
          type: "success",
        })
      );
    } else {
      //alert("err");
      yield put(
        showToastAction({
          message: response.message,
          type: "error",
        })
      );
    }
  } catch (err) {
    yield put(
      showToastAction({
        message: err.message,
        type: "error",
      })
    );
    //alert(err);
    // yield put(getResultsError(getCustomError(err)))
  }
}

function* postResetPassword(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.postResetPasswordApiResults, payloadData);
    const data = response.data;
    if (response.status === 200) {
      let updatedData = JSON.parse(data);
      yield put(successPostResetPasswordAction(updatedData));

      {
        updatedData.status === true
          ? yield put(
              showToastAction({
                message: updatedData.message,
                type: "success",
              })
            )
          : null;
      }
    } else {
      alert(response.status);
    }
  } catch (err) {
    alert(err);
    // yield put(getResultsError(getCustomError(err)))
  }
}

function* linkedInIntegration(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.getLinkedInAuthDetails, payloadData);
    if (response.status) {
      const data = response.data;

      yield put(successlinkedInIntegratioAction(data));

      yield put(
        showToastAction({
          message: response.message,
          type: response.status ? "success" : "error",
        })
      );
    } else {
      yield put(showToastAction({ message: response.message, type: "error" }));
    }
  } catch (err) {
    // let payloadData = action.payload;
    console.log("err or linkedin integration ===>", err);
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}

function* facebookIntegration(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.getFacebookAccountDetails, payloadData);
    if (response.status) {
      const data = response.data;

      yield put(succesFacebookIntegratioAction(data));

      yield put(
        showToastAction({
          message: response.message,
          type: response.status ? "success" : "error",
        })
      );
    } else {
      yield put(showToastAction({ message: response.message, type: "error" }));
    }
  } catch (err) {
    // let payloadData = action.payload;
    console.log("err or fb integration ===>", err);
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}

function* instagramIntegration(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.getInstagramAccountDetails, payloadData);
    if (response.status) {
      const data = response.data;

      yield put(succesInstagramIntegratioAction(data));

      yield put(
        showToastAction({
          message: response.message,
          type: response.status ? "success" : "error",
        })
      );
    } else {
      yield put(showToastAction({ message: response.message, type: "error" }));
    }
  } catch (err) {
    // let payloadData = action.payload;
    console.log("err or instagram integration ===>", err);
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}

function* twitterIntegration(action) {
  try {
    let payloadData = action.payload;
    if (payloadData) {
      yield put(succesTwitterIntegrationAction(payloadData));

      yield put(
        showToastAction({
          message: "Twitter Linked Successfully!",
          type: "success",
        })
      );
    } else {
      yield put(
        showToastAction({ message: "Twitter linking failed!", type: "error" })
      );
    }
  } catch (err) {
    console.log("err or twitter integration ===>", err);
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}

function* youtubeIntegration(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.getYoutubeAccountDetails, payloadData);
    if (response.status) {
      const data = response.data;

      yield put(succesYoutubeIntegrationAction(data));

      yield put(
        showToastAction({
          message: response.message,
          type: response.status ? "success" : "error",
        })
      );
    } else {
      yield put(showToastAction({ message: response.message, type: "error" }));
    }
  } catch (err) {
    console.log("err or youtube integration ===>", err);
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}

function* watchLogout(action) {}

function* fetchDraftPosts(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.fetchPosts, payloadData);
    if (response.status) {
      const data = response.data;

      yield put(fetchDraftPostSuccessAction(data));

      yield put(
        showToastAction({
          message: response.message,
          type: response.status ? "success" : "error",
        })
      );
    } else {
      yield put(showToastAction({ message: response.message, type: "error" }));
    }
  } catch (err) {
    console.log("err or draft post filter ===>", err);
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}

function* fetchFailedPosts(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.fetchPosts, payloadData);
    if (response.status) {
      const data = response.data;

      yield put(fetchFailedPostSuccessAction(data));

      yield put(
        showToastAction({
          message: response.message,
          type: response.status ? "success" : "error",
        })
      );
    } else {
      yield put(showToastAction({ message: response.message, type: "error" }));
    }
  } catch (err) {
    console.log("err or failed post filter ===>", err);
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}

function* fetchPublishedPosts(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.fetchPosts, payloadData);
    if (response.status) {
      const data = response.data;

      yield put(fetchPublishedPostSuccessAction(data));

      yield put(
        showToastAction({
          message: response.message,
          type: response.status ? "success" : "error",
        })
      );
    } else {
      yield put(showToastAction({ message: response.message, type: "error" }));
    }
  } catch (err) {
    console.log("err or published post filter ===>", err);
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}

function* fetchScheduledPosts(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.fetchPosts, payloadData);
    if (response.status) {
      const data = response.data;

      yield put(fetchScheduledPostSuccessAction(data));

      yield put(
        showToastAction({
          message: response.message,
          type: response.status ? "success" : "error",
        })
      );
    } else {
      yield put(showToastAction({ message: response.message, type: "error" }));
    }
  } catch (err) {
    console.log("err or scheduled post filter ===>", err);
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}

function* deletePost(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.deletePost, payloadData);
    if (response.status) {
      const data = response.data;

      yield put(deletePostSuccessAction(data));

      yield put(
        showToastAction({
          message: response.message,
          type: response.status ? "Delete Successful" : "Delete error",
        })
      );

      const User = yield select((state) => state.postPublisherSlice.user);
      const Token = yield select((state) => state.postPublisherSlice.tokens);

      const publishedParams = {
        filter: {
          user: User?._id,
          "postDetails.isDraft": false,
          "postDetails.isScheduled": false,
          "postDetails.isPublished": true,
          "postDetails.isPaused": false,
          isDeleted: false,
          fromdt: null,
          todt: null,
        },
        token: Token?.access?.token,
      };

      yield put(fetchPublishedPostAction(publishedParams));

      const failedParams = {
        filter: {
          user: User?._id,
          "postDetails.isDraft": false,
          "postDetails.isScheduled": false,
          "postDetails.isPublished": false,
          "postDetails.isPaused": false,
          isDeleted: false,
          fromdt: null,
          todt: null,
        },
        token: Token?.access?.token,
      };
      yield put(fetchFailedPostAction(failedParams));

      const scheduledParams = {
        filter: {
          user: User?._id,
          "postDetails.isDraft": false,
          "postDetails.isScheduled": true,
          "postDetails.isPublished": false,
          // "postDetails.isPaused": false,
          isDeleted: false,
          fromdt: null,
          todt: null,
        },
        token: Token?.access?.token,
      };
      yield put(fetchScheduledPostAction(scheduledParams));

      const draftParams = {
        filter: {
          user: User?._id,
          "postDetails.isDraft": true,
          "postDetails.isScheduled": false,
          "postDetails.isPublished": false,
          "postDetails.isPaused": false,
          isDeleted: false,
          fromdt: null,
          todt: null,
        },
        token: Token?.access?.token,
      };
      yield put(fetchDraftPostAction(draftParams));
    } else {
      yield put(showToastAction({ message: response.message, type: "error" }));
    }
  } catch (err) {
    console.log("err or scheduled post filter ===>", err);
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}

function* playNPause(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.pauseNPlayPost, payloadData);
    if (response.status) {
      const data = response.data;

      yield put(playNPauseSuccessAction(data));

      yield put(
        showToastAction({
          message: response.message,
          type: response.status ? "Success" : "error",
        })
      );
    } else {
      yield put(showToastAction({ message: response.message, type: "error" }));
    }
  } catch (err) {
    console.log("err or playNPause ===>", err);
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}

function* playNPauseAccount(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.updateAccounts, payloadData);
    if (response.status) {
      const data = response.data;

      yield put(playNPauseAccountSuccessAction(data));

      const User = yield select((state) => state.postPublisherSlice.user);
      const Token = yield select((state) => state.postPublisherSlice.tokens);

      const userFullDetailsData = {
        user: User?._id,
        token: Token?.access?.token,
      };
      yield put(getUsersFullDetailsAction(userFullDetailsData));

      yield put(
        showToastAction({
          message: response.message,
          type: response.status ? "Success" : "error",
        })
      );
    } else {
      yield put(showToastAction({ message: response.message, type: "error" }));
    }
  } catch (err) {
    console.log("err or playNPauseAccount ===>", err);
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}

function* fetchUserGroups(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.getGroups, payloadData);
    if (response.status) {
      const data = response.data;
      yield put(fetchGroupSuccessAction(data));

      // yield put(
      //   showToastAction({
      //     message: response.message,
      //     type: response.status ? "Success" : "error",
      //   })
      // );
    } else {
      yield put(showToastAction({ message: response.message, type: "error" }));
    }
  } catch (err) {
    console.log("err or fetchUserGroups ===>", err);
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}

function* updateGroups(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.updateGroups, payloadData);
    if (response.status) {
      const data = response.data;

      yield put(updateGroupsSuccessAction(data));

      const User = yield select((state) => state.postPublisherSlice.user);
      const Token = yield select((state) => state.postPublisherSlice.tokens);

      const userFullDetailsData = {
        user: User?._id,
        token: Token?.access?.token,
      };
      yield put(fetchGroupAction(userFullDetailsData));

      yield put(
        showToastAction({
          message: response.message,
          type: response.status ? "Success" : "error",
        })
      );
    } else {
      yield put(showToastAction({ message: response.message, type: "error" }));
    }
  } catch (err) {
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}

function* createGroups(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.createGroups, payloadData);
    if (response.status) {
      const data = response.data;

      yield put(createGroupSuccessAction(data));

      const User = yield select((state) => state.postPublisherSlice.user);
      const Token = yield select((state) => state.postPublisherSlice.tokens);

      // const userFullDetailsData = {
      //   user: User?._id,
      //   token: Token?.access?.token,
      // };
      // yield put(fetchGroupAction(userFullDetailsData));

      yield put(
        showToastAction({
          message: response.message,
          type: response.status ? "Success" : "error",
        })
      );
    } else {
      yield put(showToastAction({ message: response.message, type: "error" }));
    }
  } catch (err) {
    console.log("err or updateGroups ===>", err);
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}
function* fetchQueryData(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.fetchQueryData, payloadData);

    if (response.status) {
      const data = response.data;
      yield put(fetchQuerySuccessAction(data));
      yield put(
        showToastAction({
          message: response.message,
          type: response.status ? "Success" : "error",
        })
      );
    } else {
      yield put(showToastAction({ message: response.message, type: "error" }));
    }
  } catch (err) {
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    yield put(
      showToastAction({ message: errDataToObj.message, type: "error" })
    );
  }
}
function* createQuery(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.createQuery, payloadData);
    const responseObject = JSON.parse(response.data);

    if (response.status === 200) {
      yield put(successCreateQueryAction(responseObject));
      yield put(
        showToastAction({
          message: responseObject.message.message,

          type: "success",
        })
      );
    } else {
      alert(response.status);
    }
  } catch (err) {
    // yield put(getResultsError(getCustomError(err)))
    // console.log("02-03-24 100 08", err);

    const errResponseCreateQuery = JSON.parse(err.response.data);
    yield put(
      showToastAction({
        message: errResponseCreateQuery.message,
        type: "error",
      })
    );
  }
}

export function* postPublisherSaga() {
  yield all([
    takeLatest(postUsersSignInAction.type, postUsersSignIn),
    takeLatest(postCreateBlogAction.type, postCreateBlog),
    takeLatest(getCatAction.type, getApprovers),
    takeLatest(postUsersSignUpAction.type, postUsersSignUp),
    takeLatest(postAdminSignInAction.type, postAdminSignIn),
    takeLatest(getUsersFullDetailsAction.type, getUsersFullDetails),
    takeLatest(getAllBlogAction.type, getAllBlog),
    takeLatest(postCreateQueryAction.type, createQuery),
    takeLatest(putUpdateUserProfileAction.type, putUpdateUserProfile),
    takeLatest(
      postUploadUserProfileImageAction.type,
      postUploadUserProfileImage
    ),
    takeLatest(postForgotPasswordAction.type, postForgotPassword),
    takeLatest(postVerifyOtpAction.type, postVerifyOtp),
    takeLatest(postChangePasswordAction.type, changePassword),
    takeLatest(getFacebookPageAction.type, pagesFetched),
    takeLatest(postTwitterAuthenticationAction.type, twitterAuthentication),
    takeLatest(postresendMailVerificationAction.type, resendMailVerification),
    takeLatest(postToFacebookAction.type, postToFacebook),
    takeLatest(postResetPasswordAction.type, postResetPassword),
    takeLatest(linkedInIntegrationAction.type, linkedInIntegration),
    takeLatest(facebookIntegrationAction.type, facebookIntegration),
    takeLatest(instagramIntegrationAction.type, instagramIntegration),
    takeLatest(twitterIntegrationAction.type, twitterIntegration),
    takeLatest(youtubeIntegrationAction.type, youtubeIntegration),
    takeLatest(logoutAction.type, watchLogout),
    takeLatest(fetchDraftPostAction.type, fetchDraftPosts),
    takeLatest(fetchFailedPostAction.type, fetchFailedPosts),
    takeLatest(fetchPublishedPostAction.type, fetchPublishedPosts),
    takeLatest(fetchScheduledPostAction.type, fetchScheduledPosts),
    takeLatest(deletePostAction.type, deletePost),
    takeLatest(playNPauseAction.type, playNPause),
    takeLatest(playNPauseAccountAction.type, playNPauseAccount),
    takeLatest(fetchGroupAction.type, fetchUserGroups),
    takeLatest(updateGroupsAction.type, updateGroups),
    takeLatest(createGroupAction.type, createGroups),
    takeLatest(fetchQueryDataAction.type, fetchQueryData),
  ]);
}
