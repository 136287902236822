import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Scrollbars from "react-custom-scrollbars";
import Profile from "../../assests/images/Ellipse 94.png";
import HookForm from "../../components/HookForm/HookForm";
import CheckBox from "../../components/UI/CheckBox/CheckBox";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import Label from "../../components/UI/Label/Label";
import TextField from "../../components/UI/TextField/TextField";
import { useAppData } from "../../services/hooks";
import Message from "../../util/message";
import "./SocialmediaPopup.scss";

const socialPopup = {
  search: {
    name: "search",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.ALFAVALID,
      },
    },
  },
};
const SocialmediaPopup = () => {
  const [form, setLoginForm] = React.useState();
  // console.log(form);
  // const stateValuen = useAppData((r) => r);
  const stateValue = useAppData(
    (r) => r.postPublisherSlice.users.facebookPageResponseValue.data.pages
  );
  const onFormSubmit = () => {};

  return (
    <div className="socialmedia_Popup">
      <Scrollbars>
        <div className="main_Container">
          <div className="top_Column">
            <Label title="Facebook Page" className="top_Heading"></Label>
            <div>
              <i className="icon-close"></i>
            </div>
          </div>
          <div className="choose_Page">
            <Label
              title="Choose the page or group you’d like to manage"
              className="choose_Text"
            ></Label>
          </div>

          <Tabs
            defaultActiveKey="pages"
            transition={false}
            id="noanim-tab-example"
            className="mb-3 category_Select"
          >
            <Tab eventKey="groups" title="Groups"></Tab>
            <Tab eventKey="pages" title="Pages"></Tab>
          </Tabs>
          <div className="content_Box">
            <div className="search_Box">
              <div className="icon_Search">
                <i className="icon-search"></i>
              </div>

              <div className="search_Here">
                <HookForm
                  defaultForm={{}}
                  init={(form) => setLoginForm(form)}
                  onSubmit={onFormSubmit}
                >
                  {(formMethod) => {
                    return (
                      <div className="form1">
                        <TextField
                          formMethod={formMethod}
                          rules={socialPopup.search.validate}
                          name={socialPopup.search.name}
                          errors={formMethod?.errors}
                          autoFocus={false}
                          type="text"
                          placeholder="      Search the page here"
                        />
                      </div>
                    );
                  }}
                </HookForm>
              </div>
            </div>
            <div className="line"></div>

            {stateValue.map((curElem, index) => {
              return (
                <div className="group_Class" key={index}>
                  <div className="group">
                    <div className="photo_Name">
                      <div>
                        <img
                          src={Profile}
                          alt="image"
                          style={{ maxWidth: "100%", height: "auto" }}
                          // fluid="true"
                          className="profile"
                        />
                      </div>
                      <div>
                        {" "}
                        <Label title={curElem.name} className="name" />
                      </div>
                    </div>
                    <div className="check">
                      <CheckBox labelTitle="" checked={curElem.isActive} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="try_Reconnect">
            <Label
              className="text_Dont"
              title="Don’t see what you’re looking for?"
            />
            <Label className="text_Reconnect" title=" Try to Reconnect" />
          </div>
          <div className="button_Area">
            <div className="cancel_Button">
              <CustomButton title="Cancel"></CustomButton>
            </div>
            <div className="add_Button">
              {" "}
              <CustomButton title="Connect Account"></CustomButton>
            </div>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default SocialmediaPopup;
