import React from "react";

import CustomButton from "../../UI/CustomButton/CustomButton";
import Label from "../../UI/Label/Label";

import "./PlanCard.scss";

const PlanCardView = (props) => {
  let {
    planHead,
    planPrice,
    plandetail1,
    plandetail2,
    plandetail3,
    plandetail4,
    plandetail5,
    plandetail6,
    buttonTitle,
    buttonSubTitle,
    bottomLabel,
  } = props;

  return (
    <div className={"planCard " + props.className}>
      <div className="card_Head">
        <Label title={planHead}></Label>
        <Label title={planPrice}></Label>
      </div>
      <div className="plans_Details">
        <div className="icon_Text">
          <i className="icon-tick"></i>
          <Label title={plandetail1}></Label>
        </div>
        <div className="icon_Text">
          <i className="icon-tick"></i>
          <Label title={plandetail2}></Label>
        </div>
        <div className="icon_Text">
          <i className="icon-tick"></i>
          <Label title={plandetail3}></Label>
        </div>
        <div className="icon_Text">
          <i className="icon-tick"></i>
          <Label title={plandetail4}></Label>
        </div>
        <div className="icon_Text">
          <i className="icon-tick"></i>
          <Label title={plandetail5}></Label>
        </div>
        <div className="icon_Text">
          <i className="icon-tick"></i>
          <Label title={plandetail6}></Label>
        </div>
      </div>
      <CustomButton title={buttonTitle}></CustomButton>
      <CustomButton
        title={buttonSubTitle}
        className="submitBtn outlinBtn"
      ></CustomButton>
      <Label
        title={bottomLabel}
        className="bottonText d-flex justify-content-center pb-0"
      ></Label>
    </div>
  );
};

export default PlanCardView;
