import React from 'react';

const YouTubeLogin = () => {
  const popupWidth = 700;
  const popupHeight = 700;

  const handleYouTubeAddClick = async () => {
    try {
      const bearerToken = localStorage.getItem('token');

      const response = await fetch(
        'http://localhost:3003/v1/operations/youtube/oauth/generatelink',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearerToken}`,
          },
        },
      );
      const data = await response.json();

      const left = window.innerWidth / 2 - popupWidth / 2;
      const top = window.innerHeight / 2 - popupHeight / 2;
      window.open(
        data.data,
        'YouTube Authentication',
        `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`,
      );
    } catch (error) {
      console.error('Error fetching YouTube auth URL:', error);
    }
  };

  return (
    <div>
      <i className="icon-plus" onClick={handleYouTubeAddClick}></i>
    </div>
  );
};

export default YouTubeLogin;
