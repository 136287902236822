import React from "react";
import Label from "../UI/Label/Label";
import "./BlogFeature.scss";
import bloglike from "../../assests/images/bloglike.svg";
import blogcomment from "../../assests/images/blogcomment.svg";
import blogshare from "../../assests/images/blogshare.svg";
import blogbookmark from "../../assests/images/blogbookmark.svg";
import blogmore from "../../assests/images/blogmore.svg";
import { OverlayTrigger, Popover } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FacebookIcon, LinkedInIcon, TwitterIcon, Whatsaap } from "./BlogIcons";
import likefilled from "../../assests/images/likefilled.svg";
import bookmarkfilled from "../../assests/images/bookmarkfilled.svg";

const staticPopUpData = [
  {
    image: <TwitterIcon className="icon-hover" />,
    modalTitle: "Share on Twitter",
  },
  {
    image: <FacebookIcon className="icon-hover" />,
    modalTitle: "Share on Facebook",
  },
  {
    image: <LinkedInIcon className="icon-hover" />,
    modalTitle: "Share on LinkedIn",
  },
  {
    image: <Whatsaap className="icon-hover" />,
    modalTitle: "Share on Whatsapp",
  },
];
const modalPopUp = (
  <Popover className="pop" id="popover-basic">
    <div className="modalPopUp">
      {staticPopUpData.map((cur, index) => {
        return (
          <div className="modal-icon-text parent-hover" key={index}>
            {cur.image}
            <Label title={cur.modalTitle} className="title" />
          </div>
        );
      })}
    </div>
  </Popover>
);

const modalPopUpMore = (
  <Popover arrowProps="90%" className="pop" id="popover-basic">
    <div className="modalPopUp">
      <div className="modal-more">
        <Label title="Report" className="title-report on-hover" />
        <Label title="Bookmark List" className="title on-hover" />
      </div>
    </div>
  </Popover>
);

const BlogFeature = (props) => {
  const [likeCount, setLikeCount] = React.useState(0);
  const [bookmarkSave, setBookmarkSave] = React.useState(true);

  let { clickToShowModal } = props;

  const handleClickLikeIcon = () => {
    if (likeCount <= 49) {
      setLikeCount(likeCount + 1);
    }
  };

  const handleClickBookMarkIcon = () => {
    setBookmarkSave(false);
  };

  const modalPopUpLike = (
    <Popover arrowProps="90%" className="pop" id="popover-basic">
      <div className="modalPopUpLike">
        <Label title={likeCount} className="likeHover" />
      </div>
    </Popover>
  );

  return (
    <div className="blog-feature-parent">
      <div className="card-wrapper">
        <div className="card-inner">
          <div className="card-left-section">
            <div className="icon-text">
              <OverlayTrigger
                trigger="hover"
                overlay={modalPopUpLike}
                placement="left"
                rootClose
              >
                {likeCount >= 1 ? (
                  <img
                    src={likefilled}
                    className="like-icon"
                    onClick={handleClickLikeIcon}
                  />
                ) : (
                  <img
                    src={bloglike}
                    onClick={handleClickLikeIcon}
                    className="like-icon"
                    alt="like"
                  />
                )}
              </OverlayTrigger>

              <Label title={likeCount} className="like-text" />
            </div>
            <div className="icon-text">
              <img
                src={blogcomment}
                className="comment-icon"
                alt="comment"
                onClick={clickToShowModal}
              />

              <Label title="1.8k" className="like-text" />
            </div>
          </div>
          <div className="card-right-section">
            <div className="icon-text">
              <OverlayTrigger
                trigger="click"
                overlay={modalPopUp}
                placement="bottom"
                rootClose
              >
                <img src={blogshare} className="right-icon" alt="share" />
              </OverlayTrigger>
            </div>
            <div className="icon-text">
              {bookmarkSave === true ? (
                <img
                  src={blogbookmark}
                  className="right-icon"
                  alt="bookmark"
                  onClick={handleClickBookMarkIcon}
                />
              ) : (
                <img
                  src={bookmarkfilled}
                  className="right-icon"
                  alt="bookmark"
                />
              )}
            </div>
            <div className="icon-text">
              <OverlayTrigger
                trigger="click"
                overlay={modalPopUpMore}
                placement="bottom"
                rootClose
              >
                <img src={blogmore} className="right-icon" alt="more" />
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogFeature;
