import React, { useState } from "react";
import { Col, ListGroup, Nav, Navbar, Row, Tab } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import { Link, useHistory, withRouter } from "react-router-dom";
import ProfileImag from "../../assests/images/profile.png";
import Label from "../../components/UI/Label/Label";
import { useAppData } from "../../services/hooks";
import "./Header.scss";
import { useDispatch } from "react-redux";
import {
 logoutAction,
} from "../../Redux_saga/postPublisherSlice";

const Header = (props) => {
  const [clickOutside, setClickOutside] = useState(true);
  const history = useHistory();

  const userDetailStateValue = useAppData(
    (r) => r.postPublisherSlice.users.UsersFullDetailsValue.data
  );

  let activeMenu = "";
  const dispatch = useDispatch();

  if (props.location.pathname.indexOf("dashboard") != -1) {
    activeMenu = "dashboard";
  } else if (props.location.pathname.indexOf("profile") != -1) {
    activeMenu = "profile";
  } else if (props.location.pathname.indexOf("PublisherAll") != -1) {
    activeMenu = "PublisherAll";
  } else if (props.location.pathname.indexOf("group") != -1) {
    activeMenu = "group";
  } else if (props.location.pathname.indexOf("calendar") != -1) {
    activeMenu = "calendar";
  } else if (props.location.pathname.indexOf("statistics") != -1) {
    activeMenu = "statistics";
  } else if (props.location.pathname.indexOf("setting") != -1) {
    activeMenu = "setting";
  } else if (props.location.pathname.indexOf("teams") != -2) {
    activeMenu = "teams";
  }

  const renderTooltip1 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Home
    </Tooltip>
  );
  const renderTooltip2 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Social profile
    </Tooltip>
  );
  const renderTooltip3 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Publisher
    </Tooltip>
  );
  const renderTooltip4 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Groups
    </Tooltip>
  );
  const renderTooltip5 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Calendar
    </Tooltip>
  );
  const renderTooltip6 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Statistics
    </Tooltip>
  );
  const renderTooltip7 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Setting
    </Tooltip>
  );
  const renderTooltip8 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Log out
    </Tooltip>
  );
  const renderTooltip9 = (props) => (
    <Tooltip className="button-tooltip" {...props}>
      Team & Client
    </Tooltip>
  );
  const LogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("intialLoad");
    dispatch(logoutAction())
    history.push("/login");
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setClickOutside(false);
      }}
    >
      <div className="userHeader">
        <div className="container">
          <div className="row">
            <div>
              <Navbar>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse
                  id="basic-navbar-nav"
                  style={{ background: "red" }}
                >
                  {/* <span className="responsive_title"> Publish</span> */}
                  <div>
                    <div className="profile_Section">
                      <img
                        src={
                          userDetailStateValue === undefined
                            ? ProfileImag
                            : userDetailStateValue.imageUrl
                        }
                        alt="image"
                        style={{ maxWidth: "100%", height: "auto" }}
                        // fluid="true"
                        className="main_Img"
                      />
                      <div className="border-bottom"></div>
                    </div>
                    <Nav
                      className="menuItem"
                      activeKey={activeMenu}
                      onSelect={() => setClickOutside(!clickOutside)}
                    >
                      <Nav.Link eventKey="dashboard" as={Link} to="dashboard">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip1}
                        >
                          <i className="icon-home"></i>
                        </OverlayTrigger>
                      </Nav.Link>
                      <Nav.Link
                        eventKey="profile"
                        as={Link}
                        to="socialprofilelist?Facebook"
                      >
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip2}
                        >
                          <i className="icon-profile"></i>
                        </OverlayTrigger>
                      </Nav.Link>
                      <Nav.Link
                        eventKey="PublisherAll"
                        as={Link}
                        to="PublisherAll?publish"
                        // showSidebar={showMenu}
                      >
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip3}
                        >
                          <i className="icon-network"></i>
                        </OverlayTrigger>
                      </Nav.Link>
                      <Nav.Link eventKey="group" as={Link} to="group">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip4}
                        >
                          <i className="icon-manage"></i>
                        </OverlayTrigger>
                      </Nav.Link>
                      <Nav.Link eventKey="calendar" as={Link} to="calendar">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip5}
                        >
                          <i className="icon-event"></i>
                        </OverlayTrigger>
                      </Nav.Link>
                      <Nav.Link eventKey="statistics" as={Link} to="statistics">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip6}
                        >
                          <i className="icon-graph"></i>
                        </OverlayTrigger>
                      </Nav.Link>
                      <Nav.Link eventKey="setting" as={Link} to="setting">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip7}
                        >
                          <i className="icon-setting"></i>
                        </OverlayTrigger>
                      </Nav.Link>
                      <Nav.Link eventKey="teams" as={Link} to="teams">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip9}
                        >
                          <i className="icon-teams"></i>
                        </OverlayTrigger>
                      </Nav.Link>
                    </Nav>
                  </div>
                  <div className="colorIcon" style={{ marginBottom: "24px" }}>
                    <Nav.Link eventKey="log out" onClick={LogOut}>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip8}
                      >
                        {/* <i className="icon-setting"></i> */}
                        <i className="icon-logout1"></i>
                      </OverlayTrigger>
                    </Nav.Link>
                  </div>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        </div>

        {/* Sub Menu */}

        <div
          className={
            activeMenu === "PublisherAll" && clickOutside
              ? "sub_Menu show"
              : "sub_Menu "
          }
        >
          <div className="inner_SabMenu">
            <Label title="Publisher"></Label>
            <Tab.Container
              className="subMenu_Ul"
              id="list-group-tabs-example"
              defaultActiveKey="#Publish"
            >
              <Row>
                <Col sm={8}>
                  <ListGroup>
                    <ListGroup.Item
                      className="subMenu_Li "
                      onClick={() => history.push("/PublisherAll?publish")}
                      // href="#Publish"
                    >
                      Publish
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      onClick={() => history.push("/PublisherAll?schedule")}
                      // href="#Schedule"
                    >
                      Schedule
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      onClick={() => history.push("/PublisherAll?published")}
                      // href="#Published"
                    >
                      Published
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      onClick={() => history.push("/PublisherAll?failed")}
                      // href="#Failed"
                    >
                      Failed
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      onClick={() => history.push("/PublisherAll?draft")}
                      // href="#Draft"
                    >
                      Draft
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>

        {/* //social profile */}

        <div
          className={
            activeMenu === "profile" && clickOutside
              ? "sub_Menu show"
              : "sub_Menu "
          }
        >
          <div className="inner_SabMenu">
            <Label title="Social profile"></Label>
            <Tab.Container
              className="subMenu_Ul"
              id="list-group-tabs-example"
              defaultActiveKey="#Facebook"
            >
              <Row>
                <Col sm={8}>
                  <ListGroup>
                    <ListGroup.Item
                      className="subMenu_Li "
                      onClick={() =>
                        history.push("/socialprofilelist?Facebook")
                      }
                      // href="#Facebook"
                    >
                      Facebook
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li "
                      onClick={() => history.push("/socialprofilelist?Twitter")}
                      // href="#Twitter"
                    >
                      Twitter
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      onClick={() =>
                        history.push("/socialprofilelist?Linkedin")
                      }
                      // href="#LinkedIn"
                    >
                      LinkedIn
                    </ListGroup.Item>

                    <ListGroup.Item
                      className="subMenu_Li"
                      onClick={() =>
                        history.push("/socialprofilelist?Instagram")
                      }
                      // href="#Instagram"
                    >
                      Instagram
                    </ListGroup.Item>

                    <ListGroup.Item
                      className="subMenu_Li"
                      onClick={() => history.push("/socialprofilelist?Youtube")}
                      // href="#Youtube"
                    >
                      Youtube
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      onClick={() => history.push("/socialprofilelist?TikTok")}
                      // href="#TikTok"
                    >
                      TikTok
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>

        {/* Sub Menu calender */}
        <div
          className={
            activeMenu === "calendar" && clickOutside
              ? "sub_Menu show"
              : "sub_Menu "
          }
        >
          <div className="inner_SabMenu">
            <Label title="Calendar"></Label>
            <Tab.Container
              className="subMenu_Ul"
              id="list-group-tabs-example"
              defaultActiveKey="#All"
            >
              <Row>
                <Col sm={10}>
                  <ListGroup>
                    {/* <ListGroup.Item
                      className="subMenu_Li"
                      // href="#All"
                      onClick={() => history.push("/calendar?all")}
                    >
                      All
                    </ListGroup.Item> */}
                    <ListGroup.Item
                      className="subMenu_Li"
                      // href="#CustomGroup"
                      onClick={() => history.push("/calendar?customgroup")}
                    >
                      Custom Group
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      // href="#Facebookcal"
                      onClick={() => history.push("/calendar?facebook")}
                    >
                      Facebook
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      // href="#twittercal"
                      onClick={() => history.push("/calendar?twitter")}
                    >
                      twitter
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      // href="#LinkedIncal"
                      onClick={() => history.push("/calendar?linkedin")}
                    >
                      LinkedIn
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      // href="#instagramcal"
                      onClick={() => history.push("/calendar?instagram")}
                    >
                      Instagram
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      // href="#youtubecal"
                      onClick={() => history.push("/calendar?youtube")}
                    >
                      Youtube
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      // href="#tiktokcal"
                      onClick={() => history.push("/calendar?tiktok")}
                    >
                      TikTok
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
        {/* Sub Statistics */}
        <div
          className={
            activeMenu === "statistics" && clickOutside
              ? "sub_Menu show"
              : "sub_Menu "
          }
        >
          <div className="inner_SabMenu">
            <Label title="Statistics"></Label>
            <Tab.Container
              className="subMenu_Ul"
              id="list-group-tabs-example"
              defaultActiveKey="#Group"
            >
              <Row>
                <Col sm={8}>
                  <ListGroup>
                    <ListGroup.Item
                      className="subMenu_Li "
                      // href="#Group"
                      onClick={() => history.push("/statistics?group")}
                    >
                      Group
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      // href="#Facebookgrp"
                      onClick={() => history.push("/statistics?facebook")}
                    >
                      Facebook
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      // href="#Twitterkgrp"
                      onClick={() => history.push("/statistics?twitter")}
                    >
                      Twitter
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      // href="#LinkedIngrp"
                      onClick={() => history.push("/statistics?linkedin")}
                    >
                      LinkedIn
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      // href="#instagramgrp"
                      onClick={() => history.push("/statistics?instagram")}
                    >
                      Instagram
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      // href="#youtubegrp"
                      onClick={() => history.push("/statistics?youtube")}
                    >
                      Youtube
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="subMenu_Li"
                      // href="#tiktokgrp"
                      onClick={() => history.push("/statistics?tiktok")}
                    >
                      TikTok
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default withRouter(Header);
