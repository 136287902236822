import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Label from "../UI/Label/Label";
import "./BlogCommentList.scss";

const modalPopUpMore = (
  <Popover arrowProps="90%" className="pop" id="popover-basic">
    <div className="modalPopUp">
      <div className="modal-more">
        <Label title="Report" className="title-report on-hover" />
        <Label title="Undo Like" className="title on-hover" />
      </div>
    </div>
  </Popover>
);

const BlogCommentList = ({
  cur,
  handleClickReplyText,
  handleClickCommentIcon,
  handleClickLikeIcon,
}) => {
  return (
    <div className="blog-comment-list-parent">
      <div className="top-section">
        <div className="left-section">
          <img src={cur.profileImage} alt="profile" className="profile-img" />
          <div className="profile-name-time">
            <Label title={cur.profileName} className="profile-name" />
            <Label title={cur.profileTime} className="profile-time" />
          </div>
        </div>
        <OverlayTrigger
          trigger="click"
          overlay={modalPopUpMore}
          placement="bottom"
          rootClose
        >
          <i className="icon-edit"></i>
        </OverlayTrigger>
      </div>
      <div className="center-section">
        <Label className="center-text" title={cur.commentPara} />
      </div>
      <div className="bottom-section">
        <div className="icons-section">
          <div className="icons-left">
            <div className="like-reply-main">
              <div
                alt="like"
                className="icon-like-like"
                onClick={handleClickLikeIcon}
              >
                {cur.likeIcon}
              </div>
              <Label title={cur.likeText} className="like-text" />
              {cur.commentIcon && (
                <img
                  src={cur.commentIcon}
                  alt="comment"
                  className="icon-comment"
                  onClick={handleClickCommentIcon}
                />
              )}
              <Label title={cur.commentText} className="like-text" />
            </div>
          </div>
          <div className="icon-right">
            <Label
              title={cur.replyText}
              className="reply"
              onClick={handleClickReplyText}
            />
          </div>
        </div>
      </div>
      <div className="border-bottom-line"></div>
    </div>
  );
};

export default BlogCommentList;
