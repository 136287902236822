import React from "react";
import Label from "../UI/Label/Label";
import "./BlogModal.scss";
import BlogCommentCard from "./BlogCommentCard";
import BlogCommentList from "./BlogCommentList";
import profile from "../../assests/images/profile.png";
// import LikeIcon from '../../assests/images/LikeIcon.svg';
import blogcomment from "../../assests/images/blogcomment.svg";
import { LikeIcon } from "./BlogIcons";

const BlogModal = (props) => {
  let { closeModal } = props;
  const [clickedIndexMain, setClickedIndexMain] = React.useState(-1);
  const [enable, setEnable] = React.useState(false);
  const [enableCommentChild, setEnableCommentChild] = React.useState(-1);
  const [enableReplyChild, setEnableReplyChild] = React.useState(-1);
  const [likeCount, setLikeCount] = React.useState(0);
  const [likeCountReply, setLikeCountReply] = React.useState(0);

  const commentListStaticData = [
    {
      profileImage: profile,
      profileName: "John Peter",
      profileTime: "2 hours ago",
      commentPara:
        "Thank you for this enormously useful guide, Casey! I learned so much and have saved it for future reference. Writing this article was helpful and smart. You’ve provided lots of great information and tips, especially for writers new to Medium and…...",
      likeIcon:
        likeCount > 0 ? (
          <LikeIcon fillStroke="#5696FA" fillBg="#5696FA" />
        ) : (
          <LikeIcon fillStroke="#5696FA" />
        ),
      likeText: likeCount,
      commentIcon: blogcomment,
      commentText: "58 Reply",
      replyText: "Reply",
      reply: [
        {
          profileImage: profile,
          profileName: "Bil Jonson",
          profileTime: "2 hours ago",
          commentPara: "Thank you for this enormously useful guide, ",
          likeIcon:
            likeCountReply > 0 ? (
              <LikeIcon fillStroke="#5696FA" fillBg="#5696FA" />
            ) : (
              <LikeIcon fillStroke="#5696FA" />
            ),
          likeText: likeCountReply,
          replyText: "Reply",
        },
      ],
    },
  ];

  const handleClickReplyTextMain = (index) => {
    if (index === clickedIndexMain) {
      setClickedIndexMain(-1);
    } else {
      setClickedIndexMain(index);
    }
  };
  const handleClickReplyTextChild = (index) => {
    if (index === enableReplyChild) {
      setEnableReplyChild(-1);
    } else {
      setEnableReplyChild(index);
    }
  };

  const handleClickCommentIcon = (index) => {
    if (index === enableCommentChild) {
      setEnableCommentChild(-1);
    } else {
      setEnableCommentChild(index);
    }
  };

  const handleClickLikeIcon = () => {
    if (likeCount <= 49) {
      setLikeCount(likeCount + 1);
    }
  };

  const handleClickLikeIconReply = () => {
    if (likeCountReply <= 49) {
      setLikeCountReply(likeCountReply + 1);
    }
  };

  return (
    <div className="blog-modal-grand-parent">
      {enable === false ? (
        <BlogCommentCard
          clickToDisable={() => setEnable(true)}
          closeModal={closeModal}
          iconClose="icon-close"
          profileImage={profile}
          profileName="John Peter"
        />
      ) : (
        <div className="blog-modal-small-card" onClick={() => setEnable(false)}>
          <div className="top-section">
            <i className="icon-close" onClick={closeModal}></i>
          </div>
          <div className="comment-card-before">
            <Label title="Share Your Thoughts" className="profile-name"></Label>
          </div>
        </div>
      )}
      <div className="blog-commnent-section-parent">
        {commentListStaticData.map((cur, index) => (
          <div key={index}>
            <BlogCommentList
              key={index}
              cur={cur}
              handleClickCommentIcon={() => {
                handleClickCommentIcon(index);
              }}
              handleClickReplyText={() => {
                handleClickReplyTextMain(index);
              }}
              handleClickLikeIcon={handleClickLikeIcon}
            />
            {index === clickedIndexMain && (
              <div className="comment-reply-box">
                <BlogCommentCard
                  clickToDisable={() => setClickedIndexMain(-1)}
                />
              </div>
            )}
            <div className="child-comment-box">
              {index === enableCommentChild && (
                <div className="">
                  {commentListStaticData[index].reply.map((cur, key) => (
                    <div key={index}>
                      <BlogCommentList
                        key={key}
                        cur={cur}
                        handleClickReplyText={() => {
                          handleClickReplyTextChild(key);
                        }}
                        handleClickLikeIcon={handleClickLikeIconReply}
                      />
                      {key === enableReplyChild && (
                        <div className="comment-reply-box">
                          <BlogCommentCard
                            clickToDisable={() => {
                              setEnableReplyChild(-1);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogModal;
