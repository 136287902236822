import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import { postAdminSignInAction } from "../../Redux_saga/postPublisherSlice";
import MainImg from "../../assests/images/main-img.png";
import PatterBottom from "../../assests/images/patten-btm.png";
import PatterTop from "../../assests/images/patten-top.png";
import HookForm from "../../components/HookForm/HookForm";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import Label from "../../components/UI/Label/Label";
import TextField from "../../components/UI/TextField/TextField";
import { useAppData } from "../../services/hooks";
import Constant from "../../util/constant";
import Message from "../../util/message";
import {
  guideTourObjAdmin,
  localStorageSetItem,
} from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { adminLoginSteps } from "../Steps";
import "./AdminLogin.scss";

const loginForm = {
  email: {
    name: "emailId",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.EMAILEMPTY,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ERRORMESSAGE.EMAILINVALID,
      },
    },
  },
  password: {
    name: "password",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.PASSWORDEMPTY,
      },
    },
  },
};

function AdminLogin() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [form, setLoginForm] = React.useState();
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const loginAdminStateValue = useAppData((r) => r.postPublisherSlice.admin);
  const [showPassword, setShowPassword] = useState(true);
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();

  // console.log(form);
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const onFormSubmit = (form) => {
    dispatch(postAdminSignInAction(form));
    const token = localStorage.getItem("token");
    if (token) {
      localStorage.clear("token");
    }
  };

  useEffect(() => {
    const Admintoken = localStorage.getItem("Admintoken");
    if (Admintoken) {
      history.push("./admindashboard");
    }
  }, [loginAdminStateValue]);

  const handleOnRequestClose = () => {
    let newObj = { ...guideTourObjAdmin, adminLogin: false };
    localStorage.setItem("guideTourObjAdmin", JSON.stringify(newObj));

    setIsOpenTourGuide(newObj);
  };

  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(
    localStorage.getItem("guideTourObjAdmin")
  );

  return (
    <div className="Login_Page" style={{ height: dimensions.height + "px" }}>
      <img
        src={PatterTop}
        alt="image"
        style={{ maxWidth: "100%", height: "auto" }}
        // fluid="true"
        className="top_Patten"
      />
      <img
        src={PatterBottom}
        alt="image"
        style={{ maxWidth: "100%", height: "auto" }}
        // fluid="true"
        className="bottom_Patten"
      />
      <GuidedTour
        steps={adminLoginSteps}
        onRequestClose={handleOnRequestClose}
        isOpen={guideTourGetItemValue ? guideTourGetItemValue.adminLogin : true}
      />
      <Scrollbars className="scroll_Section">
        <div className="center_Section">
          <div className="img_Section">
            <img
              src={MainImg}
              alt="image"
              style={{ maxWidth: "100%", height: "auto" }}
              // fluid="true"
              className="main_Img"
            />
          </div>
          <div className="Login_Box" tourselector="admin-login">
            {/* <Label title={t('LOGIN_INPUTBOX_GLAD')}></Label> */}
            <Label title="Administration login"></Label>
            <Label
              className="inner_Text"
              // title={t('LOGIN_INPUTBOX_POSTING')}
              title={"Login to manage Application"}
            ></Label>
            {/* <div className="link_Section">
              <Label
                className="form_Name"
                // title={t('LOGIN_INPUTBOX_HAVE')}
              ></Label>
              <Link className="link_Css" to="/signup">
                {t('LOGIN_INPUTBOX_SIGNUP')}
              </Link>
            </div> */}
            <HookForm
              defaultForm={{
                emailId: "",
                password: "",
              }}
              init={(form) => setLoginForm(form)}
              onSubmit={onFormSubmit}
            >
              {(formMethod) => {
                return (
                  <div className="form1">
                    <TextField
                      controlId={"email1"}
                      formMethod={formMethod}
                      rules={loginForm.email.validate}
                      name={loginForm.email.name}
                      errors={formMethod?.formState.errors}
                      autoFocus={true}
                      type="text"
                      placeholder="Email Id*"
                      iconClass="icon-mail"
                      tourselector="email-id"
                    />
                    <TextField
                      controlId={"password1"}
                      formMethod={formMethod}
                      rules={loginForm.password.validate}
                      name={loginForm.password.name}
                      errors={formMethod?.formState.errors}
                      placeholder="6+ Character & 1 Capital letter"
                      tourselector="password"
                      type={showPassword === true ? "password" : "text"}
                      iconClass={
                        showPassword === true
                          ? "icon-hide1 cursor"
                          : "icon-eye1 cursor"
                      }
                      onIconClick={() => setShowPassword(!showPassword)}
                    />
                    <Link to="/forgot">
                      <Label
                        className="forgot"
                        title={t("LOGIN_INPUTBOX_FORGOT")}
                      ></Label>
                    </Link>
                    <CustomButton
                      title="Login"
                      tourselector="login-button"
                    ></CustomButton>
                  </div>
                );
              }}
            </HookForm>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}

export default withRouter(AdminLogin);
