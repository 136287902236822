import React from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import TermsImg from "../../assests/images/term.png";
import Footer from "../../components/Footer/Footer";
import HeaderLogin from "../../components/HeaderLogin/HeaderLogin";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import Label from "../../components/UI/Label/Label";
import "./TermsOfService.scss";

function TermsOfService() {
  const { t } = useTranslation();

  return (
    <div className="terms_Page">
      <HeaderLogin />
      <Scrollbars className="blogScroll">
        <div className="main_Banner">
          <div className="banner_Heading">
            <Label className="banner_Title" title="Terms of Service"></Label>
            <Label
              className="banner_Title1"
              title="Read our terms below to learn more about your rights and responsibilities as a Post Publisher user."
            ></Label>
            <Label
              className="banner_Title2"
              title="Updated June 18, 2022"
            ></Label>
          </div>
          <div className="img_Section">
            <img
              src={TermsImg}
              alt="image"
              style={{ maxWidth: "100%", height: "auto" }}
              // fluid="true"
              className="blog_Inner"
            />
          </div>
        </div>
        <div className="center_Section">
          <div className="inner_Section">
            <div className="inner_Scroll">
              <Label className="heading" title="Accepting the Terms"></Label>
              <p className="inner_Text">{t("BLOG_ONE")}</p>
              <Label className="heading" title="Sub Header Lorem Epsum"></Label>
              <Label
                className="inner_Text"
                title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).Sub Header Lorem Epsum"
              ></Label>
              <Label
                className="inner_Text"
                title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).Sub Header Lorem Epsum"
              ></Label>
              <Label
                className="inner_Text"
                title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).Sub Header Lorem Epsum"
              ></Label>
              <Label
                className="inner_Text"
                title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).Sub Header Lorem Epsum"
              ></Label>
              <Label
                className="inner_Text"
                title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).Sub Header Lorem Epsum"
              ></Label>
              <Label
                className="inner_Text"
                title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).Sub Header Lorem Epsum"
              ></Label>
              <Label
                className="inner_Text"
                title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).Sub Header Lorem Epsum"
              ></Label>
              <Label
                className="inner_Text"
                title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).Sub Header Lorem Epsum"
              ></Label>
            </div>
            <div className="apply-Btn d-flex justify-content-center">
              <CustomButton title="ACCEPT"></CustomButton>
              <CustomButton title="DECLINE"></CustomButton>
            </div>
          </div>
        </div>

        <Footer className="footerheight"></Footer>
      </Scrollbars>
    </div>
  );
}

export default withRouter(TermsOfService);
