import React from "react";
import { Link, withRouter } from "react-router-dom";

function AppMap() {
  return (
    <div
      style={{
        margin: 10,
        fontSize: 10,
      }}
    >
      <p style={{ fontSize: 20 }}>Screens and Components</p>
      <div
        style={{
          overflowY: "scroll",
          height: "90vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Link style={{ fontSize: 15 }} to="language">
          <p>Language -- InProgress</p>
        </Link>
        <Link style={{ fontSize: 15 }} to="guidedTour">
          <p>GuidedTour -- InProgress</p>
        </Link>
        <Link style={{ fontSize: 15 }} to="/">
          Landing Page <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="login">
          Login <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="signup">
          SignUp <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="resetPassword">
          ResetPassword <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="verifymail">
          VerifyMail <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="forgot">
          Forgot <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="dashboard">
          Dashboard <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="bloglist">
          BlogList <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="publish">
          Publish <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="schedule">
          ScheduleScreen <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="published">
          Published<span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="failed">
          Published failed<span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="draft">
          Published Draft<span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="statistics">
          Statistics<span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="schedule">
          ScheduleScreen<span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="setting">
          setting
        </Link>
        <div style={{ fontSize: 15, paddingLeft: 50 }}>
          <p style={{ display: "flex", flexDirection: "row" }}>
            userdetail: <p style={{ paddingLeft: 10 }}>Done</p>
          </p>
          <p style={{ display: "flex", flexDirection: "row" }}>
            changePassword:{" "}
            <p style={{ color: "red", paddingLeft: 10 }}>Done</p>
          </p>
          <p style={{ display: "flex", flexDirection: "row" }}>
            General: <p style={{ color: "red", paddingLeft: 10 }}>Done</p>
          </p>
          <p style={{ display: "flex", flexDirection: "row" }}>
            Billings: <p style={{ color: "red", paddingLeft: 10 }}>Done</p>
          </p>
          <p style={{ display: "flex", flexDirection: "row" }}>
            Query: <p style={{ color: "red", paddingLeft: 10 }}>Done</p>
          </p>
        </div>
        <Link style={{ fontSize: 15 }} to="socialprofile">
          SocialProfile <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="blog">
          blog <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="blogtwo">
          BlogTwo <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="blogthree">
          BlogThree <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="blogfour">
          BlogFour <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="blogfive">
          BlogFive <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="blogsix">
          BlogSix <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="blogseven">
          BlogSeven <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="group">
          Group <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="settinggeneralpage">
          SettingGeneralPage <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="creategroup">
          creategroup <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="popupone">
          PopupOne <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="popuptwo">
          PopupTwo <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="calendar">
          CalendarEvent <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="subscriptionpage">
          Subscription page{" "}
          <span style={{ color: "red", paddingLeft: 10 }}>
            In progress/Done
          </span>
        </Link>
        {/* <Link
          style={{ fontSize: 15 }}
          to="../public/Emailtemplate/MailVerification"
        >
          MailVerification
        </Link> */}
        <Link style={{ fontSize: 15 }} to="featurePage">
          Feature <span style={{ color: "red", paddingLeft: 10 }}> Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="supportPage">
          SupportPage{" "}
          <span style={{ color: "red", paddingLeft: 10 }}>Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="thankyouPopup">
          thankyouPopup{" "}
          <span style={{ color: "red", paddingLeft: 10 }}>--Not Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="aboutus">
          aboutUs <span style={{ color: "red", paddingLeft: 10 }}>--Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="MarketingVideo">
          MarketingVideo{" "}
          <span style={{ color: "red", paddingLeft: 10 }}>--Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="PrivacyPolicy">
          PrivacyPolicy <span style={{ paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="termsofservice">
          TermOfUse <span style={{ paddingLeft: 10 }}>--Done</span>
        </Link>
        <p>Mail</p>
        <Link style={{ fontSize: 15 }} to="MailVerification">
          MailVerification{" "}
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="MailpasswordReset">
          MailpasswordReset{" "}
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="socialprofilelist">
          socialprofilelist{" "}
          <span style={{ color: "red", paddingLeft: 10 }}>Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="../public/Emailtemplate/analysis">
          Analysis{" "}
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link
          style={{ fontSize: 15 }}
          to="../public/Emailtemplate/billinghistory"
        >
          Billing History(mail){" "}
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="../public/Emailtemplate/postfailed">
          Post Failed(mail){" "}
          <span style={{ color: "red", paddingLeft: 10 }}>--Done</span>
        </Link>
        <Link
          style={{ fontSize: 15 }}
          to="../public/Emailtemplate/postpublished"
        >
          Post Published(mail){" "}
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link
          style={{ fontSize: 15 }}
          to="../public/Emailtemplate/PasswordReset"
        >
          Password rest(mail){" "}
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="redux">
          Redux-Tool-Kit Saga Api Testing
        </Link>
        Admin Dashboard
        <Link style={{ fontSize: 15 }} to="admin/admindashboard">
          Admin Dashboard
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="/adminabout">
          Admin About
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="admin/adminblog">
          Admin Blogs
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="admin/adminaddblogs">
          Admin Add Blogs
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="admin/adminsupport">
          Admin Support Page
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="admin/adminsubscriber">
          Admin Subscriber Page
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="admin/adminunsubscriber">
          Admin UnSubscriber Page
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="admin/adminhelp">
          Admin Help
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="admin/adminterms">
          Admin Terms & Policy
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="admin/adminsubscription">
          Admin Subscription
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="admin/adminuserprofile">
          Admin User Profile
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="admin/admindashboardmarketing">
          Dashboard Marketing Requests
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="admin/admindashboardblog">
          Dashboard Blog List
          <span style={{ color: "red", paddingLeft: 10 }}>-- Done</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="admin/dashboardelement">
          Dashboard Element UI
          <span style={{ color: "red", paddingLeft: 10 }}>-- In Progress</span>
        </Link>
        <Link style={{ fontSize: 15 }} to="admin/adminlogin">
          Admin Login Page
          <span style={{ color: "red", paddingLeft: 10 }}>-- In Progress</span>
        </Link>
      </div>
    </div>
  );
}

export default withRouter(AppMap);
