import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import {
  postResetPasswordAction,
  successForgotPasswordAction,
  successVerifyOtpAction,
} from "../../Redux_saga/postPublisherSlice";
import MainImg from "../../assests/images/main-img.png";
import PatterBottom from "../../assests/images/patten-btm.png";
import PatterTop from "../../assests/images/patten-top.png";
import HookForm from "../../components/HookForm/HookForm";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import Label from "../../components/UI/Label/Label";
import TextField from "../../components/UI/TextField/TextField";
import { useAppData } from "../../services/hooks";
import Message from "../../util/message";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { resetSteps } from "../Steps";
import "./ResetPassword.scss";

const ResetForm = {
  password: {
    name: "password",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.PASSWORDEMPTY,
      },
    },
  },
  confirm_password: {
    name: "confirm_password",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.PASSWORDEMPTY,
      },
    },
  },
};

function ResetPassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form, setResetForm] = React.useState();
  const [newpasswordtype, setNewPasswodType] = useState(true);
  const [confirmPasswordType, setConfirmPasswordType] = useState(true);
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  // console.log(form);
  // const data = useAppData((r) => r.postPublisherSlice.users);
  const resetResponse = useAppData(
    (r) => r.postPublisherSlice.users.resetPasswordResponseValue.status
  );
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  let search = window.location.search;
  let params = search.split("?")[1];

  const onFormSubmit = (form) => {
    const resetData = {
      emailId: params,
      password: form.confirm_password,
    };
    dispatch(postResetPasswordAction(resetData));
    dispatch(successVerifyOtpAction([]));
    dispatch(successForgotPasswordAction([]));
  };
  useEffect(() => {
    if (resetResponse === true) {
      let search = window.location.search;
      let params = search.split("?")[1];
      history.push(`/login?${params}`);
    }
  }, [resetResponse]);

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObj")
    );
    let newObj = { ...previousLocalStorageValue, resetpassword: false };
    localStorage.setItem("guideTourObj", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(localStorage.getItem("guideTourObj"));
  return (
    <div className="reset_Page" style={{ height: dimensions.height + "px" }}>
      <img
        src={PatterTop}
        alt="image"
        style={{ maxWidth: "100%", height: "auto" }}
        // fluid="true"
        className="top_Patten"
      />
      <img
        src={PatterBottom}
        alt="image"
        style={{ maxWidth: "100%", height: "auto" }}
        // fluid="true"
        className="bottom_Patten"
      />
      <Scrollbars className="scroll_Section">
        <GuidedTour
          steps={resetSteps}
          onRequestClose={handleOnRequestClose}
          isOpen={
            guideTourGetItemValue ? guideTourGetItemValue.resetpassword : true
          }
        />
        <div className="center_Section">
          <div className="img_Section">
            <img
              src={MainImg}
              alt="image"
              style={{ maxWidth: "100%", height: "auto" }}
              // fluid="true"
              className="main_Img"
            />
          </div>
          <div className="reset_Box">
            <div className="reset_BoxInner" tourselector="reset-form">
              <Label title={t("RESET_INPUTBOX_RESET")}></Label>
              <Label
                className="inner_Text"
                title={t("RESET_INPUTBOX_SEND")}
              ></Label>

              <HookForm
                defaultForm={{
                  password: "",
                  confirm_password: "",
                }}
                init={(form) => setResetForm(form)}
                onSubmit={onFormSubmit}
              >
                {(formMethod) => {
                  return (
                    <div className="form">
                      <TextField
                        controlId={"password"}
                        formMethod={formMethod}
                        rules={ResetForm.password.validate}
                        name={ResetForm.password.name}
                        errors={formMethod?.formState.errors}
                        placeholder="6+ Character & 1 Capital letter"
                        type={newpasswordtype === true ? "password" : "text"}
                        iconClass={
                          newpasswordtype === true
                            ? "icon-hide1 cursor"
                            : "icon-eye1 cursor"
                        }
                        onIconClick={() => setNewPasswodType(!newpasswordtype)}
                      />

                      <TextField
                        controlId={"confirm_password1"}
                        formMethod={formMethod}
                        rules={{
                          required: Message.ERRORMESSAGE.PASSWORDEMPTY,
                          validate: (value) =>
                            value === formMethod.watch("password") ||
                            "The passwords do not match",
                        }}
                        name={ResetForm.confirm_password.name}
                        errors={formMethod?.formState.errors}
                        placeholder="6+ Character & 1 Capital letter"
                        type={
                          confirmPasswordType === true ? "password" : "text"
                        }
                        iconClass={
                          confirmPasswordType === true
                            ? "icon-hide1 cursor"
                            : "icon-eye1 cursor"
                        }
                        onIconClick={() =>
                          setConfirmPasswordType(!confirmPasswordType)
                        }
                      />

                      <CustomButton
                        title={t("RESET_INPUTBOX_RESET_PASSWORD")}
                      ></CustomButton>
                    </div>
                  );
                }}
              </HookForm>
            </div>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}

export default withRouter(ResetPassword);
