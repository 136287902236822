import React from 'react';
import { useHistory } from 'react-router-dom';
import Constant from '../../util/constant';
import { useDispatch } from 'react-redux';
import { linkedInIntegrationAction } from '../../Redux_saga/postPublisherSlice';

function LinkedinLogin() {
  const history = useHistory();
  const dispatch = useDispatch();

  const handlePostMessage = (event) => {
    const { type, code } = event.data;
    if (type === 'linkedin') {
      getUserCredentials(type, code);
    }
  };

  React.useEffect(() => {
    if (window.opener && window.opener !== window) {
      const type = getTypeFromWindowURL(window.location.href);
      const code = getCodeFromWindowURL(window.location.href);
      window.opener.postMessage({ type, code }, '*');
      if (type === 'linkedin') {
        setTimeout(() => {
          window.close();
        }, 2000);
      }
    }

    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, []);

  const getTypeFromWindowURL = (url) => {
    const popupWindowURL = new URL(url);
    return popupWindowURL.searchParams.get('type');
  };

  const getCodeFromWindowURL = (url) => {
    const popupWindowURL = new URL(url);
    return popupWindowURL.searchParams.get('code');
  };

  const showLinkedInPopup = async () => {
    const { clientId, redirectUrl, oauthUrl, scope } = Constant.LinkedInAC;
    const state = Date.now();
    const currentUser = localStorage.getItem('userId');
    const windowName = `LinkedInPopup_${Date.now()}`;
    const oauthUrlNew = `${oauthUrl}&client_id=${clientId}&scope=${scope}&state=${state}&prompt=login&redirect_uri=${redirectUrl}`;
    const width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;
    window.open(
      oauthUrlNew,
      windowName,
      'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
        width +
        ', height=' +
        height +
        ', top=' +
        top +
        ', left=' +
        left,
    );
  };

  const getUserCredentials = async (type, code) => {
    const currentUser = localStorage.getItem('userId');
    if (type === 'linkedin') {
      dispatch(linkedInIntegrationAction({ code, currentUser }));
      setTimeout(() => {
        history.push('./socialprofilelist?Linkedin');
      }, 2000);
    }
  };

  return (
    <div>
      <i className="icon-plus" onClick={() => showLinkedInPopup()}></i>
    </div>
  );
}

export default LinkedinLogin;
