import React from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
// import SliderImg2 from "../../assests/images/bloglist2.png";
import Footer from "../../components/Footer/Footer";
import HeaderLogin from "../../components/HeaderLogin/HeaderLogin";
import BlogListing from "../../components/UI/BlogListing/BlogListing";
import { useAppData } from "../../services/hooks";
import "./BlogList.scss";

function BlogList() {
  const { t } = useTranslation();
  const allBlog = useAppData(
    (r) => r.postPublisherSlice.blog.getAllBlogResponseValue
  );

  return (
    <div className="bloglist_Page">
      <HeaderLogin></HeaderLogin>
      <Scrollbars className="bloglist_Scroll">
        {/* <Scrollbars style={{ height: (dimensions.height - footerHeight - 60) + 'px' }} > */}
        <div className="main_BlogList">
          <div className="blog_Inner">
            {allBlog.data.map(function (item, id) {
              return (
                <span key={id}>
                  <BlogListing
                    SliderImg={item.images[0]}
                    // SliderImg={false ? item.images[0] : SliderImg2}
                    headingTxt={item.title}
                    innerTxt={
                      item.description
                        ? item.description
                        : t("BLOGLIST_ONE_BRIEF")
                    }
                    to="/blog"
                  ></BlogListing>
                </span>
              );
            })}
            {/* <BlogListing
              SliderImg={SliderImg2}
              headingTxt={t('BLOGLIST_HEAD_ONE')}
              innerTxt={t('BLOGLIST_ONE_BRIEF')}
              to="/blog"
            ></BlogListing>
            <BlogListing
              SliderImg={SliderImg2}
              headingTxt={t('BLOGLIST_HEAD_ONE')}
              innerTxt={t('BLOGLIST_ONE_BRIEF')}
              to="/blog"
            ></BlogListing>
            <BlogListing
              SliderImg={SliderImg2}
              headingTxt={t('BLOGLIST_HEAD_ONE')}
              innerTxt={t('BLOGLIST_ONE_BRIEF')}
              to="/blog"
            ></BlogListing>
            <BlogListing
              SliderImg={SliderImg2}
              headingTxt={t('BLOGLIST_HEAD_ONE')}
              innerTxt={t('BLOGLIST_ONE_BRIEF')}
              to="/blog"
            ></BlogListing>
            <BlogListing
              SliderImg={SliderImg2}
              headingTxt={t('BLOGLIST_HEAD_ONE')}
              innerTxt={t('BLOGLIST_ONE_BRIEF')}
              to="/blog"
            ></BlogListing>
            <BlogListing
              SliderImg={SliderImg2}
              headingTxt={t('BLOGLIST_HEAD_ONE')}
              innerTxt={t('BLOGLIST_ONE_BRIEF')}
              to="/blog"
            ></BlogListing>
            <BlogListing
              SliderImg={SliderImg2}
              headingTxt={t('BLOGLIST_HEAD_ONE')}
              innerTxt={t('BLOGLIST_ONE_BRIEF')}
              to="/blog"
            ></BlogListing>
            <BlogListing
              SliderImg={SliderImg2}
              headingTxt={t('BLOGLIST_HEAD_ONE')}
              innerTxt={t('BLOGLIST_ONE_BRIEF')}
              to="/blog"
            ></BlogListing>
            <BlogListing
              SliderImg={SliderImg2}
              headingTxt={t('BLOGLIST_HEAD_ONE')}
              innerTxt={t('BLOGLIST_ONE_BRIEF')}
              to="/blog"
            ></BlogListing> */}
          </div>
        </div>
        <Footer className="footerheight"></Footer>
      </Scrollbars>
    </div>
  );
}

export default withRouter(BlogList);
