import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import CreateGroupProfileImg from "../../../../assests/images/CreateGroupImg.png";
import HookForm from "../../../../components/HookForm/HookForm";
import CustomButton from "../../../../components/UI/CustomButton/CustomButton";
import CustomDropdown from "../../../../components/UI/CustomDropdown/CustomDropdown";
import Label from "../../../../components/UI/Label/Label";
import TextField from "../../../../components/UI/TextField/TextField";
import Constant from "../../../../util/constant";
import Message from "../../../../util/message";
import "./WhiteLabel.scss";
const userForm = {
  email: {
    name: "email",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.EMAILEMPTY,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ERRORMESSAGE.EMAILINVALID,
      },
    },
  },
};
const dropdownItem = [
  {
    id: "1",
    value: "Green",
  },
  {
    id: "2",
    value: "Blue",
  },
  {
    id: "3",
    value: "Black",
  },
];
const WhiteLabel = () => {
  const [form, setUserForm] = React.useState();
  const [staff, setSelectedStaff] = useState();
  const [newImage, setNewImage] = useState("");
  const [passwordType, setPasswordType] = useState(true);
  // console.log(form);
  const onFormSubmit = (form) => {
    // console.log(form);
    // const updatePasswordData = {
    //   bodyData: {
    //     userId: localStorage.getItem("userId"),
    //     currentPassword: form.current_password,
    //     newPassword: form.password,
    //   },
    //   token: localStorage.getItem("token"),
    // };
    // dispatch(postChangePasswordAction(updatePasswordData));
  };
  const imageHandler = (e) => {
    if (e.target.files.length === 0) return;
    let img = URL.createObjectURL(...e.target.files);
    setNewImage(img);
  };
  return (
    <div className="whiteContainer">
      <div className="logoSection">
        <div>
          <label htmlFor="file-input">
            {newImage === "" ? (
              <div className="inner_Div">
                <i className="icon-edit1 Edit"></i>

                <img
                  src={CreateGroupProfileImg}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="middle_Img"
                />
              </div>
            ) : (
              <div className="inner_Div">
                <i className="icon-edit1 Edit"></i>

                <img
                  src={newImage}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="middle_Img"
                />
              </div>
            )}
          </label>
          <input
            id="file-input"
            type="file"
            multiple
            onChange={imageHandler}
            accept="image/*"
            hidden
          />

          <div className="changeLogoContainer">
            <label htmlFor="file-input">
              <Label title="Change Logo" className="changeLogo" />
            </label>
          </div>
        </div>
      </div>

      <div className="CredentialLink">
        <Label className="adminText" title="Admin Credentials" />
        <Nav.Link eventKey="blog" as={Link} to="/bloglist">
          <Label className="clickHere" title="Click Here" />
        </Nav.Link>
      </div>
      <div>
        <HookForm
          defaultForm={{ email: "" }}
          init={(form) => setUserForm(form)}
          onSubmit={onFormSubmit}
        >
          {(formMethod) => {
            return (
              <div className="form">
                <div className="first_line">
                  <div className="first_name">
                    <TextField
                      formMethod={formMethod}
                      rules={userForm.email.validate}
                      name={userForm.email.name}
                      errors={formMethod?.errors}
                      type="text"
                      placeholder="johndoe@gmail.com"
                      iconClass=""
                    />
                  </div>
                  <div className="last_name">
                    <TextField
                      formMethod={formMethod}
                      rules={userForm.email.validate}
                      name={userForm.email.name}
                      errors={formMethod?.errors}
                      type={passwordType === true ? "password" : "text"}
                      placeholder="Jhon Doe"
                      iconClass={
                        passwordType === true
                          ? "icon-hide1 cursor"
                          : "icon-eye1 cursor"
                      }
                      onIconClick={() => setPasswordType(!passwordType)}
                    />
                  </div>
                </div>
                <div className="second_line">
                  <div className="email">
                    <TextField
                      formMethod={formMethod}
                      rules={userForm.email.validate}
                      name={userForm.email.name}
                      errors={formMethod?.errors}
                      type="text"
                      placeholder="Deployment URL"
                      iconClass=""
                    />
                  </div>
                  <div className="dropdown_inner6">
                    <CustomDropdown
                      dropDownItems={dropdownItem}
                      placeholder="Change Theme"
                      selectedValue={staff}
                      onSelect={(selected) => setSelectedStaff(selected)}
                    ></CustomDropdown>
                  </div>
                </div>
                <div className="button_line">
                  <div className="first_button">
                    <CustomButton
                      title="Discard"
                      className="whiteButton"

                      // onClick={onClickUserDetails}
                    ></CustomButton>
                  </div>
                  <div className="second_button">
                    <CustomButton
                      className="saveButton"
                      title="Save"
                      //   onClick={onClickUpdateUserProfile}
                    ></CustomButton>
                  </div>
                </div>
              </div>
            );
          }}
        </HookForm>
      </div>
    </div>
  );
};

export default WhiteLabel;
