import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Label from "../../UI/Label/Label";
import "./Card.scss";

const CardView = (props) => {
  const [isPaused, setIsPaused] = useState(false);

  const {
    image,
    headTitle,
    subTitle,
    Title,
    Time,
    iconName,
    imageMain,
    video,
    onPlayPauseClick,
    onDeleteClick,
    onPublishNow,
    iconConfig,
    onEditPost
  } = props;

  const handlePlayPauseClick = () => {
    setIsPaused(!isPaused);
    onPlayPauseClick && onPlayPauseClick(!isPaused);
  };

  const handleDeleteClick = () => {
    onDeleteClick && onDeleteClick();
  };

  const handlePublishNow = () => {
    onPublishNow && onPublishNow();
  };

  const handleEditPost = () => {
    onEditPost && onEditPost();
  };

  const shouldShowIcon = (iconKey) => iconConfig && iconConfig[iconKey];

  const renderLeftIcons = () => (
    <div className="left">
      {shouldShowIcon("delete") && (
        <i
          className="icon-delete"
          title="Delete"
          style={{ cursor: "pointer" }}
          onClick={() => handleDeleteClick()}
        ></i>
      )}
    </div>
  );

  const renderMiddleIcon = () => (
    <div className="left">
      {shouldShowIcon("playPause") && (
        <i
          className={isPaused ? "icon-play" : "icon-pause"}
          title={isPaused ? "Resume Post" : "Pause Post"}
          style={{ cursor: "pointer" }}
          onClick={handlePlayPauseClick}
        ></i>
      )}
    </div>
  );

  const renderRightIcons = () => (
    <div className="iconsTwo">
      {shouldShowIcon("edit") && (
        <div className="icon_Bg">
          <i
            className="icon-edit1"
            onClick={handleEditPost}
            title="Edit Post"
            style={{ cursor: "pointer" }}
          ></i>
        </div>
      )}
      {shouldShowIcon("reverse") && (
        <div className="icon_Bg">
          <i className="icon-reverse" style={{ cursor: "pointer" }}></i>
        </div>
      )}
      {shouldShowIcon("send") && (
        <div className="icon_Bg">
          <i
            className="icon-send1"
            title="Publish Now"
            style={{ cursor: "pointer" }}
            onClick={handlePublishNow}
          ></i>
        </div>
      )}
    </div>
  );

  return (
    <div>
      <Card className={"cardView " + props.className}>
        <div className="card_Head">
          <div className="image_Text">
            <div className="image">
              <Card.Img src={image} />
            </div>
            <div className="text">
              <Card.Title>{headTitle}</Card.Title>
              <Label className="sub_Text" title={subTitle}></Label>
            </div>
          </div>
          <i className={iconName}></i>
        </div>
        <Card.Body>
          <Label className="img_Text" title={Title}></Label>
          <div className="main_Image">
            {video ? (
              <div className="main_Image">
                <video width="100%" height="100%" controls>
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              <div className="main_Image">
                <Card.Img src={imageMain} />
              </div>
            )}
          </div>
          <Label className="time" title={Time}></Label>
        </Card.Body>
        <div className="footer_Icon">
          {renderLeftIcons()}
          {renderMiddleIcon()}
          {renderRightIcons()}
        </div>
      </Card>
    </div>
  );
};

CardView.defaultProps = {
  iconConfig: {
    delete: true,
    playPause: true,
    edit: true,
    reverse: true,
    send: true,
  },
};

export default CardView;
