import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import BlogFirst6 from "../../assests/images/BlogFirst6.png";
import BlogMiddle6 from "../../assests/images/BlogMiddle6.png";
import SliderImg from "../../assests/images/slider1.png";
import BlogFeature from "../../components/BlogFeature/BlogFeature";
import BlogModal from "../../components/BlogFeature/BlogModal";
import Footer from "../../components/Footer/Footer";
import HeaderLogin from "../../components/HeaderLogin/HeaderLogin";
import ImageSlider from "../../components/UI/ImageSlider/ImageSlider";
import Label from "../../components/UI/Label/Label";
import "./BlogSix.scss";

function BlogSix() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const clickShow = () => {
    setShow(!show);
  };
  return (
    <div className="blogsix_Page">
      {show && <BlogModal closeModal={clickShow} />}

      <HeaderLogin />
      <Scrollbars className="blogScroll">
        <div className="top_section">
          <div className="top_image">
            <img
              src={BlogFirst6}
              alt="image"
              style={{ maxWidth: "100%", height: "auto" }}
              // fluid="true"
              className="top_Img"
            />
          </div>
          <Label className="top_Title" title={t("BLOG_SIX_HEAD")}></Label>
          <div className="top_text">
            <p className="inner_Text">{t("BLOG_SIX_PARA_ONE")}</p>
          </div>
        </div>

        <div className="middle_section">
          <div className="middle_image">
            <img
              src={BlogMiddle6}
              alt="image"
              style={{ maxWidth: "100%", height: "auto" }}
              // fluid="true"
              className="middle_Img"
            />
          </div>
          <div className="middle_text">
            <p className="inner_Text">{t("BLOG_SIX_PARA_TWO")}</p>
            <p className="inner_Text">{t("BLOG_SIX_PARA_THREE")}</p>
          </div>
        </div>
        <div className="blog-feature-six">
          <BlogFeature clickToShowModal={clickShow} />
        </div>
        <div className="image_Section">
          <Label title="More article "></Label>
          <ImageSlider
            SliderImg={SliderImg}
            SliderImg1={SliderImg}
            SliderImg2={SliderImg}
            headingTxt={t("BLOG_SIX_MORE")}
            innerTxt={t("BLOG_SIX_BRIEF")}
          ></ImageSlider>
        </div>
        <Footer className="footerheight"></Footer>
      </Scrollbars>
    </div>
  );
}
export default withRouter(BlogSix);
