/* eslint-disable no-useless-escape */

const Constant = {
  REGEX: {
    email: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
    SPECIAL_CHAR: /[!@#\$%\^\&*\)\(+=._-]/g,
    NUMBER: /[0-9]/g,
    Name: /^[ a-zA-Z]+$/i,
    // ALPHANUMERIC: /^"^[a-zA-Z0-9_]*$"/i,
    ALPHANUMERIC: /^[ a-zA-Z0-9\.\-\,]+$/i,
    LOWERCASE_UPPERCASE: /[a-z].*[A-Z]|[A-Z].*[a-z]/,
    Url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
    commission: /[1-9]$|^[1-9][0-9]$|^(100)$/i,
    AMOUNT: /^[$0-9]*$/,
    NUMERIC: /^\d*\.?\d*$/,
  },
  LinkedInAC: {
    clientId: "864b2mebxqdome",
    //redirectUrl: 'http://localhost:3003/v1/operations/linkedin/auth/callback',
    redirectUrl: "http://localhost:3000/dashboard?type=linkedin",
    oauthUrl:
      "https://www.linkedin.com/oauth/v2/authorization?response_type=code",
    scope: "r_liteprofile%20r_emailaddress%20w_member_social",
    state: "",
  },
};

export default Constant;
