import { createSlice } from "@reduxjs/toolkit";

export const globalSlice = createSlice({
  name: "global",
  initialState: {
    toast: {
      isVisible: false,
      type: "info",
      message: "",
    },
  },
  reducers: {
    showToastAction: (state, action) => {
      state.toast = {
        isVisible: true,
        message: action.payload.message,
        type: action.payload.type,
      };
    },
    hideToastAction: (state) => {
      state.toast.isVisible = false;
      state.toast.message = "";
    },
  },
});

export const { showToastAction, hideToastAction } = globalSlice.actions;
