import React from "react";
import Scrollbars from "react-custom-scrollbars";
import { withRouter } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import HeaderLogin from "../../components/HeaderLogin/HeaderLogin";
import Label from "../../components/UI/Label/Label";

import "./SupportList.scss";

function SupportList() {
  return (
    <div className="supportlist_Page">
      <HeaderLogin></HeaderLogin>
      <Scrollbars className="supportlist_Scroll">
        <div className="main_supportlist">
          <div className="beadcrumb">
            <Label title="Post Publisher Support" className="small"></Label>
            <i className="icon-rightarrow"></i>
            <Label title="Scheduling & Publishing." className="small"></Label>
            <i className="icon-rightarrow"></i>
            <Label title="Getting Started" className="small"></Label>
          </div>
          <div className="listing">
            <Label title="Scheduling & Publishing." className="header"></Label>
            <div className="card-details">
              <div className="header-tab">
                <Label
                  title="Scheduling & Publishing."
                  className="header-title"
                ></Label>
              </div>
              <div className="details">
                <Label
                  title="Scheduling & Publishing."
                  className="top-text"
                ></Label>
                <Label
                  title="1. Create and Schedule Customized Social Media Posts on Every Platform:"
                  className="inner-title"
                ></Label>
                <ul className="ul">
                  <li>Maximize Reach with Customized Posts</li>
                  <li>Save Drafts and See Previews</li>
                </ul>
                <Label
                  title="2. Create and Schedule Customized Social Media Posts on Every Platform:"
                  className="inner-title padTop"
                ></Label>
                <ul className="ul">
                  <li>Maximize Reach with Customized Posts</li>
                  <li>Save Drafts and See Previews</li>
                </ul>
                <Label
                  title="3. Create and Schedule Customized Social Media Posts on Every Platform:"
                  className="inner-title padTop"
                ></Label>
                <ul className="ul">
                  <li>Maximize Reach with Customized Posts</li>
                  <li>Save Drafts and See Previews</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card-details">
            <div className="header-tab">
              <Label
                title="Scheduling & Publishing."
                className="header-title"
              ></Label>
            </div>
            <div className="details">
              <Label
                title="Scheduling & Publishing."
                className="top-text"
              ></Label>
              <Label
                title="1. Create and Schedule Customized Social Media Posts on Every Platform:"
                className="inner-title"
              ></Label>
              <ul className="ul">
                <li>Maximize Reach with Customized Posts</li>
                <li>Save Drafts and See Previews</li>
              </ul>
              <Label
                title="2. Create and Schedule Customized Social Media Posts on Every Platform:"
                className="inner-title padTop"
              ></Label>
              <ul className="ul">
                <li>Maximize Reach with Customized Posts</li>
                <li>Save Drafts and See Previews</li>
              </ul>
              <Label
                title="3. Create and Schedule Customized Social Media Posts on Every Platform:"
                className="inner-title padTop"
              ></Label>
              <ul className="ul">
                <li>Maximize Reach with Customized Posts</li>
                <li>Save Drafts and See Previews</li>
              </ul>
            </div>
          </div>
        </div>
        <Footer className="footerheight"></Footer>
      </Scrollbars>
    </div>
  );
}

export default withRouter(SupportList);
