import React from "react";

export const Whatsaap = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8.5"
        r="8"
        fill={props.fill}
        className={props.className}
      />
      <path
        d="M3.00201 13.5L3.67801 11.016C3.23258 10.2524 2.99855 9.38399 3.00001 8.5C3.00001 5.7385 5.23851 3.5 8 3.5C10.7615 3.5 13 5.7385 13 8.5C13 11.2615 10.7615 13.5 8 13.5C7.11639 13.5014 6.24833 13.2676 5.485 12.8225L3.00201 13.5ZM6.1955 6.154C6.13094 6.15801 6.06784 6.17501 6.01 6.204C5.95577 6.23471 5.90625 6.27311 5.863 6.318C5.803 6.3745 5.769 6.4235 5.7325 6.471C5.54771 6.7115 5.44831 7.00671 5.45001 7.31C5.45101 7.555 5.51501 7.7935 5.61501 8.0165C5.8195 8.4675 6.156 8.945 6.6005 9.3875C6.7075 9.494 6.812 9.601 6.9245 9.7005C7.4762 10.1862 8.13363 10.5365 8.8445 10.7235L9.129 10.767C9.2215 10.772 9.314 10.765 9.407 10.7605C9.55262 10.753 9.69481 10.7135 9.8235 10.645C9.9065 10.601 9.9455 10.579 10.015 10.535C10.015 10.535 10.0365 10.521 10.0775 10.49C10.145 10.44 10.1865 10.4045 10.2425 10.346C10.284 10.303 10.32 10.2525 10.3475 10.195C10.3865 10.1135 10.4255 9.958 10.4415 9.8285C10.4535 9.7295 10.45 9.6755 10.4485 9.642C10.4465 9.5885 10.402 9.533 10.3535 9.5095L10.0625 9.379C10.0625 9.379 9.6275 9.1895 9.362 9.0685C9.33401 9.05629 9.30401 9.04935 9.2735 9.048C9.23929 9.04448 9.20473 9.04833 9.17213 9.05928C9.13953 9.07024 9.10965 9.08804 9.0845 9.1115V9.1105C9.082 9.1105 9.0485 9.139 8.687 9.577C8.66625 9.60488 8.63767 9.62595 8.6049 9.63752C8.57213 9.6491 8.53666 9.65066 8.503 9.642C8.47042 9.63328 8.43851 9.62226 8.4075 9.609C8.3455 9.583 8.324 9.573 8.2815 9.5545L8.279 9.5535C7.99296 9.42861 7.72812 9.25992 7.494 9.0535C7.431 8.9985 7.3725 8.9385 7.3125 8.8805C7.11579 8.69212 6.94436 8.479 6.8025 8.2465L6.773 8.199C6.75182 8.16708 6.73468 8.13265 6.722 8.0965C6.703 8.023 6.7525 7.964 6.7525 7.964C6.7525 7.964 6.874 7.831 6.9305 7.759C6.97755 7.69916 7.02144 7.63691 7.062 7.5725C7.121 7.4775 7.1395 7.38 7.1085 7.3045C6.9685 6.9625 6.8235 6.622 6.6745 6.284C6.645 6.217 6.5575 6.169 6.478 6.1595C6.451 6.1565 6.424 6.1535 6.397 6.1515C6.32986 6.14816 6.26257 6.14933 6.1955 6.154Z"
        fill="white"
      />
    </svg>
  );
};

export const TwitterIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.1112 4.87547C8.4128 5.1296 7.97147 5.78507 8.0216 6.5024L8.0384 6.7792L7.75893 6.74533C6.74213 6.61547 5.8536 6.17493 5.0992 5.43467L4.7304 5.06747L4.63547 5.33867C4.4344 5.9432 4.56293 6.5816 4.98187 7.01093C5.20533 7.24827 5.15493 7.28213 4.7696 7.1408C4.63547 7.09573 4.51813 7.06187 4.50693 7.07867C4.468 7.1184 4.60187 7.63227 4.708 7.83573C4.85333 8.1184 5.14933 8.39493 5.4736 8.55893L5.74747 8.6888L5.42347 8.6944C5.11067 8.6944 5.09947 8.7 5.13307 8.81893C5.2448 9.18613 5.68613 9.576 6.17787 9.7456L6.52427 9.864L6.22267 10.0448C5.77573 10.3051 5.2504 10.4517 4.72507 10.4627C4.47333 10.4683 4.26667 10.4909 4.26667 10.508C4.26667 10.5643 4.94853 10.8805 5.34507 11.0051C6.5352 11.3723 7.94907 11.2139 9.01067 10.5869C9.76507 10.1405 10.5192 9.2536 10.8715 8.39467C11.0616 7.93733 11.2515 7.10107 11.2515 6.70027C11.2515 6.44027 11.2683 6.4064 11.5811 6.09573C11.7656 5.91493 11.9387 5.71733 11.9723 5.6608C12.0283 5.55333 12.0224 5.55333 11.7376 5.64933C11.2627 5.81893 11.1955 5.79627 11.4304 5.54213C11.6035 5.36133 11.8104 5.0336 11.8104 4.9376C11.8104 4.9208 11.7267 4.9488 11.6315 4.99973C11.5309 5.05627 11.3075 5.14107 11.1397 5.19173L10.8381 5.288L10.5643 5.10133C10.4133 4.99973 10.2013 4.88667 10.0893 4.8528C9.80427 4.77387 9.36827 4.78507 9.1112 4.87547ZM8 16C3.58187 16 0 12.4181 0 8C0 3.5816 3.58187 0 8 0C12.4181 0 16 3.5816 16 8C16 12.4181 12.4181 16 8 16Z"
        fill={props.fill}
        className={props.className}
      />
    </svg>
  );
};

export const FacebookIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8.04889C16 3.60361 12.4183 0 8 0C3.58172 0 0 3.60361 0 8.04889C0 12.0663 2.9255 15.3962 6.75 16V10.3755H4.71875V8.04889H6.75V6.27562C6.75 4.25837 7.94434 3.1441 9.77172 3.1441C10.647 3.1441 11.5625 3.3013 11.5625 3.3013V5.28208H10.5537C9.55994 5.28208 9.25 5.90251 9.25 6.53903V8.04889H11.4688L11.1141 10.3755H9.25V16C13.0745 15.3962 16 12.0663 16 8.04889Z"
        fill={props.fill}
        className={props.className}
      />
    </svg>
  );
};

export const LinkedInIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8Z"
        fill={props.fill}
        className={props.className}
      />
      <path
        d="M4.12128 2.88281C3.43604 2.88281 2.88086 3.43926 2.88086 4.12576C2.88086 4.81226 3.43604 5.36871 4.12128 5.36871C4.80652 5.36871 5.3617 4.81226 5.3617 4.12576C5.3617 3.43926 4.80652 2.88281 4.12128 2.88281ZM10.6181 6.11902V6.11986C10.478 6.11937 10.3421 6.12497 10.1642 6.13418C9.77418 6.15437 8.86324 6.57699 8.5297 7.25165C8.5297 6.95875 8.53307 6.68974 8.53307 6.28829C8.13354 6.28829 7.6107 6.29081 6.51875 6.29081C6.51875 7.51667 6.50528 11.8579 6.50528 13.1228H8.61981C8.61981 12.3724 8.62991 10.4875 8.62991 9.30386C8.62991 8.90641 8.87017 7.98849 9.85602 7.98849C10.7512 7.98849 11.0021 8.74297 11.0021 9.30386C11.0021 10.5366 10.9929 12.2791 10.9929 13.1228H13.12C13.12 12.2981 13.1276 10.0579 13.1023 8.64449C13.0717 6.92839 12.0293 6.19618 11.0939 6.13839C10.9014 6.1265 10.7582 6.11953 10.6181 6.11902V6.11902ZM3.05939 6.31776V13.1194H5.17981V6.31776H3.05939Z"
        fill="white"
      />
    </svg>
  );
};

export const LikeIcon = (props) => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 0C17.538 0 20 2.5 20 6C20 13 12.5 17 10 18.5C7.5 17 0 13 0 6C0 2.5 2.5 0 5.5 0C7.36 0 9 1 10 2C11 1 12.64 0 14.5 0ZM10.934 15.604C11.815 15.048 12.61 14.495 13.354 13.903C16.335 11.533 18 8.943 18 6C18 3.64 16.463 2 14.5 2C13.424 2 12.26 2.57 11.414 3.414L10 4.828L8.586 3.414C7.74 2.57 6.576 2 5.5 2C3.56 2 2 3.656 2 6C2 8.944 3.666 11.533 6.645 13.903C7.39 14.495 8.185 15.048 9.066 15.603C9.365 15.792 9.661 15.973 10 16.175C10.339 15.973 10.635 15.792 10.934 15.604Z"
        fill={props.fillStroke}
      />
      <path
        d="M14.325 2C16.907 2 19 4.02703 19 6.86486C19 12.5405 12.625 15.7838 10.5 17C8.375 15.7838 2 12.5405 2 6.86486C2 4.02703 4.125 2 6.675 2C8.25597 2 9.65 2.81081 10.5 3.62162C11.35 2.81081 12.744 2 14.325 2Z"
        fill={props.fillBg}
      />
    </svg>
  );
};
