import React from "react";
import Scrollbars from "react-custom-scrollbars";
import { withRouter } from "react-router-dom";
import PaginationSystem from "../../components/Pagination/PaginationSystem";
import Label from "../../components/UI/Label/Label";
import "./AdminHelp.scss";

function AdminHelp() {
  return (
    <div className="admin_HelpPage">
      <div className="main_Container">
        <Scrollbars className="admin_HelpPage_Scroll">
          <div className="dashboard_Section ">
            <div className="d-flex justify-content-between align-items-center">
              <div className="content_Header pt-4">
                <Label className="page_Title" title="Help/Support"></Label>
                <Label
                  className="inner_Title"
                  title="View all Help/supports queries"
                ></Label>
              </div>
            </div>
            <div className="divider"></div>
            <div className="table_Section pr-3">
              <div className="query-Section d-flex flex-column">
                <ul className="header d-flex p-0 m-0">
                  <li>Sl.No.</li>
                  <li className="pr-1">Query ID</li>
                  <li className="pr-1">Raised On</li>
                  <li className="pr-1">Name</li>
                  <li className="pr-1">Email</li>
                  <li className="pr-1">Query</li>
                </ul>

                <ul className="dataList d-flex p-0 m-0">
                  <li>1</li>
                  <li className="pr-1">5545</li>

                  <li className="pr-1">13/Jan/2018</li>
                  <li className="pr-1">John Deo</li>
                  <li className="pr-1 colorBlue">johndeo@test.com</li>
                  <li className="pr-1">
                    Lorem ipsum dolor sit amet Aut ipsa nisi Lorem ipsum dolor
                    sit amet{" "}
                  </li>
                </ul>

                <ul className="dataList d-flex p-0 m-0">
                  <li>1</li>
                  <li className="pr-1">6464</li>

                  <li className="pr-1">13/Jan/2018</li>
                  <li className="pr-1">John Deo</li>
                  <li className="pr-1 colorBlue">johndeo@test.com</li>
                  <li className="pr-1">
                    Lorem ipsum dolor sit amet Aut ipsa nisi Lorem ipsum dolor
                    sit amet{" "}
                  </li>
                </ul>

                <ul className="dataList d-flex p-0 m-0">
                  <li>1</li>
                  <li className="pr-1">7857</li>

                  <li className="pr-1">13/Jan/2018</li>
                  <li className="pr-1">John Deo</li>
                  <li className="pr-1 colorBlue">johndeo@test.com</li>
                  <li className="pr-1">
                    Lorem ipsum dolor sit amet Auorem ipsum dolor sit amet Aut
                    ipsa nisi Lorem ipsum
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
      {/* <SideModal show={showNotificationModal} ></SideModal> */}
      <PaginationSystem />
    </div>
  );
}

export default withRouter(AdminHelp);
