import React from "react";
import CheckBox from "../../../components/UI/CheckBox/CheckBox";
import Label from "../../../components/UI/Label/Label";
import "./AccountListing.scss";

const AccountListing = (props) => {
  let { checkboxId, accImage, accName } = props;
  return (
    <div className="account-listing-parent">
      <div className="account-listing-wrapper">
        <CheckBox labelTitle="" id={checkboxId} />
        <div className="accImage">
          <img src={accImage} alt="accImage"></img>
        </div>
        <Label title={accName} className="accName" />
      </div>
    </div>
  );
};

export default AccountListing;
