import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { Link } from "react-router-dom";
import Label from "../Label/Label";
import "./BlogListing.scss";

const BlogListing = (props) => {
  let { SliderImg, to, headingTxt, innerTxt } = props;

  return (
    <div className="boxlising_Section">
      <div className="image_Slider">
        <img
          src={SliderImg}
          alt="image"
          style={{ maxWidth: "100%", height: "auto" }}
          // fluid="true"
          className="main_Img"
        />
        <div className="content_Section">
          <Label className="main_Heading" title={headingTxt}></Label>
          <Label className="inner_Txt" title={innerTxt}></Label>
          <Link className="read_More" to={to}>
            Read more <span className="icon-more"></span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogListing;
