import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { postCreateBlogAction } from "../../Redux_saga/postPublisherSlice";
import LayoutImg from "../../assests/images/layout.png";
import UploadImg from "../../assests/images/upload.png";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import HookForm from "../../components/HookForm/HookForm";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import CustomDropdown from "../../components/UI/CustomDropdown/CustomDropdown";
import Label from "../../components/UI/Label/Label";
import TextField from "../../components/UI/TextField/TextField";
import Message from "../../util/message";
import "./AdminAddBlogs.scss";
const blogForm = {
  title: {
    name: "title",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.TITLEEMPTY,
      },
    },
  },
  firstpara: {
    name: "firstpara",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.TITLEEMPTY,
      },
    },
  },
  secondpara: {
    name: "secondpara",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.TITLEEMPTY,
      },
    },
  },
  thirdpara: {
    name: "thirdpara",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.TITLEEMPTY,
      },
    },
  },
};
const imageHandler = (e) => {
  if (e.target.files.length === 0) return;
  // setCondition(true);
  // setAllImages([...allImages, e.target.files]);
};
// const dropdownItem = [
//   {
//     id: '1',
//     value: 'Theme layouts',
//   },
//   {
//     id: '2',
//     value: 'Theme layouts 1',
//   },
//   {
//     id: '3',
//     value: 'Theme layouts 2',
//   },

//   {
//     id: '4',
//     value: 'Theme layouts 3',
//   },
//   {
//     id: '5',
//     value: 'Theme layouts 4',
//   },
//   {
//     id: '6',
//     value: 'Theme layouts 5',
//   },
// ];

function AdminAddBlogsPage() {
  const dispatch = useDispatch();
  const [form, setblogForm] = React.useState();
  // console.log(form);

  const onFormSubmit = (form) => {
    const BlogData = {
      bodyData: {
        title: form.title,
        type: "Cinema",
        layout: "Blog 5",
        status: "Publish",
        paragraphs: [form.firstpara, form.secondpara, form.thirdpara],
        images: ["blog/pics/YPI3KbHST-1675877822731.png"],
      },
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2M2UzZDQxMjA3NDgwYjE4ZmEwMmViNjEiLCJpYXQiOjE2OTMwMjkyMjYsImV4cCI6MTY5ODIxMzIyNiwidHlwZSI6ImFjY2VzcyJ9.Qbv2_6mtHMVLUSs5RfoU7w5KjvYwfJHLJCyxNWWcAN8",
    };
    dispatch(postCreateBlogAction(BlogData));
  };
  // const onSubmitConfirm = () => {
  //   const updatePasswordData = {
  //     bodyData: {
  //       title: 'My fifth blog6',
  //       type: 'Cinema',
  //       layout: 'Blog 5',
  //       status: 'Publish',
  //       paragraphs: ['first para', 'second para', 'third para'],
  //       images: ['blog/pics/YPI3KbHST-1675877822731.png'],
  //     },
  //     token:
  //       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2M2UzZDQxMjA3NDgwYjE4ZmEwMmViNjEiLCJpYXQiOjE2OTMwMjkyMjYsImV4cCI6MTY5ODIxMzIyNiwidHlwZSI6ImFjY2VzcyJ9.Qbv2_6mtHMVLUSs5RfoU7w5KjvYwfJHLJCyxNWWcAN8',
  //   };
  //   dispatch(postCreateBlogAction(updatePasswordData));
  // };

  return (
    <div className="admin_AddBlog">
      <div className="no-design-section justify-content-center align-items-center vh-100">
        <Label
          title="Mobile design not supported"
          className="no-design"
        ></Label>
      </div>
      <AdminHeader />
      <HookForm
        defaultForm={{
          title: "",
          firstpara: "",
          secondpara: "",
          thirdpara: "",
        }}
        init={(form) => setblogForm(form)}
        onSubmit={onFormSubmit}
      >
        {(formMethod) => {
          return (
            <div className="main_Container">
              <Scrollbars className="admin_AddBlog_Scroll">
                <div className="dashboard_Section ">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="content_Header d-flex w-100">
                      <div className="d-flex flex-column w80">
                        <div className="backIcon d-flex align-items-center">
                          <div className="icon_Bg justify-content-center d-flex align-items-center">
                            <i className="icon-back"></i>
                          </div>
                          <Label className="page_Title" title="Blogs"></Label>
                        </div>
                        <Label
                          className="inner_Title"
                          title="View and manage all blog post here"
                        ></Label>
                      </div>
                      <div className="right_Button d-flex align-items-end">
                        <CustomButton
                          className="submitBtn save"
                          title="Save"
                        ></CustomButton>
                        <CustomButton
                          className="submitBtn publish mr-2"
                          title="Publish now"
                          // onClick={() => setShowPublishModal(!showPublishModal)}
                          // onClick={onSubmitConfirm}
                        ></CustomButton>
                      </div>
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="input_Section">
                    <div className="box-border">
                      <div>
                        <div className="form">
                          <div className="firstLine">
                            <div className="firstTitle">
                              <TextField
                                formMethod={formMethod}
                                rules={blogForm.title.validate}
                                name={blogForm.title.name}
                                errors={formMethod?.formState.errors}
                                autoFocus={true}
                                type="text"
                                placeholder="Title"
                                iconClass=""
                                controlId={"title"}
                              />
                            </div>
                            <div className="secondDropdown">
                              <ChildrenDropdown />
                            </div>
                          </div>

                          <TextField
                            formMethod={formMethod}
                            rules={blogForm.firstpara.validate}
                            name={blogForm.firstpara.name}
                            errors={formMethod?.formState.errors}
                            autoFocus={true}
                            type="text"
                            placeholder="Paragraph one"
                            iconClass=""
                            textarea="textarea"
                            controlId={"firstpara"}
                          />
                          <TextField
                            formMethod={formMethod}
                            rules={blogForm.secondpara.validate}
                            name={blogForm.secondpara.name}
                            errors={formMethod?.formState.errors}
                            autoFocus={true}
                            type="text"
                            placeholder="Paragraph Two"
                            iconClass=""
                            textarea="textarea"
                            controlId={"secondpara"}
                          />
                          <TextField
                            formMethod={formMethod}
                            rules={blogForm.thirdpara.validate}
                            name={blogForm.thirdpara.name}
                            errors={formMethod?.formState.errors}
                            autoFocus={true}
                            type="text"
                            placeholder="Paragraph Three"
                            iconClass=""
                            textarea="textarea"
                            controlId={"thirdpara"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="x1 float-right">
                      <label className="uploadFile">
                        <span className="filename addCard d-flex align-items-center justify-content-center cursor">
                          Add Images<i className="icon-download pl-2"></i>
                        </span>
                        <input
                          id="file-input"
                          type="file"
                          multiple
                          onChange={imageHandler}
                          accept="image/*"
                          hidden
                        />
                      </label>
                    </div>
                    <div className="section_Img d-flex">
                      <div className="position-relative">
                        <img src={UploadImg} alt="image" className="time_Img" />
                        <div className="bgColor position-absolute closeIcon d-flex align-items-center justify-content-center">
                          <i className="icon-close cursor"></i>
                        </div>
                      </div>
                      <div className="position-relative ">
                        <img src={UploadImg} alt="image" className="time_Img" />
                        <div className="bgColor position-absolute closeIcon d-flex align-items-center justify-content-center">
                          <i className="icon-close cursor"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="img_Section">
                    <Label title="Page layouts"></Label>
                    {/* <div className="dropdown_Section">
                <CustomDropdown
                  dropDownItems={dropdownItem}
                  placeholder="Select theme layouts"
                  selectedValue={staff}
                  onSelect={(selected) => setSelectedStaff(selected)}
                ></CustomDropdown>
              </div> */}

                    <img src={LayoutImg} alt="image" className="time_Img" />
                  </div>
                </div>
              </Scrollbars>
            </div>
          );
        }}
      </HookForm>
      {/* <ModalPopup
        showModal={showPublishModal}
        onHide={() => setShowPublishModal(false)}
        className="publish_Modal"
        headerTitle="Hey, Wait!!"
        closeIcon={true}
      >
        <div className="modal_inner">
          <Label title="Are you sure, you want to publish the current blog"></Label>
        </div>
        <div className="float-right">
          <CustomButton
            className="submitBtn  cancelBtn mr-2"
            title="Cancel"
          ></CustomButton>
          <CustomButton
            className="submitBtn confirmBtn"
            title="Confirm"
            onClick = {onSubmitConfirm}
          ></CustomButton>
        </div>
      </ModalPopup> */}
    </div>
  );
}

export default withRouter(AdminAddBlogsPage);
const blogOptions = [
  {
    id: "0",
    value: "Sports",
  },
  {
    id: "1",
    value: "Entertainment",
  },
  {
    id: "2",
    value: "Facebook",
  },
];
function ChildrenDropdown() {
  const [state, setState] = useState("");
  const [dropdown, setDropdown] = useState("");

  const clickHandler = () => {
    if (state !== "") {
      blogOptions.push({
        id: blogOptions.length.toString(),
        value: state,
      });

      setState("");
      setDropdown(blogOptions[blogOptions.length - 1]);
    }
  };
  return (
    <CustomDropdown
      dropDownItems={blogOptions}
      placeholder="Select"
      selectedValue={dropdown}
      onSelect={(selected) => setDropdown(selected)}
    >
      <div style={{ marginLeft: "14px" }}>
        <input
          style={{ marginRight: "10px", borderRadius: "8px", padding: "4px" }}
          type="text"
          placeholder="Add Others"
          value={state}
          onChange={(e) => setState(e.target.value)}
        />
        <button
          style={{ background: "#16056B", borderRadius: "8px" }}
          onClick={clickHandler}
        >
          <i className="icon-plus" style={{ color: "white" }}></i>
        </button>
      </div>
    </CustomDropdown>
  );
}
