import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import BlogImg from "../../assests/images/blog1.png";
import BlogInnerImg from "../../assests/images/bloginner.png";
import SliderImg from "../../assests/images/slider1.png";
import BlogFeature from "../../components/BlogFeature/BlogFeature";
import BlogModal from "../../components/BlogFeature/BlogModal";
import Footer from "../../components/Footer/Footer";
import HeaderLogin from "../../components/HeaderLogin/HeaderLogin";
import ImageSlider from "../../components/UI/ImageSlider/ImageSlider";
import Label from "../../components/UI/Label/Label";
import "./Blog.scss";

function BlogSection() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const clickShow = () => {
    setShow(!show);
  };
  return (
    <div className="blog_Page">
      {show && <BlogModal closeModal={clickShow} />}
      <HeaderLogin></HeaderLogin>
      <Scrollbars className="blogScroll">
        <div className="main_Banner">
          <img
            src={BlogImg}
            alt="image"
            style={{ maxWidth: "100%", height: "auto" }}
            // fluid="true"
            className="banner_Img"
          />
        </div>
        <Label className="banner_Title" title={t("BLOG_HEAD")}></Label>
        <div className="center_Section">
          <div className="inner_Section">
            <p className="inner_Text">{t("BLOG_ONE")}</p>
            <div className="inner_Img">
              <img
                src={BlogInnerImg}
                alt="image"
                style={{ maxWidth: "100%", height: "auto" }}
                // fluid="true"
                className="blog_Inner"
              />
            </div>
            <Label className="inner_Text" title={t("BLOG_TWO")}></Label>
            <Label className="inner_Text" title={t("BLOG_THREE")}></Label>
          </div>
        </div>
        <div className="blog-feature">
          <BlogFeature clickToShowModal={clickShow} />
        </div>
        <div className="image_Section">
          {/* for blog navigation */}

          <a style={{ padding: "8px 16px" }} href="/blogtwo">
            1
          </a>
          <a style={{ padding: "8px 16px" }} href="/blogthree">
            2
          </a>
          <a style={{ padding: "8px 16px" }} href="/blogfour">
            3
          </a>
          <a style={{ padding: "8px 16px" }} href="/blogfive">
            4
          </a>
          <a style={{ padding: "8px 16px" }} href="/blogsix">
            5
          </a>
          <a style={{ padding: "8px 16px" }} href="/blogseven">
            6
          </a>

          {/* for blog navigation */}
          <Label title={t("BLOG_MORE")}></Label>
          <ImageSlider
            SliderImg={SliderImg}
            SliderImg1={SliderImg}
            SliderImg2={SliderImg}
            headingTxt={t("BLOG_MORE_HEAD")}
            innerTxt={t("BLOG_MORE_BRIEF")}
          ></ImageSlider>
        </div>

        <Footer className="footerheight"></Footer>
      </Scrollbars>
    </div>
  );
}

export default withRouter(BlogSection);
