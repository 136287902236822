import React from "react";
import { withRouter } from "react-router-dom";
import { useAppData } from "../../services/hooks";
// to be remove
import { useDispatch } from "react-redux";
import { getCatAction } from "../../Redux_saga/postPublisherSlice";

function ReduxSagaPage() {
  const dispatch = useDispatch();
  const stateValue = useAppData((r) => r.postPublisherSlice.catFact);
  const loader = stateValue.loading;
  const onClickTest = () => {
    dispatch(getCatAction());
  };
  return (
    <div style={{}}>
      <h5
        style={{
          padding: "8px",
          backgroundColor: "blue",
          textAlign: "center",
          color: "white",
        }}
      >
        Redux Saga Api testing{" "}
      </h5>
      <div style={{ paddingLeft: "24px", marginTop: "16px" }}>
        <button
          style={{ padding: "8px", borderRadius: "8px" }}
          onClick={onClickTest}
        >
          click to fetch cat facts
        </button>
        <h5 style={{ marginTop: "16px" }}>Facts : - </h5>
        {loader ? (
          <p>Loading. . . . . . . . . .</p>
        ) : (
          <p>{stateValue.catFactValue}</p>
        )}
      </div>
    </div>
  );
}

export default withRouter(ReduxSagaPage);
