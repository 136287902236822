import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { withRouter } from "react-router-dom";
import AboutImg from "../../assests/images/about.png";
import UploadImg1 from "../../assests/images/botm.png";
import LaptopImg from "../../assests/images/laptop.png";
import SocialGroup from "../../assests/images/last.png";
import LandingBottom from "../../assests/images/lp-bottom.png";
import LandingTop from "../../assests/images/lp-top.png";
import UploadImg from "../../assests/images/rectangle.png";
import SliderImg from "../../assests/images/slider1.png";
import TopImg from "../../assests/images/topimg.png";
import HookForm from "../../components/HookForm/HookForm";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import ImageSlider from "../../components/UI/ImageSlider/ImageSlider";
import Label from "../../components/UI/Label/Label";
import SearchBox from "../../components/UI/SearchBox/SearchBox";
import TextField from "../../components/UI/TextField/TextField";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { adminLandingPageSteps } from "../Steps";
import "./DashboardElement.scss";

const contactForm = {
  email: {
    name: "email",
  },
  name: {
    name: "name",
  },
};

const imageHandler = (e) => {
  if (e.target.files.length === 0) return;
  // setCondition(true);
  // setAllImages([...allImages, e.target.files]);
};
function DashboardElementPage() {
  const [form, setLoginForm] = React.useState();
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();
  // console.log(form);
  const onFormSubmit = () => {};

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObjAdmin")
    );
    let newObj = { ...previousLocalStorageValue, adminLandingPage: false };
    localStorage.setItem("guideTourObjAdmin", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(
    localStorage.getItem("guideTourObjAdmin")
  );

  return (
    <div className="dashboard_Element">
      <div className="no-design-section justify-content-center align-items-center vh-100">
        <Label
          title="Mobile design not supported"
          className="no-design"
        ></Label>
      </div>
      <GuidedTour
        steps={adminLandingPageSteps}
        onRequestClose={handleOnRequestClose}
        isOpen={
          guideTourGetItemValue ? guideTourGetItemValue.adminLandingPage : true
        }
      />
      <div className="main_Container xs-d-none">
        <Scrollbars className="dashboard_Element_Scroll">
          <div className="dashboard_Section">
            <div className="header-Section">
              <div className="d-flex justify-content-between align-items-center borderBottom">
                <div className="content_Header">
                  <Label className="page_Title" title="Landing Page"></Label>
                  <Label
                    className="inner_Title"
                    title="View and manage all blog post here"
                  ></Label>
                </div>
                <div
                  className="right_Button d-flex align-items-end"
                  tourselector="top-btn"
                >
                  <CustomButton
                    className="submitBtn save"
                    title="Save"
                  ></CustomButton>
                  <CustomButton
                    className="submitBtn publish"
                    title="Publish now"
                  ></CustomButton>
                </div>
              </div>
            </div>
            <div className="form_Img" tourselector="input-img-preview">
              <div className="inner_Section">
                <div className="form_Contact">
                  <HookForm
                    defaultForm={{}}
                    init={(form) => setLoginForm(form)}
                    onSubmit={onFormSubmit}
                  >
                    {(formMethod) => {
                      return (
                        <div className="form1">
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.name.validate}
                            name={contactForm.name.name}
                            errors={formMethod?.errors}
                            placeholder="Title"
                            iconClass=""
                            type="text"
                          />
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.email.validate}
                            name={contactForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={true}
                            type="text"
                            placeholder="Paragraph"
                            textarea="textarea"
                            iconClass=""
                          />
                        </div>
                      );
                    }}
                  </HookForm>
                  <div className="x1 float-right">
                    <label className="uploadFile">
                      <span className="filename addCard d-flex align-items-center justify-content-center cursor">
                        Add Images<i className="icon-download pl-2"></i>
                      </span>
                      <input
                        id="file-input"
                        type="file"
                        multiple
                        onChange={imageHandler}
                        accept="image/*"
                        hidden
                      />
                    </label>
                  </div>
                  <div className="section_Img d-flex ">
                    <div className="position-relative mr-1">
                      <img src={UploadImg} alt="image" className="time_Img" />
                      <div className="bgColor position-absolute closeIcon d-flex align-items-center justify-content-center">
                        <i className="icon-close cursor"></i>
                      </div>
                    </div>
                    <div className="position-relative ">
                      <img src={UploadImg} alt="image" className="time_Img" />
                      <div className="bgColor position-absolute closeIcon d-flex align-items-center justify-content-center">
                        <i className="icon-close cursor"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="img_Section d-flex align-items-center">
                  <div className="flex-direction-column">
                    <Label
                      className="main-Text"
                      title="The best way to change social media account"
                    ></Label>
                    <Label
                      className="inner-Text"
                      title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the  when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five"
                    ></Label>
                    <CustomButton title={"Get Stated"}></CustomButton>
                  </div>
                  <img
                    src={TopImg}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    // fluid="true"
                    className="time_Img"
                  />
                </div>
              </div>
            </div>
            <div className="form_Toogle">
              <div className="inner_Section">
                <div className="form_Contact">
                  <HookForm
                    defaultForm={{}}
                    init={(form) => setLoginForm(form)}
                    onSubmit={onFormSubmit}
                  >
                    {(formMethod) => {
                      return (
                        <div className="form1">
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.name.validate}
                            name={contactForm.name.name}
                            errors={formMethod?.errors}
                            placeholder="Title"
                            iconClass=""
                            type="text"
                          />
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.email.validate}
                            name={contactForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={true}
                            type="text"
                            textarea="textarea"
                            placeholder="Paragraph"
                            iconClass=""
                          />
                        </div>
                      );
                    }}
                  </HookForm>
                  <div className="toggle_Section">
                    <Tabs
                      defaultActiveKey="Monthly"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="Monthly" title="Monthly">
                        <div className="tab_Section">
                          <HookForm
                            defaultForm={{}}
                            init={(form) => setLoginForm(form)}
                            onSubmit={onFormSubmit}
                          >
                            {(formMethod) => {
                              return (
                                <div className="form1 d-flex">
                                  <div className="paragraph pr-2">
                                    <Label
                                      title="Basic"
                                      className="d-flex justify-content-center"
                                    >
                                      {" "}
                                    </Label>
                                    <TextField
                                      formMethod={formMethod}
                                      rules={contactForm.email.validate}
                                      name={contactForm.email.name}
                                      errors={formMethod?.errors}
                                      autoFocus={true}
                                      type="text"
                                      placeholder="Paragraph"
                                      textarea="textarea"
                                      iconClass=""
                                    />
                                    <TextField
                                      formMethod={formMethod}
                                      rules={contactForm.name.validate}
                                      name={contactForm.name.name}
                                      errors={formMethod?.errors}
                                      placeholder="Price"
                                      iconClass=""
                                      type="text"
                                    />
                                  </div>
                                  <div className="paragraph pr-2">
                                    <Label title="Standard"> </Label>
                                    <TextField
                                      formMethod={formMethod}
                                      rules={contactForm.email.validate}
                                      name={contactForm.email.name}
                                      errors={formMethod?.errors}
                                      autoFocus={true}
                                      type="text"
                                      placeholder="Paragraph"
                                      textarea="textarea"
                                      iconClass=""
                                    />
                                    <TextField
                                      formMethod={formMethod}
                                      rules={contactForm.name.validate}
                                      name={contactForm.name.name}
                                      errors={formMethod?.errors}
                                      placeholder="Price"
                                      iconClass=""
                                      type="text"
                                    />
                                  </div>
                                  <div className="paragraph">
                                    <Label title="Professional"> </Label>
                                    <TextField
                                      formMethod={formMethod}
                                      rules={contactForm.email.validate}
                                      name={contactForm.email.name}
                                      errors={formMethod?.errors}
                                      autoFocus={true}
                                      type="text"
                                      placeholder="Paragraph"
                                      textarea="textarea"
                                      iconClass=""
                                    />
                                    <TextField
                                      formMethod={formMethod}
                                      rules={contactForm.name.validate}
                                      name={contactForm.name.name}
                                      errors={formMethod?.errors}
                                      placeholder="Price"
                                      iconClass=""
                                      type="text"
                                    />
                                  </div>
                                </div>
                              );
                            }}
                          </HookForm>
                        </div>
                      </Tab>
                      <Tab eventKey="Yearly" title="Yearly">
                        <div className="tab_Section">
                          <div className="price_TabInner">
                            <div className="content">
                              <div className="label_Inline">
                                <Label className="no" title="01"></Label>
                                <Label
                                  className="main_Text"
                                  title="Basics"
                                ></Label>
                                <Label className="amt" title="Free"></Label>
                              </div>
                              <Label
                                className="desp"
                                title="For individuals and businesses getting started with social media. Manage up to three channels Billed at $0/year"
                              ></Label>
                              <CustomButton
                                title="Get Started"
                                // onClick={() => handleClick("subscriptionpage")}
                              ></CustomButton>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
                <div className="payment_Plans">
                  <Label
                    className="main_title"
                    title="Choose the best plan that fit best for you"
                  ></Label>
                  <Label
                    className="inner_title"
                    title="With our suite of products you can understand and engage with your customer at the speed of social."
                  ></Label>
                  <div className="preview_Section">
                    <Tabs
                      defaultActiveKey="Monthly"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="Monthly" title="Monthly">
                        <div className="tab_Section">
                          <div className="price_TabInner">
                            <div className="content">
                              <div className="label_Inline">
                                <Label className="no" title="01"></Label>
                                <Label
                                  className="main_Text"
                                  title="Basics"
                                ></Label>
                                <Label className="amt" title="Free"></Label>
                              </div>
                              <Label
                                className="desp"
                                title="For individuals and businesses getting started with social media. Manage up to three channels Billed at $0/year"
                              ></Label>
                              <CustomButton
                                title="Get Started"
                                // onClick={() => handleClick("subscriptionpage")}
                              ></CustomButton>
                            </div>
                          </div>
                          <div className="price_TabInner">
                            <div className="content">
                              <div className="label_Inline">
                                <Label className="no" title="02"></Label>
                                <Label
                                  className="main_Text"
                                  title="Standard"
                                ></Label>
                                <Label className="amt" title="Free"></Label>
                              </div>
                              <Label
                                className="desp"
                                title="For individuals and businesses getting started with social media. Manage up to three channels Billed at $0/year"
                              ></Label>
                              <CustomButton
                                title="Get Started"
                                // onClick={() => handleClick("subscriptionpage")}
                              ></CustomButton>
                            </div>
                          </div>
                          <div className="price_TabInner">
                            <div className="content">
                              <div className="label_Inline">
                                <Label className="no" title="03"></Label>
                                <Label
                                  className="main_Text"
                                  title="Professional"
                                ></Label>
                                <Label className="amt" title="Free"></Label>
                              </div>
                              <Label
                                className="desp"
                                title="For individuals and businesses getting started with social media. Manage up to three channels Billed at $0/year"
                              ></Label>
                              <CustomButton
                                title="Get Started"
                                // onClick={() => handleClick("subscriptionpage")}
                              ></CustomButton>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="Yearly" title="Yearly">
                        <div className="tab_Section">
                          <div className="price_TabInner">
                            <div className="content">
                              <div className="label_Inline">
                                <Label className="no" title="01"></Label>
                                <Label
                                  className="main_Text"
                                  title="Basics"
                                ></Label>
                                <Label className="amt" title="Free"></Label>
                              </div>
                              <Label
                                className="desp"
                                title="For individuals and businesses getting started with social media. Manage up to three channels Billed at $0/year"
                              ></Label>
                              <CustomButton
                                title="Get Started"
                                // onClick={() => handleClick("subscriptionpage")}
                              ></CustomButton>
                            </div>
                          </div>
                          <div className="price_TabInner">
                            <div className="content">
                              <div className="label_Inline">
                                <Label className="no" title="02"></Label>
                                <Label
                                  className="main_Text"
                                  title="Standard"
                                ></Label>
                                <Label className="amt" title="Free"></Label>
                              </div>
                              <Label
                                className="desp"
                                title="For individuals and businesses getting started with social media. Manage up to three channels Billed at $0/year"
                              ></Label>
                              <CustomButton
                                title="Get Started"
                                // onClick={() => handleClick("subscriptionpage")}
                              ></CustomButton>
                            </div>
                          </div>
                          <div className="price_TabInner">
                            <div className="content">
                              <div className="label_Inline">
                                <Label className="no" title="03"></Label>
                                <Label
                                  className="main_Text"
                                  title="Professional"
                                ></Label>
                                <Label className="amt" title="Free"></Label>
                              </div>
                              <Label
                                className="desp"
                                title="For individuals and businesses getting started with social media. Manage up to three channels Billed at $0/year"
                              ></Label>
                              <CustomButton
                                title="Get Started"
                                // onClick={() => handleClick("subscriptionpage")}
                              ></CustomButton>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            <div className="form_Img">
              <div className="inner_Section d-flex w-100">
                <div className="form_Contact">
                  <HookForm
                    defaultForm={{}}
                    init={(form) => setLoginForm(form)}
                    onSubmit={onFormSubmit}
                  >
                    {(formMethod) => {
                      return (
                        <div className="form1">
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.name.validate}
                            name={contactForm.name.name}
                            errors={formMethod?.errors}
                            placeholder="Title"
                            iconClass=""
                            type="text"
                          />
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.email.validate}
                            name={contactForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={true}
                            type="text"
                            placeholder="Paragraph"
                            textarea="textarea"
                            iconClass=""
                          />
                        </div>
                      );
                    }}
                  </HookForm>
                  <div className="x1 float-right">
                    <label className="uploadFile">
                      <span className="filename addCard d-flex align-items-center justify-content-center cursor">
                        Add Images<i className="icon-download pl-2"></i>
                      </span>
                      <input
                        id="file-input"
                        type="file"
                        multiple
                        onChange={imageHandler}
                        accept="image/*"
                        hidden
                      />
                    </label>
                  </div>
                  <div className="section_Img d-flex ">
                    <div className="position-relative mr-1">
                      <img src={UploadImg} alt="image" className="time_Img" />
                      <div className="bgColor position-absolute closeIcon d-flex align-items-center justify-content-center">
                        <i className="icon-close cursor"></i>
                      </div>
                    </div>
                    <div className="position-relative ">
                      <img src={UploadImg} alt="image" className="time_Img" />
                      <div className="bgColor position-absolute closeIcon d-flex align-items-center justify-content-center">
                        <i className="icon-close cursor"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="img_Section d-flex align-items-center">
                  <img
                    src={AboutImg}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    // fluid="true"
                    className="about_Img"
                  />
                  <div className="flex-direction-column paddingLeft">
                    <div className="text-section">
                      <Label
                        className="main-Text"
                        title="The best way to change social media account"
                      ></Label>
                      <Label
                        className="inner-Text"
                        title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the  when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five"
                      ></Label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form_Img">
              <div className="inner_Section d-flex w-100">
                <div className="form_Contact">
                  <HookForm
                    defaultForm={{}}
                    init={(form) => setLoginForm(form)}
                    onSubmit={onFormSubmit}
                  >
                    {(formMethod) => {
                      return (
                        <div className="form1">
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.name.validate}
                            name={contactForm.name.name}
                            errors={formMethod?.errors}
                            placeholder="Title"
                            iconClass=""
                            type="text"
                          />
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.email.validate}
                            name={contactForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={true}
                            type="text"
                            placeholder="Paragraph"
                            textarea="textarea"
                            iconClass=""
                          />
                        </div>
                      );
                    }}
                  </HookForm>
                  <div className="x1 float-right">
                    <label className="uploadFile">
                      <span className="filename addCard d-flex align-items-center justify-content-center cursor">
                        Add Images<i className="icon-download pl-2"></i>
                      </span>
                      <input
                        id="file-input"
                        type="file"
                        multiple
                        onChange={imageHandler}
                        accept="image/*"
                        hidden
                      />
                    </label>
                  </div>
                  <div className="section_Img d-flex ">
                    <div className="position-relative mr-1">
                      <img src={UploadImg} alt="image" className="time_Img" />
                      <div className="bgColor position-absolute closeIcon d-flex align-items-center justify-content-center">
                        <i className="icon-close cursor"></i>
                      </div>
                    </div>
                    <div className="position-relative ">
                      <img src={UploadImg} alt="image" className="time_Img" />
                      <div className="bgColor position-absolute closeIcon d-flex align-items-center justify-content-center">
                        <i className="icon-close cursor"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="img_Section d-flex align-items-center">
                  <div className="flex-direction-column">
                    <div className="text-section width doing-section">
                      <Label
                        className="main-Text"
                        title="Doing Everthing Yourself is Difficult Save time. Improve quality. Collaborate effectively"
                      ></Label>
                      <ul className="flex-direction-center d-flex align-items-center flex-column">
                        <li className="width-li">
                          <Label
                            className="inner-Text pb-1"
                            title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. scrambled it to make a type specimen."
                          ></Label>
                        </li>
                        <li>
                          <Label
                            className="inner-Text"
                            title="Lorem Ipsum is simply it to make a type specimen."
                          ></Label>
                        </li>
                        <li>
                          <Label
                            className="inner-Text"
                            title="Lorem Ipsum is simply it to make a type specimen."
                          ></Label>
                        </li>
                        <li>
                          <Label
                            className="inner-Text"
                            title="Lorem Ipsum is simply it to make a type specimen."
                          ></Label>
                        </li>
                        <li>
                          <Label
                            className="inner-Text"
                            title="Lorem Ipsum is simply it to make a type specimen."
                          ></Label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <img
                    src={LaptopImg}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    // fluid="true"
                    className="laptop-img"
                  />
                </div>
              </div>
            </div>
            <div className="form_Img ">
              <div className="inner_Section d-flex w-100 bgBlue">
                <div className="form_Contact">
                  <HookForm
                    defaultForm={{}}
                    init={(form) => setLoginForm(form)}
                    onSubmit={onFormSubmit}
                  >
                    {(formMethod) => {
                      return (
                        <div className="form1">
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.name.validate}
                            name={contactForm.name.name}
                            errors={formMethod?.errors}
                            placeholder="Title"
                            iconClass=""
                            type="text"
                          />
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.email.validate}
                            name={contactForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={true}
                            type="text"
                            placeholder="Paragraph"
                            textarea="textarea"
                            iconClass=""
                          />
                        </div>
                      );
                    }}
                  </HookForm>
                </div>
                <div className="img_Section d-flex align-items-center manage_Section">
                  <img
                    src={LandingTop}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    // fluid="true"
                    className="main_Top"
                  />
                  <img
                    src={LandingBottom}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    // fluid="true"
                    className="main_Bottom"
                  />
                  <div className="content_Section d-flex flex-column align-items-center">
                    <Label
                      className="main_Title"
                      title="Life Is Easier With Social Media Automation"
                    ></Label>
                    <Label
                      className="inner_Title"
                      title="Creating, posting, and analyzing content across so many touchpoints can take an overwhelming amount of time. Unless, of course, you engage in some social media automation"
                    ></Label>
                    <CustomButton title="Get Started"></CustomButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="form_Img">
              <div className="inner_Section d-flex w-100 bgWhite">
                <div className="form_Contact">
                  <SearchBox placeholder="Search by title " />
                  <div className="table_Section">
                    <div className="query-Section d-flex flex-column">
                      <ul className="header d-flex p-0 m-0">
                        <li>Sl.No.</li>
                        <li className="pr-1">Title</li>
                        <li className="pr-1">Created on</li>
                        <li className="pr-1"></li>
                        <li></li>
                      </ul>
                      <ul className="dataList d-flex p-0 m-0">
                        <li>2</li>
                        <li className="pr-1">
                          Lorem ipsum dolor sit ametAut voluptatibus
                        </li>
                        <li className="pr-1">22/Sep/2019</li>
                        <li className="text-right pr-1 ">
                          <icon className="icon-download"></icon>{" "}
                        </li>
                      </ul>
                      <ul className="dataList d-flex p-0 m-0">
                        <li>2</li>
                        <li className="pr-1">
                          Lorem ipsum dolor sit ametAut voluptatibus
                        </li>
                        <li className="pr-1">22/Sep/2019</li>
                        <li className="text-right pr-1 ">
                          <icon className="icon-download"></icon>{" "}
                        </li>
                      </ul>
                      <ul className="dataList d-flex p-0 m-0">
                        <li>2</li>
                        <li className="pr-1">
                          Lorem ipsum dolor sit ametAut voluptatibus
                        </li>
                        <li className="pr-1">22/Sep/2019</li>
                        <li className="text-right pr-1 ">
                          <icon className="icon-download"></icon>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="imgSlider_Section">
                  <Label
                    className="main_Title"
                    title="Read our latest stories"
                  ></Label>
                  <ImageSlider
                    SliderImg={SliderImg}
                    SliderImg1={SliderImg}
                    SliderImg2={SliderImg}
                    headingTxt="Lorem Ipsum is text"
                    innerTxt="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,"
                  ></ImageSlider>
                </div>
              </div>
            </div>
            <div className="form_Img bottom_Section">
              <div className="inner_Section w-100 d-flex">
                <div className="form_Contact d-flex flex-column justify-content-center align-items-center">
                  <div className="section_Img d-flex ">
                    <div className="position-relative paddingBtn">
                      <img src={UploadImg1} alt="image" className="time_Img" />
                      <div className="bgColor position-absolute closeIcon d-flex align-items-center justify-content-center">
                        <i className="icon-close cursor"></i>
                      </div>
                    </div>
                  </div>
                  <div className="x1 float-right">
                    <label className="uploadFile">
                      <span className="filename addCard d-flex align-items-center justify-content-center cursor">
                        Add Images<i className="icon-download pl-2"></i>
                      </span>
                      <input
                        id="file-input"
                        type="file"
                        multiple
                        onChange={imageHandler}
                        accept="image/*"
                        hidden
                      />
                    </label>
                  </div>
                </div>
                <div className="img_Sections d-flex align-items-center">
                  <div className="input_Section w-100">
                    <Label
                      className="main_Title"
                      title="Get in touch with us"
                    ></Label>
                    <div className="content_Textbox d-flex w-100">
                      <div className="img_Section d-flex w-100 pr-0">
                        <div className="img-part">
                          <img
                            src={SocialGroup}
                            alt="image"
                            style={{ maxWidth: "100%", height: "auto" }}
                            // fluid="true"
                            className="w-100"
                          />
                        </div>
                        <div className="text_Box d-flex">
                          <HookForm
                            defaultForm={{}}
                            init={(form) => setLoginForm(form)}
                            onSubmit={onFormSubmit}
                          >
                            {(formMethod) => {
                              return (
                                <div className="form1">
                                  <TextField
                                    formMethod={formMethod}
                                    rules={contactForm.name.validate}
                                    name={contactForm.name.name}
                                    errors={formMethod?.errors}
                                    placeholder="Name"
                                    iconClass="icon-user"
                                    type="text"
                                  />
                                  <TextField
                                    formMethod={formMethod}
                                    rules={contactForm.email.validate}
                                    name={contactForm.email.name}
                                    errors={formMethod?.errors}
                                    autoFocus={true}
                                    type="text"
                                    placeholder="Email Id*"
                                    iconClass="icon-mail"
                                  />
                                  <TextField
                                    formMethod={formMethod}
                                    rules={contactForm.email.validate}
                                    name={contactForm.email.name}
                                    errors={formMethod?.errors}
                                    autoFocus={true}
                                    type="text"
                                    placeholder="Mobile No"
                                    iconClass="icon-mail"
                                  />
                                  <TextField
                                    formMethod={formMethod}
                                    rules={contactForm.name.validate}
                                    name={contactForm.name.name}
                                    errors={formMethod?.errors}
                                    placeholder="Share your throughts"
                                    type="text"
                                    textarea="textarea"
                                  />
                                  <CustomButton
                                    title="Submit"
                                    // onClick={() => handleClick("thankyouPopup")}
                                  ></CustomButton>
                                </div>
                              );
                            }}
                          </HookForm>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
}

export default withRouter(DashboardElementPage);
