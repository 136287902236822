import React from "react";
import { Fade, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./ModalPopup.scss";

const ModalPopup = (props) => {
  const { headerTitle } = props;
  return (
    <Modal
      className={"modalPopup " + props.className}
      show={props.showModal}
      animation={Fade}
      onHide={() => props.onHide()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title">{headerTitle}</h5>
        <Link
          onClick={() => {
            props.onHide();
          }}
          className="closeBtn"
          to="#"
        >
          <i className="icon-close"></i>
        </Link>
      </div>
      <div className="modalInner">
        <Modal.Body>{props?.children}</Modal.Body>
      </div>
    </Modal>
  );
};
ModalPopup.defaultProps = {
  className: "modalPopup",
};

export default ModalPopup;
