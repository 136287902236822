import React, { useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import { postForgotPasswordAction } from "../../Redux_saga/postPublisherSlice";
import MainImg from "../../assests/images/main-img.png";
import PatterBottom from "../../assests/images/patten-btm.png";
import PatterTop from "../../assests/images/patten-top.png";
import HookForm from "../../components/HookForm/HookForm";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import Label from "../../components/UI/Label/Label";
import TextField from "../../components/UI/TextField/TextField";
import { useAppData } from "../../services/hooks";
import Constant from "../../util/constant";
import Message from "../../util/message";
import { localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { forgotSteps } from "../Steps";
import "./Forgot.scss";

const forgotForm = {
  email: {
    name: "emailId",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.EMAILEMPTY,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ERRORMESSAGE.EMAILINVALID,
      },
    },
  },
};

function Forgot() {
  const [isOpenTourGuide, setIsOpenTourGuide] = React.useState();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useAppData((r) => r.postPublisherSlice.users);
  const forgotResponse = data.ForgotPasswordResponseValue.message;

  const [form, setForgotForm] = React.useState();
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  // console.log(form);
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const onFormSubmit = (form) => {
    dispatch(postForgotPasswordAction(form));
  };
  useEffect(() => {
    if (forgotResponse === "Mail Sent Successfully!") {
      history.push(
        `/verifymail?${data.ForgotPasswordResponseValue.data.emailId}`
      );
    }
  }, [forgotResponse]);
  let search = window.location.search;
  let params = search.split("?")[1];

  const handleOnRequestClose = () => {
    let previousLocalStorageValue = JSON.parse(
      localStorage.getItem("guideTourObj")
    );
    let newObj = { ...previousLocalStorageValue, forgot: false };
    localStorage.setItem("guideTourObj", JSON.stringify(newObj));
    setIsOpenTourGuide(newObj);
  };
  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(localStorage.getItem("guideTourObj"));

  return (
    <div className="Forgot_Page" style={{ height: dimensions.height + "px" }}>
      <img
        src={PatterTop}
        alt="image"
        style={{ maxWidth: "100%", height: "auto" }}
        // fluid="true"
        className="top_Patten"
      />
      <img
        src={PatterBottom}
        alt="image"
        style={{ maxWidth: "100%", height: "auto" }}
        // fluid="true"
        className="bottom_Patten"
      />
      <Scrollbars className="scroll_Section">
        <GuidedTour
          steps={forgotSteps}
          onRequestClose={handleOnRequestClose}
          isOpen={guideTourGetItemValue ? guideTourGetItemValue.forgot : true}
        />
        <div className="center_Section">
          <div className="chatboat-section position-absolute">
            <i className="icon-chatboat"></i>
          </div>
          <div className="chatboat-section position-absolute">
            <i className="icon-chatboat"></i>
          </div>
          <div className="img_Section">
            <img
              src={MainImg}
              alt="image"
              style={{ maxWidth: "100%", height: "auto" }}
              // fluid="true"
              className="main_Img"
            />
          </div>
          <div className="Forgot_Box">
            <div className="Forgot_BoxInner" tourselector="forgot-form">
              <Label title={t("FORGOT_INPUTBOX_FORGOT")}></Label>
              <div className="link_Section">
                <Label
                  className="form_Name"
                  title={t("FORGOT_INPUTBOX_ENTER")}
                ></Label>
              </div>
              <HookForm
                defaultForm={{ emailId: params === undefined ? "" : params }}
                init={(form) => setForgotForm(form)}
                onSubmit={onFormSubmit}
              >
                {(formMethod) => {
                  return (
                    <div className="form">
                      <TextField
                        formMethod={formMethod}
                        rules={forgotForm.email.validate}
                        name={forgotForm.email.name}
                        errors={formMethod?.formState.errors}
                        autoFocus={true}
                        type="text"
                        placeholder="Email Id*"
                        iconClass="icon-mail"
                        defaultValue={formMethod?.getValues("emailId")}
                      />

                      <CustomButton
                        title={t("FORGOT_INPUTBOX_SUBMIT")}
                      ></CustomButton>
                      <Link className="back" to="/login">
                        <i className="icon-leftarrow"></i>
                        <Label
                          className="btl"
                          title={t("FORGOT_INPUTBOX_BACK")}
                        ></Label>
                      </Link>
                    </div>
                  );
                }}
              </HookForm>
            </div>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}

export default withRouter(Forgot);
