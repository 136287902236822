import React from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import Scrollbars from "react-custom-scrollbars";
import { withRouter } from "react-router-dom";
import UploadImg3 from "../../assests/images/blue-wedo.png";
import UploadImg1 from "../../assests/images/botm.png";
import SocialGroup from "../../assests/images/last.png";
import UploadImg from "../../assests/images/rectangle.png";
import SliderImg from "../../assests/images/slider1.png";
import TopImg from "../../assests/images/topimg.png";
import UploadImg2 from "../../assests/images/wedo.png";
import HookForm from "../../components/HookForm/HookForm";
import CheckBox from "../../components/UI/CheckBox/CheckBox";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import ImageSlider from "../../components/UI/ImageSlider/ImageSlider";
import Label from "../../components/UI/Label/Label";
import SearchBox from "../../components/UI/SearchBox/SearchBox";
import TextField from "../../components/UI/TextField/TextField";
import "./AdminAbout.scss";
// import { localStorageSetItem } from '../GuideTour/GuideTourJSON';

const contactForm = {
  email: {
    name: "email",
  },
  name: {
    name: "name",
  },
};

const imageHandler = (e) => {
  if (e.target.files.length === 0) return;
  // setCondition(true);
  // setAllImages([...allImages, e.target.files]);
};
function AdminAboutUs() {
  const [form, setLoginForm] = React.useState();
  // const [isOpenTourGuide, setIsOpenTourGuide] = useState();
  const onFormSubmit = () => {};
  // console.log(form);
  // const handleOnRequestClose = () => {
  //   let previousLocalStorageValue = JSON.parse(
  //     localStorage.getItem('guideTourObjAdmin'),
  //   );
  //   let newObj = { ...previousLocalStorageValue, adminLandingPage: false };
  //   localStorage.setItem('guideTourObjAdmin', JSON.stringify(newObj));
  //   setIsOpenTourGuide(newObj);
  // };
  // React.useEffect(() => {
  //   if (isOpenTourGuide != undefined) {
  //     localStorageSetItem(isOpenTourGuide);
  //   }
  // }, [isOpenTourGuide]);
  // let guideTourGetItemValue = JSON.parse(
  //   localStorage.getItem('guideTourObjAdmin'),
  // );

  return (
    <div className="dashboard_Element">
      <div className="no-design-section justify-content-center align-items-center vh-100">
        <Label
          title="Mobile design not supported"
          className="no-design"
        ></Label>
      </div>
      {/* <GuidedTour
                steps={adminLandingPageSteps}
                onRequestClose={handleOnRequestClose}
                isOpen={
                    guideTourGetItemValue ? guideTourGetItemValue.adminLandingPage : true
                }
            /> */}
      <div className="main_Container xs-d-none">
        <Scrollbars className="dashboard_Element_Scroll">
          <div className="dashboard_Section">
            <div className="header-Section">
              <div className="d-flex justify-content-between align-items-center borderBottom">
                <div className="content_Header">
                  <Label className="page_Title" title="About us"></Label>
                  <Label
                    className="inner_Title"
                    title="View and manage all blog post here"
                  ></Label>
                </div>
                <div
                  className="right_Button d-flex align-items-end"
                  tourselector="top-btn"
                >
                  <CustomButton
                    className="submitBtn save"
                    title="Save"
                  ></CustomButton>
                  <NavDropdown
                    title="Publish now"
                    id="basic-nav-dropdown"
                    className="submitBtn mr-2"
                    tourselector="admin-subscription-btn"
                  >
                    <NavDropdown.Item href="#action/3.2">
                      Publish now
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Publish now
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              </div>
            </div>
            <div className="form_Img" tourselector="input-img-preview">
              <div className="inner_Section">
                <div className="form_Contact">
                  <HookForm
                    defaultForm={{}}
                    init={(form) => setLoginForm(form)}
                    onSubmit={onFormSubmit}
                  >
                    {(formMethod) => {
                      return (
                        <div className="form1">
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.name.validate}
                            name={contactForm.name.name}
                            errors={formMethod?.errors}
                            placeholder="Title"
                            iconClass=""
                            type="text"
                          />
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.email.validate}
                            name={contactForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={true}
                            type="text"
                            placeholder="Paragraph"
                            textarea="textarea"
                            iconClass=""
                          />
                        </div>
                      );
                    }}
                  </HookForm>
                  <div className="x1 float-right">
                    <label className="uploadFile">
                      <span className="filename addCard d-flex align-items-center justify-content-center cursor">
                        Add Images<i className="icon-download pl-2"></i>
                      </span>
                      <input
                        id="file-input"
                        type="file"
                        multiple
                        onChange={imageHandler}
                        accept="image/*"
                        hidden
                      />
                    </label>
                  </div>
                  <div className="section_Img d-flex ">
                    <div className="position-relative mr-1">
                      <img src={UploadImg} alt="image" className="time_Img" />
                      <div className="bgColor position-absolute closeIcon d-flex align-items-center justify-content-center">
                        <i className="icon-close cursor"></i>
                      </div>
                    </div>
                    <div className="position-relative ">
                      <img src={UploadImg} alt="image" className="time_Img" />
                      <div className="bgColor position-absolute closeIcon d-flex align-items-center justify-content-center">
                        <i className="icon-close cursor"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="img_Section d-flex align-items-center">
                  <div className="flex-direction-column">
                    <Label
                      className="main-Text"
                      title="The best way to change social media account"
                    ></Label>
                    <Label
                      className="inner-Text"
                      title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the  when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five"
                    ></Label>
                    <CustomButton title={"Get Stated"}></CustomButton>
                  </div>
                  <img
                    src={TopImg}
                    alt="image"
                    style={{ maxWidth: "100%", height: "auto" }}
                    // fluid="true"
                    className="time_Img"
                  />
                </div>
              </div>
            </div>
            <div
              className="form_Img image-section"
              tourselector="input-img-preview"
            >
              <div className="inner_Section w-100">
                <div className="form_Contact">
                  <HookForm
                    defaultForm={{}}
                    init={(form) => setLoginForm(form)}
                    onSubmit={onFormSubmit}
                  >
                    {(formMethod) => {
                      return (
                        <div className="form1">
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.name.validate}
                            name={contactForm.name.name}
                            errors={formMethod?.errors}
                            placeholder="Title"
                            iconClass=""
                            type="text"
                          />
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.email.validate}
                            name={contactForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={true}
                            type="text"
                            placeholder="Paragraph"
                            textarea="textarea"
                            iconClass=""
                          />
                        </div>
                      );
                    }}
                  </HookForm>
                  <div className="x1 float-right">
                    <label className="uploadFile">
                      <span className="filename addCard d-flex align-items-center justify-content-center cursor">
                        Add Images<i className="icon-download pl-2"></i>
                      </span>
                      <input
                        id="file-input"
                        type="file"
                        multiple
                        onChange={imageHandler}
                        accept="image/*"
                        hidden
                      />
                    </label>
                  </div>
                  <div className="section_Img d-flex ">
                    <div className="position-relative mr-1">
                      <img src={UploadImg} alt="image" className="time_Img" />
                      <div className="bgColor position-absolute closeIcon d-flex align-items-center justify-content-center">
                        <i className="icon-close cursor"></i>
                      </div>
                    </div>
                    <div className="position-relative ">
                      <img src={UploadImg} alt="image" className="time_Img" />
                      <div className="bgColor position-absolute closeIcon d-flex align-items-center justify-content-center">
                        <i className="icon-close cursor"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="img_Section d-flex align-items-center">
                  <div className="flex-direction-column w-100 d-flex justify-content-center">
                    <img
                      src={UploadImg2}
                      alt="image"
                      style={{ maxWidth: "100%", height: "auto" }}
                      // fluid="true"
                      className="time_Img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="form_Img image-section"
              tourselector="input-img-preview"
            >
              <div className="inner_Section w-100">
                <div className="form_Contact">
                  <HookForm
                    defaultForm={{}}
                    init={(form) => setLoginForm(form)}
                    onSubmit={onFormSubmit}
                  >
                    {(formMethod) => {
                      return (
                        <div className="form1">
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.name.validate}
                            name={contactForm.name.name}
                            errors={formMethod?.errors}
                            placeholder="Title"
                            iconClass=""
                            type="text"
                          />
                          <TextField
                            formMethod={formMethod}
                            rules={contactForm.email.validate}
                            name={contactForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={true}
                            type="text"
                            placeholder="Paragraph"
                            textarea="textarea"
                            iconClass=""
                          />
                        </div>
                      );
                    }}
                  </HookForm>
                  <div className="x1 float-right">
                    <label className="uploadFile">
                      <span className="filename addCard d-flex align-items-center justify-content-center cursor">
                        Add Images<i className="icon-download pl-2"></i>
                      </span>
                      <input
                        id="file-input"
                        type="file"
                        multiple
                        onChange={imageHandler}
                        accept="image/*"
                        hidden
                      />
                    </label>
                  </div>
                  <div className="section_Img d-flex ">
                    <div className="position-relative mr-1">
                      <img src={UploadImg} alt="image" className="time_Img" />
                      <div className="bgColor position-absolute closeIcon d-flex align-items-center justify-content-center">
                        <i className="icon-close cursor"></i>
                      </div>
                    </div>
                    <div className="position-relative ">
                      <img src={UploadImg} alt="image" className="time_Img" />
                      <div className="bgColor position-absolute closeIcon d-flex align-items-center justify-content-center">
                        <i className="icon-close cursor"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="img_Section d-flex align-items-center">
                  <div className="flex-direction-column w-100 justify-content-center d-flex">
                    <img
                      src={UploadImg3}
                      alt="image"
                      style={{ maxWidth: "100%", height: "auto" }}
                      // fluid="true"
                      className="time_Img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form_Img">
              <div className="inner_Section d-flex w-100 bgWhite">
                <div className="form_Contact">
                  <div className="top-Section d-flex">
                    <div className="d-flex select-Section mr-2">
                      <Label title="Lorem ipsum"></Label>
                      <i className="icon-close closeCss"></i>
                    </div>
                    <div className="d-flex select-Section mr-2">
                      <Label title="Lorem ipsum"></Label>
                      <i className="icon-close closeCss"></i>
                    </div>
                    <div className="d-flex select-Section mr-2">
                      <Label title="Lorem ipsum"></Label>
                      <i className="icon-close closeCss"></i>
                    </div>
                    <div className="w-50">
                      <SearchBox placeholder="Search by title " />
                    </div>
                  </div>

                  <div className="table_Section">
                    <div className="query-Section d-flex flex-column">
                      <ul className="header d-flex p-0 m-0">
                        <li>Sl.No.</li>
                        <li className="pr-1">Title</li>
                        <li className="pr-1">Created on</li>
                        <li className="pr-1">Select</li>
                        <li></li>
                      </ul>
                      <ul className="dataList d-flex p-0 m-0">
                        <li>2</li>
                        <li className="pr-1">
                          Lorem ipsum dolor sit ametAut voluptatibus
                        </li>
                        <li className="pr-1">22/Sep/2019</li>
                        <li className="text-right pr-1 ">
                          <CheckBox
                            className="checkboxMain"
                            id="1"
                            labelTitle=""
                          ></CheckBox>
                        </li>
                      </ul>
                      <ul className="dataList d-flex p-0 m-0">
                        <li>2</li>
                        <li className="pr-1">
                          Lorem ipsum dolor sit ametAut voluptatibus
                        </li>
                        <li className="pr-1">22/Sep/2019</li>
                        <li className="text-right pr-1 ">
                          <CheckBox
                            className="checkboxMain"
                            id="1"
                            labelTitle=""
                          ></CheckBox>
                        </li>
                      </ul>
                      <ul className="dataList d-flex p-0 m-0">
                        <li>2</li>
                        <li className="pr-1">
                          Lorem ipsum dolor sit ametAut voluptatibus
                        </li>
                        <li className="pr-1">22/Sep/2019</li>
                        <li className="text-right pr-1 ">
                          <CheckBox
                            className="checkboxMain"
                            id="1"
                            labelTitle=""
                          ></CheckBox>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="imgSlider_Section">
                  <Label
                    className="main_Title"
                    title="Read our latest stories"
                  ></Label>
                  <ImageSlider
                    SliderImg={SliderImg}
                    SliderImg1={SliderImg}
                    SliderImg2={SliderImg}
                    headingTxt="Lorem Ipsum is text"
                    innerTxt="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,"
                  ></ImageSlider>
                </div>
              </div>
            </div>
            <div className="form_Img">
              <div className="inner_Section d-flex w-100 bgBlue">
                <div className="form_Contact">
                  <div className="top-Section d-flex">
                    <div className="d-flex select-Section mr-2">
                      <Label title="Lorem ipsum"></Label>
                      <i className="icon-close closeCss"></i>
                    </div>
                    <div className="d-flex select-Section mr-2">
                      <Label title="Lorem ipsum"></Label>
                      <i className="icon-close closeCss"></i>
                    </div>
                    <div className="d-flex select-Section mr-2">
                      <Label title="Lorem ipsum"></Label>
                      <i className="icon-close closeCss"></i>
                    </div>
                    <div className="w-50">
                      <SearchBox placeholder="Search by title " />
                    </div>
                  </div>

                  <div className="table_Section">
                    <div className="query-Section d-flex flex-column">
                      <ul className="header d-flex p-0 m-0">
                        <li>Sl.No.</li>
                        <li className="pr-1">Title</li>
                        <li className="pr-1">Created on</li>
                        <li className="pr-1">Select</li>
                        <li></li>
                      </ul>
                      <ul className="dataList d-flex p-0 m-0">
                        <li>2</li>
                        <li className="pr-1">
                          Lorem ipsum dolor sit ametAut voluptatibus
                        </li>
                        <li className="pr-1">22/Sep/2019</li>
                        <li className="text-right pr-1 ">
                          <CheckBox
                            className="checkboxMain"
                            id="6"
                            labelTitle=""
                          ></CheckBox>
                        </li>
                      </ul>
                      <ul className="dataList d-flex p-0 m-0">
                        <li>2</li>
                        <li className="pr-1">
                          Lorem ipsum dolor sit ametAut voluptatibus
                        </li>
                        <li className="pr-1">22/Sep/2019</li>
                        <li className="text-right pr-1 ">
                          <CheckBox
                            className="checkboxMain"
                            id="4"
                            labelTitle=""
                          ></CheckBox>
                        </li>
                      </ul>
                      <ul className="dataList d-flex p-0 m-0">
                        <li>2</li>
                        <li className="pr-1">
                          Lorem ipsum dolor sit ametAut voluptatibus
                        </li>
                        <li className="pr-1">22/Sep/2019</li>
                        <li className="text-right pr-1 ">
                          <CheckBox
                            className="checkboxMain"
                            id="5"
                            labelTitle=""
                          ></CheckBox>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="imgSlider_Section">
                  <Label
                    className="main_Title"
                    title="Read our latest stories"
                  ></Label>
                  <ImageSlider
                    SliderImg={SliderImg}
                    SliderImg1={SliderImg}
                    SliderImg2={SliderImg}
                    headingTxt="Lorem Ipsum is text"
                    innerTxt="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s,"
                  ></ImageSlider>
                </div>
              </div>
            </div>
            <div className="form_Img bottom_Section">
              <div className="inner_Section w-100 d-flex">
                <div className="form_Contact d-flex flex-column justify-content-center align-items-center">
                  <div className="section_Img d-flex ">
                    <div className="position-relative paddingBtn">
                      <img src={UploadImg1} alt="image" className="time_Img" />
                      <div className="bgColor position-absolute closeIcon d-flex align-items-center justify-content-center">
                        <i className="icon-close cursor"></i>
                      </div>
                    </div>
                  </div>
                  <div className="x1 float-right">
                    <label className="uploadFile">
                      <span className="filename addCard d-flex align-items-center justify-content-center cursor">
                        Add Images<i className="icon-download pl-2"></i>
                      </span>
                      <input
                        id="file-input"
                        type="file"
                        multiple
                        onChange={imageHandler}
                        accept="image/*"
                        hidden
                      />
                    </label>
                  </div>
                </div>
                <div className="img_Section d-flex align-items-center">
                  <div className="input_Section w-100">
                    <Label
                      className="main_Title"
                      title="Get in touch with us"
                    ></Label>
                    <div className="content_Textbox d-flex w-100">
                      <div className="img_Section d-flex pr-0">
                        <div className="img-part">
                          <img
                            src={SocialGroup}
                            alt="image"
                            style={{ maxWidth: "100%", height: "auto" }}
                            // fluid="true"
                            className=""
                          />
                        </div>

                        <div className="text_Box d-flex">
                          <HookForm
                            defaultForm={{}}
                            init={(form) => setLoginForm(form)}
                            onSubmit={onFormSubmit}
                          >
                            {(formMethod) => {
                              return (
                                <div className="form1">
                                  <TextField
                                    formMethod={formMethod}
                                    rules={contactForm.name.validate}
                                    name={contactForm.name.name}
                                    errors={formMethod?.errors}
                                    placeholder="Name"
                                    iconClass="icon-user"
                                    type="text"
                                  />
                                  <TextField
                                    formMethod={formMethod}
                                    rules={contactForm.email.validate}
                                    name={contactForm.email.name}
                                    errors={formMethod?.errors}
                                    autoFocus={true}
                                    type="text"
                                    placeholder="Email Id*"
                                    iconClass="icon-mail"
                                  />
                                  <TextField
                                    formMethod={formMethod}
                                    rules={contactForm.email.validate}
                                    name={contactForm.email.name}
                                    errors={formMethod?.errors}
                                    autoFocus={true}
                                    type="text"
                                    placeholder="Mobile No"
                                    iconClass="icon-mail"
                                  />
                                  <TextField
                                    formMethod={formMethod}
                                    rules={contactForm.name.validate}
                                    name={contactForm.name.name}
                                    errors={formMethod?.errors}
                                    placeholder="Share your throughts"
                                    type="text"
                                    textarea="textarea"
                                  />
                                  <CustomButton
                                    title="Submit"
                                    // onClick={() => handleClick("thankyouPopup")}
                                  ></CustomButton>
                                </div>
                              );
                            }}
                          </HookForm>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
}

export default withRouter(AdminAboutUs);
