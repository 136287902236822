import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import CustomDropdown from "../../../components/UI/CustomDropdown/CustomDropdown";
import FilterDropdown from "../../../components/UI/FilterDropdown/FilterDropdown";
import Label from "../../../components/UI/Label/Label";

import "./FilterForm.scss";

const itemsStatus = [
  {
    id: "1",
    value: "Disconnected",
  },
  {
    id: "2",
    value: "Connected",
  },
  {
    id: "3",
    value: "Paused",
  },
  {
    id: "4",
    value: "Active",
  },
];
const FilterForm = (props) => {
  const { title, onClickIconClose, onFilterChange } = props;

  const [selectedItem, setSelectedItem] = useState();

  const handleApplyFilter = () => {
    onFilterChange(selectedItem);
  };

  const handleClearFilter = () => {
    setSelectedItem(null);

    onFilterChange(null);
  };

  return (
    <>
      <FilterDropdown>
        <div className="filterDispute">
          <div className="form">
            <div className="filterItem">
              <div className="header_Section">
                <div className="heading">
                  <Label title={title}></Label>
                  <i className="icon-close" onClick={onClickIconClose}></i>
                </div>
              </div>

              <div className="dropdown_Section">
                <Label
                  className="filter_Title"
                  title="Filter by Status"
                ></Label>
                <CustomDropdown
                  dropDownItems={itemsStatus}
                  placeholder="Select group status"
                  selectedValue={selectedItem}
                  onSelect={(selected) => {
                    setSelectedItem(selected);
                  }}
                ></CustomDropdown>
              </div>
            </div>
            <div className="custombtnfield">
              <div>
                <CustomButton
                  className="apply"
                  type="button"
                  title="Apply"
                  onClick={handleApplyFilter}
                />
              </div>
              <div>
                <CustomButton
                  className="clear"
                  type="button"
                  title="Clear"
                  onClick={handleClearFilter}
                />
              </div>
            </div>
          </div>
        </div>
      </FilterDropdown>
    </>
  );
};

export default FilterForm;
