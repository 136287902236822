import React, { useState, useEffect } from "react";
import "./CustomRadio.scss";

const CustomRadio = (props) => {
  let { labelTitle, id, onCheckedChange, register, name } = props;
  const [checked, setChecked] = useState(!!props.checked);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const onChecked = (e) => {
    setChecked(e.target.checked);
    onCheckedChange && onCheckedChange(e.target.checked);
  };

  return (
    <div className="radioMain">
      <input
        type="radio"
        ref={register}
        name={name}
        value={labelTitle}
        id={id}
        className="checkbox"
        checked={checked}
        onChange={onChecked}
      />
      <label className="pointerC" id="checkLabel" htmlFor={id}>
        {labelTitle}
      </label>
    </div>
  );
};

CustomRadio.defaultProps = {
  id: 1,
  checked: false,
  labelTitle: "Title",
};

export default CustomRadio;
