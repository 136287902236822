import React from "react";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import DashboardElement from "../DashboardElement/DashboardElement";
import AdminSupportPage from "../AdminSupportPage/AdminSupportPage";
import AdminAboutPage from "../AdminSupportPage/AdminSupportPage"; // Add proper page path

const AdminUiElements = () => {
  let search = window.location.search;
  let params = search.split("?")[1];
  return (
    <div>
      <AdminHeader />
      {params === "landing" ? (
        <DashboardElement />
      ) : params === "features" ? (
        <h1> Features page </h1>
      ) : params === "support" ? (
        <AdminSupportPage />
      ) : params === "admin/adminabout" ? (
        <AdminAboutPage />
      ) : params === "tour" ? (
        <h1>Tour Guide </h1>
      ) : (
        <DashboardElement />
      )}
    </div>
  );
};

export default AdminUiElements;
