import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { withRouter, useHistory } from "react-router-dom";
import { PublishAllJsonData } from "../../ConstantJson/ConstantData";
import CardView from "../../components/UI/Card/Card";
import Label from "../../components/UI/Label/Label";
import SearchBox from "../../components/UI/SearchBox/SearchBox";
import "./../../assests/scss/allModalPopup.scss";
import FilterForm from "./Filter/FilterForm";
import "./Published.scss";
import { useDispatch } from "react-redux";
import {
  deletePostAction,
  fetchPublishedPostAction,
} from "../../Redux_saga/postPublisherSlice";
import { useAppData } from "../../services/hooks";
import noimagepreview from "../../assests/images/noimagepreview.jpg";
import twittericon from "../../assests/images/twittericon.png";
import linkedinicon from "../../assests/images/linkedinicon.png";
import instagramicon from "../../assests/images/instagram.png";
import fbicon from "../../assests/images/fbicon.png";
import youtubeicon from "../../assests/images/youtubeicon.png";
import PaginationSystem from "../../components/Pagination/PaginationSystem";

const PublishedScreen = () => {
  const { t } = useTranslation();

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const history = useHistory();

  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const dispatch = useDispatch();
  const User = useAppData((r) => r.postPublisherSlice?.user);
  const Token = useAppData((r) => r.postPublisherSlice?.tokens);
  const UserGroups = useAppData((r) => r.postPublisherSlice?.groups);

  const PublishedPosts = useAppData(
    (r) => r.postPublisherSlice?.publishedPosts
  );

  const [facebookPosts, setFacebookPosts] = useState([]);
  const [youtubePosts, setYoutubePosts] = useState([]);
  const [instagramPosts, setInstagramPosts] = useState([]);
  const [linkedInPosts, setLinkedinPosts] = useState([]);
  const [twitterPosts, setTwitterPosts] = useState([]);
  const [userGroupPosts, setUserGroupPosts] = useState([]);

  const [filteredFacebookPosts, setFilteredFacebookPosts] = useState([]);
  const [filteredYoutubePosts, setFilteredYoutubePosts] = useState([]);
  const [filteredInstagramPosts, setFilteredInstagramPosts] = useState([]);
  const [filteredLinkedInPosts, setFilteredLinkedinPosts] = useState([]);
  const [filteredTwitterPosts, setFilteredTwitterPosts] = useState([]);
  const [filteredUserGroupPosts, setFilteredUserGroupPosts] = useState([]);

  const [paginatedFacebookPosts, setPaginatedFacebookPosts] = useState([]);
  const [paginatedYoutubePosts, setPaginatedYoutubePosts] = useState([]);
  const [paginatedInstagramPosts, setPaginatedInstagramPosts] = useState([]);
  const [paginatedLinkedinInPosts, setPaginatedLinkedInPosts] = useState([]);
  const [paginatedTwitterPosts, setPaginatedTwitterPosts] = useState([]);
  const [paginatedUserGroupPosts, setPaginatedUserGroupPosts] = useState([]);

  const [currentPageFacebook, setCurrentPageFacebook] = useState(1);
  const [currentPageTwitter, setCurrentPageTwitter] = useState(1);
  const [currentPageLinkedIn, setCurrentPageLinkedIn] = useState(1);
  const [currentPageInstagram, setCurrentPageInstagram] = useState(1);
  const [currentPageYoutube, setCurrentPageYoutube] = useState(1);
  const [currentPageUserGroup, setCurrentPageUserGroup] = useState(1);

  const itemsPerPage = 10;

  const handlePageChange = (page, tab) => {
    switch (tab) {
      case "Facebook":
        setCurrentPageFacebook(page);
        break;
      case "Twitter":
        setCurrentPageTwitter(page);
        break;
      case "LinkedIn":
        setCurrentPageLinkedIn(page);
        break;
      case "Instagram":
        setCurrentPageInstagram(page);
        break;
      case "Youtube":
        setCurrentPageYoutube(page);
        break;
      case "Group":
        setCurrentPageUserGroup(page);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const fetchPublishedPosts = async (fromdt = null, todt = null) => {
    const params = {
      filter: {
        user: User?._id,
        "postDetails.isDraft": false,
        "postDetails.isScheduled": false,
        "postDetails.isPublished": true,
        "postDetails.isPaused": false,
        isDeleted: false,
        fromdt: fromdt,
        todt: todt,
      },
      token: Token?.access?.token,
    };
    await dispatch(fetchPublishedPostAction(params));
  };

  useEffect(() => {
    fetchPublishedPosts();
  }, []);

  const deletePost = async (post) => {
    const data = {
      payload: {
        _id: post.pPostId,
        "postDetails._id": post._id,
      },
      user: User._id,
      token: Token?.access?.token,
    };

    dispatch(deletePostAction(data));
  };

  useEffect(() => {
    const fetchPostData = async () => {
      if (PublishedPosts.length > 0) {
        setFacebookPosts([]);
        setTwitterPosts([]);
        setLinkedinPosts([]);
        setYoutubePosts([]);
        setInstagramPosts([]);
        setUserGroupPosts([]);

        setFilteredFacebookPosts([]);
        setFilteredInstagramPosts([]);
        setFilteredLinkedinPosts([]);
        setFilteredTwitterPosts([]);
        setFilteredYoutubePosts([]);
        setFilteredUserGroupPosts([]);

        PublishedPosts.forEach((schPosts) => {
          if (schPosts.category === "group") {
            const userGrp = UserGroups.find(
              (grp) => grp._id == schPosts.groupId
            );

            if (userGrp) {
              const imgMain =
                schPosts.postDetails[0].contentType == "photo"
                  ? schPosts.postDetails[0].link
                  : noimagepreview;
              const video =
                schPosts.postDetails[0].contentType == "video"
                  ? schPosts.postDetails[0].link
                  : "";

              const epochTime = +schPosts.postDetails[0].scheduledTime;

              const date = new Date(epochTime);

              const createdOn = new Date(schPosts.createdAt).toLocaleString(
                "en-US",
                {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  timeZone: "Asia/Kolkata",
                }
              );

              const formattedTime = date.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
                day: "numeric",
                month: "short",
                year: "numeric",
                timeZone: "Asia/Kolkata",
              });

              const newPost = {
                image: "",
                headTitle: userGrp.groupTitle,
                subTitle: `Created On ${createdOn} (UTC + 5.30)`,
                iconName: "",
                imageMain: imgMain,
                Title: schPosts.postDetails[0].message,
                Time: ``,
                video,
                onDeleteClick: () =>
                  deletePost({
                    pPostId: schPosts._id,
                    ...schPosts.postDetails,
                  }),
                onEditPost: () =>
                  editPost({
                    ...schPosts,
                  }),
              };

              console.log("new group post", newPost);

              setUserGroupPosts((prevPosts) => [...prevPosts, newPost]);
              setFilteredUserGroupPosts((prevPosts) => [...prevPosts, newPost]);
            }
          } else {
            schPosts.postDetails.forEach((post) => {
              switch (post.mediaType) {
                case "facebook":
                  const fbUser = schPosts.user.fb.find(
                    (fb) => fb.userProfile.id === post.profileId
                  );

                  if (fbUser) {
                    const fbPage = fbUser.pages.find(
                      (page) => page.id === post.postIntoId
                    );

                    const fbGroup = fbUser.groups.find(
                      (group) => group.id === post.postIntoId
                    );

                    const imgMain =
                      post.contentType == "photo" ? post.link : noimagepreview;
                    const video = post.contentType == "video" ? post.link : "";

                    const epochTime = +post.scheduledTime;

                    const date = new Date(epochTime);

                    const createdOn = new Date(
                      schPosts.createdAt
                    ).toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      timeZone: "Asia/Kolkata",
                    });

                    const formattedTime = date.toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      timeZone: "Asia/Kolkata",
                    });

                    if (fbPage) {
                      const newPost = {
                        image: fbUser.userProfile.picture.data.url ?? fbicon,
                        headTitle:
                          post.postingInto === "page"
                            ? fbPage.name
                            : fbGroup.name,
                        subTitle: `Created On ${createdOn} (UTC + 5.30)`,
                        iconName: "",
                        imageMain: imgMain,
                        Title: post.message,
                        Time: "",
                        video,
                        deleteOnly: false,
                        showSendIcons: false,
                        showthreeIcons: false,
                        onDeleteClick: () =>
                          deletePost({
                            pPostId: schPosts._id,
                            ...post,
                          }),
                        onEditPost: () =>
                          editPost({
                            ...schPosts,
                          }),
                      };

                      setFacebookPosts((prevPosts) => [...prevPosts, newPost]);
                      setFilteredFacebookPosts((prevPosts) => [
                        ...prevPosts,
                        newPost,
                      ]);
                    }
                  }
                  break;
                case "youtube":
                  const youtubeUser = schPosts.user.youtube.find(
                    (utube) => utube.id === post.profileId
                  );

                  if (youtubeUser) {
                    const imgMain =
                      post.contentType == "photo" ? post.link : noimagepreview;
                    const video = post.contentType == "video" ? post.link : "";

                    const epochTime = +post.scheduledTime;

                    const date = new Date(epochTime);

                    const createdOn = new Date(
                      schPosts.createdAt
                    ).toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      timeZone: "Asia/Kolkata",
                    });

                    const formattedTime = date.toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      timeZone: "Asia/Kolkata",
                    });

                    const newPost = {
                      image:
                        youtubeUser.snippet.thumbnails.default.url ??
                        youtubeicon,
                      headTitle: `${youtubeUser.snippet.title} `,
                      subTitle: `Created On ${createdOn} (UTC + 5.30)`,
                      iconName: "",
                      imageMain: imgMain,
                      Title: post.message,
                      Time: "",
                      video,
                      deleteOnly: false,
                      showSendIcons: false,
                      showthreeIcons: false,
                      onDeleteClick: () =>
                        deletePost({
                          pPostId: schPosts._id,
                          ...post,
                        }),
                      onEditPost: () =>
                        editPost({
                          ...schPosts,
                        }),
                    };

                    setYoutubePosts((prevPosts) => [...prevPosts, newPost]);
                    setFilteredYoutubePosts((prevPosts) => [
                      ...prevPosts,
                      newPost,
                    ]);
                  }
                  break;
                case "instagram":
                  const instaUser = schPosts.user.instagram.find(
                    (insta) =>
                      insta.profile.instagram_business_account_id ===
                      post.profileId
                  );

                  if (instaUser) {
                    const imgMain =
                      post.contentType == "photo" ? post.link : noimagepreview;
                    const video = post.contentType == "reels" ? post.link : "";

                    const epochTime = +post.scheduledTime;

                    const date = new Date(epochTime);

                    const createdOn = new Date(
                      schPosts.createdAt
                    ).toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      timeZone: "Asia/Kolkata",
                    });

                    const formattedTime = date.toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      timeZone: "Asia/Kolkata",
                    });

                    const newPost = {
                      image:
                        instaUser.profile.picture.data.url ?? instagramicon,
                      headTitle: `${instaUser.profile.instagram_business_account_id} `,
                      subTitle: `Created On ${createdOn} (UTC + 5.30)`,
                      iconName: "",
                      imageMain: imgMain,
                      Title: post.message,
                      Time: "",
                      video,
                      deleteOnly: false,
                      showSendIcons: false,
                      showthreeIcons: false,
                      onDeleteClick: () =>
                        deletePost({
                          pPostId: schPosts._id,
                          ...post,
                        }),
                      onEditPost: () =>
                        editPost({
                          ...schPosts,
                        }),
                    };

                    setInstagramPosts((prevPosts) => [...prevPosts, newPost]);
                    setFilteredInstagramPosts((prevPosts) => [
                      ...prevPosts,
                      newPost,
                    ]);
                  }
                  break;
                case "linkedin":
                  const linkedinuser = schPosts.user.linkedIn.find(
                    (linkedin) => linkedin.id === post.profileId
                  );

                  if (linkedinuser) {
                    const imgMain =
                      post.contentType == "photo" ? post.link : noimagepreview;
                    const video = post.contentType == "video" ? post.link : "";

                    const epochTime = +post.scheduledTime;

                    const date = new Date(epochTime);

                    const createdOn = new Date(
                      schPosts.createdAt
                    ).toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      timeZone: "Asia/Kolkata",
                    });

                    const formattedTime = date.toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      timeZone: "Asia/Kolkata",
                    });

                    const newPost = {
                      image: linkedinicon,
                      headTitle: `${linkedinuser.firstName} ${linkedinuser.lastName} `,
                      subTitle: `Created On ${createdOn} (UTC + 5.30)`,
                      iconName: "",
                      imageMain: imgMain,
                      Title: post.message,
                      Time: "",
                      video,
                      deleteOnly: false,
                      showSendIcons: false,
                      showthreeIcons: false,
                      onDeleteClick: () =>
                        deletePost({
                          pPostId: schPosts._id,
                          ...post,
                        }),
                      onEditPost: () =>
                        editPost({
                          ...schPosts,
                        }),
                    };

                    setLinkedinPosts((prevPosts) => [...prevPosts, newPost]);
                    setFilteredLinkedinPosts((prevPosts) => [
                      ...prevPosts,
                      newPost,
                    ]);
                  }
                  break;
                case "twitter":
                  const twitterUser = schPosts.user.twitter.find(
                    (twitter) => twitter.userId === post.profileId
                  );

                  if (twitterUser) {
                    const imgMain =
                      post.contentType == "photo" ? post.link : noimagepreview;
                    const video = post.contentType == "video" ? post.link : "";

                    const epochTime = +post.scheduledTime;

                    const date = new Date(epochTime);

                    const createdOn = new Date(
                      schPosts.createdAt
                    ).toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      timeZone: "Asia/Kolkata",
                    });

                    const formattedTime = date.toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      timeZone: "Asia/Kolkata",
                    });

                    const newPost = {
                      image: twittericon,
                      headTitle: twitterUser.screenName,
                      subTitle: `Created On ${createdOn} (UTC + 5.30)`,
                      iconName: "",
                      imageMain: imgMain,
                      Title: post.message,
                      Time: "",
                      video,
                      deleteOnly: false,
                      showSendIcons: false,
                      showthreeIcons: false,
                      onDeleteClick: () =>
                        deletePost({
                          pPostId: schPosts._id,
                          ...post,
                        }),
                      onEditPost: () =>
                        editPost({
                          ...schPosts,
                        }),
                    };

                    setTwitterPosts((prevPosts) => [...prevPosts, newPost]);
                    setFilteredTwitterPosts((prevPosts) => [
                      ...prevPosts,
                      newPost,
                    ]);
                  }
                  break;
                default:
                  break;
              }
            });
          }
        });
      } else {
        setFacebookPosts([]);
        setTwitterPosts([]);
        setLinkedinPosts([]);
        setYoutubePosts([]);
        setInstagramPosts([]);
        setUserGroupPosts([]);

        setFilteredFacebookPosts([]);
        setFilteredInstagramPosts([]);
        setFilteredLinkedinPosts([]);
        setFilteredTwitterPosts([]);
        setFilteredYoutubePosts([]);
        setFilteredUserGroupPosts([]);
      }
    };

    fetchPostData();
  }, [PublishedPosts]);

  const handleSearch = async (query) => {
    const filterPostsByQuery = (posts) =>
      posts.filter((post) =>
        post.headTitle.toLowerCase().includes(query.toLowerCase())
      );

    setFilteredFacebookPosts(filterPostsByQuery(facebookPosts));
    setFilteredTwitterPosts(filterPostsByQuery(twitterPosts));
    setFilteredLinkedinPosts(filterPostsByQuery(linkedInPosts));
    setFilteredInstagramPosts(filterPostsByQuery(instagramPosts));
    setFilteredYoutubePosts(filterPostsByQuery(youtubePosts));
    setFilteredUserGroupPosts(filterPostsByQuery(userGroupPosts));
  };

  useEffect(() => {
    const paginatedFBPosts = [
      ...filteredFacebookPosts.slice(
        (currentPageFacebook - 1) * itemsPerPage,
        currentPageFacebook * itemsPerPage
      ),
    ];

    setPaginatedFacebookPosts(paginatedFBPosts);
  }, [filteredFacebookPosts, currentPageFacebook, itemsPerPage]);

  useEffect(() => {
    const paginatedTwtrPosts = [
      ...filteredTwitterPosts.slice(
        (currentPageTwitter - 1) * itemsPerPage,
        currentPageTwitter * itemsPerPage
      ),
    ];

    setPaginatedTwitterPosts(paginatedTwtrPosts);
  }, [filteredTwitterPosts, currentPageTwitter, itemsPerPage]);

  useEffect(() => {
    const paginatedInstaPosts = [
      ...filteredInstagramPosts.slice(
        (currentPageInstagram - 1) * itemsPerPage,
        currentPageInstagram * itemsPerPage
      ),
    ];

    setPaginatedInstagramPosts(paginatedInstaPosts);
  }, [filteredInstagramPosts, currentPageInstagram, itemsPerPage]);

  useEffect(() => {
    const paginatedYtubePosts = [
      ...filteredYoutubePosts.slice(
        (currentPageYoutube - 1) * itemsPerPage,
        currentPageYoutube * itemsPerPage
      ),
    ];

    setPaginatedYoutubePosts(paginatedYtubePosts);
  }, [filteredYoutubePosts, currentPageYoutube, itemsPerPage]);

  useEffect(() => {
    const paginatedlinkdinPosts = [
      ...filteredLinkedInPosts.slice(
        (currentPageLinkedIn - 1) * itemsPerPage,
        currentPageLinkedIn * itemsPerPage
      ),
    ];

    setPaginatedLinkedInPosts(paginatedlinkdinPosts);
  }, [filteredLinkedInPosts, currentPageLinkedIn, itemsPerPage]);

  useEffect(() => {
    const paginatedUserGrpPosts = [
      ...filteredUserGroupPosts.slice(
        (currentPageUserGroup - 1) * itemsPerPage,
        currentPageUserGroup * itemsPerPage
      ),
    ];

    console.log("filteredUserGroupPosts", filteredUserGroupPosts);

    setPaginatedUserGroupPosts(paginatedUserGrpPosts);
  }, [filteredUserGroupPosts, currentPageUserGroup, itemsPerPage]);

  const handleFilter = (startDate, endDate, selectedStatus, selectedGroup) => {
    setShowFilterDropdown(false);

    const originalStartDate = new Date(startDate);

    const startYear = originalStartDate.getFullYear();
    const startMonth = (originalStartDate.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const startDay = originalStartDate.getDate().toString().padStart(2, "0");

    const formattedStateDate = `${startYear}-${startMonth}-${startDay}`;

    const originalEndDate = new Date(endDate);

    const endYear = originalEndDate.getFullYear();
    const endMonth = (originalEndDate.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const endDay = originalEndDate.getDate().toString().padStart(2, "0");

    const formattedEndDate = `${endYear}-${endMonth}-${endDay}`;

    fetchPublishedPosts(formattedStateDate, formattedEndDate);
  };

  const handleClearFilters = () => {
    setShowFilterDropdown(false);
    fetchPublishedPosts();
  };

  const iconConfig = {
    delete: false,
    playPause: false,
    edit: true,
    reverse: false,
    send: false,
  };

  const editPost = async (post) => {
    history.push("/PublisherAll?publish", { ...post });
  };

  return (
    <div className="published_Page">
      <div className="main_Container position-relative">
        <div className="chatboat-section position-absolute">
          <i className="icon-chatboat"></i>
        </div>
        <Scrollbars style={{ height: dimensions.height + "px" }}>
          <div className="schedule_Inner">
            <div className="tabSearch_section">
              <div className="title_Height">
                <Label className="main_Heading" title={t("PUBLISHED")}></Label>
              </div>

              <div className="parent">
                <div className="left">
                  <Tabs defaultActiveKey="Group" id="uncontrolled-tab-example">
                    <Tab eventKey="Group" title="Group">
                      <div className="tabs-wrapper">
                        {paginatedUserGroupPosts.map((e, index) => {
                          return (
                            <div key={index}>
                              <CardView
                                image={e.image}
                                headTitle={e.headTitle}
                                subTitle={e.subTitle}
                                iconName={e.iconName}
                                imageMain={e.imageMain}
                                Title={e.Title}
                                Time={e.Time}
                                tourselector={e.tourselector}
                                postInfo={e.postInfo}
                                onDeleteClick={e.onDeleteClick}
                                iconConfig={iconConfig}
                                onEditPost={e.onEditPost}
                              ></CardView>
                            </div>
                          );
                        })}
                      </div>
                      <PaginationSystem
                        currentPage={currentPageUserGroup}
                        totalPages={Math.ceil(
                          filteredUserGroupPosts.length / itemsPerPage
                        )}
                        onPageChange={(page) => handlePageChange(page, "Group")}
                      />
                    </Tab>
                    <Tab eventKey="Facebook" title="Facebook">
                      <div className="tabs-wrapper">
                        {paginatedFacebookPosts &&
                          paginatedFacebookPosts.map((e, index) => {
                            return (
                              <div key={index}>
                                <CardView
                                  image={e.image}
                                  headTitle={e.headTitle}
                                  subTitle={e.subTitle}
                                  iconName={e.iconName}
                                  imageMain={e.imageMain}
                                  Title={e.Title}
                                  Time={e.Time}
                                  tourselector={e.tourselector}
                                  video={e.video}
                                  postInfo={e.postInfo}
                                  onDeleteClick={e.onDeleteClick}
                                  iconConfig={iconConfig}
                                  onEditPost={e.onEditPost}
                                ></CardView>
                              </div>
                            );
                          })}
                      </div>
                      <PaginationSystem
                        currentPage={currentPageFacebook}
                        totalPages={Math.ceil(
                          filteredFacebookPosts.length / itemsPerPage
                        )}
                        onPageChange={(page) =>
                          handlePageChange(page, "Facebook")
                        }
                      />
                    </Tab>
                    <Tab eventKey="Twitter" title="Twitter">
                      <div className="tabs-wrapper">
                        {paginatedTwitterPosts &&
                          paginatedTwitterPosts.map((e, index) => {
                            return (
                              <div key={index}>
                                <CardView
                                  image={e.image}
                                  headTitle={e.headTitle}
                                  subTitle={e.subTitle}
                                  iconName={e.iconName}
                                  imageMain={e.imageMain}
                                  Title={e.Title}
                                  Time={e.Time}
                                  tourselector={e.tourselector}
                                  video={e.video}
                                  postInfo={e.postInfo}
                                  onDeleteClick={e.onDeleteClick}
                                  iconConfig={iconConfig}
                                  onEditPost={e.onEditPost}
                                ></CardView>
                              </div>
                            );
                          })}
                      </div>
                      <PaginationSystem
                        currentPage={currentPageTwitter}
                        totalPages={Math.ceil(
                          filteredTwitterPosts.length / itemsPerPage
                        )}
                        onPageChange={(page) =>
                          handlePageChange(page, "Twitter")
                        }
                      />
                    </Tab>
                    <Tab eventKey="LinkedIn" title="LinkedIn">
                      <div className="tabs-wrapper">
                        {paginatedLinkedinInPosts &&
                          paginatedLinkedinInPosts.map((e, index) => {
                            return (
                              <div key={index}>
                                <CardView
                                  image={e.image}
                                  headTitle={e.headTitle}
                                  subTitle={e.subTitle}
                                  iconName={e.iconName}
                                  imageMain={e.imageMain}
                                  Title={e.Title}
                                  Time={e.Time}
                                  tourselector={e.tourselector}
                                  video={e.video}
                                  postInfo={e.postInfo}
                                  onDeleteClick={e.onDeleteClick}
                                  iconConfig={iconConfig}
                                  onEditPost={e.onEditPost}
                                ></CardView>
                              </div>
                            );
                          })}
                      </div>
                      <PaginationSystem
                        currentPage={currentPageLinkedIn}
                        totalPages={Math.ceil(
                          filteredLinkedInPosts.length / itemsPerPage
                        )}
                        onPageChange={(page) =>
                          handlePageChange(page, "LinkedIn")
                        }
                      />
                    </Tab>
                    <Tab eventKey="Instagram" title="Instagram">
                      <div className="tabs-wrapper">
                        {paginatedInstagramPosts &&
                          paginatedInstagramPosts.map((e, index) => {
                            return (
                              <div key={index}>
                                <CardView
                                  image={e.image}
                                  headTitle={e.headTitle}
                                  subTitle={e.subTitle}
                                  iconName={e.iconName}
                                  imageMain={e.imageMain}
                                  Title={e.Title}
                                  Time={e.Time}
                                  tourselector={e.tourselector}
                                  video={e.video}
                                  postInfo={e.postInfo}
                                  onDeleteClick={e.onDeleteClick}
                                  iconConfig={iconConfig}
                                  onEditPost={e.onEditPost}
                                ></CardView>
                              </div>
                            );
                          })}
                      </div>
                      <PaginationSystem
                        currentPage={currentPageInstagram}
                        totalPages={Math.ceil(
                          filteredInstagramPosts.length / itemsPerPage
                        )}
                        onPageChange={(page) =>
                          handlePageChange(page, "Instagram")
                        }
                      />
                    </Tab>
                    <Tab eventKey="Youtube" title="Youtube">
                      <div className="tabs-wrapper">
                        {paginatedYoutubePosts &&
                          paginatedYoutubePosts.map((e, index) => {
                            return (
                              <div key={index}>
                                <CardView
                                  image={e.image}
                                  headTitle={e.headTitle}
                                  subTitle={e.subTitle}
                                  iconName={e.iconName}
                                  imageMain={e.imageMain}
                                  Title={e.Title}
                                  Time={e.Time}
                                  tourselector={e.tourselector}
                                  video={e.video}
                                  postInfo={e.postInfo}
                                  onDeleteClick={e.onDeleteClick}
                                  iconConfig={iconConfig}
                                  onEditPost={e.onEditPost}
                                ></CardView>
                              </div>
                            );
                          })}
                      </div>
                      <PaginationSystem
                        currentPage={currentPageYoutube}
                        totalPages={Math.ceil(
                          filteredYoutubePosts.length / itemsPerPage
                        )}
                        onPageChange={(page) =>
                          handlePageChange(page, "Youtube")
                        }
                      />
                    </Tab>
                    <Tab eventKey="TikTok" title="TikTok">
                      <div className="tabs-wrapper">
                        {PublishAllJsonData.map((e, index) => {
                          return (
                            <div key={index}>
                              <CardView
                                image={e.image}
                                headTitle={e.headTitle}
                                subTitle={e.subTitle}
                                iconName={e.iconName}
                                imageMain={e.imageMain}
                                Title={e.Title}
                                Time={e.Time}
                                tourselector={e.tourselector}
                                postInfo={e.postInfo}
                                onDeleteClick={e.onDeleteClick}
                                iconConfig={iconConfig}
                                onEditPost={e.onEditPost}
                              ></CardView>
                            </div>
                          );
                        })}
                      </div>
                    </Tab>
                  </Tabs>
                </div>
                <div className="right">
                  <div className="search-filter-main">
                    <div className="search">
                      <SearchBox
                        placeholder={t("SCHEDULE_SEARCH")}
                        onSearch={handleSearch}
                      />
                    </div>
                    <div className="filter">
                      <div className="filterBtn">
                        <button
                          className="filterIcon"
                          onClick={() =>
                            setShowFilterDropdown(!showFilterDropdown)
                          }
                        >
                          <i className="icon-filter">
                            {" "}
                            <span>{t("SCHEDULE_FILTER")}</span>
                          </i>
                        </button>
                      </div>
                      {showFilterDropdown && (
                        <>
                          <div
                            className="overLay"
                            onClick={() => setShowFilterDropdown(false)}
                          ></div>
                          <FilterForm
                            title="Filter"
                            onClick={() => setShowFilterDropdown(false)}
                            onFilter={handleFilter}
                            onClear={handleClearFilters}
                          ></FilterForm>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default withRouter(PublishedScreen);
