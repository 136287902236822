import React, { useState } from "react";
import "./SearchBox.scss";

const SearchBox = (props) => {
  const { placeholder, onSearch } = props;
  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (onSearch) {
      onSearch(query); 
    }
  };

  // const handleClearClick = () => {
  //   setSearchQuery("");
  //   if (onSearch) {
  //     onSearch(""); 
  //   }
  // };

  return (
    <div className="search_Box p-0">
      <input
        className="search_Txt"
        placeholder={placeholder}
        value={searchQuery}
        onChange={handleInputChange}
      />
      <i className="icon-search"></i>

      {/* {searchQuery && (
        <i
          className="icon-clear"
          onClick={handleClearClick}
          style={{ cursor: "pointer" }}
        ></i>
      )} */}
    </div>
  );
};

export default SearchBox;
