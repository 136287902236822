import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import AdvantageImg from "../../assests/images/advantage.png";
import {
  default as AdvantageImg1,
  default as AdvantageImg3,
} from "../../assests/images/advantage1.png";
import TopadvantageImg from "../../assests/images/advantage3.png";
import TopadvantageImg1 from "../../assests/images/advantage4.png";
import marketBanner from "../../assests/images/market.png";
import TestimonialsImg from "../../assests/images/slider1.png";
import TestimonialsImg1 from "../../assests/images/slider2.png";
import TestimonialsImg2 from "../../assests/images/slider3.png";
import VideoImg from "../../assests/images/video.png";
import Footer from "../../components/Footer/Footer";
import HeaderLogin from "../../components/HeaderLogin/HeaderLogin";
import HookForm from "../../components/HookForm/HookForm";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import ImageSlider from "../../components/UI/ImageSlider/ImageSlider";
import Label from "../../components/UI/Label/Label";
import TextField from "../../components/UI/TextField/TextField";
import Constant from "../../util/constant";
import Message from "../../util/message";

const contactForm = {
  name: {
    name: "name",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.EMAILEMPTY,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ERRORMESSAGE.EMAILINVALID,
      },
    },
  },
  email: {
    name: "email",
    validate: {
      required: {
        value: true,
      },
    },
  },
};

const onFormSubmit = () => {};
function MarketingVideoScreen() {
  const { t } = useTranslation();
  const [accordion, setActiveAccordion] = useState(-1);
  const [form, setLoginForm] = React.useState();
  const [condition, setCondition] = useState(false);
  const [allImages, setAllImages] = useState([]);
  function toggleAccordion(index) {
    setActiveAccordion(index);
  }
  // console.log(form, condition);
  const listData = [
    {
      brief: `${t("MARKETING_FAQ_FIRST_QUESTION")}`,
      answer: `${t("MARKETING_FAQ_FIRST_ANSWER")}`,
    },
    {
      brief: `${t("MARKETING_FAQ_SECOND_QUESTION")}`,
      answer: `${t("MARKETING_FAQ_SECOND_ANSWER")}`,
    },
    {
      brief: `${t("MARKETING_FAQ_THIRD_QUESTION")}`,
      answer: `${t("MARKETING_FAQ_THIRD_ANSWER")}`,
    },
  ];
  const removeImage = (index) => {
    setAllImages([
      ...allImages.slice(0, index),
      ...allImages.slice(index + 1, allImages.length),
    ]);
    if (allImages.length === 1) {
      setCondition(false);
    }
  };
  const imageHandler = (e) => {
    if (e.target.files.length === 0) return;
    setCondition(true);
    setAllImages([...allImages, e.target.files]);
  };
  return (
    <div className="market_Page">
      <HeaderLogin />
      <Scrollbars className="scroll_Section">
        <div className="main_Banner">
          <img
            src={marketBanner}
            alt="image"
            style={{ maxWidth: "100%", height: "auto" }}
            // fluid="true"
            className="banner_Img"
          />
        </div>
        <div className="center_Section">
          <Label
            className="banner_Title"
            title={t("MARKETING_MAIN_HEAD_ONE")}
          ></Label>
          <Label
            className="center_Title"
            title="Video content can grab a viewer’s attention or hook your target audience. Learn how to make high-quality video productions that turn watchers into customers."
          ></Label>
          <CustomButton title={t("MARKETING_READ_MORE_BUTTON")}></CustomButton>
        </div>
        <div className="overlap_Section">
          <div className="content_Top">
            <div className="d-flex">
              <div className="left_Section">
                <Label
                  className="header_Title pb-0 text-uppercase"
                  title="Grow your businesss,"
                ></Label>
                <Label
                  className="color_heading text-uppercase pb-0"
                  title="start marketing"
                ></Label>
              </div>
              <div className="right_Section">
                <Label
                  className="inner_Title"
                  title="Video content can grab a viewer’s attention or hook your target audience. Learn how to make high-quality video productions that turn watchers into customers."
                ></Label>
              </div>
            </div>
            <div className="bottom-Section d-flex justify-content-around">
              <div className="image_Content d-flex">
                <img
                  src={AdvantageImg}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="banner_Img"
                />
                <div className="d-flex flex-column content">
                  <Label
                    className="text-uppercase header-text"
                    title="Advantages"
                  ></Label>
                  <Label
                    className="inner pb-0"
                    title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the indust"
                  ></Label>
                </div>
              </div>
              <div className="image_Content d-flex">
                <img
                  src={AdvantageImg1}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="banner_Img"
                />
                <div className="d-flex flex-column content">
                  <Label
                    className="text-uppercase header-text"
                    title="Advantages"
                  ></Label>
                  <Label
                    className="inner pb-0"
                    title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the indust"
                  ></Label>
                </div>
              </div>
              <div className="image_Content d-flex">
                <img
                  src={AdvantageImg3}
                  alt="image"
                  style={{ maxWidth: "100%", height: "auto" }}
                  // fluid="true"
                  className="banner_Img"
                />
                <div className="d-flex flex-column content">
                  <Label
                    className="text-uppercase header-text"
                    title="Advantages"
                  ></Label>
                  <Label
                    className="inner pb-0"
                    title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the indust"
                  ></Label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="top_Feature">
          <div className="header_Section">
            <Label
              className="header d-flex justify-content-center"
              title={t("MARKETING_TOP_ADVANTAGE")}
            ></Label>
            <Label
              className="inner_title"
              title={t("MARKETING_ADVANTAGE_SHORT")}
            ></Label>
          </div>
          <div className="img_Content">
            <div className="img_Section">
              <img
                src={TopadvantageImg}
                alt="image"
                style={{ maxWidth: "100%", height: "auto" }}
                // fluid="true"
                className="time_Img"
              />
            </div>
            <div className="content_Section">
              <Label className="top_Title" title="Lorem Ipsum"></Label>
              <Label
                className="inner_Title"
                title={t("MARKETING_ADVANTAGE_SUMMARY")}
              ></Label>
              <Label
                className="inner_Title"
                title={t("MARKETING_ADVANTAGE_SUMM_ARR")}
              ></Label>
              {/* <Label
                className="inner_Title"
                title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
              ></Label> */}
            </div>
          </div>
          <div className="img_Content">
            <div className="content_Section">
              <Label className="top_Title" title="Lorem Ipsum"></Label>
              <Label
                className="inner_Title"
                title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
              ></Label>
              <Label
                className="inner_Title"
                title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
              ></Label>
              <Label
                className="inner_Title"
                title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
              ></Label>
            </div>
            <div className="img_Section">
              <img
                src={TopadvantageImg1}
                alt="image"
                style={{ maxWidth: "100%", height: "auto" }}
                // fluid="true"
                className="time_Img"
              />
            </div>
          </div>
        </div>
        <div className="video_Section d-flex flex-row">
          <div className="img_Content d-flex ">
            <div className="content_Section">
              <Label
                className="top_Title"
                title={t("MARKETING_VIDEO_TOUR")}
              ></Label>
              <Label
                className="inner_Title"
                title={t("MARKETING_VIDEO_TOUR_SUMMARY")}
              ></Label>
              <CustomButton title={t("MARKETING_BUTTON")}></CustomButton>
            </div>
            <div className="img_Sections">
              <img
                src={VideoImg}
                alt="image"
                style={{ maxWidth: "100%", height: "auto" }}
                // fluid="true"
                className="time_Img"
              />
            </div>
          </div>
        </div>
        <div className="imgSlider_Section">
          <Label className="main_Title" title={t("MARKETING_READ")}></Label>
          <ImageSlider
            SliderImg={TestimonialsImg}
            SliderImg1={TestimonialsImg1}
            SliderImg2={TestimonialsImg2}
            headingTxt="Lorem Ipsum is text "
            innerTxt="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
          ></ImageSlider>
        </div>
        <div className="accordion_Section">
          <div className="inner_Section">
            <Label
              className="top_Title justify-content-center d-flex"
              title={t("MARKETING_FAQ")}
            ></Label>
            <div className="accordion__Block">
              <div className="accordion__faq">
                {listData.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => toggleAccordion(index)}
                    className="accordion_Item"
                  >
                    <div className="accordion__Faq-heading">
                      <div className="accordion__Icon">
                        {accordion === index ? (
                          <>
                            <span className="vertical">
                              <i className="icon-downarrow"></i>
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="vertical">
                              <i className="icon-downarrow"></i>
                            </span>
                          </>
                        )}
                      </div>
                      <div className={accordion === index ? "active" : ""}>
                        {item.brief}
                      </div>
                    </div>

                    <div className="accordion__Answer">
                      <div
                        className={accordion === index ? "active" : "inactive"}
                      >
                        {item.answer}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="input_Section">
          <Label
            className="header d-flex justify-content-center"
            title={t("MARKETING_REQUEST")}
          ></Label>

          <div className="content_Textbox">
            <div className="text_Box">
              <HookForm
                defaultForm={{}}
                init={(form) => setLoginForm(form)}
                onSubmit={onFormSubmit}
              >
                {(formMethod) => {
                  return (
                    <div className="form1">
                      <div className="d-flex">
                        <TextField
                          formMethod={formMethod}
                          rules={contactForm.name.validate}
                          name={contactForm.name.name}
                          errors={formMethod?.errors}
                          placeholder="Your name"
                          iconClass="icon-user"
                          type="text"
                        />
                        <TextField
                          formMethod={formMethod}
                          rules={contactForm.email.validate}
                          name={contactForm.email.name}
                          errors={formMethod?.errors}
                          autoFocus={false}
                          type="text"
                          placeholder="Email"
                          iconClass="icon-mail"
                        />
                      </div>
                      <div className="d-flex">
                        <TextField
                          formMethod={formMethod}
                          rules={contactForm.name.validate}
                          name={contactForm.name.name}
                          errors={formMethod?.errors}
                          placeholder="Website url"
                          iconClass=""
                          type="text"
                        />
                        <TextField
                          formMethod={formMethod}
                          rules={contactForm.name.validate}
                          name={contactForm.name.name}
                          errors={formMethod?.errors}
                          placeholder="Video creating purpose "
                          iconClass=""
                          type="text"
                        />
                      </div>
                      <TextField
                        formMethod={formMethod}
                        rules={contactForm.name.validate}
                        name={contactForm.name.name}
                        errors={formMethod?.errors}
                        placeholder="Share your throughts"
                        type="text"
                        textarea="textarea"
                      />
                      <div className="uploadButtonImage">
                        <div className="x1">
                          <label className="uploadFile">
                            <span className="filename">Upload File</span>
                            <input
                              id="file-input"
                              type="file"
                              multiple
                              onChange={imageHandler}
                              accept="image/*"
                              hidden
                            />
                          </label>
                        </div>

                        <div className="x2">
                          {allImages.map((element, index) => {
                            return (
                              <div key={index} className="box">
                                <span className="text-truncate">
                                  {element[0].name}
                                </span>

                                <i
                                  className="icon-close"
                                  variant="white"
                                  onClick={() => removeImage(index)}
                                ></i>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <CustomButton
                        title={t("MARKETING_SUBMIT_BUTTON")}
                        // onClick={() => setShowThankYou(!showThankYou)}
                      ></CustomButton>
                    </div>
                  );
                }}
              </HookForm>
            </div>
          </div>
        </div>

        <Footer className="footerheight"></Footer>
      </Scrollbars>
    </div>
  );
}

export default withRouter(MarketingVideoScreen);
