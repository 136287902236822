import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideToastAction } from "../../Redux_saga/globalSlice";
import { useAppData } from "../../services/hooks";
import "./Toasts.scss";
const Toasts = (props) => {
  const dispatch = useDispatch();
  const toastValue = useAppData((r) => r.globalSlice.toast);

  useEffect(() => {
    setTimeout(() => {
      dispatch(hideToastAction());
    }, 5000);
  }, [toastValue]);

  return (
    <>
      {props.isVisible && (
        <div className={`ToastBlock  ${props.type}`}>
          <div className="innerToast">
            <div className="iconWrapper">
              {props.type === "error" ? (
                <i className="icon-close" />
              ) : (
                <i className="icon-tick" />
              )}
            </div>
            <div>
              <p className="head">{props.header}</p>
              <p className="message"> {props.message}</p>
            </div>
            <div>
              <i
                className="icon-close cursor"
                onClick={() => dispatch(hideToastAction())}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Toasts;
