import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Blog7 from "../../assests/images/Blog7.png";
import BlogMiddle6 from "../../assests/images/BlogMiddle6.png";
import SliderImg from "../../assests/images/slider1.png";
import BlogFeature from "../../components/BlogFeature/BlogFeature";
import BlogModal from "../../components/BlogFeature/BlogModal";
import Footer from "../../components/Footer/Footer";
import HeaderLogin from "../../components/HeaderLogin/HeaderLogin";
import ImageSlider from "../../components/UI/ImageSlider/ImageSlider";
import Label from "../../components/UI/Label/Label";
import "./BlogSeven.scss";

function BlogSeven() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const clickShow = () => {
    setShow(!show);
  };

  return (
    <div className="blogseven_Page">
      {show && <BlogModal closeModal={clickShow} />}

      <HeaderLogin />
      <Scrollbars className="blogScroll">
        <div className="main_Banner">
          <img
            src={Blog7}
            alt="image"
            style={{ maxWidth: "100%", height: "auto" }}
            // fluid="true"
            className="banner_Img"
          />
        </div>
        <Label className="banner_Title" title={t("BLOG_SEVEN")}></Label>
        <div className="center_Section">
          <Label className="center_Title" title={t("BLOG_SEVEN_HEAD")}></Label>
          <div className="center_text">
            <p className="inner_Text">{t("BLOG_SEVEN_PARA_ONE")}</p>
          </div>
        </div>
        <div className="middle_section">
          <div className="middle_image">
            <img
              src={BlogMiddle6}
              alt="image"
              style={{ maxWidth: "100%", height: "auto" }}
              // fluid="true"
              className="middle_Img"
            />
          </div>
          <div className="middle_text">
            <p className="inner_Text">{t("BLOG_SEVEN_PARA_TWO")}</p>
            <p className="inner_Text">{t("BLOG_SEVEN_PARA_TWO")}</p>
          </div>
        </div>
        <div className="blog-feature-seven">
          <BlogFeature clickToShowModal={clickShow} />
        </div>
        <div className="image_Section">
          <Label title={t("BLOG_SEVEN_MORE")}></Label>
          <ImageSlider
            SliderImg={SliderImg}
            SliderImg1={SliderImg}
            SliderImg2={SliderImg}
            headingTxt={t("BLOG_SEVEN_MORE_HEAD")}
            innerTxt={t("BLOG_SEVEN_BRIEF")}
          ></ImageSlider>
        </div>
        <Footer className="footerheight"></Footer>
      </Scrollbars>
    </div>
  );
}

export default withRouter(BlogSeven);
