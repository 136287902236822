import React, { useCallback, useMemo, useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "./BigCalendar.scss";
import {
  fetchDraftPostAction,
  fetchFailedPostAction,
  fetchPublishedPostAction,
  fetchScheduledPostAction,
} from "../../Redux_saga/postPublisherSlice";
import { useHistory } from "react-router-dom";
import { useAppData } from "../../services/hooks";

import { useDispatch } from "react-redux";
const myevents = [
  {
    id: 0,
    title: "Meeting",
    allDay: true,
    start: new Date(2024, 1, 17, 0, 0, 0),
    end: new Date(2024, 1, 17, 0, 0, 0),
  },
];

const BigCalendar = ({ socialMedia, onEventClick, selectedItem }) => {
  const [myEvents, setMyEvents] = useState(myevents);
  const localizer = momentLocalizer(moment);
  const DnDCalendar = withDragAndDrop(Calendar);

  const history = useHistory();
  const dispatch = useDispatch();
  const User = useAppData((r) => r.postPublisherSlice?.user);
  const Token = useAppData((r) => r.postPublisherSlice?.tokens);

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const { allDay } = event;
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true;
      }

      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end, allDay }];
      });
    },
    [setMyEvents]
  );

  const defaultDate = useMemo(() => new Date(), []);

  const fetchScheduledPosts = async (fromdt = null, todt = null) => {
    const params = {
      filter: {
        user: User?._id,
        "postDetails.isDraft": false,
        "postDetails.isScheduled": true,
        "postDetails.isPublished": false,
        // "postDetails.isPaused": false,
        isDeleted: false,
        fromdt: fromdt,
        todt: todt,
      },
      token: Token?.access?.token,
    };
    dispatch(fetchScheduledPostAction(params));
  };

  const fetchDraftPosts = async (fromdt = null, todt = null) => {
    const params = {
      filter: {
        user: User?._id,
        "postDetails.isDraft": true,
        "postDetails.isScheduled": false,
        "postDetails.isPublished": false,
        "postDetails.isPaused": false,
        isDeleted: false,
        fromdt: fromdt,
        todt: todt,
      },
      token: Token?.access?.token,
    };
    dispatch(fetchDraftPostAction(params));
  };

  const fetchFailedPosts = async (fromdt = null, todt = null) => {
    const params = {
      filter: {
        user: User?._id,
        "postDetails.isDraft": false,
        "postDetails.isScheduled": false,
        "postDetails.isPublished": false,
        "postDetails.isPaused": false,
        isDeleted: false,
        fromdt: fromdt,
        todt: todt,
      },
      token: Token?.access?.token,
    };
    dispatch(fetchFailedPostAction(params));
  };

  const fetchPublishedPosts = async (fromdt = null, todt = null) => {
    const params = {
      filter: {
        user: User?._id,
        "postDetails.isDraft": false,
        "postDetails.isScheduled": false,
        "postDetails.isPublished": true,
        "postDetails.isPaused": false,
        isDeleted: false,
        fromdt: fromdt,
        todt: todt,
      },
      token: Token?.access?.token,
    };
    await dispatch(fetchPublishedPostAction(params));
  };

  useEffect(() => {
    if (selectedItem == "Scheduled") {
      fetchScheduledPosts();
    }
    if (selectedItem == "Draft") {
      fetchDraftPosts();
    }
    if (selectedItem == "Failed") {
      fetchFailedPosts();
    }
    if (selectedItem == "Published") {
      fetchPublishedPosts();
    }
  }, [selectedItem]);

  const ScheduledPosts = useAppData(
    (r) => r.postPublisherSlice?.scheduledPosts
  );

  const PublishedPosts = useAppData(
    (r) => r.postPublisherSlice?.publishedPosts
  );

  const DraftPosts = useAppData((r) => r.postPublisherSlice?.draftPosts);
  const FailedPosts = useAppData((r) => r.postPublisherSlice?.failedPosts);

  const [facebookPosts, setFacebookPosts] = useState([]);
  const [youtubePosts, setYoutubePosts] = useState([]);
  const [instagramPosts, setInstagramPosts] = useState([]);
  const [linkedInPosts, setLinkedinPosts] = useState([]);
  const [twitterPosts, setTwitterPosts] = useState([]);
  const [userGroupPosts, setUserGroupPosts] = useState([]);

  useEffect(() => {
    const fetchPostData = async () => {
      if (selectedItem == "Scheduled" && ScheduledPosts.length > 0) {
        console.log("inside scheduled");
        setFacebookPosts([]);
        setTwitterPosts([]);
        setLinkedinPosts([]);
        setYoutubePosts([]);
        setInstagramPosts([]);
        setUserGroupPosts([]);

        const groupedEvents = {};

        ScheduledPosts.map(async (schPosts) => {
          if (schPosts.category === "group" && socialMedia == "Group") {
            const scheduledTime = new Date(
              +schPosts.postDetails[0].scheduledTime
            );

            const formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

            if (!groupedEvents[formattedDate]) {
              groupedEvents[formattedDate] = {
                date: formattedDate,
                count: 1,
                events: [
                  {
                    id: schPosts._id,
                    title: `Post ${schPosts._id}`,
                    start: scheduledTime,
                    end: scheduledTime,
                  },
                ],
              };
            } else {
              groupedEvents[formattedDate].count += 1;
              groupedEvents[formattedDate].events.push({
                id: schPosts._id,
                title: `Post ${schPosts._id}`,
                start: scheduledTime,
                end: scheduledTime,
              });
            }
          }

          if (socialMedia != "Group") {
            switch (socialMedia.toLowerCase()) {
              case "facebook":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(+post.scheduledTime);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              case "youtube":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(+post.scheduledTime);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              case "instagram":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(+post.scheduledTime);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              case "linkedin":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(+post.scheduledTime);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              case "twitter":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(+post.scheduledTime);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              default:
                break;
            }
          }
        });

        const summarizedEvents = Object.values(groupedEvents);
        switch (socialMedia) {
          case "Group":
            setUserGroupPosts(summarizedEvents);
            break;
          case "Facebook":
            setFacebookPosts(summarizedEvents);
            break;
          case "Twitter":
            setTwitterPosts(summarizedEvents);
            break;
          case "LinkedIn":
            setLinkedinPosts(summarizedEvents);
            break;
          case "Youtube":
            setYoutubePosts(summarizedEvents);
            break;
          case "Instagram":
            setInstagramPosts(summarizedEvents);
            break;
          default:
            setUserGroupPosts(summarizedEvents);

            break;
        }
      } else if (selectedItem == "Draft" && DraftPosts.length > 0) {
        setFacebookPosts([]);
        setTwitterPosts([]);
        setLinkedinPosts([]);
        setYoutubePosts([]);
        setInstagramPosts([]);
        setUserGroupPosts([]);

        const groupedEvents = {};

        DraftPosts.map(async (schPosts) => {
          if (schPosts.category === "group" && socialMedia == "Group") {
            const scheduledTime = new Date(schPosts.createdAt);
            console.log("draft group chPosts.createdAt", scheduledTime);

            const formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

            if (!groupedEvents[formattedDate]) {
              groupedEvents[formattedDate] = {
                date: formattedDate,
                count: 1,
                events: [
                  {
                    id: schPosts._id,
                    title: `Post ${schPosts._id}`,
                    start: scheduledTime,
                    end: scheduledTime,
                  },
                ],
              };
            } else {
              groupedEvents[formattedDate].count += 1;
              groupedEvents[formattedDate].events.push({
                id: schPosts._id,
                title: `Post ${schPosts._id}`,
                start: scheduledTime,
                end: scheduledTime,
              });
            }
          }

          if (socialMedia != "Group") {
            switch (socialMedia.toLowerCase()) {
              case "facebook":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(schPosts.createdAt);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              case "youtube":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(schPosts.createdAt);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              case "instagram":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(schPosts.createdAt);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              case "linkedin":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(schPosts.createdAt);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              case "twitter":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(schPosts.createdAt);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              default:
                break;
            }
          }
        });

        const summarizedEvents = Object.values(groupedEvents);
        switch (socialMedia) {
          case "Group":
            setUserGroupPosts(summarizedEvents);
            break;
          case "Facebook":
            setFacebookPosts(summarizedEvents);
            break;
          case "Twitter":
            setTwitterPosts(summarizedEvents);
            break;
          case "LinkedIn":
            setLinkedinPosts(summarizedEvents);
            break;
          case "Youtube":
            setYoutubePosts(summarizedEvents);
            break;
          case "Instagram":
            setInstagramPosts(summarizedEvents);
            break;
          default:
            setUserGroupPosts(summarizedEvents);

            break;
        }
      } else if (selectedItem == "Failed" && FailedPosts.length > 0) {
        setFacebookPosts([]);
        setTwitterPosts([]);
        setLinkedinPosts([]);
        setYoutubePosts([]);
        setInstagramPosts([]);
        setUserGroupPosts([]);

        const groupedEvents = {};

        FailedPosts.map(async (schPosts) => {
          if (schPosts.category === "group" && socialMedia == "Group") {
            const scheduledTime = new Date(schPosts.createdAt);
            console.log("draft group chPosts.createdAt", scheduledTime);

            const formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

            if (!groupedEvents[formattedDate]) {
              groupedEvents[formattedDate] = {
                date: formattedDate,
                count: 1,
                events: [
                  {
                    id: schPosts._id,
                    title: `Post ${schPosts._id}`,
                    start: scheduledTime,
                    end: scheduledTime,
                  },
                ],
              };
            } else {
              groupedEvents[formattedDate].count += 1;
              groupedEvents[formattedDate].events.push({
                id: schPosts._id,
                title: `Post ${schPosts._id}`,
                start: scheduledTime,
                end: scheduledTime,
              });
            }
          }

          if (socialMedia != "Group") {
            switch (socialMedia.toLowerCase()) {
              case "facebook":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(schPosts.createdAt);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              case "youtube":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(schPosts.createdAt);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              case "instagram":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(schPosts.createdAt);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              case "linkedin":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(schPosts.createdAt);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              case "twitter":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(schPosts.createdAt);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              default:
                break;
            }
          }
        });

        const summarizedEvents = Object.values(groupedEvents);
        switch (socialMedia) {
          case "Group":
            setUserGroupPosts(summarizedEvents);
            break;
          case "Facebook":
            setFacebookPosts(summarizedEvents);
            break;
          case "Twitter":
            setTwitterPosts(summarizedEvents);
            break;
          case "LinkedIn":
            setLinkedinPosts(summarizedEvents);
            break;
          case "Youtube":
            setYoutubePosts(summarizedEvents);
            break;
          case "Instagram":
            setInstagramPosts(summarizedEvents);
            break;
          default:
            setUserGroupPosts(summarizedEvents);

            break;
        }
      } else if (selectedItem == "Published" && PublishedPosts.length > 0) {
        setFacebookPosts([]);
        setTwitterPosts([]);
        setLinkedinPosts([]);
        setYoutubePosts([]);
        setInstagramPosts([]);
        setUserGroupPosts([]);

        const groupedEvents = {};

        PublishedPosts.map(async (schPosts) => {
          if (schPosts.category === "group" && socialMedia == "Group") {
            const scheduledTime = new Date(schPosts.createdAt);
            console.log("draft group chPosts.createdAt", scheduledTime);

            const formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

            if (!groupedEvents[formattedDate]) {
              groupedEvents[formattedDate] = {
                date: formattedDate,
                count: 1,
                events: [
                  {
                    id: schPosts._id,
                    title: `Post ${schPosts._id}`,
                    start: scheduledTime,
                    end: scheduledTime,
                  },
                ],
              };
            } else {
              groupedEvents[formattedDate].count += 1;
              groupedEvents[formattedDate].events.push({
                id: schPosts._id,
                title: `Post ${schPosts._id}`,
                start: scheduledTime,
                end: scheduledTime,
              });
            }
          }

          if (socialMedia != "Group") {
            switch (socialMedia.toLowerCase()) {
              case "facebook":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(schPosts.createdAt);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              case "youtube":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(schPosts.createdAt);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              case "instagram":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(schPosts.createdAt);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              case "linkedin":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(schPosts.createdAt);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              case "twitter":
                schPosts.postDetails.forEach((post) => {
                  let scheduledTime, formattedDate;
                  if (socialMedia.toLowerCase() == post.mediaType) {
                    scheduledTime = new Date(schPosts.createdAt);

                    formattedDate = moment(scheduledTime).format("YYYY-MM-DD");

                    if (!groupedEvents[formattedDate]) {
                      groupedEvents[formattedDate] = {
                        date: formattedDate,
                        count: 1,
                        events: [
                          {
                            id: schPosts._id,
                            title: `Post ${schPosts._id}`,
                            start: scheduledTime,
                            end: scheduledTime,
                          },
                        ],
                      };
                    } else {
                      groupedEvents[formattedDate].count += 1;
                      groupedEvents[formattedDate].events.push({
                        id: schPosts._id,
                        title: `Post ${schPosts._id}`,
                        start: scheduledTime,
                        end: scheduledTime,
                      });
                    }
                  }
                });
                break;
              default:
                break;
            }
          }
        });

        const summarizedEvents = Object.values(groupedEvents);
        switch (socialMedia) {
          case "Group":
            setUserGroupPosts(summarizedEvents);
            break;
          case "Facebook":
            setFacebookPosts(summarizedEvents);
            break;
          case "Twitter":
            setTwitterPosts(summarizedEvents);
            break;
          case "LinkedIn":
            setLinkedinPosts(summarizedEvents);
            break;
          case "Youtube":
            setYoutubePosts(summarizedEvents);
            break;
          case "Instagram":
            setInstagramPosts(summarizedEvents);
            break;
          default:
            setUserGroupPosts(summarizedEvents);

            break;
        }
      } else {
        setFacebookPosts([]);
        setTwitterPosts([]);
        setLinkedinPosts([]);
        setYoutubePosts([]);
        setInstagramPosts([]);
        setUserGroupPosts([]);
      }
    };

    fetchPostData();
  }, [
    ScheduledPosts,
    socialMedia,
    PublishedPosts,
    DraftPosts,
    FailedPosts,
    selectedItem,
  ]);

  useEffect(() => {
    if (socialMedia) {
      let newEventss = [];
      switch (socialMedia) {
        case "Group":
          newEventss = [];
          if (userGroupPosts.length > 0) {
            newEventss = userGroupPosts.map((event, index) => ({
              id: index + 1,
              title: `${event.count} Posts`,
              start: event.events[0].start,
              end: event.events[0].end,
            }));
          }
          setMyEvents(newEventss);
          break;
        case "Facebook":
          newEventss = [];
          if (facebookPosts.length > 0) {
            newEventss = facebookPosts.map((event, index) => ({
              id: index + 1,
              title: `${event.count} Posts`,
              start: event.events[0].start,
              end: event.events[0].end,
            }));
          }

          setMyEvents(newEventss);
        case "Twitter":
          newEventss = [];
          if (twitterPosts.length > 0) {
            newEventss = twitterPosts.map((event, index) => ({
              id: index + 1,
              title: `${event.count} Posts`,
              start: event.events[0].start,
              end: event.events[0].end,
            }));
          }
          setMyEvents(newEventss);
          break;
        case "Instagram":
          newEventss = [];
          if (instagramPosts.length > 0) {
            newEventss = instagramPosts.map((event, index) => ({
              id: index + 1,
              title: `${event.count} Posts`,
              start: event.events[0].start,
              end: event.events[0].end,
            }));
          }
          setMyEvents(newEventss);
          break;
        case "LinkedIn":
          newEventss = [];
          if (linkedInPosts.length > 0) {
            newEventss = linkedInPosts.map((event, index) => ({
              id: index + 1,
              title: `${event.count} Posts`,
              start: event.events[0].start,
              end: event.events[0].end,
            }));
          }
          setMyEvents(newEventss);
          break;
        case "Youtube":
          newEventss = [];
          if (youtubePosts.length > 0) {
            newEventss = youtubePosts.map((event, index) => ({
              id: index + 1,
              title: `${event.count} Posts`,
              start: event.events[0].start,
              end: event.events[0].end,
            }));
          }
          setMyEvents(newEventss);
          break;
        default:
          newEventss = [];
          if (userGroupPosts.length > 0) {
            newEventss = userGroupPosts.map((event, index) => ({
              id: index + 1,
              title: `${event.count} Posts`,
              start: event.events[0].start,
              end: event.events[0].end,
            }));
          }
          setMyEvents(newEventss);
          break;
      }
    }
  }, [
    socialMedia,
    userGroupPosts,
    facebookPosts,
    twitterPosts,
    instagramPosts,
    linkedInPosts,
    youtubePosts,
  ]);

  return (
    <div className="wrapper" style={{ height: 800 }}>
      <DnDCalendar
        defaultDate={defaultDate}
        defaultView="month"
        events={myEvents}
        localizer={localizer}
        onEventDrop={moveEvent}
        popup
        resizable
        onDragStart={(e) => console.log(e, "e")}
        onSelectEvent={() => {
          onEventClick(true);
        }}
        showMultiDayTimes={true}
        views={["month", "week", "day"]}
      />
    </div>
  );
};

export default BigCalendar;
