import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { withRouter, useHistory } from "react-router-dom";
import CreateGroupImg from "../../assests/images/CreateGroupImg.png";
import CreateGroupProfileImg from "../../assests/images/small.png";
import DropdownGroup from "../../components/DropdownGroup/DropdownGroup";
import Header from "../../components/Header/Header";
import HookForm from "../../components/HookForm/HookForm";
import CheckBox from "../../components/UI/CheckBox/CheckBox";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import Label from "../../components/UI/Label/Label";
import SearchBox from "../../components/UI/SearchBox/SearchBox";
import TextField from "../../components/UI/TextField/TextField";
import Constant from "../../util/constant";
import Message from "../../util/message";
import "./CreateGroup.scss";
import CustomTabs from "./CustomTabs";
import { useDispatch } from "react-redux";
import twittericon from "../../assests/images/twittericon.png";
import linkedinicon from "../../assests/images/linkedinicon.png";
import instagramicon from "../../assests/images/instagram.png";
import fbicon from "../../assests/images/fbicon.png";
import youtubeicon from "../../assests/images/youtubeicon.png";
import { useAppData } from "../../services/hooks";
import { useForm } from "react-hook-form";
import { createGroupAction } from "../../Redux_saga/postPublisherSlice";
import AWS from "aws-sdk";
import { showToastAction } from "../../Redux_saga/globalSlice";

const groupForm = {
  groupTitle: {
    name: "groupTitle",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.TITLEEMPTY,
      },
    },
  },
  description: {
    name: "description",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.DESCRIPTION,
      },
    },
  },
};

const CreateGroup = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const { t } = useTranslation();

  const [showGroupDropdown, setShowGroupDropdown] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showBTopTitle, setShowTopTitle] = useState(false);
  const [newImage, setNewImage] = useState("");

  const Token = useAppData((r) => r.postPublisherSlice?.tokens);
  const User = useAppData((r) => r.postPublisherSlice?.user);

  const [facebook, setFacebook] = useState();
  const [instagram, setInstagram] = useState();
  const [twitter, setTwitter] = useState();
  const [linkedin, setLinkedin] = useState();
  const [youtube, setYoutube] = useState();

  const [selectedItems, setSelectedItems] = useState({});

  const [form, setCreateForm] = React.useState();

  const onFormSubmit = async (form) => {
    if (Object.keys(selectedItems).length === 0) {
      console.error("Please select atleat one social media to proceed.");
      dispatch(
        showToastAction({
          message: "Please select atleat one social media to proceed.",
          type: "error",
        })
      );
      return;
    }

    const { groupTitle, description } = form;

    const selectedAccounts = await Promise.all(
      Object.entries(selectedItems)
        .filter(([itemId, isSelected]) => isSelected)
        .map(async ([itemId, isSelected]) => {
          if (isSelected) {
            const selectedItem = findSelectedItem(itemId);

            if (selectedItem !== undefined) {
              return {
                id: itemId,
                type: selectedItem.type,
                socialMedia: selectedItem.socialMedia,
              };
            }
          }
        })
    );

    const payload = {
      groupTitle: groupTitle,
      groupDescription: description,
      profilePicture: newImage,
      user: User?._id,
      linkedAccounts: selectedAccounts,
    };

    dispatch(createGroupAction({ payload, token: Token?.access?.token }));
    setSelectedItems({});
    // setCreateForm({ groupTitle: "", description: "" });
    setTimeout(() => {
      history.push("./group");
    }, 1000);
  };

  // const clear = (form) => {
  //   form.reset();
  //   setSelectedItems({});
  // };

  useEffect(() => {
    if (User) {
      setFacebook(User.fb[0]);
      setInstagram(User.instagram);
      setTwitter(User.twitter);
      setLinkedin(User.linkedIn);
      setYoutube(User.youtube);
    }
  }, [User]);

  const [facebookData, setFacebookData] = useState();
  const [instagramData, setInstagramData] = useState();
  const [twitterData, setTwitterData] = useState();
  const [linkedinData, setLinkedinData] = useState();
  const [youtubeData, setYoutubeData] = useState();

  const [filteredFacebookData, setFilteredFacebookData] = useState();
  const [filteredInstagramData, setFilteredInstagramData] = useState();
  const [filteredTwitterData, setFilteredTwitterData] = useState();
  const [filteredLinkedinData, setFilteredLinkedinData] = useState();
  const [filteredYoutubeData, setFilteredYoutubeData] = useState();

  useEffect(() => {
    if (facebook) {
      setFacebookData(facebook);
      setFilteredFacebookData({
        ...facebook,
        combined: [...facebook.pages, ...facebook.groups],
      });
    }
    if (instagram) {
      setInstagramData(instagram);
      setFilteredInstagramData(instagram);
    }
    if (linkedin) {
      setLinkedinData(linkedin);
      setFilteredLinkedinData(linkedin);
    }

    if (twitter) {
      setTwitterData(twitter);
      setFilteredTwitterData(twitter);
    }

    if (youtube) {
      setYoutubeData(youtube);
      setFilteredYoutubeData(youtube);
    }
  }, [facebook, twitter, linkedin, instagram, youtube]);

  useEffect(() => {
    setShowButton(document.getElementsByClassName("button")[0].offsetHeight);
    setShowTopTitle(
      document.getElementsByClassName("top_title")[0].offsetHeight
    );
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const imageHandler = async (e) => {
    if (e.target.files.length === 0) return;

    const file = e.target.files[0];
    const S3_BUCKET = "vinodevs3new";
    const REGION = "ap-south-1";
    const FOLDER_NAME = "profile";

    AWS.config.update({
      accessKeyId: "AKIAWTSP3CRY47CP6MMC",
      secretAccessKey: "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",
      region: REGION,
    });

    const s3 = new AWS.S3();

    try {
      const params = {
        Bucket: S3_BUCKET,
        Key: `${FOLDER_NAME}/${file.name.replace(/ /g, "_")}`,
        Body: file,
        ContentType: file.type,
      };

      await s3
        .upload(params)
        .promise()
        .catch((err) => console.log("error on upload", err));

      const s3Url = `https://s3.${AWS.config.region}.amazonaws.com/${params.Bucket}/${params.Key}`;

      setNewImage(s3Url);
    } catch (error) {
      console.error("Error handling image:", error);
      // Handle error if needed
    }
  };

  const bindSocialMediaTabs = () => {
    const socialMedia = [
      "Facebook",
      "Twitter",
      "LinkedIn",
      "Instagram",
      "Youtube",
    ];

    const mediaTabs = socialMedia.map((media) => {
      return {
        key: media,
        title: media,
        className: "inner_link1",
        enabled: true,
        content: bindTabItems(media),
      };
    });

    return mediaTabs;
  };

  const handleSearch = (query) => {
    //facebook
    const pages = facebook.pages.filter((page) =>
      page.name.toLowerCase().includes(query.toLowerCase())
    );
    const groups = facebook.groups.filter((group) =>
      group.name.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredFacebookData({
      ...facebookData,
      pages: pages,
      groups: groups,
    });

    const twtr = twitter.filter((profile) =>
      profile.screenName.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredTwitterData(twtr);

    const linkIn = linkedin.filter((profile) =>
      profile.firstName.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredLinkedinData(linkIn);

    const insta = instagram.filter((business) => {
      return business.profile.instagram_business_account_id.includes(query);
    });
    setFilteredInstagramData(insta);

    const ytube = youtube.filter((channel) => {
      return channel.snippet.title.toLowerCase().includes(query.toLowerCase());
    });

    setFilteredYoutubeData(ytube);
  };

  const bindTabItems = (socialMedia) => {
    return (
      <>
        <div className="middle_dropdown">
          <div className="dropdown_Part">
            {/* <div> // onClick={() => setShowGroupDropdown(!showGroupDropdown)} */}
            <div>
              <SearchBox
                placeholder={t("CREATE_GROUP_SEARCH")}
                onSearch={handleSearch}
              ></SearchBox>
            </div>

            {/* {showGroupDropdown && (
              <>
                <div onClick={() => setShowGroupDropdown(false)}></div>
                <DropdownGroup title="Filter"></DropdownGroup>
              </>
            )} */}
          </div>
          {/* <div className="middle_select_all">
            <Label className="select" title={t("CREATE_GROUP_SELECT")}></Label>
            <div className="checkboxall">
              <CheckBox id="all" labelTitle=""></CheckBox>
            </div>
          </div> */}
        </div>
        <div className="link">
          {<CustomTabs tabs={bindSubItems(socialMedia)} />}
        </div>
      </>
    );
  };

  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [itemId]: !prevSelectedItems[itemId],
    }));
  };

  const bindSubItems = (socialMedia) => {
    switch (socialMedia) {
      case "Facebook":
        return [
          {
            key: "Groups",
            title: "Groups",
            className: "inner_link1",
            enabled: true,
            content: (
              <div className="middle_main_box">
                <div
                  className="main_box1"
                  style={{
                    height:
                      dimensions.height -
                      showButton -
                      showBTopTitle -
                      180 +
                      "px",
                  }}
                >
                  <div className="inner_box1">
                    {filteredFacebookData &&
                      filteredFacebookData.groups.length > 0 &&
                      filteredFacebookData.groups.map((group, index) => (
                        <div className="content1" key={group._id}>
                          <div className="content_inner1">
                            <CheckBox
                              className="checkboxMain"
                              id={group._id}
                              labelTitle=""
                              checked={selectedItems[group._id]}
                              onCheckedChange={() =>
                                handleCheckboxChange(group._id)
                              }
                            ></CheckBox>
                            <img
                              src={
                                group?.userProfile?.picture?.data?.url ?? fbicon
                              }
                              alt="image"
                              style={{ maxWidth: "100%", height: "auto" }}
                              // fluid="true"
                              className="profile_Img"
                            />
                            <Label
                              className="profile_name"
                              title={group.name}
                            ></Label>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ),
          },
          {
            key: "Pages",
            title: "Pages",
            className: "inner_link1",
            enabled: true,
            content: (
              <div className="middle_main_box">
                <div
                  className="main_box1"
                  style={{
                    height:
                      dimensions.height -
                      showButton -
                      showBTopTitle -
                      180 +
                      "px",
                  }}
                >
                  <div className="inner_box1">
                    {filteredFacebookData &&
                      filteredFacebookData.pages.length > 0 &&
                      filteredFacebookData.pages.map((page, index) => (
                        <div className="content1" key={page._id}>
                          <div className="content_inner1">
                            <CheckBox
                              className="checkboxMain"
                              id={page._id}
                              labelTitle=""
                              checked={selectedItems[page._id]}
                              onCheckedChange={() =>
                                handleCheckboxChange(page._id)
                              }
                            ></CheckBox>
                            <img
                              src={
                                page?.userProfile?.picture?.data?.url ?? fbicon
                              }
                              alt="image"
                              style={{ maxWidth: "100%", height: "auto" }}
                              // fluid="true"
                              className="profile_Img"
                            />
                            <Label
                              className="profile_name"
                              title={page.name}
                            ></Label>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ),
          },
        ];
      case "Twitter":
        return [
          {
            key: "Profiles",
            title: "Profiles",
            className: "inner_link1",
            enabled: true,
            content: (
              <div className="middle_main_box">
                <div
                  className="main_box1"
                  style={{
                    height:
                      dimensions.height -
                      showButton -
                      showBTopTitle -
                      180 +
                      "px",
                  }}
                >
                  <div className="inner_box1">
                    {filteredTwitterData &&
                      filteredTwitterData.length > 0 &&
                      filteredTwitterData.map((profile, index) => (
                        <div className="content1" key={profile._id}>
                          <div className="content_inner1">
                            <CheckBox
                              className="checkboxMain"
                              id={profile._id}
                              labelTitle=""
                              checked={selectedItems[profile._id]}
                              onCheckedChange={() =>
                                handleCheckboxChange(profile._id)
                              }
                            ></CheckBox>
                            <img
                              src={twittericon}
                              alt="image"
                              style={{ maxWidth: "100%", height: "auto" }}
                              // fluid="true"
                              className="profile_Img"
                            />
                            <Label
                              className="profile_name"
                              title={profile.screenName}
                            ></Label>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ),
          },
        ];
      case "Instagram":
        return [
          {
            key: "Business Accounts",
            title: "Business Accounts",
            className: "inner_link1",
            enabled: true,
            content: (
              <div className="middle_main_box">
                <div
                  className="main_box1"
                  style={{
                    height:
                      dimensions.height -
                      showButton -
                      showBTopTitle -
                      180 +
                      "px",
                  }}
                >
                  <div className="inner_box1">
                    {filteredInstagramData &&
                      filteredInstagramData.length > 0 &&
                      filteredInstagramData.map((profile, index) => (
                        <div className="content1" key={profile._id}>
                          <div className="content_inner1">
                            <CheckBox
                              className="checkboxMain"
                              id={profile._id}
                              labelTitle=""
                              checked={selectedItems[profile._id]}
                              onCheckedChange={() =>
                                handleCheckboxChange(profile._id)
                              }
                            ></CheckBox>
                            <img
                              src={
                                profile.profile.picture.data.url ??
                                instagramicon
                              }
                              alt="image"
                              style={{ maxWidth: "100%", height: "auto" }}
                              // fluid="true"
                              className="profile_Img"
                            />
                            <Label
                              className="profile_name"
                              title={
                                profile.profile.instagram_business_account_id
                              }
                            ></Label>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ),
          },
        ];
      case "LinkedIn":
        return [
          {
            key: "Profiles",
            title: "Profiles",
            className: "inner_link1",
            enabled: true,
            content: (
              <div className="middle_main_box">
                <div
                  className="main_box1"
                  style={{
                    height:
                      dimensions.height -
                      showButton -
                      showBTopTitle -
                      180 +
                      "px",
                  }}
                >
                  <div className="inner_box1">
                    {filteredLinkedinData &&
                      filteredLinkedinData.length > 0 &&
                      filteredLinkedinData.map((profile, index) => (
                        <div className="content1" key={profile._id}>
                          <div className="content_inner1">
                            <CheckBox
                              className="checkboxMain"
                              id={profile._id}
                              labelTitle=""
                              checked={selectedItems[profile._id]}
                              onCheckedChange={() =>
                                handleCheckboxChange(profile._id)
                              }
                            ></CheckBox>
                            <img
                              src={linkedinicon}
                              alt="image"
                              style={{ maxWidth: "100%", height: "auto" }}
                              // fluid="true"
                              className="profile_Img"
                            />
                            <Label
                              className="profile_name"
                              title={profile.firstName + " " + profile.lastName}
                            ></Label>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ),
          },
        ];
      case "Youtube":
        return [
          {
            key: "Channels",
            title: "Channels",
            className: "inner_link1",
            enabled: true,
            content: (
              <div className="middle_main_box">
                <div
                  className="main_box1"
                  style={{
                    height:
                      dimensions.height -
                      showButton -
                      showBTopTitle -
                      180 +
                      "px",
                  }}
                >
                  <div className="inner_box1">
                    {filteredYoutubeData &&
                      filteredYoutubeData.length > 0 &&
                      filteredYoutubeData.map((channel, index) => (
                        <div className="content1" key={channel._id}>
                          <div className="content_inner1">
                            <CheckBox
                              className="checkboxMain"
                              id={channel._id}
                              labelTitle=""
                              checked={selectedItems[channel._id]}
                              onCheckedChange={() =>
                                handleCheckboxChange(channel._id)
                              }
                            ></CheckBox>
                            <img
                              src={channel.snippet.thumbnails.default.url}
                              alt="image"
                              style={{ maxWidth: "100%", height: "auto" }}
                              // fluid="true"
                              className="profile_Img"
                            />
                            <Label
                              className="profile_name"
                              title={channel.snippet.title}
                            ></Label>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ),
          },
        ];
      default:
        return [];
    }
  };

  const findSelectedItem = (itemId) => {
    let filteredItem;

    if (!filteredItem) {
      filteredItem = filteredFacebookData.pages.find(
        (page) => page._id === itemId
      );
      if (filteredItem) {
        return {
          ...filteredItem,
          type: "Page",
          socialMedia: "Facebook",
          uniqueId: filteredItem.id,
        };
      }
    }

    if (!filteredItem) {
      filteredItem = filteredFacebookData.groups.find(
        (group) => group._id === itemId
      );
      if (filteredItem) {
        return {
          ...filteredItem,
          type: "Group",
          socialMedia: "Facebook",
          uniqueId: filteredItem.id,
        };
      }
    }

    if (!filteredItem) {
      filteredItem = filteredInstagramData.find(
        (profile) => profile._id === itemId
      );
      if (filteredItem) {
        return {
          ...filteredItem,
          type: "Business",
          socialMedia: "Instagram",
          uniqueId: filteredItem.profile.instagram_business_account_id,
        };
      }
    }

    if (!filteredItem) {
      filteredItem = filteredLinkedinData.find(
        (profile) => profile._id === itemId
      );
      if (filteredItem) {
        return {
          ...filteredItem,
          type: "Profile",
          socialMedia: "LinkedIn",
          uniqueId: filteredItem.id,
        };
      }
    }

    if (!filteredItem) {
      filteredItem = filteredTwitterData.find(
        (profile) => profile._id === itemId
      );
      if (filteredItem) {
        return {
          ...filteredItem,
          type: "Profile",
          socialMedia: "Twitter",
          uniqueId: filteredItem.userId,
        };
      }
    }

    if (!filteredItem) {
      filteredItem = filteredYoutubeData.find(
        (channel) => channel._id === itemId
      );
      if (filteredItem) {
        return {
          ...filteredItem,
          type: "Channel",
          socialMedia: "Youtube",
          uniqueId: filteredItem.id,
        };
      }
    }

    return null;
  };

  const cancelClick = () => {
    history.push("./group");
  };

  return (
    <div className="CreateGroup_Page">
      <Header></Header>
      <div className="main_Container">
        <Scrollbars className="CreateGroupScroll">
          <div className="top_title">
            <Label
              className="titleText1"
              title={t("CREATE_GROUP_HEAD")}
            ></Label>
            <Label
              className="titleText2"
              title={t("CREATE_GROUP_EASILY")}
            ></Label>
            <div className="border"></div>
          </div>
          <div className="middle_section">
            <div className="middle_section_left">
              <div className="middle_image">
                <label htmlFor="file-input">
                  <div className="inner_Div">
                    <i className="icon-edit1 Edit"></i>

                    <img
                      src={newImage === "" ? CreateGroupImg : newImage}
                      alt="image"
                      style={{ height: "200px", width: "200px" }}
                      // fluid="true"
                      className="middle_Img"
                    />
                  </div>
                </label>
              </div>
              <input
                id="file-input"
                type="file"
                multiple
                onChange={imageHandler}
                accept="image/*"
                hidden
              />
              <HookForm
                defaultForm={{
                  groupTitle: "",
                  description: "",
                }}
                init={(form) => setCreateForm(form)}
                onSubmit={onFormSubmit}
              >
                {(formMethod) => {
                  return (
                    <div className="form1">
                      <TextField
                        formMethod={formMethod}
                        rules={groupForm.groupTitle.validate}
                        name={groupForm.groupTitle.name}
                        errors={formMethod?.formState.errors}
                        placeholder={t("GROUP_CREATE_INPUT_TITLE")}
                        type="text"
                      />
                      <TextField
                        formMethod={formMethod}
                        rules={groupForm.description.validate}
                        name={groupForm.description.name}
                        errors={formMethod?.formState.errors}
                        placeholder={t("GROUP_CREATE_INPUT_DESCRIPTION")}
                        type="text"
                        textarea="textarea"
                      />
                      <div className="button">
                        <div className="btn1">
                          <CustomButton
                            title="Cancel"
                            type="reset"
                            onClick={() => {
                              cancelClick();
                            }}
                          ></CustomButton>
                        </div>
                        <div className="btn2">
                          <CustomButton title="Create group"></CustomButton>
                        </div>
                      </div>
                    </div>
                  );
                }}
              </HookForm>
            </div>
            <div className="middle_section_right">
              <div className="link">
                <CustomTabs tabs={bindSocialMediaTabs()} />
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
      <div className="chatboat-section position-absolute">
        <i className="icon-chatboat"></i>
      </div>
    </div>
  );
};
export default withRouter(CreateGroup);
