import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import ReactHighcharts from "react-highcharts";
import { withRouter } from "react-router-dom";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import CustomDropdown from "../../components/UI/CustomDropdown/CustomDropdown";
import Label from "../../components/UI/Label/Label";
import FilterForm from "../Publish/Filter/FilterForm";
import "./AdminDashboardBlog.scss";

var chartConfigPost = {
  colors: ["#5696FA", "#F45B0F", "#16056B", "#3F3F47"],
  chart: {
    type: "column",
    backgroundColor: "#FFFFFF",
  },
  title: {
    text: '<span style="font-size: 18px; color:#000000;font-weight:600">Post Stats</span>',
    align: "left",
    verticalAlign: "top",
  },

  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: false,
        distance: -50,
        style: {
          fontWeight: "bold",
          color: "white",
        },
      },
      startAngle: -90,
      endAngle: -90,
      center: ["50%", "60%"],
      size: "80%",
      showInLegend: true,
    },
  },
  series: [
    {
      type: "pie",
      name: "Share",
      innerSize: "75%",
      data: [
        ["Success", 33.3],
        ["Pending", 33.3],
        ["Draft", 13.33],
        ["Failed", 33.33],
      ],
    },
  ],
  legend: {
    align: "right",
    verticalAlign: "bottom",
  },
  credits: {
    enabled: false,
  },
};

const dropdownItemMonth = [
  {
    id: "1",
    value: "January",
  },
  {
    id: "2",
    value: "February",
  },
  {
    id: "3",
    value: "March",
  },

  {
    id: "4",
    value: "April",
  },
  {
    id: "5",
    value: "May",
  },
  {
    id: "6",
    value: "June",
  },
  {
    id: "7",
    value: "July",
  },
  {
    id: "8",
    value: "August",
  },
  {
    id: "9",
    value: "September",
  },
  {
    id: "10",
    value: "October",
  },
  {
    id: "11",
    value: "November",
  },
  {
    id: "12",
    value: "December",
  },
];
const dropdownItemYear = [
  {
    id: "1",
    value: "2000",
  },
  {
    id: "2",
    value: "2001",
  },
  {
    id: "3",
    value: "2002",
  },

  {
    id: "4",
    value: "2003",
  },
  {
    id: "5",
    value: "2004",
  },
  {
    id: "6",
    value: "2005",
  },
];
// const dropdownItemAll = [
//   {
//     id: "1",
//     value: "All",
//   },
//   {
//     id: "2",
//     value: "Month",
//   },
//   {
//     id: "3",
//     value: "Year",
//   },
//   {
//     id: "3",
//     value: "Year",
//   },
// ];
function AdminDashboardBlog() {
  const [selectItem, setSelectedData] = useState();
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  return (
    <div className="admin_DashboardBlog">
      <div className="no-design-section justify-content-center align-items-center vh-100">
        <Label
          title="Mobile design not supported"
          className="no-design"
        ></Label>
      </div>
      <AdminHeader />
      <div className="main_Container">
        <Scrollbars className="admin_DashboardBlog_Scroll">
          <div className="dashboard_Section">
            <Label className="page_Title" title="John Peter"></Label>
            <Label className="id_Title" title="UserId: 00001"></Label>
            <div className="main_ChartSection">
              <div className="chart_Part">
                <div className="chart_Section d-flex m-0 ">
                  <div className="w25 position-relative mr-2">
                    <div className="position-absolute main_Dropdown post_Dropdown">
                      <div className="dropdown_section d-flex">
                        <CustomDropdown
                          dropDownItems={dropdownItemMonth}
                          placeholder="All"
                          selectedValue={selectItem}
                          onSelect={(selected) => setSelectedData(selected)}
                        ></CustomDropdown>
                        <CustomDropdown
                          dropDownItems={dropdownItemMonth}
                          placeholder="Month"
                          selectedValue={selectItem}
                          onSelect={(selected) => setSelectedData(selected)}
                        ></CustomDropdown>
                        <CustomDropdown
                          dropDownItems={dropdownItemYear}
                          placeholder="Year"
                          selectedValue={selectItem}
                          onSelect={(selected) => setSelectedData(selected)}
                        ></CustomDropdown>
                      </div>
                    </div>
                    <div className="box_Shadow">
                      <ReactHighcharts
                        config={chartConfigPost}
                      ></ReactHighcharts>
                    </div>
                  </div>
                  <div className="w25 position-relative mr-2">
                    <div className="position-absolute main_Dropdown post_Dropdown">
                      <div className="dropdown_section d-flex">
                        <CustomDropdown
                          dropDownItems={dropdownItemMonth}
                          placeholder="All"
                          selectedValue={selectItem}
                          onSelect={(selected) => setSelectedData(selected)}
                        ></CustomDropdown>
                        <CustomDropdown
                          dropDownItems={dropdownItemMonth}
                          placeholder="Month"
                          selectedValue={selectItem}
                          onSelect={(selected) => setSelectedData(selected)}
                        ></CustomDropdown>
                        <CustomDropdown
                          dropDownItems={dropdownItemYear}
                          placeholder="Year"
                          selectedValue={selectItem}
                          onSelect={(selected) => setSelectedData(selected)}
                        ></CustomDropdown>
                      </div>
                    </div>
                    <div className="box_Shadow">
                      <ReactHighcharts
                        config={chartConfigPost}
                      ></ReactHighcharts>
                    </div>
                  </div>
                  <div className="w25 position-relative">
                    <div className="position-absolute main_Dropdown post_Dropdown">
                      <div className="dropdown_section d-flex">
                        <CustomDropdown
                          dropDownItems={dropdownItemMonth}
                          placeholder="All"
                          selectedValue={selectItem}
                          onSelect={(selected) => setSelectedData(selected)}
                        ></CustomDropdown>
                        <CustomDropdown
                          dropDownItems={dropdownItemMonth}
                          placeholder="Month"
                          selectedValue={selectItem}
                          onSelect={(selected) => setSelectedData(selected)}
                        ></CustomDropdown>
                        <CustomDropdown
                          dropDownItems={dropdownItemYear}
                          placeholder="Year"
                          selectedValue={selectItem}
                          onSelect={(selected) => setSelectedData(selected)}
                        ></CustomDropdown>
                      </div>
                    </div>
                    <div className="box_Shadow">
                      <ReactHighcharts
                        config={chartConfigPost}
                      ></ReactHighcharts>
                    </div>
                  </div>
                </div>
                <div className="table_Sections">
                  <div className="header_Filter d-flex justify-content-between">
                    <Label
                      className="table_Title"
                      title="Billings History"
                    ></Label>
                    <div className="icon_Filter">
                      <div className="filterBtn">
                        <button
                          className="filterIcon"
                          onClick={() =>
                            setShowFilterDropdown(!showFilterDropdown)
                          }
                        >
                          <i className="icon-filter">
                            {" "}
                            <span>Filter</span>
                          </i>
                        </button>
                      </div>
                      {showFilterDropdown && (
                        <>
                          <div
                            className="overLay"
                            onClick={() => setShowFilterDropdown(false)}
                          ></div>
                          <FilterForm
                            title="Filter"
                            onClick={() => setShowFilterDropdown(false)}
                          ></FilterForm>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="query-Section d-flex flex-column pt-0">
                    <ul className="header d-flex p-0 m-0">
                      <li>No</li>
                      <li className="pr-1">Date</li>
                      <li className="pr-1">Subscription type</li>
                      <li className="pr-1">Invoice id</li>
                      <li className="pr-1">Payment method</li>
                      <li className="pr-1">Price</li>
                    </ul>

                    <ul className="dataList d-flex p-0 m-0">
                      <li>00011</li>
                      <li className="pr-1">01/01/2021 </li>
                      <li className="pr-1">Professional plan (1 year)</li>
                      <li className="pr-1">XT123SD2232878BNM</li>
                      <li className="pr-1">Debit Card</li>
                      <li className="pr-1">$250 </li>
                    </ul>
                    <ul className="dataList d-flex p-0 m-0">
                      <li>00011</li>
                      <li className="pr-1">01/01/2021 </li>
                      <li className="pr-1">Professional plan (1 year)</li>
                      <li className="pr-1">XT123SD2232878BNM</li>
                      <li className="pr-1">Debit Card</li>
                      <li className="pr-1">$250 </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
}

export default withRouter(AdminDashboardBlog);
