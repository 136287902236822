export const LoginSteps = [
  {
    selector: '[tourselector="login-form"]',
    content:
      "Please Enter Your Credentials To access the Post Publisher account.",
  },
];

export const SignUpSteps = [
  {
    selector: '[tourselector="signup-form"]',
    content: "SignUp Form",
  },
];

export const forgotSteps = [
  {
    selector: '[tourselector="forgot-form"]',
    content: "Forgot Form",
  },
];

export const verifySteps = [
  {
    selector: '[tourselector="verify-form"]',
    content: "Verify Form",
  },
];

export const resetSteps = [
  {
    selector: '[tourselector="reset-form"]',
    content: "Reset Form",
  },
];

export const dashboardSteps = [
  {
    selector: '[tourselector="dashboard-btn"]',
    content:
      "Let us guide you can easily manage your profiles, schedule posts, track analytics, and more.",
  },
];

export const socialProfileSteps = [
  {
    selector: '[tourselector="facebook"]',
    content: "Connect Your Facebook Accounts Groups and Pages",
  },
  {
    selector: '[tourselector="linkedin"]',
    content: "Connect your LinkedIn Profile and Company page",
  },
  {
    selector: '[tourselector="twitter"]',
    content: "Connect Your Twitter Profile",
  },
  {
    selector: '[tourselector="instagram"]',
    content: "Connect Your Instagram profile",
  },
  {
    selector: '[tourselector="youtube"]',
    content: "Connect Your Youtube Channel",
  },
  {
    selector: '[tourselector="tiktok"]',
    content: "Connect Your TikTok Account",
  },
];

export const socialProfileListSteps = [
  {
    selector: '[tourselector="add-more-accounts"]',
    content: "you can add multiple accounts",
  },
];

export const publishSteps = [
  {
    selector: '[tourselector="tabs"]',
    content:
      "Select any Social media accounts to Publish, Schedule, Draft search and filter Your Post ",
  },

  {
    selector: '[tourselector="create-post-btn"]',
    content: "Create post and preview your post before uploading",
  },
];

export const previewTabSteps = [
  {
    selector: '[tourselector="preview-tabs"]',
    content: "Preview Your Post before uploading ",
  },
  {
    selector: '[tourselectorIcons="click-to-add-img"]',
    content: "Click to add Emoji, Image, Location, Tag & Gif ",
  },
  {
    selector: '[tourselectorschedulebtn="click-to-schedule-post"]',
    content: "You can schedule your post from here",
  },
  {
    selector: '[tourselectorpublishbtn="click-to-publish-post"]',
    content: "You can publish your post from here",
  },
];

export const scheduleSteps = [
  {
    selector: '[tourselector="schedule-card"]',
    content: "Scheduled post you can edit or delete your scheduled posts",
  },
];

export const groupSteps = [
  {
    selector: '[tourselector="create-grp-btn"]',
    content: "Click here to create your custom Group.",
  },
];

export const calendarSteps = [
  {
    selector: '[tourselector="change-tab"]',
    content:
      "Click here change the tab and see your listing and schdule your posts",
  },
];

export const calendarListSteps = [
  {
    selector: '[tourselector="calendar-input"]',
    content: "Click here to select the date",
  },
];

export const statisticsSteps = [
  {
    selector: '[tourselector="export-btn"]',
    content: "Click here to download your stats chart",
  },
  {
    selector: '[tourselector="overview-graph"]',
    content: "Overview statistics of your post",
  },
  {
    selector: '[tourselector="age-group"]',
    content: "See age group stats of your post.",
  },
  {
    selector: '[tourselector="gender-graph"]',
    content: "See graph on the basis of Gender",
  },
  {
    selector: '[tourselector="demography-graph"]',
    content: "See graph on the basis of Country",
  },
];
export const settingSteps = [
  {
    selector: '[tourselector="setting-tabs"]',
    content: "Change tab to change your details according to your need",
  },
  {
    selector: '[tourselector="subscribed-btn"]',
    content: "Your subscription ending date is shown here",
  },
];

export const settingBillingSteps = [
  {
    selector: '[tourselector="upgrade-btn"]',
    content: "Upgrade your subscription plan",
  },
  {
    selector: '[tourselector="manage-card-btn"]',
    content: "You can manage multiple cards from here",
  },
];

export const settingQuerySteps = [
  {
    selector: '[tourselector="create-query-btn"]',
    content: "Create Your Query",
  },
];

export const adminLoginSteps = [
  {
    selector: '[tourselector="admin-login"]',
    content:
      "Please Enter Your Credentials To access Admin panel of Post Publisher account.",
  },
];

export const adminDashBoardSteps = [
  {
    selector: '[tourselector="top-section"]',
    content: "You can see the Overall view in these cards",
  },
  {
    selector: '[tourselector="profit-analysis"]',
    content:
      "You can see Overall profit graph and you can also filter by month and year",
  },
  {
    selector: '[tourselector="post-stats"]',
    content:
      "Post Stats: Filter All Social Media Accounts by selecting the each dropdown",
  },
  {
    selector: '[tourselector="age-group-stats"]',
    content: "See Age group stats of users who all are using the application",
  },
  {
    selector: '[tourselector="gender-stats"]',
    content: "You can see Gender stats of the users",
  },
  {
    selector: '[tourselector="demography"]',
    content: "You can see country wise users of the application",
  },
];

export const adminLandingPageSteps = [
  {
    selector: '[tourselector="input-img-preview"]',
    content:
      "Add Title, Paragraph & Image and see instant preview in Right Section",
  },
  {
    selector: '[tourselector="top-btn"]',
    content:
      "You Can either Save Changes or Publish Now To show data to Users LandingPage",
  },
];

export const adminBlogSteps = [
  {
    selector: '[tourselector="add-blog-btn"]',
    content: "Click here to add Blogs.",
  },
  {
    selector: '[tourselector="manage-blog"]',
    content: "You can edit, delete, publish your blog from here",
  },
];

export const adminUserProfileSteps = [
  {
    selector: '[tourselector="admin-user-filter"]',
    content: "Click to filter the data according to your needs",
  },
  {
    selector: '[tourselector="admin-user-list"]',
    content:
      "Here are the list of users, Click on List to navigate to user profile",
  },
];

export const adminSubscriptionSteps = [
  {
    selector: '[tourselector="admin-subscription-data"]',
    content: "Ipnut fields to update the user subscription page",
  },
  {
    selector: '[tourselector="admin-subscription-btn"]',
    content: "Click to Publish Now or Publish Later",
  },
];

export const adminSubscribersSteps = [
  {
    selector: '[tourselector="admin-subscribers-list"]',
    content: "Here are the list of subscribed users",
  },
];

export const adminMarketingSteps = [
  {
    selector: '[tourselector="admin-marketing-list"]',
    content: "Here are the list of request of the users",
  },
];

export const adminTermSteps = [
  {
    selector: '[tourselector="admin-term-use"]',
    content: "Update your term of use from below given input fields",
  },
  {
    selector: '[tourselector="admin-term-policy"]',
    content: "Update your term of policy from below given input fields",
  },
  {
    selector: '[tourselector="admin-term-btn"]',
    content: "Click here to publish your term of use and policy",
  },
];
