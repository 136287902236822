import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  postUsersSignInAction,
  postresendMailVerificationAction,
  successPostResetPasswordAction,
} from "../../Redux_saga/postPublisherSlice";
import MainImg from "../../assests/images/main-img.png";
import PatterBottom from "../../assests/images/patten-btm.png";
import PatterTop from "../../assests/images/patten-top.png";
import HookForm from "../../components/HookForm/HookForm";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import Label from "../../components/UI/Label/Label";
import TextField from "../../components/UI/TextField/TextField";
import { useAppData } from "../../services/hooks";
import Constant from "../../util/constant";
import Message from "../../util/message";
import { guideTourObj, localStorageSetItem } from "../GuideTour/GuideTourJSON";
import GuidedTour from "../GuideTour/GuidedTour";
import { LoginSteps } from "../Steps";
import "./Login.scss";
import PropTypes from "prop-types";

const loginForm = {
  email: {
    name: "emailId",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.EMAILEMPTY,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ERRORMESSAGE.EMAILINVALID,
      },
    },
  },
  password: {
    name: "password",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.PASSWORDEMPTY,
      },
    },
  },
};
function Login() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [mail, setMail] = useState("");
  const [form, setLoginForm] = React.useState();
  const [verifyMail, setVerifyMail] = useState("");
  const [isOpenTourGuide, setIsOpenTourGuide] = useState();
  // console.log(form);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [showPassword, setShowPassword] = useState(true);
  const loggedInUser = useAppData((r) => r.postPublisherSlice.user);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      history.push("./dashboard");
    }
  }, [loggedInUser]);

  const resendMailVerification = () => {
    const resendMailVerificationData = {
      emailId: verifyMail,
    };
    dispatch(postresendMailVerificationAction(resendMailVerificationData));
  };

  useEffect(() => {
    dispatch(successPostResetPasswordAction([]));
  }, []);

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const onFormSubmit = (form) => {
    // console.log("Login clicked1111",form);
    dispatch(postUsersSignInAction(form));

    setVerifyMail(form.emailId);
    const Admintoken = localStorage.getItem("Admintoken");
    if (Admintoken) {
      localStorage.clear("Admintoken");
    }
  };

  let search = window.location.search;
  let params = search.split("?")[1];

  const handleOnRequestClose = () => {
    let newObj = { ...guideTourObj, login: false };
    localStorage.setItem("guideTourObj", JSON.stringify(newObj));

    setIsOpenTourGuide(newObj);
  };

  React.useEffect(() => {
    if (isOpenTourGuide != undefined) {
      localStorageSetItem(isOpenTourGuide);
    }
  }, [isOpenTourGuide]);
  let guideTourGetItemValue = JSON.parse(localStorage.getItem("guideTourObj"));

  return (
    <div className="Login_Page" style={{ height: dimensions.height + "px" }}>
      <img
        src={PatterTop}
        alt="image"
        style={{ maxWidth: "100%", height: "auto" }}
        // fluid="true"
        className="top_Patten"
      />
      <img
        src={PatterBottom}
        alt="image"
        style={{ maxWidth: "100%", height: "auto" }}
        // fluid="true"
        className="bottom_Patten"
      />
      <Scrollbars className="scroll_Section">
        <GuidedTour
          steps={LoginSteps}
          onRequestClose={handleOnRequestClose}
          isOpen={guideTourGetItemValue ? guideTourGetItemValue.login : true}
        />
        <div className="center_Section">
          <div className="chatboat-section position-absolute">
            <i className="icon-chatboat"></i>
          </div>

          <div className="img_Section">
            <img
              src={MainImg}
              alt="image"
              style={{ maxWidth: "100%", height: "auto" }}
              // fluid="true"
              className="main_Img"
            />
          </div>
          <div className="Login_Box" tourselector="login-form">
            <Label title={t("LOGIN_INPUTBOX_GLAD")}></Label>
            <Label
              className="inner_Text"
              title={t("LOGIN_INPUTBOX_POSTING")}
            ></Label>
            <div className="link_Section">
              <Label
                className="form_Name"
                title={t("LOGIN_INPUTBOX_HAVE")}
              ></Label>
              <Link className="link_Css" to="/signup">
                {t("LOGIN_INPUTBOX_SIGNUP")}
              </Link>
            </div>
            <HookForm
              defaultForm={{
                emailId: params === undefined ? "" : params,
                password: "",
              }}
              init={(form) => setLoginForm(form)}
              onSubmit={onFormSubmit}
            >
              {(formMethod) => {
                return (
                  <div className="form1">
                    <TextField
                      controlId={"email1"}
                      formMethod={formMethod}
                      rules={loginForm.email.validate}
                      name={loginForm.email.name}
                      errors={formMethod?.formState.errors}
                      autoFocus={true}
                      type="text"
                      placeholder="Email Id*"
                      iconClass="icon-mail"
                      // tourselector="email-id"
                      onChange={(e) => setMail(e.target.value)}
                      defaultValue={formMethod?.getValues("emailId")}
                    />
                    <TextField
                      controlId={"password1"}
                      formMethod={formMethod}
                      rules={loginForm.password.validate}
                      name={loginForm.password.name}
                      errors={formMethod?.formState.errors}
                      placeholder="6+ Character & 1 Capital letter"
                      // tourselector="password"
                      type={showPassword === true ? "password" : "text"}
                      iconClass={
                        showPassword === true
                          ? "icon-hide1 cursor"
                          : "icon-eye1 cursor"
                      }
                      onIconClick={() => setShowPassword(!showPassword)}
                    />

                    {loggedInUser &&
                    !loggedInUser.emailVerified ? null : params ===
                      undefined ? (
                      <Link to={`/forgot?${mail}`}>
                        <Label
                          className="forgot"
                          title={t("LOGIN_INPUTBOX_FORGOT")}
                        ></Label>
                      </Link>
                    ) : (
                      <Link to={`/forgot?${params}`}>
                        <Label
                          className="forgot"
                          title={t("LOGIN_INPUTBOX_FORGOT")}
                        ></Label>
                      </Link>
                    )}

                    {loggedInUser ? null : (
                      <CustomButton
                        title="Login"
                        // tourSelector="login-button"
                      ></CustomButton>
                    )}
                  </div>
                );
              }}
            </HookForm>

            {loggedInUser && !loggedInUser.emailVerified ? (
              <CustomButton
                title="Verify Mail"
                onClick={resendMailVerification}
                tourselector="login-button"
              ></CustomButton>
            ) : null}
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}

Login.propTypes = {
  tourselector: PropTypes.string.isRequired, // Adjust the prop type according to your use case
};

export default withRouter(Login);
