import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Draft from "../Draft/Draft";
import Failed from "../Failed/Failed";
import Publish from "../Publish/Publish";
import Published from "../Published/Published";
import Schedule from "../Schedule/Schedule";
import "./PublishAll.scss";
// import { useAppData } from "../../services/hooks";
// import { fetchGroupAction } from "../../Redux_saga/postPublisherSlice";
// import { useDispatch } from "react-redux";

function PublisherAll() {
  let search = window.location.search;
  let params = search.split("?")[1];
  // const dispatch = useDispatch();

  // const User = useAppData((r) => r.postPublisherSlice?.user);
  // const Token = useAppData((r) => r.postPublisherSlice?.tokens);


  // useEffect(() => {
  //   console.log('user, token ==>', User, Token)
  //   if (User && Token) {
  //     const data = {
  //       user: User?._id,
  //       token: Token?.access?.token,
  //     };

  //     dispatch(fetchGroupAction(data));
  //   }
  // }, []);


  return (
    <div className="mainPublisher">
      <Header></Header>
      {params === "publish" ? (
        <div className="publisherAll_Page">
          <Publish />{" "}
        </div>
      ) : params === "schedule" ? (
        <div className="publisherAll_Page">
          <Schedule />{" "}
        </div>
      ) : params === "published" ? (
        <div className="publisherAll_Page">
          <Published />{" "}
        </div>
      ) : params === "failed" ? (
        <div className="publisherAll_Page">
          <Failed />{" "}
        </div>
      ) : params === "draft" ? (
        <div className="publisherAll_Page">
          <Draft />{" "}
        </div>
      ) : (
        <div className="publisherAll_Page">
          <Publish />{" "}
        </div>
      )}
    </div>
  );
}

export default PublisherAll;
