const Message = {
  ERRORMESSAGE: {
    EMAILEMPTY: "Email ID cannot be empty",
    CONTACTEMPTY: "Contact number cannot be empty",
    EMAILINVALID: "Are you sure you entered the valid email id?",
    PASSWORDEMPTY: "Password cannot be empty",
    FIRSTNAMEEMPTY: "First name cannot be empty.",
    SUBJECTEMPTY: "Subject cannot be empty.",
    DESPEMPTY: "Subject cannot be empty.",
    DISPUTEEMPTY: "Dispute reason can’t be empty.",
    TITLEEMPTY: "Title cannot be empty.",
    AMOUNTEMPTY: "Amount cannot be empty.",
    AMOUNTVALID:
      'This field can only contain "Numeric” values, “Decimal” value and “Decimal symbol" Please try again.',
    JOBTITLEEEMPTY: "Job title cannot be empty.",
    JOBDESPEEMPTY: "Job description can’t be empty.",
    JOBFOREMPTY: "Job for can’t be empty.",
    MILESTONEEMPTY: "Milestone  can’t be empty.",
    NATIONALITYEMPTY: "Nationality cannot be empty.",
    ORGANISATIONEMPTY: "Organisation name cannot be empty.",
    BUSINESSNAMEEMPTY: "Bussiness name cannot be empty.",
    LASTNAMEEMPTY: "Last name cannot be empty.",
    ALFANUMINVALID:
      'This field can only contain "ALPHANUMERIC,  Dot, Coma, and Hyphen" values.',
    ALFAVALID: "Allow only alphabetic value",
    ONLYALFAVALID:
      'This field can only contain "Alphanumeric" values. Please try again. ',
    NEWPASSOWRD: "New password cannot be empty.",
    CONFIRMPASSWORD: "Confirm password cannot be empty.",
    CONFIRMINVALID: "Both passwords must match.",
    MAXEMPTY: "Maximum value can’t be empty",
    MINEMPTY: "Minimum value can’t be empty",
    MININVALID: "Only contain numeric values",
    MAXINVALID: "Only contain numeric values",
    DESCRIPTIONEMPTY: "Description cannot be empty.",
    ALPHABETSONLY:"Inputs should only contain alphabets",
    CONTACTINVALID:"Contact is invalid"
  },
};

export default Message;
