import React, { useState } from "react";
import Label from "../UI/Label/Label";
import CheckBox from "../UI/CheckBox/CheckBox";
import { Link } from "react-router-dom";
import FilterDropdown from "../UI/FilterDropdown/FilterDropdown";

import "./TabListing.scss";

const TabListing = (props) => {
  let {
    viwePost,
    postingDateTime,
    userName,
    accType,
    statusTitle,
    ProfileImg,
    id,
    Labeltitle,
    DatePick,
    icon,
    editIcon,
    onClickEdit,
    onProfileClick,
    onCheckedChange1,
    checked,
    imgHeight = 32,
    imgWidth = 32,
    onPlayPauseClick,
    isPaused,
    isConnected,
    onDisconnectClick,
  } = props;
  const [showDropdown, setShowDropdown] = useState(false);

  const onCheckedChange = (e, id) => {
    setChecked(e.target.checked);
    // onCheckedChange1(e.target.checked, id);
  };

  const handlePlayPauseClick = () => {
    console.log('isPaused handlePlayPauseClick==>', isPaused)
    onPlayPauseClick && onPlayPauseClick(!isPaused);
  };
  const handleDisconnectClick = () => {
    // setShowDropdown(!showDropdown);
    onDisconnectClick && onDisconnectClick();
  };

  return (
    <div className="tab_Listing">
      <div className="listing_Head profile" onClick={onProfileClick}>
        <img
          src={ProfileImg}
          style={{ height: imgHeight + "px", width: imgWidth + "px" }}
        ></img>
      </div>

      {userName 
      ? (
        <Label className="listing_Head userName" title={userName}></Label>
      ): postingDateTime && (
        <Label className="listing_Head userName" title={postingDateTime}></Label>
      )}
      {accType && (
        <Label className="listing_Head accType" title={accType}></Label>
      )}
      {statusTitle && (
        <Label className="listing_Head status" title={statusTitle}></Label>
      )}
      {DatePick && (
        <Label className="listing_Head date" title={DatePick}></Label>
      )}
      {viwePost && (
        <Label className="listing_Head viwePost" title={viwePost}></Label>
      )}
      {/* {Labeltitle && (
        <CheckBox
          id={id}
          checked={checked}
          onCheckedChange={onCheckedChange1}
          labelTitle=""
        ></CheckBox>
      )} */}

<button type="button" class="custom-button" data-toggle="tooltip" data-placement="top" title={statusTitle === "Disconnected" || statusTitle === "Connected - Paused"? `User is ${statusTitle}, try re-connecting your account`:`User is ${statusTitle}`}>
{Labeltitle && (
        <CheckBox
          id={id}
          checked={checked}
          onCheckedChange={onCheckedChange1}
          labelTitle=""
        ></CheckBox>
      )}
</button>
      {icon && (
        <div>
          {/* // className="bgColor" */}
          {/* <i className="listing_Head icon-rotate " icon=""></i> */}
          {isConnected && (
            <i
              className={isPaused ? "icon-play" : "icon-pause"}
              title={isPaused ? "Resume Group" : "Pause Group"}
              style={{ cursor: "pointer" }}
              onClick={handlePlayPauseClick}
            ></i>
          )}

          {isConnected && (
            <i
              className="icon-delete"
              title="Delete Group"
              style={{ cursor: "pointer" }}
              onClick={handleDisconnectClick}
            ></i>
          )}
        </div>
      )}
      {/* {icon && <i className="listing_Head icon-editr1 pr-0" icon=""></i>} */}
      {editIcon && (
        <div className="edit_Dropdown">
          {/* <i
            className="icon-editr1"
            onClick={() => setShowDropdown(!showDropdown)}
          ></i> */}
          {isConnected && (
            <i
              className="icon-delete"
              title="Delete Group"
              style={{ cursor: "pointer" }}
              onClick={handleDisconnectClick}
            ></i>
          )}
        </div>
      )}
      {/* {showDropdown && (
        <>
          <div className="overLay" onClick={() => setShowDropdown(false)}></div>
          <FilterDropdown>
            <ul className="dropdown_List">
              <li>
                <Link onClick={onClickEdit} to="#">
                  Edit post
                </Link>
              </li>
              <li>
                <Link to="#">Delete post</Link>
              </li>
            </ul>
          </FilterDropdown>
        </>
      )} */}
    </div>
  );
};

export default TabListing;
