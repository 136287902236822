import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import CustomCalendar from "../../components/UI/CustomCalendar/CustomCalendar";
import FilterDropdown from "../../components/UI/FilterDropdown/FilterDropdown";
import Label from "../../components/UI/Label/Label";

import "./ExportFilterForm.scss";

// const itemsStatus = [
//   {
//     id: '1',
//     value: 'Stop',
//   },
//   {
//     id: '2',
//     value: 'Pause',
//   },
//   {
//     id: '3',
//     value: 'Play',
//   },
// ];

const ExportFilterForm = (props) => {
  const { title, onclose } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // const onFormSubmit = () => {};
  // const YearCustomInput = ({ value, onClick }) => (
  //   <button type="button" className="custom-input" onClick={onClick}>
  //     {value ? value : '2020'} <span className="joyclub-arrow-down"></span>
  //   </button>
  // );

  // const MonthCustomInput = ({ value, onClick }) => (
  //   <button
  //     type="button"
  //     className="custom-input monthcustom"
  //     onClick={onClick}
  //   >
  //     {value ? value : 'MONTH'} <span className="joyclub-arrow-down"></span>
  //   </button>
  // );
  return (
    <>
      <FilterDropdown className="export_Form">
        <div className="filterDispute">
          <div className="form">
            <div className="filterItem">
              <div className="header_Section">
                <div className="heading">
                  <Label title={title}></Label>
                  <i className="icon-close" onClick={onclose}></i>
                </div>
              </div>
              <div className="calender_Section">
                <div className="date_Filter">
                  <Label
                    className="date_Title"
                    title="Reoccuring pattern"
                  ></Label>
                </div>
                <div className="date_Part">
                  <div className="calenderMain">
                    <CustomCalendar
                      selected={startDate}
                      onChange={(e) => setStartDate(e)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Date"
                      renderCustomHeader={true}
                    ></CustomCalendar>
                  </div>
                  <div className="calenderMain enddate">
                    <CustomCalendar
                      selected={endDate}
                      onChange={(e) => setEndDate(e)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Date"
                      renderCustomHeader={true}
                    ></CustomCalendar>
                  </div>
                </div>
              </div>
            </div>
            <div className="custombtnfield d-flex justify-content-center">
              <CustomButton type="submit" title="Cancel" />
              <CustomButton type="submit" title="Download" />
            </div>
          </div>
        </div>
      </FilterDropdown>
    </>
  );
};

export default ExportFilterForm;
