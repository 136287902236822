import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import CustomCalendar from "../../../components/UI/CustomCalendar/CustomCalendar";
import CustomDropdown from "../../../components/UI/CustomDropdown/CustomDropdown";
import FilterDropdown from "../../../components/UI/FilterDropdown/FilterDropdown";
import Label from "../../../components/UI/Label/Label";

import "./FilterForm.scss";

const typeItemsDropDown = [
  {
    id: "1",
    value: "Sports",
  },
  {
    id: "2",
    value: "Social Media",
  },
  {
    id: "3",
    value: "Games",
  },
];

const statusItemsDropDown = [
  {
    id: "4",
    value: "Published",
  },
  {
    id: "5",
    value: "Publish",
  },
  {
    id: "6",
    value: "Now",
  },
  {
    id: "7",
    value: "Draft",
  },
  {
    id: "8",
    value: "Spam",
  },
  {
    id: "9",
    value: "Relevance",
  },
];
const FilterForm = (props) => {
  const { title, onClickIcon } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [staff, setSelectedStaff] = useState();
  const [selectStatusDropDown, setSelectStatusDropDown] = useState();

  return (
    <>
      <FilterDropdown>
        <div className="filterDispute">
          <div className="form">
            <div className="filterItem">
              <div className="header_Section">
                <div className="heading">
                  <Label title={title}></Label>
                  <i className="icon-close" onClick={onClickIcon}></i>
                </div>
              </div>
              <div className="calender_Section">
                <div className="date_Filter">
                  <Label className="date_Title" title="Filter by date"></Label>
                  <Link className="filter_Title" to="#">
                    Clear all filter
                  </Link>
                </div>
                <div className="calenderMain">
                  <CustomCalendar
                    selected={startDate}
                    onChange={(e) => setStartDate(e)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Date"
                    renderCustomHeader={true}
                  ></CustomCalendar>
                </div>
                <div className="calenderMain">
                  <CustomCalendar
                    selected={endDate}
                    onChange={(e) => setEndDate(e)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Date"
                    renderCustomHeader={true}
                  ></CustomCalendar>
                </div>
              </div>
              <div className="dropdown_Section">
                <Label className="filter_Title" title="Filter by Type"></Label>
                <div className="filter_dropdown_byType">
                  <CustomDropdown
                    dropDownItems={typeItemsDropDown}
                    placeholder="Select Type"
                    selectedValue={staff}
                    onSelect={(selected) => setSelectedStaff(selected)}
                  ></CustomDropdown>
                </div>
              </div>
              <div className="dropdown_Section">
                <Label
                  className="filter_Title"
                  title="Filter by Status"
                ></Label>
                <div className="filter_dropdown_byType">
                  <CustomDropdown
                    dropDownItems={statusItemsDropDown}
                    placeholder="Select status"
                    selectedValue={selectStatusDropDown}
                    onSelect={(selected) => setSelectStatusDropDown(selected)}
                  ></CustomDropdown>
                </div>
              </div>
            </div>
            <div className="custombtnfield">
              <CustomButton type="submit" title="Apply" />
              <CustomButton type="submit" title="Clear" />
            </div>
          </div>
        </div>
      </FilterDropdown>
    </>
  );
};

export default FilterForm;
