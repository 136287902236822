import React from "react";
import Label from "../UI/Label/Label";
import "./GroupHeader.scss";

const GroupHeader = () => {
  return (
    <div className="group_Heading">
      <Label className="heading profile" title=""></Label>
      <Label className="heading userName" title="Title"></Label>
      <Label className="heading Description" title="Description"></Label>
      <Label className="heading Status" title="Status"></Label>
      <Label className="heading CreatedDate" title="Created Date"></Label>
    </div>
  );
};

export default GroupHeader;
